(function() {
    /**
     * Decimal adjustment of a number.
     *
     * @param {String}  type  The type of adjustment.
     * @param {Number}  value The number.
     * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
     * @returns {Number} The adjusted value.
     */
    function decimalAdjust(type, value, exp) {
      // If the exp is undefined or zero...
      if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
      }
      value = +value;
      exp = +exp;
      // If the value is not a number or the exp is not an integer...
      if (Number.isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
      }
      // Shift
      value = value.toString().split('e');
      value = Math[type](+(`${value[0]  }e${  value[1] ? (+value[1] - exp) : -exp}`));
      // Shift back
      value = value.toString().split('e');
      return +(`${value[0]  }e${  value[1] ? (+value[1] + exp) : exp}`);
    }

    // Decimal round
    if (!Math.round10) {
      Math.round10 = function(value, exp) {
        return decimalAdjust('round', value, exp);
      };
    }
    // Decimal floor
    if (!Math.floor10) {
      Math.floor10 = function(value, exp) {
        return decimalAdjust('floor', value, exp);
      };
    }
    // Decimal ceil
    if (!Math.ceil10) {
      Math.ceil10 = function(value, exp) {
        return decimalAdjust('ceil', value, exp);
      };
    }
  })();

export function GetFraction(val, decPlaceUser) {
    const decPlace = Number.isInteger(Number(decPlaceUser)) ? decPlaceUser : 2;
    let value = val.substr(0, decPlace);

    const maxCount = decPlace - value.length;

    for (let count = 0; count < maxCount; count++) {
        value = value.concat("0");
    }

    value = value.concat("/1");

    for (let i = 0; i < decPlace; i++) {
        value = value.concat("0");
    }

    return value;
}


export function GetTensAndOnes(val) {
    const onesStep = ["One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const tensStep = ["Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const teensStep = ["Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    let words = "";

    if (val >= 100) {
        val %= 100;
    }

    if (val === 0) {
        return "";
    }

    if (val < 10) {
        words = onesStep[val - 1];
    } else if ((val > 10) && (val < 20)) {
        words = teensStep[val - 11];
    } else {
        const a = Math.floor(val / 10);
        words = tensStep[a - 1];
        if (val % 10 > 0) {
            words = `${words  }-${  onesStep[(val % 10) - 1]}`;
        }
    }
    return words;
}


export function GetHundreds(val) {
    const onesStep = ["One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    let word = "";

    if (val >= 100) {
        const a = Math.floor(val / 100);
        word = `${onesStep[a - 1]  } Hundred`;
    }
    return word;
}

export function getNumberStr(balance) {
    const kSteps = ["Thousand", "Million", "Billion", "Trillion", ""];
    let strSz = 0;
    let kPart;
    let k = 0;
    let retVal = '';
    while (balance.length > 0) {
        if (balance.length >= 3) {
            strSz = 3;
        } else {
            strSz = balance.length;
        }

        kPart = balance.substr(balance.length - strSz, strSz);
        balance = balance.substr(0, balance.length - strSz);


        if (Number(kPart) !== 0) {
            if (k > 0) {
                retVal = ` ${kSteps[k - 1]} ${retVal}`;
            }

            retVal = `${GetHundreds(Number(kPart))} ${GetTensAndOnes(Number(kPart))}${retVal}`;
        }
        k += 1;
    }
    return retVal;
}

export function formatAmountWords(currencyCode, value, decPlaceUser) {

    if (value.length === 0) {
        return `********** ${  currencyCode  } Zero Only **********`;
    }

    let strValue = value.toString();

    // Remove digit grouping character "," if there's any.
    const myPattern = /,/g;


    strValue = strValue.replace(myPattern, "");

    let input = Number(strValue);
    if(decPlaceUser){
        input = Math.round10(input, decPlaceUser * -1);
    }

    if (Number.isNaN(input) || input < 0) {
        return "********************";
    }

    if (input === 0 || input === 0.0) {
        return `********** ${  currencyCode  } Zero Only **********`;
    }

    // Get Fraction and integer should not exceed 16 digit
    let retVal = "";
    let balance = "";
    const dPosition = input.toString().indexOf(".", 0);

    if (dPosition > 0) {
        const num = input.toString().split(".");
        if (num[0].toString().length > 16) {
            return "********** EXCEEDED **********";
        }

        balance = num[0].toString();

        if(Number(num[1])>0){
            retVal = ` And ${  GetFraction(num[1].toString(), decPlaceUser)}`;
        }

    } else {
        if (input.toString().length > 16) {
            return "********** EXCEEDED **********";
        }

        balance = input.toString();
    }

    retVal = getNumberStr(balance) + retVal;

    return `********** ${  currencyCode  } ${  retVal  } Only **********`;
}


export function formateAmount(value, decimalScale){
    if(typeof value === 'string' && value.includes(",")){
      value = value.replace(/,/g, "");
    }
    if (Number.isNaN(Number(value))) {
        return null;
    }

    return decimalScale? Number(value).toFixed(decimalScale) : value;
}
