import Grid from '@material-ui/core/Grid';
import ActionBar from "common/components/ActionBar/ActionBar";
import DocInfo from "common/components/DocInfo/DocInfo";
import PageHeader from "common/components/PageHeader/PageHeader";
import Tab from 'common/components/Tab/Tab';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import UserMessage from 'common/components/UserMessage/UserMessage';
import { setCountryList, setMasterDoc, setPreviousPage, showMasterDocMessage } from 'common/redux/actions';
import MasterDocService from 'common/services/masterDoc.service';
import ModuleService from "common/services/module.service";
import React from 'react';
import { connect } from 'react-redux';
import { detectChanges } from 'common/utils/MasterDocUtil';
import { formatAmountWords } from 'common/utils/AmountFormatter';
import DocPrepService from "common/services/doc-prep.service";
import { masterDocDefaultValue } from 'common/utils/DocPrepConstant';
import MasterDocForm from './MasterDocForm';

const _ = require('lodash');

class MasterDocument extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};
      this.linkClick = this.linkClick.bind(this);

      if (!this.props.masterDoc || !this.props.masterDoc.initial) {
        this.props.setMasterDoc({});
        MasterDocService.initMasterDoc().then(res => {
          DocPrepService.performResetDocument(res);
        })
      }


      MasterDocService.getMasterDocCountry().then(res => {
        if (res && res.list) {
          const countryArr = res.list.map(c => {
            return  {label: c.countryName, value: c.countryName}
          })

          this.optCountry = countryArr;

          this.prefillData(res.list);
        }

      })

    }

    getCountry = (countryList, code) => {
      const country = countryList.find(c => c.countryID?.trim().toLowerCase() === code.toLowerCase());
      return country ? country.countryName : code;
    }

    prefillData = async (countryList) =>{
      const dc = this.props.location.state?.dc;
      if(this.props.masterDoc && dc){
        let preloadMD = this.props.masterDoc.values;
        if(!preloadMD.cubicmassdeccat){
          preloadMD = Object.assign(preloadMD, _.cloneDeep(masterDocDefaultValue));
        }

        const exporter = await MasterDocService.getExporterByTradeID(dc.tradeID).then(response => {
            return response;
        });

        preloadMD.exportercode = exporter.exporterCode;
        preloadMD.expname = exporter.expName;
        preloadMD.expaddr1 = exporter.expAddr1;
        preloadMD.expaddr2 = exporter.expAddr2;
        preloadMD.expaddr3 = exporter.expAddr3;
        preloadMD.expcity = exporter.expCity;
        preloadMD.expstate = exporter.expState;
        preloadMD.expcountry = exporter.expCountry;
        preloadMD.exppostcode = exporter.expPostCode;
        preloadMD.exptel = exporter.expTel;
        preloadMD.expfax = exporter.expFax;
        preloadMD.expemail = exporter.expEmail;
        preloadMD.expref = exporter.expRef;
        preloadMD.expregno = exporter.expRegNo;
        preloadMD.expacctno = exporter.expAcctNo;
        preloadMD.expdbacctno = exporter.expDBAcctNo;

        const importer = await MasterDocService.getImporter(dc.applicantName, this.getCountry(countryList, dc.issuanceBank?.issuanceCountry)).then(response => {
          return response;
        });
        preloadMD.importercode = importer.importerCode;
        preloadMD.impname = importer.impName;
        preloadMD.impref = importer.impRef;
        preloadMD.impaddr1 = importer.impAddr1;
        preloadMD.impaddr2 = importer.impAddr2;
        preloadMD.impaddr3 = importer.impAddr3;
        preloadMD.impcity = importer.impCity;
        preloadMD.impstate = importer.impState;
        preloadMD.impcountry = importer.impCountry;
        preloadMD.imppostcode = importer.impPostCode;
        preloadMD.imptel = importer.impTel;
        preloadMD.impfax = importer.impFax;
        preloadMD.impemail = importer.impEmail;

        const cbid = dc.issuanceBank?.branchCode + dc.issuanceBank?.bankSwiftCode;
        let importerBank = await MasterDocService.getImporterBank(cbid, dc.issuanceBank?.bankName, this.getCountry(countryList, dc.issuanceBank?.issuanceCountry))
        .then(response => {
          return response;
        });
        if(!importerBank.bankname && importer.def_Bank){
          importerBank = await MasterDocService.getMasterDocBankDetail(importer.def_Bank).then(response => {
            return response;
          });
        }
        preloadMD.bankname = importerBank.bankname;
        preloadMD.bankbranch = importerBank.bankbranch;
        preloadMD.bankref = importerBank.bankref;
        preloadMD.banktradedept = importerBank.banktradedept;
        preloadMD.bankaddr1 = importerBank.bankaddr1;
        preloadMD.bankaddr2 = importerBank.bankaddr2;
        preloadMD.bankaddr3 = importerBank.bankaddr3;
        preloadMD.bankcity = importerBank.bankcity;
        preloadMD.bankstate = importerBank.bankstate;
        preloadMD.bankcountry = importerBank.bankcountry;
        preloadMD.bankpostcode = importerBank.bankpostcode;

        if(importer.def_Consignee){
          const consignee = await MasterDocService.getMasterDocImpDetailsByCode(importer.def_Consignee).then(response => {
            return response;
          });
          preloadMD.consigneecode = consignee.importerCode;
          preloadMD.consigneename = consignee.impName;
          preloadMD.consigneeaddr1 = consignee.impAddr1;
          preloadMD.consigneeaddr2 = consignee.impAddr2;
          preloadMD.consigneeaddr3 = consignee.impAddr3;
          preloadMD.consigneecity = consignee.impCity;
          preloadMD.consigneestate = consignee.impState;
          preloadMD.consigneecountry = consignee.impCountry;
          preloadMD.consigneepostcode = consignee.impPostCode;
        }

        preloadMD.incotermid = importer.def_Incoterm;
        preloadMD.portdischarge = importer.def_PortDischarge;
        preloadMD.portLoading = importer.def_PortLoad;

        preloadMD.dcno = dc.dcNumber;
        preloadMD.dcdate = dc.issuanceDate;

        preloadMD.tenorid = dc.tenor;
        if(!dc.tenor && importer.def_Tenor){
          preloadMD.tenorid = importer.def_Tenor;
        }
        preloadMD.descofgoods = dc.goodsDescription;

        const amount = 0.00;
        const currency = dc.dcAmount?.currency;
        const words = formatAmountWords(currency, amount, 2);
        preloadMD.currencycode = currency;
        preloadMD.invgrandtotal = amount;
        preloadMD.invgrandtotalinwords = words;

        DocPrepService.performResetDocument(preloadMD);
      }
    };

    handleInputValue = (name, val) => {
        this.setState({ [name]: val });
    }


    linkClick = (targetUrl) => {

      if (this.props.location.pathname !== targetUrl) {
        detectChanges( ()=>{
          this.props.history.push(targetUrl)
        } );
      } else {
        this.props.history.push(targetUrl);
      }
    }

    closeMessage = () => {
      this.props.showMasterDocMessage({isShowMessage: false, masterDocMessage: ''});
    }


    render(){
        const tabs = ModuleService.getTabs(this.props.module);
        return (
          <div>
            <PageHeader withLeftArrow title={this.props.title} history={this.props.history} />
            <div className="mainContent">
              <Tab tabs={tabs} click={this.linkClick} />
              <Grid container spacing={0}>
                {this.props.isShowMessage &&
                  <Grid item xs={12} style={{paddingTop: '20px'}}>
                    {!this.props.messageType &&
                    <SuccessMessage message={this.props.masterDocMessage} handleCloseButtonClick={this.closeMessage} />}

                    {this.props.messageType === 'ERROR' &&
                    <UserMessage
                      showCloseButton
                      border
                      type="userError"
                      iconName="triangle-error"
                      iconColor="ERROR"
                      handleCloseButtonClick={this.closeMessage}
                      closeText="Close"
                    >
                      {this.props.masterDocMessage}
                    </UserMessage>}

                  </Grid>}
                <Grid item xs={12}>
                  <DocInfo />
                </Grid>
              </Grid>
              <MasterDocForm tabs={tabs} optCountry={this.optCountry} />


            </div>
            <ActionBar formData={this.state} history={this.props.history} handleInputValue={this.props.handleInputValue} />

          </div>


        )
    }
}


const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc,
  isShowMessage: state.masterDocAction?.isShowMessage,
  masterDocMessage: state.masterDocAction?.masterDocMessage,
  messageType: state.masterDocAction.messageType,
})

const mapDispatchToProps = dispatch => {
  return {
    setMasterDoc: (data) => dispatch(setMasterDoc(data)),
    setCountryList: (data) => dispatch(setCountryList(data)),
    showMasterDocMessage: (data) => dispatch(showMasterDocMessage(data)),
    setPreviousPage: (payload) => dispatch(setPreviousPage(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterDocument)
