import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import axios from "axios";
import PrivateRoute from "common/components/PrivateRoute/PrivateRoute";
import SessionTimeout from "common/components/Timeout/SessionTimeout";
import UserPage from "common/components/UserPage/UserPage";
import { changeLoadingState } from 'common/redux/actions';
import store from 'common/redux/store';
import history from 'common/services/history';
import LoginPage from "common/views/LoginPage/LoginPage";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { Route, Router, Switch } from 'react-router-dom';
import CookieConsent from 'common/views/Popup/CookieConsent';
import Message from 'common/views/Popup/Message';
import SaveAsDetectChange from 'common/views/Popup/SaveAsDetectChange';
import LogoutPage from "./common/views/LogoutPage/LogoutPage";
import PwdPage from './common/views/PwdPage/PwdPage';
import './index.css';


ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <>
        <SessionTimeout history={history} />
        <CookieConsent />
        <Switch>
          <Route path="/Login" component={LoginPage} />
          <Route path="/Logout" component={LogoutPage} />
          <Route path="/ForceChangePWD" component={PwdPage} />
          <PrivateRoute path="/ChangePWD" component={PwdPage} />
          <PrivateRoute path="/" component={UserPage} />
        </Switch>
        <Message />
        <SaveAsDetectChange />
      </>
    </Router>
  </Provider>,
  document.getElementById('root')
);


const DEBUG = process.env.NODE_ENV === "development";

axios.interceptors.request.use((config) => {
  if (config.showLoading) {
    store.dispatch(changeLoadingState(true));
  }
  return config;
}, (error) => {
  if (DEBUG) {
    console.error("✉️ ", error);
  }
  setTimeout(() => {
    store.dispatch(changeLoadingState(false));
  }, 1000);
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {

  if (response.config.showLoading) {
    store.dispatch(changeLoadingState(false));
  }
  return response;
}, (error) => {

  console.log('failed at response');
  setTimeout(() => {
    store.dispatch(changeLoadingState(false));
  }, 1000);
  return Promise.reject(error);
});
