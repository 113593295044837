import BaseService from 'common/services/BaseService';
import UserService from 'common/services/user.service';

class ProductService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/ProductBO/';
  }


  // eslint-disable-next-line class-methods-use-this
  getExtraPropsFromUserDTO() {
    const currentUser = UserService.getCurrentUser();
    return {
      "codocexpressid": currentUser.codocexpressid,
      "countrycode": currentUser.countrycode,
      "groupmemberid": currentUser.groupmemberid,
    }
  }

  // eslint-disable-next-line class-methods-use-this
  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  addProduct(productDTO) {
    const userDTO = this.getUserDTO();
    const extraPropsFromUserDTO = this.getExtraPropsFromUserDTO();
    const requestBody = {
      "product": {
        ...extraPropsFromUserDTO,
        ...productDTO
      },
      ...userDTO
    }
    return this.post('addProduct', requestBody, true);
  }

  deleteProduct(productDTO) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      "exportercode": productDTO?.[0],
      "productcode": productDTO?.[1],
      ...userDTO
    }
    return this.post('deleteProduct', requestBody, true);
  }

  getDrawerName(productDTO) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      "drawerid": productDTO,
      ...userDTO
    }
    return this.post('getDrawerName', requestBody, false);
  }

  getProduct(productDTO) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      "exportercode": productDTO?.[0],
      "productcode": productDTO?.[1],
      ...userDTO
    }
    return this.post('getProduct', requestBody, true);
  }

  initProduct() {
    return this.post('initProduct', this.getUserDTO(), false);
  }

  loadProduct() {
    const useDTO = this.getUserDTO();
    const requestBody = {
      ...useDTO
    }
    return this.post('loadProduct', requestBody, true);
  }

  saveProduct(productDTO) {
    const userDTO = this.getUserDTO();

    const extraPropsFromUserDTO = this.getExtraPropsFromUserDTO();
    const requestBody = {
      "product": {
        ...extraPropsFromUserDTO,
        ...productDTO,
      },
      ...userDTO
    }
    return this.post('saveProduct', requestBody, true);
  }

}

export default new ProductService();
