import BaseService from 'common/services/BaseService';

class PortService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/PortsBO/';
  }

  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  addPorts(portDTO) {
    const inUserDTO = this.getInUserDTO();
    const requestBody = {
      ...inUserDTO,
      ports: {...portDTO}
    }
    return this.axiosPost(`${this.basePath}addPorts`, requestBody, true);
  }

  deletePorts(portDTO) {
    const inUserDTO = this.getInUserDTO();
    delete portDTO.actionBtn;
    const requestBody = {
      ...inUserDTO,
      ports: {...portDTO}
    }
    return this.post('deletePorts', requestBody, true);
  }

  loadPorts() {
    return this.post('loadPorts', this.getInUserDTO(), true);
  }

  updatePorts(portDTO) {
    const inUserDTO = this.getInUserDTO();
    delete portDTO.actionBtn;
    const requestBody = {
      ...inUserDTO,
      ports: {...portDTO}
    }
    return this.axiosPost(`${this.basePath}updatePorts`, requestBody, true);
  }

}

export default new PortService();
