import CDLDatePicker from '@cdl/date-picker';
import { DATE_FORMAT } from "common/utils/DateUtil";
import React from 'react';
import styled from "styled-components";

const moment = require('moment');


const RangeContainer = styled.div`
    position: relative;
    background: white;
    width: 100%;
    height: 100%;
    border: 1px solid #929292;

    & div[type="single"] {
      width: calc(100% - 5px);
      border: none;
      margin-bottom: inherit;

      &>input {
        font-size: 14px;
        background: transparent;
      }

      &>input:focus {
        outline: none;
      }

      &>div[role="button"] {
        position: absolute;
        transform: translateY(-50%);
        right: 0;
        padding: 12px;
      }
    }

    & div#default-datepicker-wrapper > div:last-child:not(:first-child) {
      margin-top: 7px;
    }

    & .displayLabel {
      margin-top: 5px;
    }

    & .displayDate {
      color: black;
      font-weight: normal;
    }

    & div>.displayContent {
      padding-top: initial;
      padding-bottom: initial;
    }

    & button {
      height: 80%;
      border-left: thin solid #929292;

      & svg {
        width: 20px;
        height: 20px;
      }
    }
    
    & > div > div{
      height: 55px;
      border: none; 
    }

    & > div > div[role="button"] {
      width: 100%;
    }

    #calendarWrapper {
      background-color: white;
    }
    
    & #datepicker-wrapper{
      display: block;
      & > div:first-of-type{
        width: auto;
      }

    }

    & .clearBtn {
      border: none;
      cursor: pointer;
      background: transparent;
      position: absolute;
      right: 40px;
      top: -7px;
    }

`;


export default class DateRange extends React.Component {

  constructor(props) {
    super(props);
    this.state ={
      defaultDate: (props.value && props.value !== '') ? moment(props.value, DATE_FORMAT).toDate() : props.defaultDate
    };
  }


  static getDerivedStateFromProps(props, state) {
    // eslint-disable-next-line react/prop-types
    if (!props.value || props.value === '') {
      return {
        defaultDate: {from: null}
      }
    }
    if (typeof props.value === 'string') {
      return {
        defaultDate: {from: moment(props.value, DATE_FORMAT).toDate()}
      }
    }
    if (props.value && state.defaultDate &&
      moment(state.defaultDate, DATE_FORMAT).toDate().getTime() !== moment(props.value, DATE_FORMAT).toDate().getTime()) {
        return {
            // eslint-disable-next-line react/prop-types
            defaultDate:   props.value
        };
    }
    return null;
}

  dateSelected = (event) => {
    if (this.props.onChange) {
      if (this.props.type === 'quickRange') {
        this.props.onChange(event);
      } else if (this.props.dateFormat) {
          this.props.onChange(moment(event.from).format(this.props.dateFormat));
        } else {
          this.props.onChange(event.from);
        }
    }
  }

  reset = () => {
    if (this.props.onDateSelected) {
      this.props.onDateSelected({});
    }
  }


  render() {

      const {seamless, invalid, width, label, displayFormat, inputFormat, invalidMessage, disabled, hidden, alignPwd, ...rest} = this.props;
      const disabledLayer = disabled ? (<div style={{cursor: 'not-allowed' ,width: '100%', height: '32px', position: 'absolute', top: 0}} />) : null;
      const className = seamless ? 'seamless' : 'date-picker-wrapper';

    return (
      <>
        <RangeContainer disabled={disabled} invalid={this.props.invalid} className={className} alignPwd={alignPwd}>
          <CDLDatePicker
            onDateSelected={this.dateSelected}
            defaultDate={this.state.defaultDate}
            displayFormat={displayFormat || DATE_FORMAT}
            inputFormat={inputFormat || DATE_FORMAT}
            hidden={this.props.hidden}
            {...rest}
          />
          <button title="Clear" type="button" onClick={this.reset} className="clearBtn">
            <svg width="22" height="22" viewBox="0 0 32 32">
              <path
                fill="#333"
                d="M25.292 9.707l-2.655-2.655-6.637 6.638-6.638-6.638-2.655 2.655 6.638 6.637-6.637 6.637 2.655 2.655 6.638-6.638 6.637 6.638 2.655-2.655-6.637-6.637z"
              />
            </svg>
          </button>
        </RangeContainer>
        {disabledLayer}
      </>
    );

  }
}

