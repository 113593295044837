import Grid from '@material-ui/core/Grid';
import renderInput from 'common/components/ReduxForm/RenderInput';
import renderDropdown from 'common/components/ReduxForm/RenderDropdown';
import { updateMasterDocByFieldArray } from 'common/redux/actions';
import { getCountry } from 'common/utils/OptionUtil';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import BankTable from './BankTable';


class DraweeAndReimbursementBankSection extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};
      this.countryList = getCountry();

    }

    renderInputField = (isEDS, label, id, name, maxLength, size) => {
      return (
        <Grid item xs={size}>
          <Field
            component={renderInput}
            label={label}
            id={id}
            name={isEDS ? name : name.toLowerCase()}
            maxLength={maxLength}
          />
        </Grid>
      )
    }

    render() {
      const {isEDS} = this.props;
      return (
        <div>
          <Grid container spacing={0} style={{paddingTop:'20px'}}>
            <Grid item xs={12}>
              <BankTable
                id="draweebank"
                updateMasterDocByFieldArray={this.props.updateMasterDocByFieldArray}
                fieldNamePrefix={isEDS ? 'eidto.Drawee' : 'eidto.drawee'}
                isEDS={isEDS}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0} style={{paddingTop:'20px'}}>

            {this.renderInputField(isEDS, "Bank name", "draweebankname", 'eidto.DraweeBankName', 55, 3)}

            {this.renderInputField(isEDS, "Bank branch", "draweebankbranch", 'eidto.DraweeBankBranch', 35, 3)}

            {this.renderInputField(isEDS, "Bank reference", "draweebankref", 'eidto.DraweeBankRef', 35, 3)}

            {this.renderInputField(isEDS, "Bank trade department", "draweebanktradedept", 'eidto.DraweeBankTradeDept', 35, 3)}


            {this.renderInputField(isEDS, "Address line 1", "draweebankaddr1", 'eidto.DraweeBankAddr1', 35, 4)}

            {this.renderInputField(isEDS, "Address line 2", "draweebankaddr2", 'eidto.DraweeBankAddr2', 35, 4)}

            {this.renderInputField(isEDS, "Address line 3", "draweebankaddr3", 'eidto.DraweeBankAddr3', 35, 4)}


            {this.renderInputField(isEDS, "City", "draweebankcity", 'eidto.DraweeBankCity', 35, 3)}

            {this.renderInputField(isEDS, "State", "draweebankstate", 'eidto.DraweeBankState', 35, 3)}

            <Grid item xs={3}>
              <Field
                component={renderDropdown}
                labelOutside="Country / Territory"
                id="draweebankcountry"
                name={isEDS ? 'eidto.DraweeBankCountry' : 'eidto.draweebankcountry'}
                options={this.countryList}
              />
            </Grid>

            {this.renderInputField(isEDS, "Postal code", "draweebankpostcode", 'eidto.DraweeBankPostCode', 10, 3)}

          </Grid>


        </div>
      );
    }
}


const mapStateToProps = state => ({
  countryList: state.masterDocAction?.countryList || [],
})

const mapDispatchToProps = dispatch => {
  return {updateMasterDocByFieldArray: (payload) => dispatch(updateMasterDocByFieldArray(payload))}
}

export default connect(mapStateToProps, mapDispatchToProps)(DraweeAndReimbursementBankSection)
