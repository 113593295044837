import React, { useState, useEffect } from 'react';
import { ModalContent, ModalTitle, ModalBtnList, ModalBtn, ModalAction, ModalActionExtraButton, ModalActionButton } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Grid from '@material-ui/core/Grid';
import Modal from "common/components/Modal/Modal";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import ModalHeader from "common/components/Modal/ModalHeader";
import ModalFooter from "common/components/Modal/ModalFooter";
import CompanyAddFieldService from 'common/services/company-add-field.service';
import Confirm from 'common/views/Popup/Confirm';

const initialData = Object.freeze({
  company_FieldLb1: "",
  company_FieldLb2: "",
  company_FieldLb3: "",
  company_FieldLb4: "",
  company_FieldLb5: "",
  company_FieldLb6: "",
  company_FieldLb7: "",
  company_FieldLb8: "",
  company_FieldLb9: "",
  company_FieldLb10: ""
})

export default function CompanyAdditionalFieldsDetail(props) {
  const [additionalData, setAdditionalData] = useState({});
  const [openConfirmPopup, setConfirmPopupOpen] = useState(false);
  const [openMessageDialog, setMessageDialogOpen] = useState(false);

  useEffect(() => {
    if (!props.edit) {
      setAdditionalData(initialData);
    } else if (props.edit && props?.editProps) {
      setAdditionalData({...props?.editProps});
    }
  }, [props.edit, props?.editProps]);

  const clearState = () => {
    setConfirmPopupOpen(false);
    setAdditionalData(initialData);
  }

  function closeMessage() {
    setMessageDialogOpen(false);
  }

  function handleChange(e) {
    setAdditionalData({
      ...additionalData,
      [e.target.name]: e.target.value
    })
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (props.edit && additionalData !== initialData) {
      CompanyAddFieldService.saveCompanyAddField(additionalData).then(response => {
        if (response) {
          props.success(true, 'editAdditionalData', `Company additional fields has been updated successfully.`.bold());
        }
      }).catch(err => {
        throw err;
      });
    }
    clearState();
    return props.close();
  }

  const handleOpenConfirmPopup = () => {
    if (openMessageDialog) {
      closeMessage();
    }
    if (props.edit) {
      setConfirmPopupOpen(true);
    }
  }

  function closeConfirmPopup() {
    setConfirmPopupOpen(false);
  }

  return (
    <div>
      <Confirm
        content="Are you wish to update the additional field settings?"
        isOpen={openConfirmPopup}
        confirm={handleSubmit}
        close={closeConfirmPopup}
      />
      <Modal
        ariaLabelledby="modal-heading"
        classname="myClass"
        isOpen={props.isOpen && !openConfirmPopup}
        width={966}
      >
        <ModalHeader hasTopLine>
          <ModalTitle>Company additional field</ModalTitle>
          <ModalBtnList>
            <ModalBtn name="delete" title="close" onClick={props.close} />
          </ModalBtnList>
        </ModalHeader>

        <ModalContent style={{ paddingTop: '30px', paddingRight: '0px', marginRight: '0px' }}>
          <Grid container spacing={0}>

            <Grid item xs={6}>
              <TextFieldForm
                label="Local field 1"
                inline
                name="company_FieldLb1"
                value={additionalData.company_FieldLb1}
                maxLength={35}
                onChange={handleChange}
                inputWidth={355}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldForm
                label="Local field 6"
                inline
                name="company_FieldLb6"
                value={additionalData.company_FieldLb6}
                maxLength={35}
                onChange={handleChange}
                inputWidth={366}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldForm
                label="Local field 2"
                inline
                name="company_FieldLb2"
                value={additionalData.company_FieldLb2}
                maxLength={35}
                onChange={handleChange}
                inputWidth={355}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldForm
                label="Local field 7"
                inline
                name="company_FieldLb7"
                value={additionalData.company_FieldLb7}
                maxLength={35}
                onChange={handleChange}
                inputWidth={366}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldForm
                label="Local field 3"
                inline
                name="company_FieldLb3"
                value={additionalData.company_FieldLb3}
                maxLength={35}
                onChange={handleChange}
                inputWidth={355}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldForm
                label="Local field 8"
                inline
                name="company_FieldLb8"
                value={additionalData.company_FieldLb8}
                maxLength={35}
                onChange={handleChange}
                inputWidth={366}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldForm
                label="Local field 4"
                inline
                name="company_FieldLb4"
                value={additionalData.company_FieldLb4}
                maxLength={35}
                onChange={handleChange}
                inputWidth={355}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldForm
                label="Local field 9"
                inline
                name="company_FieldLb9"
                value={additionalData.company_FieldLb9}
                maxLength={35}
                onChange={handleChange}
                inputWidth={366}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldForm
                label="Local field 5"
                inline
                name="company_FieldLb5"
                value={additionalData.company_FieldLb5}
                maxLength={35}
                onChange={handleChange}
                inputWidth={355}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldForm
                label="Local field 10"
                inline
                name="company_FieldLb10"
                value={additionalData.company_FieldLb10}
                maxLength={35}
                onChange={handleChange}
                inputWidth={366}
              />
            </Grid>

          </Grid>
        </ModalContent>

        <ModalFooter hasBottomLine>
          <ModalAction>
            <ModalActionExtraButton>
              <Button onClick={props.close}>Cancel</Button>
            </ModalActionExtraButton>
            <ModalActionButton>
              <Button onClick={handleOpenConfirmPopup} themeColor="primary">Save</Button>
            </ModalActionButton>
          </ModalAction>
        </ModalFooter>
      </Modal>
    </div>
  );
}
