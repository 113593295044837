import TextFieldForm from "common/components/TextField/TextFieldForm";
import React from 'react';
import styled from "styled-components";


const TextFieldWrapper = styled.div`
    >div {
      ${props => props.cellCenter ?  'margin: 0 auto': 'margin: 0'};
    }

`;

export default class Datagrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          value: props.value || {}
        };
    }

    static getDerivedStateFromProps (props, prevState) {
      if (props.value !== prevState.value) {
        return {
          value: props.value
        };
      }

      return null;
    }

    valueChange = (col, row, value) => {
      value = value.replace(/[\D]/, '')
        this.setState(prevState => {
          if (!prevState.value) {
            prevState.value = {};
          }
          prevState.value[col + this.props.splitter + row] = value;
          return {
            value: prevState.value
          }
        });
    }

    render() {
            const th = this.props.columns.map(col => {
                return (
                  <th
                    key={col.field}
                    style={{width: col.width? col.width : `${this.props.headerWidth  }px`, paddingLeft: `${this.props.padding  }px`,
                 paddingRight: `${this.props.padding  }px`}}
                  >{col.label}
                  </th>
                        );
            });

            const tr = this.props.rows.map(row => {
                const textField = this.props.columns.map(col => {
                    return (
                      <td key={`${col.field}-${row.field}`} style={{paddingLeft: `${this.props.padding  }px`, paddingRight: `${this.props.padding  }px`}}>
                        <TextFieldWrapper key={`${col.field}-${row.field}`} cellCenter={col.cellCenter}>
                          <TextFieldForm
                            value={this.state.value[`${col.field}${this.props.splitter}${row.field}`] || ''}
                            key={`${col.field}-${row.field}`}
                            id={`${col.field}-${row.field}`}
                            inputWidth={col.cellWidth ? col.cellWidth : this.props.cellWidth}
                            onChange={(event) => this.valueChange(col.field, row.field, event.target.value)}
                            onBlurWithValue={(value) => this.props.valueChange(col.field, row.field, value)}
                            maxLength={this.props.maxLength}
                          />
                        </TextFieldWrapper>
                      </td>
                      );
                });

                return (
                  <tr key={row.label}>
                    <td style={{paddingRight: "10px"}}>{row.label}</td>
                    {textField}
                  </tr>
                    );
            });

            return (
              <div style={{float: this.props.floatRight ? 'right': 'null'}}>
                <table style={{borderCollapse: 'collapse'}}>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      {th}
                    </tr>

                  </thead>
                  <tbody>
                    {tr}
                  </tbody>
                </table>
              </div>

              );

    }
}

