/* eslint-disable import/no-extraneous-dependencies */
import { Radio } from '@cdl/radio-group';
import Grid from '@material-ui/core/Grid';
import ActionButtons from "common/components/ActionButtons/ActionButtons";
import Dropdown from 'common/components/Dropdown/Dropdown';
import IconButton from "common/components/IconButton/IconButton";
import RadioGroupStyled from 'common/components/RadioGroup/RadioGroupStyled';
import Table from 'common/components/Table/Table';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import ValidationMessage from 'common/components/UserMessage/ValidationMessage';
import PortService from 'common/services/port.service';
import ProcessingCentreService from 'common/services/processing-centre.service';
import UserService from 'common/services/user.service';
import VesselService from 'common/services/vessel.service';
import { isAC } from "common/utils/AppUtil";
import { getCompanyByProcessingCtr } from 'common/utils/OptionUtil';
import AddCarrier from 'common/views/Popup/AddCarrier';
import AddPort from 'common/views/Popup/AddPort';
import Confirm from 'common/views/Popup/Confirm';
import React from 'react';

const isAccessControl = isAC();

const caPortTableArray = [
  {
    Header: 'Port name',
    accessor: 'portName',
  },
  {
    Header: 'Port country / territory',
    accessor: 'portCountry',
  },
  {
    Header: 'WOLF status',
    accessor: 'wolfStatus',
    width: 150
  },
  {
    Header: 'Action',
    accessor: 'actionBtn',
    width: 110,
    sortable: false
  }
];

const caCarrierTableArray = [
  {
    Header: 'Carrier',
    accessor: 'vesselDesc',
    Cell: row => <span title={row.original.vesselDesc}>{row.original.vesselDesc}</span>
  },
  {
    Header: 'WOLF status',
    accessor: 'wolfStatus',
    width: 150
  },
  {
    Header: 'Action',
    accessor: 'actionBtn',
    width: 110,
    sortable: false
  }
];

const baCarrierTableArray = [
  {
    Header: 'Carrier',
    accessor: 'vesseldesc',
    Cell: row => <span title={row.original.vesseldesc}>{row.original.vesseldesc}</span>
  },
  {
    Header: 'Action',
    accessor: 'actionBtn',
    maxWidth: 110,
    sortable: false
  }
];

const buttonArray = [
  { id: 'openEdit', name: 'Edit', showModal: 'openEditPopup' },
  { id: 'openDelete', name: 'Delete', showModal: 'showDeleteRequestMessage' }
];


export default class DataMaintenance extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      querystring: 'carrier',
    }
  }

  componentDidMount() {
    if(isAccessControl){
      this.setState({
        querystring: 'port',
      });
      this.fetchPortData();
      ProcessingCentreService.loadProcessingCtr().then(response => {
        if (response && response.list) {
          const options = [];
          response.list.forEach(element => {
            options.push({ "label": element.processCtrName, "value": element.processCtrID });
          });

          this.setState({
            processingCenterList: options,
            companyList: getCompanyByProcessingCtr(options[0]?.value),
            processCtrSelected: options[0]?.value,
            processCtrSelectedLabel: options[0]?.label
          });
        }
      });

    }else{
      const user = UserService.getCurrentUser();
      this.setState({
        companySelected: user.codocexpressid,
      },
      this.fetchVesselData);
    }
  }

  processCenterChange = (event) => {
    this.setState({
      tableData: [],
      processCtrSelected: event.value,
      processCtrSelectedLabel: event.label,
      companyList: getCompanyByProcessingCtr(event.value),
      companySelected: null,
      companySelectedLabel: '',
    })
  }

  companyChange = (event) => {
    this.setState({
      companySelected: event.value,
      companySelectedLabel: event.label,
    },
    this.fetchVesselData)
  }


  fetchVesselData = () => {
    if(this.state.companySelected){
      VesselService.getCarrierList(this.state.companySelected).then(response => {
        if (response && response.list) {
          this.setState({tableData: response.list});
        }
      }).catch(error => {
        throw error;
      });
    }
  }

  fetchPortData = () => {
      PortService.loadPorts().then(res => {
        if (res && res.list) {
          this.setState({tableData: res.list});
        }
      }).catch(error => {
        throw error;
      });
  }

  isPort = () => {
    return this.state.querystring==='port';
  }

  refreshTable = () => {
    if(this.isPort()){
      this.fetchPortData();
    }else{
      this.fetchVesselData();
    }
  }

  showMessage = (msg, isError) => {
    if(isError){
      this.setState({
        message: msg,
        showError: true,
      });
    }else{
      this.setState({
        message: msg,
        showSuccess: true,
      });
      this.refreshTable();
    }
  }

  onRadioChange = (event) => {
    const {value} = event.target;
    this.setState({
      tableData: [],
      querystring: value,
      showSuccess: false,
      showError: false,
      companySelected: null,
      companySelectedLabel: '',
    },
    this.refreshTable);
  }


  openEditPopup = (rowIndex) => {
    this.setState({
      selectedIndex: rowIndex,
      isEdit: true
    },
    ()=>{
      if(this.isPort()){
        this.setState({
          openPortPopup: true
        })
      }else{
        this.setState({
          openCarrierPopup: true
        })
      }
    })
  }

  showDeleteRequestMessage = (rowIndex) => {
    this.setState({
      selectedIndex: rowIndex,
      openDeletePopup: true,
    })
  }


  deleteRecord = () => {
    const {tableData, selectedIndex} = this.state;

    if (this.isPort()) {
      PortService.deletePorts(tableData[selectedIndex]).then(response => {
        if (response) {
          this.showMessage(`Port "${tableData[selectedIndex]?.portName}" - "${tableData[selectedIndex]?.portCountry}" has been deleted successfully.`);
        }
      }).catch(error => {
        throw error;
      });
    } else {
      VesselService.deleteVessel(tableData[selectedIndex]).then(response => {

        if (response && response>0) {
          this.showMessage(`Carrier ${this.getDisplayName()} has been deleted successfully.`);
        }else{
          this.showMessage(`Error deleting carrier ${this.getDisplayName()}.`, true);
        }
      }).catch(error => {
        throw error;
      });
    }
  }

  addCarrier = () => {
    this.setState({
      openCarrierPopup: true,
      isEdit: false,
      selectedIndex: null,
    })
  }

  addPort = () => {
    this.setState({
      openPortPopup: true,
      isEdit: false,
      selectedIndex: null,
    })
  }

  getDisplayName = () => {
    const {tableData, selectedIndex} = this.state;
    return isAccessControl ? `"${tableData[selectedIndex]?.vesselDesc}"` : `"${tableData[selectedIndex]?.vesseldesc}"`;
  }

  getTableColumns = () => {
    let columns = [];

    if(isAccessControl){
      if(this.isPort()){
        columns = caPortTableArray;

      }else{
        columns = caCarrierTableArray;

      }
    }else{
      columns = baCarrierTableArray;

    }
    return columns;
  }

  render() {
    const {tableData, selectedIndex, processingCenterList, processCtrSelected, processCtrSelectedLabel,
      companyList, companySelected, companySelectedLabel, isEdit,
      querystring, openCarrierPopup, openPortPopup, openDeletePopup, showSuccess, showError, message} = this.state;

    const displayData = tableData.length > 0 ? tableData.map((t, i) => {
      return Object.assign(t, {actionBtn: <ActionButtons
        rowIndex={i}
        buttonArray={buttonArray}
        openEditPopup={this.openEditPopup}
        showDeleteRequestMessage={this.showDeleteRequestMessage}
        justify='flex-start'
      />});
    }) : [];

    const name = this.isPort() ? `port "${tableData[selectedIndex]?.portName}"` : `carrier ${this.getDisplayName()}`;
    const popupMsg = `Do you wish to delete the ${name}?`;

    const newButton = this.isPort() ?
      <IconButton onClick={this.addPort} name="add">Add new port</IconButton> :
      <IconButton onClick={this.addCarrier} name="add" disabled={!companySelected}>Add new carrier</IconButton>;

  return (
    <div>
      <Grid container spacing={0} style={{ paddingTop: '20px' }}>
        {showSuccess && !showError &&
          <Grid item xs={12}>
            <SuccessMessage message={message} handleCloseButtonClick={() => this.setState({ showSuccess: false })} />
          </Grid>}
        {showError && !showSuccess &&
          <Grid item xs={12}>
            <ValidationMessage message={message} onClose={() => this.setState({ showError: false })} />
          </Grid>}
        <Grid item xs={2}>
          <RadioGroupStyled
            id="portAndCarrier"
            name="portAndCarrier"
            legend=""
            horizontal
          >
            {isAccessControl ?
              <Radio
                id="radio-number-port"
                name="port"
                label="Port"
                value="port"
                checked={querystring === 'port'}
                onChange={this.onRadioChange}
              /> : <></>}
            <Radio
              id="radio-number-carrier"
              name="carrier"
              label="Carrier"
              value="carrier"
              checked={querystring === 'carrier'}
              onChange={this.onRadioChange}
            />
          </RadioGroupStyled>
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={2}>
          <div className="rightBtn" style={{paddingRight: '0px'}}>
            {newButton}
          </div>
        </Grid>
      </Grid>
      {isAccessControl && querystring === 'carrier' &&
      <Grid
        container
        spacing={0}
        style={{paddingTop: '20px', paddingBottom: '30px', paddingLeft: '20px', paddingRight: '20px', marginBottom: '20px', backgroundColor: '#ededed'}}
      >
        <Grid container style={{backgroundColor: '#eee'}}>
          <Grid item xs={6}>
            <Dropdown
              seamless
              id="processing-centre"
              options={processingCenterList}
              onChange={this.processCenterChange}
              placeHolder={processCtrSelectedLabel}
              value={processCtrSelected}
              labelInside="Processing centre"
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              seamless
              id="company-name"
              options={companyList}
              onChange={this.companyChange}
              placeHolder={companySelectedLabel}
              labelInside="Company"
            />
          </Grid>
        </Grid>
      </Grid>}
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {this.isPort() &&
            <Table
              maxRows={12}
              showPagination
              defaultPageSizeOption={{'value':0}}
              data={displayData}
              columns={this.getTableColumns()}
            />}
          {!this.isPort() &&
            <Table
              maxRows={12}
              showPagination
              defaultPageSizeOption={{'value':0}}
              data={displayData}
              columns={this.getTableColumns()}
            />}
        </Grid>
      </Grid>

      <Confirm
        content={popupMsg}
        confirm={this.deleteRecord}
        isOpen={openDeletePopup}
        close={()=>this.setState({openDeletePopup:false})}
      />

      {openCarrierPopup &&
      <AddCarrier
        isOpen={openCarrierPopup}
        edit={isEdit}
        editProps={tableData[selectedIndex]}
        close={()=>this.setState({openCarrierPopup:false})}
        success={this.showMessage}
        codocexpressid={companySelected}
        title='Carrier details'
        refreshTable={this.refreshTable}
      />}

      {openPortPopup &&
      <AddPort
        isOpen={openPortPopup}
        edit={isEdit}
        editProps={tableData[selectedIndex]}
        close={()=>this.setState({openPortPopup:false})}
        success={this.showMessage}
        refreshTable={this.refreshTable}
      />}
    </div>
  )
  }
}
