import DrawerService from "common/services/drawer.service";
import { getCountry } from 'common/utils/OptionUtil';
import React from 'react';
import { WOLF_SCAN_EXPORTER_FIELDS } from 'common/utils/Constants';
import GenericPopup from 'common/views/Popup/GenericPopup';

const initalData = Object.freeze({
  defcountry: [""], exportercode: "", expname: "", expref: "", expregno: "", expacctno: "",
  expdbacctno: "", expaddr1: "", expaddr2: "", expaddr3: "", expcity: "", expstate: "", expcountry: "", exppostcode: "",
  expgpobox: "", exptel: "", expfax: "", exptelex: "", expemail: "", logo: false, signature: false
});

export default class AddExporter extends React.Component {

  save = (data) => {
    return DrawerService.addDrawer(data);
  }

  render(){

    const fields = [
      {id: 'exportercode', label:'Exporter code*', name: 'exportercode', maxLength: 18, size: 4, isMandatory: true, regex: "^[a-zA-Z0-9]+$"},
      {id: 'expname', label:'Exporter name', name: 'expname', maxLength: 55, size: 4, },
      {id: 'expref', label:'Exporter reference', name: 'expref', maxLength: 20, size: 4,},

      {id: 'expregno', label:'Registration number', name: 'expregno', maxLength: 20, size: 4,},
      {id: 'expacctno', label:'Exporter account number', name: 'expacctno', maxLength: 14, size: 4, isAccount: true, validations: ['accountNumber'], },
      {id: 'expdbacctno', label:'Debit charges account number', name: 'expdbacctno', maxLength: 14, size: 4, isAccount: true, validations: ['accountNumber'], },

      {id: 'expaddr1', label:'Address line 1', name: 'expaddr1', maxLength: 35, size: 4, },
      {id: 'expaddr2', label:'Address line 2', name: 'expaddr2', maxLength: 35, size: 4, },
      {id: 'expaddr3', label:'Address line 3', name: 'expaddr3', maxLength: 35, size: 4, },

      {id: 'expcity', label:'City', name: 'expcity', maxLength: 35, size: 3, },
      {id: 'expstate', label:'State', name: 'expstate', maxLength: 35, size: 3, },
      {id: 'expcountry', label:'Country / Territory', name: 'expcountry', type: 'dropdown', size: 3, options: getCountry()},
      {id: 'exppostcode', label:'Postal code', name: 'exppostcode', maxLength: 10, size: 3, },

      {id: 'expgpobox', label:'GPO box', name: 'expgpobox', maxLength: 50, size: 3, },
      {id: 'exptel', label:'Telephone number', name: 'exptel', maxLength: 30, size: 3, },
      {id: 'expfax', label:'Fax number', name: 'expfax', maxLength: 30, size: 3, },
      {id: 'exptelex', label:'Telex', name: 'exptelex', maxLength: 50, size: 3, },

      {id: 'expemail', label:'Email address', name: 'expemail', maxLength: 100, size: 6, validations: ["multiEmail"], },
      {id: 'add-exporter-logo', label:'Logo', name: 'logo', size: 1, style: { alignSelf: "center" }, type: 'checkbox' },
      {id: 'add-exporter-signature', label:'Signature', name: 'signature', size: 1, style: { alignSelf: "center" }, type: 'checkbox' },
    ]

    return (
      <GenericPopup
        initalData={initalData}
        fields={fields}
        {...this.props}
        save={this.save}
        displayField={['exportercode', 'expname']}
        displayFieldTile='Exporter'
        wolfField={WOLF_SCAN_EXPORTER_FIELDS}
        popupWidth={1000}
        tailMap={{'-8':'Reached maximum number of entries allowed.'}}
        title="Add exporter (drawer / DC beneficiary)"
      />
    )
  }
}
