import CDLIcon from "@cdl/icon";
import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import React from 'react';

const MESSAGE_TITLE_SURE = 'Are you sure?'

export default class Message extends React.Component {
  static messageInstance

  static confirm(text, callback) {
    this.messageInstance._show(MESSAGE_TITLE_SURE, text, callback, null);
  }

  static choose(text, callback, noCallback) {
    this.messageInstance._show(MESSAGE_TITLE_SURE, text, callback, noCallback);
  }

  static select(text, callback, noCallback, cancelCallback) {
    this.messageInstance._show(MESSAGE_TITLE_SURE, text, callback, noCallback, cancelCallback);
  }

  static notice(text) {
    this.messageInstance._show("Notice", text, null, null, null, true)
  }

  static error(text) {
    this.messageInstance._show("Error", text, null, null, null, true)
  }

  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      title: "",
      text: "",
      isSingle: false
    }

    Message.messageInstance = this
  }

  close = () => {
    this.setState({ visible: false })
    if (this.state.noCallback) {
      this.state.noCallback();
    }
  }

  cancel = () => {
    this.setState({visible: false})
    if (this.state.cancelCallback) {
      this.state.cancelCallback();
    }
  }

  handleAction = () => {
    this.setState({ visible: false })
    this.state.callback();
  }

  _show(title, text, callback, noCallback, cancelCallback, isSingle) {
    this.setState({
      visible: true,
      title,
      text,
      callback,
      noCallback,
      cancelCallback,
      isSingle
    })
  }

    render() {
        let button = this.state.cancelCallback ?
          <>
            <ModalActionExtraButton>
              <Button onClick={this.cancel}>Cancel</Button>
            </ModalActionExtraButton>
            <ModalActionButton>
              <Button style={{marginRight:'10px'}} onClick={this.close}>No</Button>
              <Button themeColor="primary" onClick={this.handleAction}>Yes</Button>
            </ModalActionButton>
          </>
          :
          <>
            <ModalActionExtraButton>
              <Button onClick={this.close}>No</Button>
            </ModalActionExtraButton>
            <ModalActionButton>
              <Button themeColor="primary" onClick={this.handleAction}>Yes</Button>
            </ModalActionButton>
          </>
          ;

        let icon = <CDLIcon name="circle-error-solid" color="WARNING" size={29} />

        if(this.state.isSingle){
          button =
            <ModalActionButton>
              <Button themeColor="primary" onClick={this.close}>OK</Button>
            </ModalActionButton>
          icon = <CDLIcon name="circle-error-solid" color={this.state.title === 'Error' ? 'ERROR' : ''} size={29} />
        }

        return (
          <Modal
            ariaLabelledby="modal-heading"
            width={this.props.width || 400}
            height={this.props.height || 200}
            isOpen={this.state.visible}
          >
            <ModalHeader>
              <ModalTitle>
                <span style={{paddingRight:'5px'}}>
                  {icon}
                </span>
                {this.state.title}
              </ModalTitle>
              <ModalBtnList>
                <ModalBtn name="delete" title="close" onClick={this.state.cancelCallback ? this.cancel : this.close} />
              </ModalBtnList>

            </ModalHeader>
            <ModalContent>
              {this.state.text}
            </ModalContent>
            <ModalFooter hasBottomLine>
              <ModalAction>{button}</ModalAction>
            </ModalFooter>
          </Modal>
        )
    }
}
