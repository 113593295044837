import React from 'react';
import { connect } from 'react-redux';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Tab from 'common/components/Tab/Tab';
import { detectChanges } from 'common/utils/MasterDocUtil';
import PrivateRoute from 'common/components/PrivateRoute/PrivateRoute';
import MDSummary from 'de/views/DocPreparation//MDSummary';
import MDTemplate from 'de/views/DocPreparation/MDTemplate';

class MasterDocOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.linkClick = this.linkClick.bind(this);
    }

    linkClick = (targetUrl) => {
        if (this.props.location.pathname !== targetUrl) {
            detectChanges(() => {
                this.props.history.push(targetUrl);
            });
        } else {
            this.props.history.push(targetUrl);
        }
    }

    render() {
        const tabs = [
            {
                title: "MD Summary",
                url: `/MasterDocOverview/MDSummary`,
                name: "MDSummary",
                component: MDSummary
            },
            {
                title: "MD Template",
                url: `/MasterDocOverview/MDTemplate`,
                name: "MDTemplate",
                component: MDTemplate
            }
        ];

        return (
          <div>
            <PageHeader title="Doc. overview" />
            <div className="mainContent">
              <Tab tabs={tabs} click={this.linkClick} />
              {tabs?.map(item =>{
                        return <PrivateRoute path={item.url} component={item.component} />
                    })}
            </div>
          </div>
        );
    }
}

export default connect()(MasterDocOverview);
