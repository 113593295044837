import ActionButtons from "common/components/ActionButtons/ActionButtons";
import Table from 'common/components/Table/Table';
import { OVERFLOW_CLASS } from "common/utils/Constants";
import React, { Component } from "react";
import UserService from 'common/services/user.service';

const _ = require('lodash');

export default class TableHelper extends Component {
    constructor(props) {
        super(props);
        this.state = {
          tableData: props.data || [],
          selectedIndex: null,
          openItem: false,
          openItemList: false,
        };
    }

    static getDerivedStateFromProps (props, prevState) {
      if (props.data && props.data !== prevState.prevData) {
        if(props.mdocid !== prevState.prevID){
          return {
            prevID: props.mdocid,
            prevData: props.data,
            tableData: props.data
          };
        }
        return {
          prevID: props.mdocid,
          prevData: props.data,
          tableData: props.data
        };
      }
      return null;
    }

    newRow =() => {
        this.setState({openItem:true,selectedIndex: null});
        document.documentElement.classList.add(OVERFLOW_CLASS);
        return this.props.isNested;
    }

    closeRowItem =() => {
        this.setState({
            openItem: false,
            selectedIndex: null
        });
        document.documentElement.classList.remove(OVERFLOW_CLASS);
    }

    deleteRow = (rowIndex) => {
        if(this.props.deleteRow){
          this.props.deleteRow(this.state.tableData[rowIndex]);
        }
        this.setState(prevState => ({
          tableData: prevState.tableData.filter((item, index) => index !== rowIndex)
        }));
        document.documentElement.classList.remove(OVERFLOW_CLASS);
    }

    lazyDeleteRow = (rowIndex) => {
      this.setState(prevState => ({
        tableData: prevState.tableData.filter((item, index) => index !== rowIndex)
      }),
      ()=>{
        if(this.props.refreshData){
          this.props.refreshData(this.state.tableData)
        }
      });
    }

    extraAction = (rowIndex) => {
      if(this.props.extraAction){
        this.props.extraAction(this.state.tableData[rowIndex]);
      }
    }

    showRowItem = (rowIndex) => {
        this.setState({
          openItem: true,
          selectedIndex: rowIndex
        })
        document.documentElement.classList.add(OVERFLOW_CLASS);
    }

    saveRow = (rowItem, isEdit, rowIndex) => {
        this.setState(prevState => {
          if (isEdit) {
            const newTableData = prevState.tableData.find((data, i) => i === rowIndex);
            Object.keys(rowItem).forEach(key => {
              newTableData[key] = rowItem[key];
            });
            return ({
              tableData: prevState.tableData,
              selectedIndex: null
            });
          }

          return ({
            tableData: [...prevState.tableData, rowItem],
            selectedIndex: null
          });
        }, ()=>{
          if(this.props.callback && !isEdit){
            this.props.callback(rowItem);
          }
        });

        this.closeRowItem();
    }

    renewRow = (rowItem, filenames) => {
      this.setState(prevState => {
        console.log(prevState);
        return ({
          tableData: [...rowItem],
          selectedIndex: null
        });
      }, ()=>{
        if(this.props.callback){
          this.props.callback(filenames);
        }
      });

      this.closeRowItem();
  }

    showItemList = (rowIndex) => {
      this.setState({
        openItemList: true,
        selectedIndex: rowIndex
      });
      document.documentElement.classList.add(OVERFLOW_CLASS);
    }

    closeItemList = () => {
      this.setState({
        openItemList: false,
        selectedIndex: null
      });
      document.documentElement.classList.remove(OVERFLOW_CLASS);
    }

    saveRowItems = (rowItems, labelHeaders) => {
      this.setState(prevState => {
        prevState.tableData[prevState.selectedIndex][this.props.listField || 'itemlist'] = rowItems;
        prevState.tableData[prevState.selectedIndex].labelHeaders = labelHeaders;
        return ({
          tableData: prevState.tableData,
          openItemList: false,
          selectedIndex: null
        })
      });
    }

    saveAndUpdateParent = (rowItems, labelHeaders, objectForParent) => {
      this.setState(prevState => {
        prevState.tableData[prevState.selectedIndex][this.props.listField || 'itemlist'] = rowItems;
        prevState.tableData[prevState.selectedIndex].labelHeaders = labelHeaders;
        prevState.tableData[prevState.selectedIndex] = Object.assign(prevState.tableData[prevState.selectedIndex], objectForParent);
        console.log(prevState.tableData);
        return ({
          tableData: prevState.tableData,
          openItemList: false,
          selectedIndex: null
        })
      });
    }

    refreshTable = (data) =>{
      this.setState({tableData: data});
    }

    isSameRole = (role) => {
      const userRole = UserService.getCurrentUser().userroles;
      let isSame = role === userRole;
      if(role==='CA' || role==='CU'){
        isSame = (userRole==='CA' || userRole==='CU');
      }
      return isSame;
    }

    getDisplayData = () => {
      return this.state.tableData?.filter(d => !d.isDelete).map((t, i) => {
        let {buttonArray} = this.props;
        if (this.props.exemptDisableButtonMap) {
            const tempArray = _.cloneDeep(buttonArray);

            buttonArray = tempArray.map(b => {
              if (this.props.exemptDisableButtonMap && this.props.exemptDisableButtonMap[b.id] && !this.isSameRole(t.userroles)) {
                b.disabled = true;
              }
              return b;
            });
        }

          return Object.assign(t, {actionBtn: <ActionButtons
            rowIndex={i}
            buttonArray={buttonArray}
            deleteRow={this.deleteRow}
            lazyDeleteRow={this.lazyDeleteRow}
            showRowItem={this.showRowItem}
            showItemList={this.showItemList}
            extraAction={this.extraAction}
            detailsCallBack={this.props.detailsCallBack}
            justify='space-between'
            titleField={t[this.props.titleField]}
          />});

        });
    }

  render() {

    const displayData = this.getDisplayData();


    return (
      <>
        <Table
          showPagination={false}
          defaultPageSizeOption={{'value':0}}
          data={displayData}
          columns={this.props.columns}
          maxRows={this.props.maxRows}
          hoverSelect={this.props.hoverSelect}
          sortedChange={this.props.sortedChange}
          shortTable
        />

        {this.state.openItem && this.props.component &&
                React.createElement(
                    this.props.component,
                    {isOpen: this.state.openItem,
                     close: this.closeRowItem,
                     save: this.saveRow,
                     delete: this.deleteRow,
                     renew: this.renewRow,
                     fullDataSet: displayData,
                     data: displayData[this.state.selectedIndex],
                     selectedIndex: this.state.selectedIndex,
                     labelHeaders: this.props.labelHeaders,
                     mdocid: this.props.mdocid,
                     viewMode: this.props.viewMode,
                     callback: this.props.callback,
                     isEDS: this.props.isEDS,
                     queryType: this.props.queryType,
                     hasEdit: this.props.hasEdit
                     }
                )}

        {this.state.openItemList && this.props.componentItemList &&
                React.createElement(this.props.componentItemList,
                    {
                      isOpen: this.state.openItemList,
                      close: this.closeItemList,
                      save: this.saveRowItems,
                      saveForParentAsWell: this.saveAndUpdateParent,
                      data: this.props.listField ? this.state.tableData[this.state.selectedIndex][this.props.listField]
                      : this.state.tableData[this.state.selectedIndex]?.itemlist || this.state.tableData[this.state.selectedIndex] || [],
                      selectedIndex: this.state.selectedIndex,
                      rowIndex: this.state.selectedIndex,
                      headers: this.state.tableData[this.state.selectedIndex],
                      labelHeaders: this.state.tableData[this.state.selectedIndex]?.labelHeaders
                     }
                )}
      </>
    );
  }
}

