
export const LOGIN_STATUSCODE_AUTHORISEDLOGON = "1";
export const LOGIN_STATUSCODE_INVALIDUSER 	= "2";
export const LOGIN_STATUSCODE_INVALIDPASSWORD = "3";
export const LOGIN_STATUSCODE_INVALIDATTEMPTS = "4";
export const LOGIN_STATUSCODE_INVALIDCOUNTRY 	= "5";
export const LOGIN_STATUSCODE_SUSPENDEDACCT 	= "6";
export const LOGIN_STATUSCODE_ACCESSOVER60DAYS = "7";
export const LOGIN_STATUSCODE_INVALIDUSERROLES = "8";
export const LOGIN_STATUSCODE_FIRSTLOGON 		= "9";
export const LOGIN_STATUSCODE_PWDCHGOVER90DAYS = "10";
export const LOGIN_STATUSCODE_CONCURRENTLOGON = "11";
export const LOGIN_STATUSCODE_DOUBLELOGON 	= "12";
export const LOGIN_STATUSCODE_AUTHORISEDLOGONEXPIRED = "13";
export const LOGIN_STATUSCODE_ISBLOCKED = "14";
export const LOGIN_STATUSCODE_SUISBLOCKED = "15";
export const LOGIN_STATUSCODE_FORCECHANGEPASSWORD = "16";

export const CHANGEPWD_STATUSCODE_AUTHORISEDPWDCHG = 1;
export const CHANGEPWD_STATUSCODE_INVALIDOLDPASSWORD = 2;
export const CHANGEPWD_STATUSCODE_INVALIDNEWPASSWORD = 3;
export const CHANGEPWD_STATUSCODE_INVALIDLEGACYPASSWORD = 4;

export const OVERFLOW_CLASS = 'over-flow-hidden';
export const ROW_SELECTED_CLASS = 'row-selected';

export const LABEL_ADDRESS_LINE_1 = "Address line 1";
export const LABEL_ADDRESS_LINE_2 = "Address line 2";
export const LABEL_ADDRESS_LINE_3 = "Address line 3";
export const LABEL_COUNTRY = "Country / Territory";

export const PRINT_CMD = {
    PRINT_DIRECT: "Electronic Direct Send",
    PRINT_DRAFT: "Print Draft",
    PRINT_EDS: "Reprint EDS Document",
    PRINT_EDS_EMAIL: "Re-send EDS Document via Email",
    PRINT_TRADITIONAL: "Print For Traditional Collection",
    PRINT_TRANSMIT: "Transmit via Email",
    PRINT_EMAIL: "Print and Email",
};

export const SUB_MODULES = {
    EDS: "GenericEDS",
    EDS_FINANCED: "GenericFinancedEDS",
    LIFUNG_EDS: "GenericLiFungEDS",
    EDS_WORKFLOW: "GenericEDSWorkflow",
    EDS_WORKFLOW_2_LEVEL: "GenericEDSWorkflow2Level",
    SETTLEMENT: "GenericLnFSettlementInstr",
};

export const MODULES = {
    DOC_PREP: "GenericDocPrep",
    ...SUB_MODULES
}

export const WOLF_SCAN_FIELDS = {
    impname: "Importer name",
    impaddr1: "Importer address line 1",
    impaddr2: "Importer address line 2",
    impaddr3: "Importer address line 3",
    impcity: "Importer city",
    impcountry: "Importer country / territory",
    agtname: "Forwarding agent name",
    agtaddr1: "Forwarding agent address line 1",
    agtaddr2: "Forwarding agent address line 2",
    agtaddr3: "Forwarding agent address line 3",
    agtcity: "Forwarding agent city",
    agtcountry: "Forwarding agent country / territory",
    consigneename: "Consignee name",
    consigneeaddr1: "Consignee address line 1",
    consigneeaddr2: "Consignee address line 2",
    consigneeaddr3: "Consignee address line 3",
    consigneecity: "Consignee city",
    consigneecountry: "Consignee country / territory",
    bankname: "Importer's bank name",
    bankaddr1: "Importer's bank address line 1",
    bankaddr2: "Importer's bank address line 2",
    bankaddr3: "Importer's bank address line 3",
    bankcity: "Importer's bank city",
    bankcountry: "Importer's bank country / territory",
    expcountry: "Exporter country / territory",
    vesselname: "Carrier",
    voyage: "Vessel",
    portloading: "Seaport / Airport of loading",
    portdischarge: "Port of discharge",
    finaldestination: "Final destination, if on carriage",
    location: "Location name",
    commoditydesc: "Brief description of goods",
    descofgoods: "Description of goods",
    currencycode: "Currency code",
    countryoforigin: "Country of origin",

    'eidto.draweebankcountry': "Drawee bank country / territory",
    'eidto.otherinstr': "Other instructions",
    "eidto.draweebankaddr1": "Drawee bank address line 1",
    "eidto.draweebankaddr2": "Drawee bank address line 2",
    "eidto.draweebankaddr3": "Drawee bank address line 3",

    "billofexchangedto.boetoaddr1": "Bill of exchange - To - Address line 1",
    "billofexchangedto.boetoaddr2": "Bill of exchange - To - Address line 2",
    "billofexchangedto.boetoaddr3": "Bill of exchange - To - Address line 3",
    "billofexchangedto.forandonbehalfofaddr1": "Bill of exchange - For and on behalf of - Address line 1",
    "billofexchangedto.forandonbehalfofaddr2": "Bill of exchange - For and on behalf of - Address line 2",
    "billofexchangedto.forandonbehalfofaddr3": "Bill of exchange - For and on behalf of - Address line 3",
};

export const WOLF_SCAN_BANK_FIELDS = {
    bankName: "Bank name",
    bankAddr1: LABEL_ADDRESS_LINE_1,
    bankAddr2: LABEL_ADDRESS_LINE_2,
    bankAddr3: LABEL_ADDRESS_LINE_3,
    bankCity: "City",
    bankCountry: LABEL_COUNTRY,
};

export const WOLF_SCAN_RA_BANK_FIELDS = {
    bankCBIDCode: "Bank CBID code",
    bankRef: "Bank reference",
    bankName: "Bank name",
    bankBranch: "Branch",
    bankTradeDept: "Trade department",
    bankAddr1: LABEL_ADDRESS_LINE_1,
    bankAddr2: LABEL_ADDRESS_LINE_2,
    bankAddr3: LABEL_ADDRESS_LINE_3,
    bankPostalCode: "Postal code",
    bankState: "State",
    bankCity: "City",
    bankCountry: LABEL_COUNTRY,
};

export const WOLF_SCAN_DRAWEE_IMPORTER_FIELDS = {
    impAddr1: LABEL_ADDRESS_LINE_1,
    impAddr2: LABEL_ADDRESS_LINE_2,
    impAddr3: LABEL_ADDRESS_LINE_3,
    impCity: "City",
    impCountry: LABEL_COUNTRY,
};

export const WOLF_SCAN_COMPANY_FIELDS = {
    coName: "Company name",
    coAddr1: LABEL_ADDRESS_LINE_1,
    coAddr2: LABEL_ADDRESS_LINE_2,
    coAddr3: LABEL_ADDRESS_LINE_3,
    coCity: "City",
    coCountry: LABEL_COUNTRY,
};

export const WOLF_SCAN_DRAWEE_FIELDS = {
    impName: "Drawee name",
    ...WOLF_SCAN_DRAWEE_IMPORTER_FIELDS
};

export const WOLF_SCAN_IMPORTER_FIELDS = {
    impName: "Importer name",
    ...WOLF_SCAN_DRAWEE_IMPORTER_FIELDS
};

export const WOLF_SCAN_EXPORTER_FIELDS = {
    expcountry: LABEL_COUNTRY,
};

export const WOLF_SCAN_DRAWER_FIELDS = {
    expname: "Drawer name",
    expaddr1: LABEL_ADDRESS_LINE_1,
    expaddr2: LABEL_ADDRESS_LINE_2,
    expaddr3: LABEL_ADDRESS_LINE_3,
    expcity: "City",
    expcountry: LABEL_COUNTRY,
};

export const WOLF_SCAN_PRODUCT_FIELDS = {
    drawername: "Drawer name",
    prodcode: "Code",
    proddesc: "Description",
    serialno: "Serial number",
    prodcommodity: "Product commodity",
};

export const WOLF_SCAN_COUNTRY_FIELDS = {
    countryName: "Country / Territory name",
};

export const WOLF_SCAN_LEGAL_FIELDS = {
    gpMemberID: "Group member ID",
    legalEntityDesc: "Legal entity",
};

export const WOLF_SCAN_COMMODITY_FIELDS = {
    commodityName: "Commodity name",
    commodityDesc: "Description",
};

export const WOLF_SCAN_CURRENCY_FIELDS = {
    currencyCode: "Currency code",
    currencyName: "Description",
};

export const WOLF_SCAN_PORT_FIELDS = {
    portName: "Port name",
    portCountry: "Port country / territory",
};

export const WOLF_SCAN_CARRIER_FIELDS = {
    vesselDesc: "Carrier",
    vesseldesc: "Carrier",
};


export const DOC_STATUS = {
    APPROVED: "APPROVED",
    UNLOCKED: "UNLOCKED",
    SUBMITTED: "SUBMITTED",
    PRINTED: "PRINTED",
    BANK_APPROVED: "BANK APPROVED",
    BANK_REJECTED: "BANK REJECTED",
    PARTIAL: "PARTIAL",
    REVIEWED: "REVIEWED",
};


export const TABS = {
    MASTERDOC: 'GenericMasterDocument',
    INVOICE: 'GenericInvoice',
    PACKINGLIST: 'GenericPackingList',
    BENECERT: 'GenericBeneCert',
    BOE: 'GenericBillOfExchange',
    EI: 'GenericExportInstruction',
    EDS_DASHBOARD: 'GenericEDSDashboard'
};

export const FORM_NAME = {
    'GenericMasterDocument': "Master Document",
    'GenericInvoice': "Invoice",
    'GenericPackingList': "Packing List",
    'GenericBeneCert': "Beneficiary Cert/Miscellaneous Doc",
    'GenericBillOfExchange': "Bill of Exchange",
    'GenericExportInstruction': "Export Trade Collection/Negotiation Instructions",
    'PRINT': "Document Printing",
    'GenericEDSDashboard': "EDS Dashboard",
    'UserMaint': "User Maintenance",
    'ProcessingCtr': "Processing Centre",
    'Company': 'Company',
    'GenericDataMaintenance': 'Data Maintenance',
    'CompanyBlock': 'Company Blocking',
    'ExceptionAct': 'Exception Activity',
    'GenericMDDashboard': 'MD Dashboard',
    'RA_CA_CollectingBank': 'Collecting Bank',
    'PrintLog': 'Printing Log',
    'AuditTrail': 'Audit Trail',
    'AccBlockRpt': 'MI / Report',
    'CountryServ': 'Country & Service',
    'GenericDBMaint': 'Generic DB Maintenance',
    'PwdIssuance': 'Password Issuance',
    'Compliance': 'Compliance',
    'SysAdminErrorLog': 'System Admin and Error Log',
    'RegionalMIReport': 'MI / Report',
    'Drawer': 'Drawer',
    'Product': 'Product',
    'Tenor': 'Tenor',
    'COAddField': 'Company Additional Fields',
    'CollectingBank': 'Collecting Bank',
    'Drawee': 'Drawee',
    'UserActLog': 'User Activity Log',
};

export const invoiceLabelHeadersObj = {
    casenoheading: 'Case no.',
    partnoheading: 'Prod. code',
    proddescheading: 'Product description',
    qtynoheading: 'Quantity'
};

export const packingListLabelHeadersObj = {
    casenoheading: 'Case no.',
    partnoheading: 'Prod. code',
    proddescheading: 'Product description',
    qtyheading: 'Quantity',
    nopkgsheading: 'No. of pkgs.',
    gmassheading: 'Gross mass',
    netmassheading: 'Net mass',
    cubicmassheading: 'Cubic mass',
  };

export const MSG_EMPTY = "This field is required";
