import React from "react";
import styled from "styled-components";
import { ModalHeader as ModalHeaderCDL } from '@cdl/modal';

const Container = styled(ModalHeaderCDL)`
    margin-bottom: 0;

    & > h2{
      font-size: 26px;
      font-family: "HSBC-Universe-Light";
      line-height: 1;
      padding-bottom: ${props => props.hasTopLine ? '14px;' : '4px'};
    }
    
`;


export default class ModalHeader extends React.PureComponent {
  render() {
    return (

      <Container hasTopLine={this.props.hasTopLine}>{this.props.children}</Container>

    );
  }
}

