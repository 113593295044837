import React from 'react';
import UserMessage from './UserMessage';

export default function ValidationMessage(props) {
    return (
      <UserMessage
        showCloseButton
        border
        iconName="triangle-error"
        iconColor="ERROR"
        type="userError"
        handleCloseButtonClick={props.onClose}
        closeText="Close"
      >
        {props.message}
      </UserMessage>
    )
}
