import Collapsible from "common/components/Collapsible/Collapsible";
import { fixActionBarBottom, initActionBarPosition } from 'common/utils/UserInterfaceUtil';
import PackingListSection from "de/views/DocPreparation/Sections/PackingListSection";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getStatus } from 'common/utils/StatusUtil';

export default function PackingList() {

    const masterDoc = useSelector(state => state.form?.masterDoc);
    const packingListError = masterDoc?.submitErrors?.packingList;
    const [isConstruct, setIsConstruct] = useState(false);
    const [updatedStatus, setUpdatedStatus] = useState(false);

    useEffect(() => {
      if (!isConstruct) {
        initActionBarPosition();
        fixActionBarBottom();
        setIsConstruct(true);
      }
      }, [isConstruct]
    );
    const hideErrors = useSelector(state => state.masterDocAction?.hideErrors?.packingList);

    const accordionMark = {};
    if (packingListError) {
      accordionMark.packingList = true;
    } else {
      accordionMark.packingList = false;
    }

    const packingList = useSelector(state => state.masterDocAction?.packingList);

    const updateStatus = () => {
      masterDoc.packingList = packingList.values;
      const status = getStatus('packingList', masterDoc);
      setUpdatedStatus(status);
    }

    return (


      <Collapsible label="5. Packing list(s)" className="collapsible last" section='packingList' updatedStatus={updatedStatus}>
        <PackingListSection showErrorMessage={accordionMark.packingList && !hideErrors} updateStatus={updateStatus} />
      </Collapsible>


    )
}
