import React from 'react';
import styled from "styled-components";
import CDLIcon from "@cdl/icon";

const Container = styled.div`
    position: relative;
    line-height: 1rem;
    border: 0.125rem solid rgb(0, 133, 128);
    margin: 0 0 1.5625rem;
    font-size: 0.875rem;
    color: rgb(51, 51, 51);
    background: rgb(233, 244, 244);

    &.cookie, .info {
      border: 0.125rem solid rgb(26, 72, 117);
      background: rgb(235, 239, 244);
    }

    &.userError {
      border: 0.125rem solid rgb(175, 0, 13);
      background: rgb(249, 242, 243);
    }

    & > div{
      padding: 14px 18px;
      font-size: 14px;

      svg {
          position: absolute;
          top: 0.6rem;
      }

      .message {
        display: inline-block;
        padding-left: 30px;
      }
      
      button{
        font-size: 14px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        right: 0.625rem;
        font-weight: 700;
        color: rgb(51, 51, 51);
        text-decoration: none;
        border: none;
        background: transparent;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
      }     
    }

    & > div > span:nth-child(2){
      width: calc(100% - 75px);
      word-wrap: break-word;
    }
`;

export default class UserMessage extends React.PureComponent {

    render() {
        const theme = this.props.type || 'info';
        return (
          <Container className={theme}>
            <div>
              {React.createElement(CDLIcon, {
                name: this.props.iconName || 'circle-fill-information',
                color: this.props.iconColor || 'info',
                size: 'large'
              })}
              <span className="message">{this.props.children}</span>
              <button type="button" onClick={this.props.handleCloseButtonClick}>Close</button>
            </div>
          </Container>
        )
    }
}
