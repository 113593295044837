import BaseService from 'common/services/BaseService';


class ContactService extends BaseService {
    constructor() {
        super();
        this.basePath = '/api/Contact/';
    }

    post(url, paras, showLoading) {
        return super.post(this.basePath + url, paras, showLoading);
    }

    initCompanyDetail() {
        return this.post('initCompanyDetail', this.getInUserDTO());
    }

    getContactList() {
        const requestBody = {
            ...this.getUserDTO()
        };
        return this.post('getContactList', requestBody, false);
    }
}

export default new ContactService();
