import React from 'react';
import CDLCheckbox from '@cdl/checkbox';
import { ModalTitle, ModalBtnList, ModalBtn, ModalAction, ModalActionExtraButton, ModalActionButton } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Grid from '@material-ui/core/Grid';
import TextFieldForm from "common/components/TextField/TextFieldForm";
import styled from "styled-components";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import UserMaintService from 'common/services/user-maint.service';
import ValidationMessage from 'common/components/UserMessage/ValidationMessage';
import Confirm from 'common/views/Popup/Confirm';
import UserService from 'common/services/user.service';
import CDLButton from '@cdl/button';
import DropdownField from 'common/components/Dropdown/DropdownField';
import { isAC } from "common/utils/AppUtil";
import { getProcessingCtr } from 'common/utils/OptionUtil';
import { capitalizeFirstLetter } from 'common/utils/StringUtils';
import { required } from 'common/utils/ReduxFormValidation';

const CheckboxWrapper = styled.div`
  padding-top: 29px;
  padding-bottom: 25px;
`;


export default class AddNewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmPopup: false,
      formData: {
        langdto: {},
        proctrdto: {},
        companydto: {}
      },
      openMessage: false,
      message: '',
      optLangdto: [],
    }

    this.refUsername = React.createRef();
    this.refFullname = React.createRef();
    this.refLang = React.createRef();
    this.refEmail = React.createRef();
    this.refPassword = React.createRef();
    this.refNo = React.createRef();

    this.refProc = React.createRef();
    this.refCompany = React.createRef();

    this.proctrOpt = [];
    this.countryOpt = [];

    this.isAC = isAC();
    this.isShowProCtr = this.showProcessingCentre();
    this.isShowCountry = this.showCountry();
  }

  componentDidMount(){
    if(this.isShowProCtr){
      this.proctrOpt = getProcessingCtr(true);
    }

    if(this.isShowCountry){
      UserMaintService.loadCSCountry().then(res => {
        if(res && res.list){
          res.list.forEach(element => {
            this.countryOpt.push({ "label": element.countryCode, "value": element.countryCode });
          });
        }
      });
    }

    UserMaintService.loadLanguage().then(response => {
      if (response && response.list) {
        const lang = [];
        response.list.forEach(element => {
          lang.push({ "label": element.langdesc, "value": element.langid });
        });
        this.setState({optLangdto: lang});
      }
    });

    if(this.props.username){
      this.getUserDetails();
    }
  }

  getGroupMember = async (country) => {
    const opt = await UserMaintService.loadGroupMemberID(country, this.props.role).then(res => {
      if (res && res.list) {
        return res.list.map(element => {
          return { "label": element, "value": element };
        });
      }
      return [];
    });
    this.setState({groupMemberOpt: opt});

  }

  getCompany = async (proctrid) => {
    const opt = await UserMaintService.loadCompany(proctrid).then(res => {
      if (res && res.list) {
        return res.list.map(element => {
          return { "label": element.codocexpressidconame, "value": element.codocexpressid };
        });
      }
      return [];
    });
    this.setState({companyOpt: opt});
  }

  getUserDetails = () => {
    UserMaintService.getUserMaint(this.props.username, this.props.role).then(response => {
      if (response) {
        const data = response;
        data.langid = response.langdto?.langid;
        data.langdesc = response.langdto?.langdesc;
        if (this.isShowProCtr) {
          this.getCompany(response.proctrdto?.proctrid);
        }

        if (this.isShowCountry) {
          this.getGroupMember(response.countrycode);
        }

        this.setState({ formData: data });

      }
    });
  }

  handleInputValue = (name, val) => {
    const {formData} = this.state;
    formData[name] = val;
    this.setState({ formData });
  }

  handleChange = (event) => {
      const {formData} = this.state;
      const {name, value} = event.target;
      formData[name] = name==='userName' ? value?.replace(/[^a-z0-9]/gi, '') : value;
      this.setState({ formData });

      if(name==='userName' && !this.isAC && formData[name].length>0){
        this.setState({ disableButton: false });
      }

      if(name==='refno' && this.isAC && formData[name].length>0){
        this.setState({ disableButton: false });
      }

      if(name==='refno'){
        this.setState({
          invalid: false,
          invalidMessage: '',
        })
      }

  }


  handleSelect = (name, value) => {

    this.setState(prevState => {
        const {formData} = prevState;
        if(name.includes('.')){
          const tmp = name.split('.');
          formData[tmp[0]][tmp[1]] = value;
        }else{
          formData[name] = value;
        }
        return {formData}
    });
    if(name==="proctrdto.proctrid"){
      this.getCompany(value);
    }
    if(name==="countrycode"){
      this.getGroupMember(value, this.props.role);
    }
  }

  handleCheck = (event) => {
    const {formData} = this.state;
    formData[event.target.name] = event.target.checked;
    this.setState({ formData });
  }


  handleGenPwd = () =>{
    if(this.isAC){
      const {refno} = this.state.formData;
      if(refno!==""){
        UserMaintService.getRefAllocByRefNo(refno).then(res=>{
          if(res){
            if(res.result){
              this.setState(prevState => {
                const {formData} = prevState;
                formData.resetpwd = true;
                formData.refnumber = formData.refno;
                formData.password = res.pwd;
                return {
                  formData,
                  disableRefNO: true,
                  disableButton: true,
                  invalid: false,
                  invalidMessage: false,
                  openMessage: false,
                }
              })
            }else{
              this.setState({
                invalid: true,
                invalidMessage: capitalizeFirstLetter(res.message).replace(/\./, ''),
              })
            }
          }
        })
      }

    }else{
      this.generatePassword();
    }
  }

  generatePassword = () => {

      const UPPERCASE_CHARS= "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const LOWERCASE_CHARS= "abcdefghijklmnopqrstuvwxyz";
      const NUMBER_CHARS= "1234567890";
      const NORMAL_CHARS= UPPERCASE_CHARS + LOWERCASE_CHARS + NUMBER_CHARS;
      const SPECIAL_CHARS= "~!@#$%^&*()_+`-={}[]|\\;:\"'<>?,./";

      let s1= "";
      let s2= "";
      let n = 0;

      n = Math.floor(Math.random() * UPPERCASE_CHARS.length);
      s1 += UPPERCASE_CHARS.charAt(n);

      n = Math.floor(Math.random() * LOWERCASE_CHARS.length);
      s1 += LOWERCASE_CHARS.charAt(n);

      n = Math.floor(Math.random() * NUMBER_CHARS.length);
      s1 += NUMBER_CHARS.charAt(n);

      n = Math.floor(Math.random() * SPECIAL_CHARS.length);
      s1 += SPECIAL_CHARS.charAt(n);

      for (let i = 4; i < 12; i++) {

        // 80% chances of normal character
        if (Math.random() < 0.8) {

          n = Math.floor(Math.random() * NORMAL_CHARS.length);
          s1 += NORMAL_CHARS.charAt(n);
        } else {

          n = Math.floor(Math.random() * SPECIAL_CHARS.length);
          s1 += SPECIAL_CHARS.charAt(n);
        }
      }

      s2 = this.shuffleString(s1);

      this.setState(prevState => {
        const {formData} = prevState;
        formData.password = s2;
        formData.generatedPassword = s2;
        formData.resetpwd = true;
        return {formData};
      });
  }

  shuffleString = (str) => {

    const oriStr = Object.assign([], str);
    const newStr = [];

    while (oriStr.length > 0) {
      newStr.push(oriStr.splice(Math.round(Math.random() * (oriStr.length - 1)), 1)[0]);
    }

    return newStr.join('');
  }

  showMessage = (msg) => {
    this.setState({
      openMessage: true,
      message: msg,
    })
  }

  save = (userMaint, formData) => {
    UserMaintService.saveOrUpdate(userMaint, this.props.role, this.props.edit).then(response => {
      if (response) {
        const verErr = this.props.edit ? 'updating' : 'adding' ;
        if (response === 1) {
          const ver = this.props.edit ? 'updated' : 'added' ;
          const msg = `User "${formData?.userName}" - "${formData?.fullName}" has been ${ver} successfully`;
          this.props.success(msg);
          return this.props.close();
        }

        if (response === -1) {
          const msg = this.isAC ? 'Failed to proceed this action as this company was blocked.'
                        : 'Fail to proceed this action as this in-sourcing service was blocked.';

          this.showMessage(`Error ${verErr} user - ${msg}`);
        }else if (response === -9) {
          this.showMessage(`Error ${verErr} user - User "${userMaint?.userName}" - "${userMaint?.fullName}" already exist, duplicated entry is not allowed.`);
        }else{
          this.showMessage(`Error ${verErr} user.`);
        }
      }
      return null;
    });
  }


  handleSubmit = (e) => {
    e.preventDefault();

    const {formData} = this.state;
    const {role} = this.props;
    const userMaint = formData;


    const user = UserService.getCurrentUser();

    if(!userMaint.companydto?.codocexpressid){
      const companydto = {codocexpressid: user.codocexpressid};
      userMaint.companydto = companydto;
    }

    if(role==="CU" || role==="CA"
        || role==="RU" || role==="SA"){
      delete userMaint.companydto;
    }

    if(!userMaint.groupmemberid){
      userMaint.groupmemberid = user.groupmemberid;
    }

    if(!userMaint.countrycode){
      userMaint.countrycode = user.countrycode;
    }

    userMaint.codocexpressid = this.isAC ? userMaint.companydto?.codocexpressid : user.codocexpressid;
    if(!userMaint.resetpwd){
      userMaint.resetpwd = false;
    }
    if(!userMaint.suspended){
      userMaint.suspended = false;
    }
    if(!userMaint.refnumber){
      userMaint.refnumber = userMaint.userName;
    }

    this.save(userMaint, formData);
  }

  validateField = (field, isValid) => {
    return field.current.validate() && isValid;
  }

  handleOpenConfirmPopup = () => {
    let isValid = this.refUsername.current.validate();
    isValid = this.validateField(this.refFullname, isValid);
    isValid = this.validateField(this.refLang, isValid);
    if(!this.props.edit && !this.isAC){
      isValid = this.validateField(this.refPassword, isValid);
    }

    if(this.isShowProCtr || this.isShowCountry){
      isValid = this.validateField(this.refCompany, isValid);
      isValid = this.validateField(this.refProc, isValid);
    }

    if(this.isAC && !this.props.edit){
      const msg = required(this.state.formData.refno);
      if(msg.length > 0){
        isValid = false;
        this.setState({
          invalid: true,
          invalidMessage: msg,
        })
      }

      if(!this.state.formData.password){
        isValid = false;
        this.showMessage('Please generate password.');
      }
    }

    if(this.state.formData.email?.length > 0){
      isValid = this.validateField(this.refEmail, isValid);
    }

    if(isValid){
      this.setState({openConfirmPopup:true});
    }

  }

  closeConfirmPopup = () => {
    this.setState({openConfirmPopup:false});
  }

  closeMessage = () => {
    this.setState({openMessage:false});
  }

  showCountry = () => {
    const {role} = this.props;
    if(role==='CA' || role==='SA'){
      return true;
    }
    return false;
  }

  showProcessingCentre = () => {
    const {role} = this.props;
    if(this.isAC && (role==='BA' || role==='BU')){
      return true;
    }
    return false;
  }

  clearValue = (name) => {
    this.setState(prevState => {
      const {formData} = prevState;
      if(name.includes('.')){
        const tmp = name.split('.');
        formData[tmp[0]][tmp[1]] = '';
      }else{
        formData[name] = '';
      }
      return {formData}
    });
  }

  render(){
    const {formData, openConfirmPopup, message, openMessage, optLangdto, disableButton, invalid, invalidMessage} = this.state;
    const ver = this.props.edit ? 'update' : 'add' ;
    return (
      <>
        {openConfirmPopup &&
        <Confirm
          content={`Do you wish to ${ver} the user "${formData.userName}" - "${formData.fullName}"?`}
          isOpen={openConfirmPopup}
          confirm={this.handleSubmit}
          close={this.closeConfirmPopup}
        />}
        <Modal
          ariaLabelledby="modal-heading"
          isOpen={this.props.isOpen && !openConfirmPopup}
          width={704}
        >
          <ModalHeader hasTopLine>
            <ModalTitle>{capitalizeFirstLetter(this.props.roleDesc)}</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.props.close} />
            </ModalBtnList>
          </ModalHeader>
          <ModalContent style={{paddingBottom: '20px'}}>
            <Grid container spacing={0}>
              {openMessage &&
              <Grid item xs={12} style={{paddingRight: '20px'}}>
                <ValidationMessage message={message} onClose={this.closeMessage} />
              </Grid>}
              <Grid item xs={6}>
                <TextFieldForm
                  label="User name*"
                  name="userName"
                  value={formData.userName}
                  validations={["required","min:5"]}
                  onChange={this.handleChange}
                  disabled={this.props?.edit}
                  maxLength={15}
                  ref={this.refUsername}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldForm
                  label="Full name*"
                  name="fullName"
                  value={formData.fullName}
                  validations={["required"]}
                  onChange={this.handleChange}
                  maxLength={35}
                  ref={this.refFullname}
                />
              </Grid>

              <Grid item xs={6}>
                <TextFieldForm label="Description" name="desc" value={formData.desc} onChange={this.handleChange} maxLength={35} />
              </Grid>
              <Grid item xs={6}>
                <TextFieldForm
                  label="Email"
                  name="email"
                  value={formData.email}
                  validations={["multiEmail"]}
                  onChange={this.handleChange}
                  maxLength={50}
                  ref={this.refEmail}
                />
              </Grid>

              <Grid item xs={6}>
                <DropdownField
                  labelOutside="Language*"
                  options={optLangdto}
                  name="langdto.langid"
                  validations={["required"]}
                  onChange={value =>this.handleSelect("langdto.langid", value)}
                  ref={this.refLang}
                  value={formData.langdto?.langid}
                  clearValue={()=>this.clearValue("langdto.langid")}
                />
              </Grid>
              <Grid item xs={6}>
                <CheckboxWrapper>
                  <CDLCheckbox
                    label="Account suspended"
                    id="account-suspended"
                    name="suspended"
                    checked={formData.suspended}
                    onChange={this.handleCheck}
                    value="suspended"
                  />
                </CheckboxWrapper>
              </Grid>


              <Grid item xs={6}>
                <CDLButton
                  style={{marginTop: '23px', marginRight: '20px', float: 'right'}}
                  size="small"
                  disabled={disableButton}
                  onClick={this.handleGenPwd}
                >
                  {this.props.edit ? 'Reset' : 'Generate'} password
                </CDLButton>
              </Grid>
              {!this.isAC &&
              <Grid item xs={6}>
                <TextFieldForm
                  label="Password value"
                  name="password"
                  value={formData.generatedPassword}
                  maxLength={35}
                  disabled
                  validations={["required"]}
                  ref={this.refPassword}
                />
              </Grid>}

              {this.isAC &&
              <>
                <Grid item xs={6}>
                  <TextFieldForm
                    label={this.props.edit ? "Reference number" : "Reference number*"}
                    name="refno"
                    value={formData.refno}
                    onChange={this.handleChange}
                    maxLength={15}
                    ref={this.refNo}
                    disabled={this.state.disableRefNO}
                    invalid={invalid}
                    invalidMessage={invalidMessage}
                  />
                </Grid>
              </>}

              {this.isShowProCtr &&
              <>
                <Grid item xs={6}>
                  <DropdownField
                    labelOutside="Processing centre*"
                    options={this.proctrOpt}
                    name="proctrid"
                    validations={["required"]}
                    onChange={value =>this.handleSelect("proctrdto.proctrid", value)}
                    ref={this.refProc}
                    value={formData.proctrdto?.proctrid}
                    clearValue={this.clearValue}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DropdownField
                    labelOutside="Company*"
                    options={this.state.companyOpt}
                    name="company"
                    validations={["required"]}
                    onChange={value =>this.handleSelect("companydto.codocexpressid", value)}
                    ref={this.refCompany}
                    value={formData.companydto?.codocexpressid}
                    clearValue={()=>this.clearValue("companydto.codocexpressid")}
                  />
                </Grid>
              </>}

              {this.isShowCountry &&
              <>
                <Grid item xs={6}>
                  <DropdownField
                    labelOutside="Country / Territory code*"
                    options={this.countryOpt}
                    name="countrycode"
                    validations={["required"]}
                    onChange={value =>this.handleSelect("countrycode", value)}
                    ref={this.refProc}
                    value={formData.countrycode}
                    clearValue={this.clearValue}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DropdownField
                    labelOutside="Group member ID*"
                    options={this.state.groupMemberOpt}
                    name="groupmemberid"
                    validations={["required"]}
                    onChange={value =>this.handleSelect("groupmemberid", value)}
                    ref={this.refCompany}
                    value={formData.groupmemberid}
                    clearValue={this.clearValue}
                  />
                </Grid>
              </>}

            </Grid>

          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.props.close}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button onClick={this.handleOpenConfirmPopup} themeColor="primary">Save</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}
