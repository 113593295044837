import CDLIcon from "@cdl/icon";
import Icon from "common/components/Icon/Icon";
import React, { Component } from 'react';
import styled from "styled-components";

const ButtonEditMode = styled.button`
    height: 22px;
    position: relative;
    border: none;
    background: white;
    top: 5px;
    padding: 0 5px;
    outline: none;
    border-left: 1px solid #767676;

    &.focus {
      outline: none !important;
    }

`;

const Button = styled.button`
    height: 22px;
    position: relative;
    border: none;
    background: none;
    padding: 0 5px;
    outline: none;
    top: -3px;
`;

const Container = styled.div`
    display: flex;
    position: relative;
    width: ${props => props.width ? props.width : '100%'};
    justify-content: space-between;
    background: ${props => props.isEdit ? 'white': 'transparent'};
    border: ${props => props.isEdit ? '1px solid black' : 'none'};

    #${props => props.containerId} {
        width: calc(100% + 18px);
    }

    & .option-active{
        background-color: #f1f1f1;
    }

    &:focus{
        outline: none;
    }
`;

const Input = styled.input`
    box-sizing: border-box;
    height: 30px;
    width: ${props => props.width ? props.width : '100%'};
    outline: none;
    font-family:HSBC-Universe-Medium;
    border: none;
    &:focus{
        outline: none !important;
    }
`;


export default class EditableHeader extends Component {


  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      value: props.value,
    };
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    console.log(e);
    if (this.ref.current && !this.ref.current.contains(e.target)) {
        this.setState({
            isEdit: false
        });
    }
  }

  onChange = (event) =>{
    const {value} = event.target;
    this.setState(
      {value},
      this.props.onChange(value)
    );
  }


  onButtonClick = () => {
    this.setState(prevState => ({
      isEdit: !prevState.isEdit,
    }));
  };

  onBlur = () => {
    console.log("onBlur");
    this.setState({
      isEdit: false
    });
  };

  handleClickOutside(e) {
      if (this.ref.current && !this.ref.current.contains(e.target)) {
          this.setState({
              isEdit: false
          });
      }
  }

  render() {
    const {width} = this.props;
    const {value} = this.state;


    let icon;
    let textField;
    let button;
    if(this.state.isEdit){
        icon = <CDLIcon name='edit' size={18} />;
        textField = <Input
          type="text"
          onChange={this.onChange}
          value={value}
          onBlur={this.onBlur}
          width={width}
        />;
        button = (
          <ButtonEditMode title="Open" type="button" onClick={this.onButtonClick}>
            {icon}
          </ButtonEditMode>
                 );
    }else{
        icon = <Icon name='edit' size={18} />;
        textField = (<div style={{maxWidth: this.props.width, overflow: 'hidden'}}>{value}</div>);
        button = (
          <Button title="Open" type="button" onClick={this.onButtonClick}>
            {icon}
          </Button>
                 );
    }


    return (
      <div ref={this.ref}>
        <Container containerId={this.props.id} isEdit={this.state.isEdit}>
          {textField}
          {button}
        </Container>
      </div>

    );
  }
}


