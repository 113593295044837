import React, { useState, useEffect } from 'react';
import Table from 'common/components/Table/Table';
import Grid from '@material-ui/core/Grid';
import IconButton from "common/components/IconButton/IconButton";
import ActionButtons from "common/components/ActionButtons/ActionButtons";
import ProductAddDetail from "common/views/Popup/ProductAddDetail";
import ProductService from 'common/services/product.service';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import Msg from "common/views/Popup/Msg";

const buttonArray = [
  { id: 'openEdit', name: 'Edit', showModal: 'openEditProductPopup' },
  { id: 'openDelete', name: 'Delete', showModal: 'showDeleteRequestMessage' }
];

export default function Product() {
  const [tableData, setTableData] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [openProductPopup, setProductPopupOpen] = useState(false);
  const [openDeletePopup, setDeletePopupOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editProps, setEditProps] = useState({});
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);


  useEffect(() => {
    const fetchTableData = () => {
      ProductService.loadProduct().then(response => {
        if (response && response.list) {
          setTableData(response.list);
        }
      }).catch(error => {
        throw error;
      });
    }
    fetchTableData();
  }, [isRefresh]);

  // use async await pair if necessary
  useEffect(() => {
    const fetchEditData = () => {
      const { exportercode, prodcode } = tableData.find((item, index) => index === selectedIndex);
      ProductService.getProduct([exportercode, prodcode]).then(response => {
        if (response) {
          setEditProps(response);
          setProductPopupOpen(true);
        }
      }).catch(error => {
        throw error;
      });
    }
    if (isEdit) {
      fetchEditData();
    }

  }, [isEdit]);

  const showSuccessMessage = (msg) => {
      setMessage(msg);
      setSuccess(true);
      setIsRefresh(!isRefresh);
      setIsEdit(false);
  }

  const openEditProductPopup = (rowIndex) => {
    setSelectedIndex(rowIndex);
    setIsEdit(true);
  }

  const showDeleteRequestMessage = (rowIndex) => {
    setSelectedIndex(rowIndex);
    setDeletePopupOpen(true);
  }

  const closeDeletePopup = () => {
    setSelectedIndex(null);
    setDeletePopupOpen(false);
  }

  function closeProductPopup() {
    setIsEdit(false);
    setProductPopupOpen(false);
  }

  const closeMessage = () => {
    setMessage("");
    setSuccess(false);
  }

  const deleteProduct = () => {
    const { exportercode, prodcode } = tableData.find((item, index) => index === selectedIndex);
    setSelectedIndex(selectedIndex);
    ProductService.deleteProduct([exportercode, prodcode]).then(response => {
      if (response) {
        showSuccessMessage(`Product "${tableData[selectedIndex]?.prodcode}" for drawer "${tableData[selectedIndex]?.exportercode}" has been deleted successfully`.bold());
      }
    }).then(() => {
      setDeletePopupOpen(false);
    }).catch(error => {
      throw error;
    });
  }

  const displayData = tableData.filter(d => !d.deleted).map((t, i) => {
    return Object.assign(t, {
      actionBtn: <ActionButtons
        rowIndex={i}
        buttonArray={buttonArray}
        openEditProductPopup={openEditProductPopup}
        showDeleteRequestMessage={showDeleteRequestMessage}
        justify='flex-start'
      />
    });
  });

  const getDisplayName = () => {
    let displayName = tableData[selectedIndex]?.prodcode;
    if(tableData[selectedIndex]?.proddesc){
      displayName += ` - ${tableData[selectedIndex]?.proddesc}`;
    }
    return displayName;
  }

  const newProduct = () => {
    setProductPopupOpen(true);
    setIsEdit(false);
    setEditProps(null);
  }

  return (
    <div>
      <Msg
        title="Are you sure?"
        content={`Do you wish to delete the product "${getDisplayName()}" for drawer "${tableData[selectedIndex]?.exportercode}"?`}
        width={500}
        confirm={deleteProduct}
        isOpen={openDeletePopup}
        close={closeDeletePopup}
        warning
      />
      <Grid container spacing={0} style={{ paddingTop: '20px' }}>
        {success &&
          <Grid item xs={12}>
            <SuccessMessage message={message} handleCloseButtonClick={closeMessage} />
          </Grid>}
        <Grid item xs={10} />
        <Grid item xs={2}>
          <div className="rightBtn" style={{ paddingRight: '0px' }}>
            <IconButton onClick={newProduct} name="add">Add new product</IconButton>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Table
            maxRows={12}
            showPagination={false}
            defaultPageSizeOption={{'value':0}}
            data={displayData}
            columns={[
              {
                Header: 'Drawer ID',
                accessor: 'exportercode',
                maxWidth: 230
              },
              {
                Header: 'Product code',
                accessor: 'prodcode',
                Cell: row => <span title={row.original.prodcode}>{row.original.prodcode}</span>
              },
              {
                Header: 'Product description',
                accessor: 'proddesc',
                Cell: row => <span title={row.original.proddesc}>{row.original.proddesc}</span>
              },
              {
                Header: 'Action',
                accessor: 'actionBtn',
                maxWidth: 140,
                sortable: false
              }
            ]}
          />
        </Grid>
      </Grid>
      {openProductPopup &&
      <ProductAddDetail
        isOpen={openProductPopup}
        edit={isEdit}
        editProps={editProps}
        close={closeProductPopup}
        success={showSuccessMessage}
      />}
    </div>
  );
}
