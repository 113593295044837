import BaseService from 'common/services/BaseService';
import UserService from 'common/services/user.service';
import { masterDocDefaultValue } from 'common/utils/DocPrepConstant';
import { objectToEmptyString } from 'common/utils/ObjectUtil';
import { isBlank } from 'common/utils/StringUtils';

const _ = require('lodash');


class MasterDocService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/MasterDoc/';
  }

  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  postWithStatus(url, paras, showLoading) {
    return super.postReturnWithStatus(this.basePath + url, paras, showLoading);
  }

  getExportInstruction(mdocid) {
    const requestBody = {
      "mdocid": mdocid,
      ...this.getUserDTO()
    }
    return super.postReturnWithStatus('/api/ExportInstructionBO/getExportInstruction', requestBody, true);
  }

  getMasterDoc(mdocid, mdocType) {
    const requestBody = {
      "mdocid": mdocid,
      "mdoctype": mdocType,
      ...this.getUserDTO()
    }

    if(mdocType==='TM'){
      return this.getExportInstruction(mdocid).then(res=>{
        if(res){
          return this.postWithStatus('getMasterDoc', requestBody, true).then(resp=>{
            if(resp && resp.statusCode === 200){
              resp.data.eidto = res?.data;
              return resp;
            }
            return {};
          });
        }
        return this.postWithStatus('getMasterDoc', requestBody, true);

      })
    }

    return this.postWithStatus('getMasterDoc', requestBody, true);
  }

  getMasterDocBankDetail(bankCode) {
    const requestBody = {
      "bankcode": bankCode,
      ...this.getUserDTO()
    }

    return this.post('getMasterDocBankDetail', requestBody, true);
  }

  getMasterDocBankList() {
    return this.postWithStatus('getMasterDocBankList', this.getUserDTO(), true);
  }

  getMasterDocBankList2(codocid) {
    const requestBody = {
      "codocexpressid": codocid,
      ...this.getUserDTO()
    }
    return this.postWithStatus('getMasterDocBankList2', requestBody, true);
  }

  getMasterDocAddField() {
    const requestBody = {
      "codocid": this.getDocExpressId(),
      "moduleid": "DocPrep",
      ...this.getUserDTO()
    }
    return this.post('getMasterDocAddField', requestBody, true);
  }

  getMasterDocCountry() {
    return this.post('getMasterDocCountry', this.getUserDTO());
  }

  getMasterDocCurrencyCode(codocexpressid) {
    const requestBody = {
      "codocexpressid": codocexpressid || this.getDocExpressId(),
      ...this.getUserDTO()
    }
    return this.post('getMasterDocCurrencyCode', requestBody);
  }

  getMasterDocPortName() {
    return this.post('getMasterDocPortName', this.getDocExpressIdObj());
  }

  getMasterDocCommodityDesc() {
    return this.post('getMasterDocCommodityDesc', this.getDocExpressIdObj());
  }

  getMasterDocExpCode(codocexpressidOptional) {
    return this.post('getMasterDocExpCode', this.getDocExpressIdObj(codocexpressidOptional));
  }

  getMasterDocExporter(codocexpressidOptional) {
    return this.post('getMasterDocExporter', this.getDocExpressIdObj(codocexpressidOptional));
  }

  getMasterDocExporterWithTradeID(codocexpressidOptional) {
    return this.post('getMasterDocExporterWithTradeID', this.getDocExpressIdObj(codocexpressidOptional));
  }

  getMasterDocForwAgentCode() {
    return this.post('getMasterDocForwAgentCode', this.getDocExpressIdObj());
  }

  getMasterDocImpCode(codocexpressidOptional) {
    return this.post('getMasterDocImpCode', this.getDocExpressIdObj(codocexpressidOptional));
  }

  getMasterDocImporter(codocexpressidOptional) {
    return this.post('getMasterDocImporter', this.getDocExpressIdObj(codocexpressidOptional));
  }

  getMasterDocIncoTerm() {
    return this.post('getMasterDocIncoTerm', this.getUserDTO());
  }

  getMasterDocVessel(codocexpressid) {
    const requestBody = {
      "codocexpressid": codocexpressid,
      ...this.getUserDTO()
    }
    return this.post('getMasterDocVessel', requestBody);
  }

  getMasterDocMDocID(countryCode1, groupMemberID1, strFilename, strFormName, strProcessCtrID, strSaveAsType) {
    const requestBody = {
      "codocexpressid": this.getDocExpressId(),
      "countrycode1": countryCode1,
      "groupmemberid1": groupMemberID1,
      "strfilename": strFilename,
      "strformname": strFormName,
      "strprocessctrid": strProcessCtrID,
      "strsaveastype": strSaveAsType,
      ...this.getUserDTO()
    }

    return new Promise((resolve, reject) => {
      this.postWithStatus('getMasterDocMDocID', requestBody, true).then(res => {
        const respData = res.data;
        if (respData===-2 || respData===-3) {
          reject(respData);
        } else {
          resolve(respData);
        }
      })
    });
  }

  getMasterDocTenor(codocexpressidOptional) {
    return this.post('getMasterDocTenor', this.getDocExpressIdObj(codocexpressidOptional));
  }

  getDocTypeList() {
    return this.post('getDocTypeList', this.getInUserDTO());
  }

  getDocTitles() {
    return this.post('getDocTitles', this.getInUserDTO());
  }

  executeSQLSP(){
    const requestBody = {
      "spname": "lp_GetParametersByGroupID",
      "args": ["ReferenceNumber"],
      ...this.getInUserDTO()
    }
    return this.post('executeSQLSP', requestBody);
  }

  initMasterDoc() {
    return new Promise((resolve, reject) => {
      this.post('initMasterDoc', this.getUserDTO(), true).then(response => {
        if (response) {
          if(UserService.getCurrentUser()?.legalentity){
            masterDocDefaultValue.billofexchangedto.paytotheorderof = UserService.getCurrentUser().legalentity;
          }
          response = Object.assign(response, _.cloneDeep(masterDocDefaultValue));

          this.checkCurrency().then(res => {
            if(!res){
              response.currencycode = '';
              response.invgrandtotalinwords = '********** Zero Only **********';

              response.billofexchangedto.boecurrencycode = '';
              response.billofexchangedto.billamtinwords = '********** Zero Only **********';
              resolve(response);
            }else{
              resolve(response);
            }
          })
        } else {
          reject('ERROR');
        }
      })
    })
  }

  addExportInstruction(exportInstructionDTO, masterDocDTO) {
    exportInstructionDTO.expinstrid = "";
    exportInstructionDTO.mdocid = masterDocDTO.mdocid;
    exportInstructionDTO.fromproceeddeduct = exportInstructionDTO.fromproceeddeduct ? parseFloat(exportInstructionDTO.fromproceeddeduct) : 0;
    exportInstructionDTO.interestrate = exportInstructionDTO.interestrate ? parseFloat(exportInstructionDTO.interestrate) : 0;
    exportInstructionDTO.loans_amount = exportInstructionDTO.loans_amount ? parseFloat(exportInstructionDTO.loans_amount) : 0;
    exportInstructionDTO.proceeds_amount = exportInstructionDTO.proceeds_amount ? parseFloat(exportInstructionDTO.proceeds_amount) : 0;
    exportInstructionDTO.deductamt = exportInstructionDTO.deductamt ? parseFloat(exportInstructionDTO.deductamt) : 0;
    exportInstructionDTO.applyproceedstoexploan = exportInstructionDTO.applyproceedstoexploan ? exportInstructionDTO.applyproceedstoexploan : false;
    exportInstructionDTO.fromproceed = exportInstructionDTO.fromproceed ? exportInstructionDTO.fromproceed : false;

    const requestBody = {
      "exportinstructiondto": exportInstructionDTO,
      "masterdocdto": masterDocDTO,
      ...this.getUserDTO()
    }
    return super.post('/api/ExportInstructionBO/updateForm', requestBody, true);
  }

  constructorRequestBody(masterDoc, metadata, newMasterDocID) {
    masterDoc.mdocid = newMasterDocID;
    masterDoc.mdoctype = metadata.mdoctype;
    if(!masterDoc.tmplmdocid){
      masterDoc.tmplmdocid = masterDoc.mdocid;
    }

    return {
      "masterdocdto": masterDoc,
      ...this.getUserDTO()
    }
  }

  masterDocSaveNewForm(masterDoc, metadata) {
    const userDTO = this.getUserDTO();
    this.massageMasterDocBeforeSubmission(masterDoc, userDTO);
    masterDoc.thirdpartydoc = isBlank(masterDoc.thirdpartydoc) ? [] : masterDoc.thirdpartydoc;
    masterDoc.billofexchangedto = isBlank(masterDoc.billofexchangedto) ? {} : masterDoc.billofexchangedto;
    masterDoc.invoicedetaildto = isBlank(masterDoc.invoicedetaildto) ? [] : masterDoc.invoicedetaildto;
    masterDoc.mdocdesc = metadata.mdocdesc ? metadata.mdocdesc : '';
    const {user} = userDTO.userdto;
    masterDoc.tmplmdocid = masterDoc.mdocid;

    return new Promise((resolve, reject) => {
      this.getMasterDocMDocID(user.codocexpressid, user.groupmemberid, '', '', user.codocexpressid, metadata.mdoctype).then(newMasterDocID => {
        const requestBody = this.constructorRequestBody(masterDoc, metadata, newMasterDocID);

        this.post('masterDocSaveNewForm', requestBody, true).then(res => {
          if (res) {
            if(masterDoc.tmplmdocid.startsWith("TM")){
              resolve(newMasterDocID);
            }else{
              this.addExportInstruction(masterDoc.eidto, masterDoc).then(()=>{
                resolve(newMasterDocID);
              })
            }
          }
        })
      }, (err) => {
        reject(err);
      })
    })

  }

  updateMasterDoc(masterDoc) {
    this.removeActionButton(masterDoc);
    const userDTO = this.getUserDTO();
    masterDoc.mdoctype = masterDoc.mdoctype?.toUpperCase();
    this.massageMasterDocBeforeSubmission(masterDoc, userDTO);

    const requestBody = {
      "masterdocdto": masterDoc,
      ...userDTO
    }

    return this.postWithStatus('updateMasterDoc', requestBody, true);
  }

  updateMasterDocWithPackingList(masterDoc) {
    masterDoc.formid = 'PL';
    return this.updateMasterDoc(masterDoc);
  }

  massageMasterDocBeforeSubmission(masterDoc, userDTO) {
    objectToEmptyString(masterDoc);
    Object.keys(userDTO.userdto.user).forEach(key => {
      if (key !== 'username' && key !== 'countrycode' && key !== 'groupmemberid' && key !== 'codocexpressid' && key !== 'userroles' && key !== 'encryptsid' && key !== 'formname') {
        delete userDTO.userdto.user[key];
      }
    })

    this.removeActionButton(masterDoc);

    if (!masterDoc.invgrandtotal) {
      masterDoc.invgrandtotal = 0;
    } else {
      masterDoc.invgrandtotal = parseFloat(masterDoc.invgrandtotal);
    }

    masterDoc.thirdpartydoc = isBlank(masterDoc.thirdpartydoc) ? [] : masterDoc.thirdpartydoc;
    masterDoc.billofexchangedto = isBlank(masterDoc.billofexchangedto) ? {} : masterDoc.billofexchangedto;
    masterDoc.invoicedetaildto = isBlank(masterDoc.invoicedetaildto) ? [] : masterDoc.invoicedetaildto;

    masterDoc.mdocid = masterDoc.mdocid || '';
    masterDoc.eidto = masterDoc.eidto || {};
    masterDoc.verno = masterDoc.verno || '1.0';

    if (masterDoc.formid !== 'PL') {
      masterDoc.formid = 'MD';
    }
    masterDoc.codocexpressid = userDTO.userdto.user.codocexpressid;
    masterDoc.countrycode = userDTO.userdto.user.countrycode;
    masterDoc.username = userDTO.userdto.user.username;
    masterDoc.bolvarform = 1;
  }

  saveOtherDocs(docid, mdocid, docDetail) {
    const requestBody = {
      "docid": docid,
      "mdocid": mdocid,
      "thirdpartydocdto": docDetail,
      ...this.getInUserDTO()
    }

    return this.post('saveOtherDocs', requestBody, true);
  }

  deleteMasterDoc(mdocid, mdoctype, formtype) {
    const userDTO = this.getUserDTO();

    const requestBody = {
      "mdoctype": mdoctype,
      "mdocid": mdocid,
      "formtype": formtype,
       ...userDTO
    };
    return this.postWithStatus('deleteMasterDoc', requestBody, true);
  }

  deleteOtherDocs(docid, mdocid, docimage, doctitle) {
    const requestBody = {
      "docid": docid,
      "docimage": docimage || "0",
      "doctitle": doctitle,
      ...this.getInUserDTO(),
      "mdocid": mdocid
    }

    return this.post('deleteOtherDocs', requestBody, true);
  }

  getDocFile(docid, mdocid, querytype) {
    const requestBody = {
      "docid": docid,
      "mdocid": mdocid,
      "querytype": querytype,
      ...this.getInUserDTO()
    }

    return this.post('getDocFile', requestBody);
  }


  getDiscrepancy() {
    return this.post('getDiscrepancy', this.getInUserDTO());
  }

  getDocStatus() {
    return this.post('getDocStatus', this.getInUserDTO());
  }

  getDiscrepancyDetails(docid, querytype) {
    const requestBody = {
      "docid": docid,
      "querytype": querytype,
      ...this.getInUserDTO()
    }

    return this.post('getDiscrepancyDetails', requestBody);
  }

  getCommentDetails(docid, querytype) {
    const requestBody = {
      "docid": docid,
      "querytype": querytype,
      ...this.getInUserDTO()
    }

    return this.post('getCommentDetails', requestBody);
  }

  saveCommentDiscrepancyDetails(id, docid, discrepancy) {
    const requestBody = {
      "id": id,
      "docid": docid,
      "commentdiscrepancydto": discrepancy,
      ...this.getInUserDTO()
    }

    return this.post('saveCommentDiscrepancyDetails', requestBody, true);
  }

  saveDiscrepancyFix(id, discrepancy) {
    const requestBody = {
      "id": id,
      "commentdiscrepancydto": discrepancy,
      ...this.getInUserDTO()
    }

    return this.post('saveDiscrepancyFix', requestBody, true);
  }

  chkLFCompanyEDS() {
    return this.post('chkLFCompanyEDS', this.getUserDTO());
  }

  approveUnlockMasterDocScanCheck(strstatus, masterDoc) {
    this.removeActionButton(masterDoc);
    const userdto = this.getUserDTO();
    const user = UserService.getCurrentUser();
    userdto.userdto.user.submodules = user.submodules;
    userdto.userdto.user.userroles = user.userroles;

    const requestBody = {
      "strstatus": strstatus,
      "masterdocdto": masterDoc,
      ...userdto
    }

    return this.post('approveUnlockMasterDocScanCheck', requestBody, true);
  }

  updateStatus(strstatus, masterDoc) {
    this.removeActionButton(masterDoc);
    const userdto = this.getUserDTO();
    const user = UserService.getCurrentUser();
    userdto.userdto.user.submodules = user.submodules;
    userdto.userdto.user.userroles = user.userroles;

    const requestBody = {
      "strstatus": strstatus,
      "masterdocdto": masterDoc,
      ...userdto
    }

    return this.post('updateStatus', requestBody, true);
  }

  printMasterDocScanCheck(strstatus, masterDoc) {
    this.removeActionButton(masterDoc);
    const userdto = this.getUserDTO();
    userdto.userdto.user.submodules = UserService.getCurrentUser().submodules;
    userdto.userdto.user.userroles = UserService.getCurrentUser().userroles;
    const requestBody = {
      "strstatus": strstatus,
      "masterdocdto": masterDoc,
      ...this.getMaxUserDTO()
    }

    return this.post('printMasterDocScanCheck', requestBody, true);
  }

  get3rdPartyDocs(mdocid) {
    const requestBody = {
      "mdocid": mdocid,
      ...this.getInUserDTO()
    }

    return this.post('get3rdPartyDocs', requestBody);
  }

  get3rdPartyDocsForAccessControl(mdocid, querytype) {
    const requestBody = {
      "mdocid": mdocid,
      ...this.getInUserDTO(),
      querytype
    }

    return this.post('get3rdPartyDocs', requestBody, true);
  }

  getMasterDocExpDetailsByCode(sexpcodeid, codocexpressidOptional) {
    const requestBody = {
      "scodocexpressid": codocexpressidOptional || this.getDocExpressId(),
      "sexpcodeid": sexpcodeid,
      ...this.getUserDTO()
    }

    return this.post('getMasterDocExpDetailsByCode', requestBody);
  }

  getMasterDocImpDetailsByCode(simpcodeid, codocexpressid) {
    const requestBody = {
      "scodocexpressid": codocexpressid || this.getDocExpressId(),
      "simpcodeid": simpcodeid,
      ...this.getUserDTO()
    }

    return this.post('getMasterDocImpDetailsByCode', requestBody);
  }

  getHouseKeepingBalance(strdoctype) {
    const requestBody = {
      "strdoctype": strdoctype,
      ...this.getUserDTO()
    }

    return this.post('GetHousekeepingBalance', requestBody);
  }

  checkCurrency() {
    return this.post('checkCurrency', this.getUserDTO());
  }

  sendWolfScan(masterDoc, searchmode, strstatus) {
    const requestBody = {
      masterdocdto: masterDoc,
      searchmode,
      strstatus,
      ...this.getUserDTO()
    }
    return this.postWithStatus('sendWolfScan', requestBody, true);
  }

  removeDocFile(docid, mdocid) {
    const requestBody = {
      docid,
      mdocid,
      ...this.getInUserDTO()
    }
    return this.post('removeDocFile', requestBody);
  }

  // eslint-disable-next-line class-methods-use-this
  removeActionButton(values) {
    if(values?.invoicedetaildto){
      values.invoicedetaildto.forEach(invoicedetail=>{
        delete invoicedetail.actionBtn;
        if (invoicedetail.invitemdetaildtolist) {
          invoicedetail.invitemdetaildtolist.forEach(invoiceItemDetail=>{
            delete invoiceItemDetail.accountNumber;
          });
        }
      });
    }

    if(values?.otherdocscommentlist){
      values.otherdocscommentlist.forEach(otherDocsComment=>{
        delete otherDocsComment.actionBtn;
      });
    }

    if(values?.thirdpartydoc){
      values.thirdpartydoc.forEach(thirdPartydocItem=>{
        delete thirdPartydocItem.actionBtn;
      });
    }
  }

  initDrawee() {
    return this.post('initDrawee', this.getUserDTO(), true);
  }

  getCreatorDetails(formtype, masterDoc, mdoctype) {
    this.removeActionButton(masterDoc);
    const userdto = this.getUserDTO();
    const user = UserService.getCurrentUser();
    userdto.userdto.user.approvalright = user.approvalright;

    if(masterDoc.eidto===""){
      masterDoc.eidto = {};
    }

    const requestBody = {
      "formtype": formtype,
      "masterdocdto": masterDoc,
      "mdoctype": mdoctype,
       ...userdto
    };
    return this.post('getCreatorDetails', requestBody, true);
  }

  filterMasterDoc(filter) {
    const userdto = this.getUserDTO();
    const requestBody = {
      filter,
      ...userdto
    };
    return this.post('filterMasterDoc', requestBody, true);
  }

  filterMasterDocTemplate(filter) {
    const userdto = this.getUserDTO();
    const requestBody = {
      filter,
      ...userdto
    };
    return this.post('filterMasterDocTemplate', requestBody, true);
  }

  filterDC(filter) {
    const requestBody = {
      'dcfilter': filter,
    };
    return this.post('filterDC', requestBody, true);
  }

  getTradeIDs() {
    const userdto = this.getUserDTO();
    const requestBody = {
      ...userdto
    };
    return this.post('getTradeIDs', requestBody, true);
  }

  getExporterByTradeID(tradeid) {
    const userdto = this.getUserDTO();
    const requestBody = {
      ...userdto,
      tradeid
    };
    return this.post('getExporterByTradeID', requestBody, true);
  }

  getImporter(importername, countrycode) {
    const userdto = this.getUserDTO();
    const requestBody = {
      ...userdto,
      importername,
      countrycode
    };
    return this.post('getImporter', requestBody, true);
  }

  getImporterBank(bankcode, bankname, bankcountry) {
    const userdto = this.getUserDTO();
    const requestBody = {
      ...userdto,
      bankcode,
      bankname,
      bankcountry
    };
    return this.post('getImporterBank', requestBody, true);
  }
}

export default new MasterDocService();
