import BaseService from 'common/services/BaseService';
import { isAC } from "common/utils/AppUtil";

class VesselService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/VesselBO/';
  }


  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  addVessel(vesselDTO) {
    const inUserDTO = this.getInUserDTO();
    let requestBody = null;
    if (isAC()) {
      requestBody = {
        "vessel": {
          "coDocExpressID": vesselDTO.codocexpressid,
          "vesselDesc": vesselDTO.vesselDesc,
          "vesselID": vesselDTO.vesselDesc
        },
        ...inUserDTO
      }
    } else {
      requestBody = {
        "vessel": {
          "codocexpressid": this.getDocExpressId(),
          "vesseldesc": vesselDTO.vesseldesc,
          "vesselid": vesselDTO.vesseldesc
        },
        ...inUserDTO
      }
    }
    return this.axiosPost(`${this.basePath}addVessel`, requestBody, true);
  }

  deleteVessel(vesselDTO) {
    const inUserDTO = this.getInUserDTO();
    delete vesselDTO.actionBtn;
    const requestBody = {
      "vessel": {
        ...vesselDTO
      },
      ...inUserDTO
    }
    return this.post('deleteVessel', requestBody, true);
  }

  getVesselList(payload, caFlag = false) {
    const inUserDTO = this.getInUserDTO();
    let requestBody = {};
    if (caFlag) {
      requestBody = {
        "codocexpressid": payload?.incompanydto.coDocExpressID,
        ...inUserDTO
      }
    } else {
      requestBody = {
        "codocexpressid": this.getDocExpressId(),
        ...inUserDTO
      }
    }
    return this.post('getVesselList', requestBody, true);
  }

  getCarrierList(codocexpressid) {
    const requestBody = {
      "codocexpressid": codocexpressid,
      ...this.getInUserDTO()
    }
    return this.post('getVesselList', requestBody, true);
  }

  updateVessel(vesselDTO) {
    const inUserDTO = this.getInUserDTO();
    delete vesselDTO.actionBtn;
    const requestBody = {
      "vessel": {
        ...vesselDTO,
      },
      ...inUserDTO
    };

    return this.axiosPost(`${this.basePath}updateVessel`, requestBody, true);
  }

}

export default new VesselService();
