import axios from "axios";
import UserService from 'common/services/user.service';
import { isAC, getFormName } from "common/utils/AppUtil";

export default class BaseService {
  constructor() {

    if(isAC()){
      this.apiPrefix = '/AccessControl2';
    }else{
      this.apiPrefix = '/DocExpress2';
    }

  }

  // eslint-disable-next-line class-methods-use-this
  post(url, paras, showLoading) {

    return this.postReturnWithStatus(url, paras, showLoading)
      .then(response => {
        if (response && response.statusCode === 200) {
          return response.data;
        }
        return false;
      })
  }

  postReturnWithStatus(url, paras, showLoading) {
      return axios
      .post(this.apiPrefix + url, paras, { 'showLoading': showLoading, withCredentials: true } )
      .then(response => {
        return {statusCode: response.status, data: response.data};
      })
      .catch(error => {
        console.log(error);
      });
  }

  axiosPost(url, paras, showLoading) {
    return axios.post(this.apiPrefix + url, paras, { 'showLoading': showLoading, withCredentials: true } ).then(response => {
      if (response && response.status === 200) {
        return response.data;
      }
      return false;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getUserObj(){
    const user = UserService.getCurrentUser() || {};

    return {
      "user": {
        "username": user.username,
        "countrycode": user.countrycode,
        "groupmemberid": user.groupmemberid,
        "codocexpressid": user.codocexpressid,
        "userroles": user.userroles,
        "encryptsid": user.encryptsid,
        "formname": getFormName(),
        "modules": user.modules,
        "submodules": user.submodules,
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getMaxUserDTO(){
    return {
      "userdto": {
        "user": {
          ...UserService.getCurrentUser()
        }
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getUserDTO(){
    return {
        "userdto": {
          ...this.getUserObj()
        }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getInUserDTO(){
      return {
          "inuserdto": {
            ...this.getUserObj()
          }
      }
  }

  // eslint-disable-next-line class-methods-use-this
  getDocExpressId(){
    return UserService.getCurrentUser().codocexpressid;
  }

  getDocExpressIdObj(codocexpressid){
    const userDTO = this.getUserDTO();
    return {
    "codocexpressid": codocexpressid || this.getDocExpressId(),
    ...userDTO
    }
}


}


