import Collapsible from 'common/components/Collapsible/Collapsible';
import { setMasterDoc, setMasterDocState } from 'common/redux/actions';
import masterDocService from 'common/services/masterDoc.service';
import { fixActionBarBottom, initActionBarPosition } from 'common/utils/UserInterfaceUtil';
import React from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";
import UploadDocument from 'common/views/CommonPage/Sections/UploadDocument';
import AdditionalFields from './AdditionalFields';


const UploadDocumentWrapper = styled.div`
  &>div {
    padding-top: 30px;
  }

`;

class MasterDocSection extends React.Component {

  componentDidMount() {
    const masterDoc = this.props.location.state?.masterDoc || this.props.masterDoc;
    if (masterDoc?.mdocid) {
      const metaData = {mdocid: masterDoc.mdocid, mdoctype: masterDoc.mdoctype};
      masterDocService.getMasterDoc(metaData.mdocid, metaData.mdoctype).then(mdocResponse => {
        this.props.setMasterDoc(null);
        this.props.setMasterDoc(mdocResponse.data);
        this.props.setMasterDocState(mdocResponse.data.status);
      })
    }
    initActionBarPosition();
    fixActionBarBottom();
  }

  render() {
    return (
      <div>
        <Collapsible label="5. Additional fields" className="collapsible">
          <AdditionalFields />
        </Collapsible>
        <Collapsible label="6. Upload document" className="collapsible last">
          <UploadDocumentWrapper>
            <UploadDocument />
          </UploadDocumentWrapper>
        </Collapsible>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values,
  docStatus: state.masterDocAction?.masterDocState || 'isNew'
})

const mapDispatchToProps = dispatch => {
  return {
    setMasterDoc: (data) => dispatch(setMasterDoc(data)),
    setMasterDocState: (data) => dispatch(setMasterDocState(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterDocSection)
