import { formateAmount } from 'common/utils/AmountFormatter';
import React from "react";
import NumberFormat from 'react-number-format';
import TextFieldForm from '../TextField/TextFieldForm';

export default class NumberField extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      value: formateAmount(props.value, props.decimalScale),
      decimalScale: props.decimalScale,
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.decimalScale !== this.state.decimalScale){
      this.setState({
        value: formateAmount(nextProps.value, nextProps.decimalScale),
        decimalScale: nextProps.decimalScale,
      });
    }
    if(!nextProps.reduxForm && nextProps.value !== this.state.value){
      this.setState({
        value: formateAmount(nextProps.value, nextProps.decimalScale)
      });
    } else if(nextProps.value !== this.state.value){
      this.setState({
        value: nextProps.value
      });
    }

  }


  handleChange = (values) => {
    const { value, formattedValue } = values;
    console.log("handleChange", this.props.name, value);
    this.setState({value});
    if (!this.props.reduxForm && this.props.onChange) {
      this.props.onChange(this.props.name, this.props.formatted ? formattedValue : value);
    }
  }

  onBlur = (event) => {
    const formatedValue = formateAmount(event.target.value, this.props.decimalScale);
    console.log(formatedValue);
    event.target.value = formatedValue;
    this.setState({value: formatedValue});

    if(this.props.onBlur){
      this.props.onBlur(event, formatedValue);
    }
  }

  render() {
    const {decimalScale, value, onBlur, noThousandSeparator, ...rest} = this.props;
    console.log(decimalScale);
    let thousandSeparator = true;
    if(noThousandSeparator){
      thousandSeparator = false;
    }

    return <NumberFormat
      customInput={TextFieldForm}
      thousandSeparator={thousandSeparator}
      fixedDecimalScale
      onValueChange={this.handleChange}
      onBlur={this.onBlur}
      value={this.state.value}
      {...rest}
    />
  }


}

