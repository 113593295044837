export const required = value => value && value.trim().length > 0 ? '' : 'This field is required'

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : ''

export const maxLength20 = maxLength(20);

const minLength = min => value =>
  value && value.length < min ? `Must be at least ${min} characters` : ''

export const minLength12 = minLength(12);

export const number = value => value && Number.isNaN(Number(value)) ? 'Must be a number' : ''

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : ''

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : ''

export const oneUpper = value =>  value && !/^(?=.*[A-Z]).*$/.test(value) ?
  'Include at least one Uppercase letters (A-Z)' : ''

export const oneLower = value =>  value && !/^(?=.*[a-z]).*$/.test(value) ?
  'Include at least one Lowercase letters (a-z)' : ''

export const oneNumber = value =>  value && !/^(?=.*[\d]).*$/.test(value) ?
  'Include at least one Numbers (0-9)' : ''

// eslint-disable-next-line
export const oneSpecial = value =>  value && !/^(?=.*[!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~]).*$/.test(value) ?
  'Include at least one Special Characters (!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~)' : ''

// eslint-disable-next-line
export const consecutive = value =>  value && /([a-z0-9!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~])\1|([A-Z])\2/.test(value) ?
  'Cannot contain consecutive characters (aa, 22, ..)' : ''

export const nonSpace = value =>  value && /\s/.test(value) ?
  'Cannot contain blank (space) character' : ''
