import Autocomplete from 'common/components/Autocomplete/Autocomplete';
import React from 'react';
import RenderField from './RenderField';

const renderAutocomplete = ({ ...props }) => (
  <RenderField
    component={Autocomplete}
    reduxForm
    {...props}
  />
)

export default renderAutocomplete
