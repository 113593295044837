import BaseService from 'common/services/BaseService';
import UserService from 'common/services/user.service';
import { getFormName } from "common/utils/AppUtil";

const fields = ["bankCBIDCode", "coDocExpressID", "bankName", "bankRef", "bankTradeDept", "bankAddr1", "bankAddr2", "bankAddr3",
  "bankPostCode", "bankCity", "bankState", "bankCountry", "userUpdate", "dateUpdate", "deleted", "bankBranch", "defCountry", "wolfStatus"
];

const derivedPropsFromAddCollectingBank = ["jFBank_Field1", "jFBank_Field10", "jFBank_Field2", "jFBank_Field3", "jFBank_Field4", "jFBank_Field5",
  "jFBank_Field6", "jFBank_Field7", "jFBank_Field8", "jFBank_Field9", "jFBank_FieldLbl1", "jFBank_FieldLbl10", "jFBank_FieldLbl2", "jFBank_FieldLbl3",
  "jFBank_FieldLbl4", "jFBank_FieldLbl5", "jFBank_FieldLbl6", "jFBank_FieldLbl7", "jFBank_FieldLbl8", "jFBank_FieldLbl9"
];

class ImporterBankService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/CollectingBankBO/';
    this.userDTO = null;
  }

  getUserDTO() {
    const user = UserService.getCurrentUser();
    user.formname = getFormName();
    this.userDTO = {
      "userdto": {
        "user": user
      }
    }
    return this.userDTO;
  }

  // eslint-disable-next-line class-methods-use-this
  getDocExpressId() {
    return UserService.getCurrentUser().codocexpressid;
  }

  getDocExpressIdObj() {
    const userDTO = this.getUserDTO();
    return {
      "codocexpressid": this.getDocExpressId(),
      ...userDTO
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getExtraPropsFromUser() {
    const current = new Date();
    return {
      "dateUpdate": current,
      "deleted": true,
      "userUpdate": "",
      "wolfStatus": ""
    }
  }

  // eslint-disable-next-line class-methods-use-this
  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  // eslint-disable-next-line class-methods-use-this
  getDerivedPropsFromAddCollectingBank() {
    const obj = Object.assign(...derivedPropsFromAddCollectingBank.map(i => ({[i]: ""})));
    return {
      defCountry: null,
      ...obj
    }
  }

  addCollectingBank(importerBankDTO, coDocExpressID = "") {
    const userDTO = this.getUserDTO();
    const extraPropsFromUser = this.getExtraPropsFromUser();
    const propsFromCollectingBank = this.getDerivedPropsFromAddCollectingBank();
    const requestBody = {
      "incbankdto": {
        ...importerBankDTO,
        ...extraPropsFromUser,
        ...propsFromCollectingBank,
        "coDocExpressID": this.getDocExpressId()
      },
      ...userDTO
    }
    if (coDocExpressID) {
      requestBody.incbankdto.coDocExpressID = coDocExpressID;
    }
    return this.post('addCollectingBank', requestBody, true);
  }

  deleteCollectingBank(importerBankDTO) {
    const userDTO = this.getUserDTO();
    const obj = fields.concat(derivedPropsFromAddCollectingBank).reduce((i, v) => {
      i[v] = importerBankDTO[v];
      return i;
    }, {});
    const requestBody = {
      "incbankdto": {
        ...obj
      },
      ...userDTO
    }
    return this.post('deleteCollectingBank', requestBody, true);
  }

  getCollectingBank(selectedDTO) {
    const userDTO = this.getUserDTO();
    let requestBody = {};
    if (selectedDTO?.actionBtn) {
      delete selectedDTO.actionBtn;
      requestBody = {
        "incbankdto": {
          ...selectedDTO,
          "coDocExpressID": this.getDocExpressId()
        },
        ...userDTO
      }
    } else {
      requestBody = {
        "incbankdto": {
          "bankCBIDCode": selectedDTO,
          "coDocExpressID": this.getDocExpressId()
        },
        ...userDTO
      }
    }
    return this.post('getCollectingBank', requestBody, false);
  }

  loadCollectingBank(importerBankDTO) {
    const userDTO = this.getUserDTO();
    let requestBody = {};
    if (importerBankDTO && Object.keys(importerBankDTO).length !== 0) {
      requestBody = {
        ...importerBankDTO,
        ...userDTO
      }
    } else {
      requestBody = {
        ...userDTO
      }
    }
    return this.post('loadCollectingBank', requestBody, true);
  }

  saveCollectingBank(importerBankDTO) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      "incbankdto": {
        ...importerBankDTO
      },
      ...userDTO
    }
    return this.post('saveCollectingBank', requestBody, true);
  }

}

export default new ImporterBankService();
