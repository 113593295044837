import { Radio } from '@cdl/radio-group';
import Grid from '@material-ui/core/Grid';
import Divider from 'common/components/Divider/Divider';
import Heading from "common/components/Heading/Heading";
import renderInput from 'common/components/ReduxForm/RenderInput';
import renderCheckbox from 'common/components/ReduxForm/RenderCheckbox';
import renderNumberField from 'common/components/ReduxForm/RenderNumberField';
import renderNumberFormat from 'common/components/ReduxForm/RenderNumberFormat';
import renderRadioGroup from 'common/components/ReduxForm/RenderRadioGroup';
import renderTextArea from 'common/components/ReduxForm/RenderTextArea';
import { updateMasterDocByFields } from 'common/redux/actions';
import ModuleService from 'common/services/module.service';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import styled from "styled-components";

const TextFieldFromWrapper = styled.span`
    &>div {
        display: inline;
    }


    label#receive-within-daysLabel {
        display: none;
    }

    #receive-within-days {
        margin-left: 5px;
        margin-right: 5px;
    }
`;

const RadioButtonWrapper = styled.div`
    display: flex;
    &>div {
      margin-right: 20px;
    }
`;


class ExportInstructionOthers extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};
    }

    isDisabledFinanceIndemnity = () => {
      const isLFEDS = ModuleService.hasLFEDSModule();
      const eiType = this.props.isEDS ? this.props.EIType : this.props.eitype;
      console.log(eiType, isLFEDS);

      switch(eiType){
        case 'ND':
        case 'NA':
          if(!isLFEDS){
            return false;
          }
          break;
        case 'AD':
        case 'PA':
        case 'NU':
          if(isLFEDS){
            return false;
          }
          break;
        default:
          return true;
      }
      return true;
    }

    onRadioSelection = (name, event) => {
      this.props.updateMasterDocByFields({field: name, data: event.target.value});
    }

    render() {
      const {isEDS} = this.props;
      const disableFinanceIndemnity = this.isDisabledFinanceIndemnity();

      return (
        <div>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Heading level={2} brandbar><span className="medium">Others</span></Heading>
            </Grid>
            <Grid item xs={12} style={{marginBottom: '10px'}}>
              <div>Documents to be forwarded by COURIER unless stated otherwise in the DC or instructed otherwise below:</div>
            </Grid>
            <Grid item xs={12}>
              <Field
                component={renderRadioGroup}
                id="courier"
                name={isEDS ? 'eidto.Courier' : 'eidto.courier'}
                legend="Documents to be forwarded by COURIER unless stated otherwise in the DC or instructed otherwise below:"
                radio={
                  <RadioButtonWrapper>
                    <Radio id="registerMail" label="Register mail" value="M" />
                    <Radio id="speedpost" label="Speedpost" value="P" />
                    <Radio id="dhl" label="DHL" value="D" />
                  </RadioButtonWrapper>
                  }
              />
            </Grid>
          </Grid>


          <Divider short />

          <Heading level={2} brandbar><span className="medium">Documents - Bill of Exchange (for printing only)</span></Heading>


          <Grid container spacing={0}>
            <Grid item xs={12} style={{marginBottom: '10px'}}>
              <div>Number of Bill of Exchange:</div>
            </Grid>
            <Grid item xs={12}>
              <Field
                radio={
                  <RadioButtonWrapper>
                    <Radio id="radio-number-bill-none" label="None" value="N" />
                    <Radio id="radio-number-bill-one" label="1" value="1" />
                    <Radio id="radio-number-bill-two" label="2" value="2" />
                  </RadioButtonWrapper>
                  }
                component={renderRadioGroup}
                id="noofboe"
                name={isEDS ? 'eidto.NoOfBOE' : 'eidto.noofboe'}
                legend="Number of Bill of Exchange"
              />
            </Grid>
          </Grid>


          <Divider short />

          <Heading level={2} brandbar><span className="medium">Insurance</span></Heading>


          <Grid container spacing={0}>
            <Grid item xs={12}>

              <Field
                component={renderRadioGroup}
                id="insurancetype"
                name={isEDS ? 'eidto.InsuranceType' : 'eidto.insurancetype'}
                vertical
                radio={
                  <>
                    <Radio
                      id="radio-insurance-cover-by-applicant"
                      label="Insurance will be covered by Applicant / Drawee / Ultimate buyer."
                      value="BCB"
                    />
                    <Radio
                      id="radio-insurance-arrange"
                      label="Please arrange the marine insurance with extended cover of
                          'Seller's Interest Clause' to protect our title of goods during transit to destination under FOB or CFR term"
                      value="ARR"
                    />
                  </>
                }
              />
            </Grid>
          </Grid>


          <Divider short />

          <Heading level={2} brandbar><span className="medium">Finance Indemnity</span></Heading>

          <Grid container spacing={0} style={{paddingBottom: '20px'}}>
            <Grid item xs={12}>
              <div style={{paddingBottom: '20px'}}>(Click on the box if you require finance against discrepant documents)</div>
              <Field
                component={renderCheckbox}
                label="Finance / negotiate under the finance indemnity"
                id="check-box-negotiate-indemnity"
                name={isEDS ? 'eidto.FinanceIndemnity' : 'eidto.financeindemnity'}
                disabled={disableFinanceIndemnity}
              />
            </Grid>
          </Grid>


          <Divider short />

          <Heading level={2} brandbar><span className="medium">Other Disposal Instructions</span></Heading>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Field
                component={renderTextArea}
                id="txt-area-other-disposal-instructions"
                name={isEDS ? 'eidto.OtherDisposalInstr' : 'eidto.otherdisposalinstr'}
                label="Other disposal instructions"
                maxLength={200}
              />
            </Grid>
            <Grid item xs={6} />
          </Grid>


          <Divider short />

          <Heading level={2} brandbar><span className="medium">Loan Granted under Restricted Bill (if available)</span></Heading>


          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div>Loan Granted under Restricted Bill (if available)</div>
            </Grid>
          </Grid>


          <Grid container spacing={0} alignItems="center" style={{paddingTop:'20px'}}>
            <Grid item>
              <Field
                component={renderCheckbox}
                id="check-box-request-on-loan"
                name={isEDS ? 'eidto.ApplyProceedsToExpLoan' : 'eidto.applyproceedstoexploan'}
              />
            </Grid>
            <Grid item xs={2}>
              <div>Request for loan of (amount)
              </div>
            </Grid>
            <Grid item>
              <TextFieldFromWrapper>
                <Field
                  component={renderNumberField}
                  id="input-request-on-loan"
                  name={isEDS ? 'eidto.Loans_Amount' : 'eidto.loans_amount'}
                  className="no-fixed-width"
                  inputWidth="340"
                  inline
                  decimalScale={(this.props.isEDS ? this.props.masterDoc?.AmtDecCat : this.props.masterDoc?.amtdeccat) || 2}
                  maxLength={26}
                  thousandSeparator
                />
              </TextFieldFromWrapper>
            </Grid>

          </Grid>

          <Grid container spacing={0} alignItems="center" style={{paddingTop: '10px'}}>
            <Grid item><div style={{width: '32px'}} /></Grid>
            <Grid item xs={2}>
              <div>DC advice and restricted to (name of paying bank)
              </div>
            </Grid>
            <Grid item>
              <TextFieldFromWrapper>
                <Field
                  component={renderInput}
                  id="input-dc-advice"
                  name={isEDS ? 'eidto.Loans_PayingBank' : 'eidto.loans_payingbank'}
                  className="no-fixed-width"
                  inputWidth="340"
                  inline
                  maxLength={55}
                />
              </TextFieldFromWrapper>
            </Grid>

          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={12}>
              <p>In consideration, of your granting us a Loan against documents submitted under the aforementioned DC advised and/or restricted to
                the paying bank, we hereby assign to you, as security for the Loan and any other amounts owing in connection therewith, all our rights, title
                and interest in the aforementioned DC (and any bills issued thereunder), and without prejudice to your rights under the above assignment,
                irrevocably instruct you to apply any sums received in respect of the aforementioned DC in the reduction of the Loan and of any interest
                which may be due thereon.
              </p>

              <div>In the event that payment is not received by you within
                <TextFieldFromWrapper containerId="receive-within-days">
                  <Field
                    component={renderNumberFormat}
                    id="receive-within-days"
                    name={isEDS ? 'eidto.Loans_PaymentPeriod' : 'eidto.loans_paymentperiod'}
                    inputWidth="80"
                    size="small"
                    inline
                    maxLength={18}
                  />
                </TextFieldFromWrapper> days of this Collection Order, we authorise you to debit our account in the refund of the Loan an/or
                enforce the above assignment without notice.
              </div>

              <p>This instruction is in addition to and not in substitution for any other Instructions or Agreements that have been made between us.</p>
            </Grid>
          </Grid>


        </div>
      );
    }
}

const mapStateToProps = state => ({
  eitype: state.form.masterDoc.values?.eidto?.eitype,
  masterDoc: state.form.masterDoc.values,
})

const mapDispatchToProps = dispatch => ({
  updateMasterDocByFields: (payload) => dispatch(updateMasterDocByFields(payload)),
})


export default connect(mapStateToProps, mapDispatchToProps)(ExportInstructionOthers)
