import React from 'react';
import { connect } from 'react-redux';
import Filter from "common/components/Filter/Filter";
import { Grid } from '@material-ui/core';
import Dropdown from 'common/components/Dropdown/Dropdown';
import TextFieldForm from "common/components/TextField/TextFieldForm";
import DateRange from 'common/components/DatePicker/DateRange';
import { DATE_FORMAT, formatDate } from 'common/utils/DateUtil';
import styled from "styled-components";
import CDLRadioGroup, { Radio } from '@cdl/radio-group';
import Table from "common/components/Table/Table";
import Button from "common/components/Button/Button";
import MasterDocService from "common/services/masterDoc.service";
import ModuleService from 'common/services/module.service';
import { MD_STATUS } from 'common/utils/OptionUtil';
import CDLButton from '@cdl/button';
import { setFilter, setCollection, resetDCCollection } from "common/redux/actions";
import DocPrepService from "common/services/doc-prep.service";

const _ = require('lodash');

const from = new Date();
from.setDate(from.getDate() -30);

const initialFilters = {
  type: '',
  mdocid: '',
  mdocdesc: '',
  exporter: '',
  importer: '',
  status: '',
  creator: '',
  from,
  to: new Date()
};


const RadioNoLabelWrapper = styled.div`
    >fieldset {
        padding-top: 30px;
    }
`;

const LAST_MODIFY_ON = "Last modified on";

const dcColumn = [
  {
      Header: 'Master doc ID',
      accessor: 'id',
      className: 'docID',
      width: 120
  },
  {
      Header: 'DC number',
      accessor: 'dcno',
      width: 150,
      Cell: row => <span title={row.original.dcno}>{row.original.dcno}</span>
  },
  {
      Header: 'MD description',
      accessor: 'desc',
      Cell: row => <span title={row.original.desc}>{row.original.desc}</span>
  },
  {
      Header: 'Version',
      accessor: 'ver',
      width: 75
  }
];

const dadpColumn = [

  {
      Header: 'Master doc ID',
      accessor: 'id',
      className: 'docID',
      width: 120
  },
  {
      Header: 'MD description',
      accessor: 'desc',
      Cell: row => <span title={row.original.desc}>{row.original.desc}</span>
  },
  {
      Header: 'Version',
      accessor: 'ver',
      width: 75
  },
  {
      Header: 'Exporter',
      accessor: 'exporter',
      width: 210,
      Cell: row => <span title={row.original.exporter}>{row.original.exporter}</span>
  },
];

const commonColumn = [
    {
        Header: 'Importer',
        accessor: 'importer',
        width: 210,
        Cell: row => <span title={row.original.importer}>{row.original.importer}</span>
    },
    {
        Header: 'Status',
        accessor: 'status',
        width: 100,
        Cell: row => <span title={row.original.status}>{row.original.status}</span>
    },
    {
        Header: 'Creator',
        accessor: 'creater',
        width: 90,
        Cell: row => <span title={row.original.creater}>{row.original.creater}</span>
    },
    {
        Header: 'Last modified user',
        accessor: 'lastModifyUser',
        width: 145,
        Cell: row => <span title={row.original.lastModifyUser}>{row.original.lastModifyUser}</span>
    },
    {
        Header: LAST_MODIFY_ON,
        accessor: 'lastModifyOn',
        width: 135
    }
  ]

class MDSummary extends React.Component {
    constructor(props) {
        super(props);
        const isEDS = ModuleService.isEDSWorkflow();
        const isGenericEDS = ModuleService.isGenericEDS();
        const filters = this.props.filters || _.cloneDeep(initialFilters);

        if(isEDS && _.isEqual(filters, initialFilters)){
          filters.type = 'DC';
        }
        if(!isEDS && isGenericEDS && _.isEqual(filters, initialFilters)){
          filters.type = 'DADP';
        }
        this.state = {
          isEDS,
          isGenericEDS,
          filters,
          exporterOptions: [],
          importerOptions: [],
          statusOptions: MD_STATUS,
        }
    }

    componentDidMount() {
        MasterDocService.getMasterDocExporter().then(
            response => {
                if (response) {
                    this.setState({
                        exporterOptions: response.list.map(exporter => ({
                            label: exporter.expName ? `${exporter.exporterCode} | ${exporter.expName}` : exporter.exporterCode,
                            value: exporter.exporterCode
                        }))
                    });
                }
            }
        );
        MasterDocService.getMasterDocImporter().then(
            response => {
                if (response) {
                    this.setState({
                        importerOptions: response.list.map(importer => ({
                            label: importer.impName ? `${importer.importerCode} | ${importer.impName}` : importer.importerCode,
                            value: importer.importerCode
                        }))
                    });
                }
            }
        );
        this.fetchTableData();
    }


    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => {
          const {filters} = prevState;
          filters[name] = value;
          return { filters }
        })
      }

      handleAutocomplete = name => value => {
        this.setState(prevState => {
          const {filters} = prevState;
          filters[name] = value.value;
          return { filters }
        })
      }


      dateSelected = (name, value) => {
        this.setState(prevState => {
          const {filters} = prevState;
          filters[name] = value ? formatDate(value.from) : null;
          return { filters }
        })
      }

      onDateRangeSelected = (dateObj) => {
        this.setState(prevState => {
            const {filters} = prevState;
            filters.from = dateObj.from;
            filters.to = dateObj.to;
            return { filters }
        })
      }

    newMD = () => {
      this.props.setFilter(this.state.filters);
      const {type} = this.state.filters;
      if(type==='DC'){
          this.props.history.push('/DCSummary');
      } else {
          MasterDocService.initMasterDoc().then(res => {
            DocPrepService.performResetDocument(res);
            if(type==='DADP'){
              this.props.setCollection();
              this.props.history.push('/GenericDocPrep/GenericExportInstruction');
            } else {
              this.props.resetDCCollection();
              this.props.history.push('/GenericDocPrep');
            }
          });
      }
    }

    handleRadioChange = (event) => {
        const {name, value} = event.target;

        this.setState(prevState => {
          const {filters} = prevState;
          filters[name] = value;
          return { filters, docList: [] }
        }, this.fetchTableData);
    }

    openMD = (id, dcno) => {
        let link = "/GenericDocPrep/GenericMasterDocument";
        const {type} = this.state.filters;
        if(type==='DC' || type==='DADP' || (type==='nonDADP' && dcno)){
            link = "/GenericDocPrep/GenericExportInstruction";
        }
        const masterDoc = {
          mdocid: id,
          mdoctype: 'MD'
        }

        this.props.resetDCCollection();
        this.props.setFilter(this.state.filters);
        this.props.history.push(link, { masterDoc });
    }

    fetchTableData = () => {
        MasterDocService.filterMasterDoc(this.state.filters).then(response => {
            if (response && response.list){
                const doclist = []
                response.list.forEach(element => {
                    doclist.push({
                        id : <CDLButton size="small" onClick={()=>this.openMD(element[0], element[10])} plain style={{ fontSize: '12px' }}>{element[0]}</CDLButton>,
                        desc : element[1],
                        ver : element[2],
                        importer : element[3],
                        status : element[4],
                        creater : element[5],
                        lastModifyUser : element[6],
                        lastModifyOn : element[7],
                        lastUpdateTime : element[8],
                        exporter : element[9],
                        dcno: element[10]
                    });
                });

                this.setState({
                    docList: doclist,
                });
            }
        });
    }

    clearFilter = () => {
      this.setState(prevState => {
        const {filters} = prevState;
        filters.mdocid = '';
        filters.mdocdesc = '';
        filters.from = null;
        filters.to = null;
        filters.exporter = '';
        filters.importer = '';
        filters.status = '';

        return { filters }
      }, this.fetchTableData);
    }

    renderFilter() {
      const { exporterOptions, importerOptions, statusOptions, filters } = this.state;
      return (
        <Grid container spacing={0} style={{marginTop: '30px'}}>
          <Filter clearFilter={this.clearFilter} applyFilter={this.fetchTableData}>
            {filters.type!=='DC' &&
            <Grid container spacing={0}>
              <Grid item xs="auto" style={{width: '130px'}}>
                <TextFieldForm
                  seamless
                  name="mdocid"
                  value={filters.mdocid}
                  onChange={this.handleChange}
                  label="Master doc ID"
                  maxLength={11}
                  eds
                />
              </Grid>
              <Grid item xs="auto" style={{width: '125px'}}>
                <TextFieldForm
                  seamless
                  name="mdocdesc"
                  value={filters.mdocdesc}
                  onChange={this.handleChange}
                  label="MD description"
                  maxLength={11}
                  eds
                />
              </Grid>
              <Grid item xs="auto" style={{width: '270px'}}>
                <Dropdown
                  seamless
                  id="exporter"
                  name="exporter"
                  options={exporterOptions}
                  onChange={this.handleAutocomplete("exporter")}
                  placeHolder={filters.exporter || 'All'}
                  value={filters.exporter}
                  labelInside="Exporter"
                />
              </Grid>
              <Grid item xs="auto" style={{width: '270px'}}>
                <Dropdown
                  seamless
                  id="importer"
                  name="importer"
                  options={importerOptions}
                  onChange={this.handleAutocomplete("importer")}
                  placeHolder={filters.importer || 'All'}
                  value={filters.importer}
                  labelInside="Importer"
                />
              </Grid>
              <Grid item xs="auto" style={{width: '245px'}}>
                <DateRange
                  defaultDate={{from: filters.from, to: filters.to}}
                  onDateSelected={this.onDateRangeSelected}
                  inputFormat={DATE_FORMAT}
                  displayFormat={DATE_FORMAT}
                  id="qtyUOM"
                  type="quickRange"
                  localeData={{'displayLabel':LAST_MODIFY_ON}}
                  label={LAST_MODIFY_ON}
                />
              </Grid>
              <Grid item xs="auto" style={{width: '130px'}}>
                <Dropdown
                  seamless
                  id="status"
                  name="status"
                  options={statusOptions}
                  onChange={this.handleAutocomplete("status")}
                  placeHolder={filters.status || 'All'}
                  value={filters.status}
                  labelInside="Status"
                />
              </Grid>
            </Grid>}

            {filters.type==='DC' &&
            <>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <TextFieldForm
                    seamless
                    name="mdocid"
                    value={filters.mdocid}
                    onChange={this.handleChange}
                    label="Master doc ID"
                    maxLength={11}
                    eds
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFieldForm
                    seamless
                    name="mdocdesc"
                    value={filters.mdocdesc}
                    onChange={this.handleChange}
                    label="MD description"
                    maxLength={35}
                    eds
                  />
                </Grid>
                <Grid item xs={3}>
                  <Dropdown
                    seamless
                    id="exporter"
                    name="exporter"
                    options={exporterOptions}
                    onChange={this.handleAutocomplete("exporter")}
                    placeHolder={filters.exporter || 'All'}
                    value={filters.exporter}
                    labelInside="Exporter"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Dropdown
                    seamless
                    id="importer"
                    name="importer"
                    options={importerOptions}
                    onChange={this.handleAutocomplete("importer")}
                    placeHolder={filters.importer || 'All'}
                    value={filters.importer}
                    labelInside="Importer"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={0} style={{paddingTop: '20px'}}>
                <Grid item xs={3}>
                  <TextFieldForm
                    seamless
                    name="dcno"
                    value={filters.dcno}
                    onChange={this.handleChange}
                    label="DC number"
                    maxLength={20}
                    eds
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFieldForm
                    seamless
                    name="bank"
                    value={filters.bank}
                    onChange={this.handleChange}
                    label="Issuing bank"
                    maxLength={55}
                    eds
                  />
                </Grid>
                <Grid item xs={3}>
                  <DateRange
                    defaultDate={{from: filters.from, to: filters.to}}
                    onDateSelected={this.onDateRangeSelected}
                    inputFormat={DATE_FORMAT}
                    displayFormat={DATE_FORMAT}
                    id="qtyUOM"
                    type="quickRange"
                    localeData={{'displayLabel':LAST_MODIFY_ON}}
                    label={LAST_MODIFY_ON}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Dropdown
                    seamless
                    id="status"
                    name="status"
                    options={statusOptions}
                    onChange={this.handleAutocomplete("status")}
                    placeHolder={filters.status || 'All'}
                    value={filters.status}
                    labelInside="Status"
                  />
                </Grid>
              </Grid>
            </>}
          </Filter>
        </Grid>
      )
    }

    render() {
        const { isEDS, isGenericEDS, docList, filters } = this.state;
        const tableColumn = filters.type==='DC' ? dcColumn.concat(commonColumn) : dadpColumn.concat(commonColumn)

        return (
          <>
            {isEDS &&
            <RadioNoLabelWrapper>
              <CDLRadioGroup name="type">
                <Radio
                  id="other"
                  label="Document preparation"
                  value="Other"
                  onChange={this.handleRadioChange}
                  checked={filters.type==='Other'}
                />
                <Radio
                  id="dc"
                  label="Export direct send - DC"
                  value="DC"
                  onChange={this.handleRadioChange}
                  checked={filters.type==='DC'}
                />
                <Radio
                  id="dadp"
                  label="Export direct send - collection"
                  value="DADP"
                  onChange={this.handleRadioChange}
                  checked={filters.type==='DADP'}
                />
              </CDLRadioGroup>
            </RadioNoLabelWrapper>}

            {!isEDS && isGenericEDS &&
            <RadioNoLabelWrapper>
              <CDLRadioGroup name="type">
                <Radio
                  id="other"
                  label="Document preparation"
                  value="nonDADP"
                  onChange={this.handleRadioChange}
                  checked={filters.type==='nonDADP'}
                />
                <Radio
                  id="dadp"
                  label="Export direct send - collection"
                  value="DADP"
                  onChange={this.handleRadioChange}
                  checked={filters.type==='DADP'}
                />
              </CDLRadioGroup>
            </RadioNoLabelWrapper>}

            {this.renderFilter()}

            <Grid container spacing={0}>
              <Grid item xs={12} style={{paddingBottom: '20px', paddingTop: '30px'}}>
                <Table
                  id="openDocTable"
                  defaultPageSizeOption={{ 'value': 0 }}
                  showPagination
                  data={docList}
                  loading={this.state.loading}
                  maxRows={11}
                  columns={tableColumn}
                />
              </Grid>

              <Grid item xs={10} />
              <Grid item xs={2}>
                <Button disabled={this.state.isOpenDisabled} themeColor="primary" onClick={this.newMD} style={{float: 'right', marginTop: '10px'}}>Create new MD</Button>
              </Grid>
            </Grid>

          </>
        );
    }
}

const mapStateToProps = state => {
  return {
    filters: state.filterAction?.filters,
  }
}

const mapDispatchToProps = dispatch => {
    return {
        resetDCCollection: (data) => dispatch(resetDCCollection(data)),
        setCollection: (data) => dispatch(setCollection(data)),
        setFilter: (data) => dispatch(setFilter(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MDSummary);
