import React from 'react';
import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Grid from '@material-ui/core/Grid';
import Table from 'common/components/Table/Table';
import TableCheckbox from "common/components/TableCheckbox/TableCheckbox";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import Confirm from 'common/views/Popup/Confirm';
import UserMaintService from 'common/services/user-maint.service';
import CDLHeading from '@cdl/heading';
import $ from "jquery";
import { ROW_SELECTED_CLASS } from 'common/utils/Constants';
import CDLCheckbox from '@cdl/checkbox';
import styled from "styled-components";
import ValidationMessage from 'common/components/UserMessage/ValidationMessage';
import DashboardService from 'common/services/dashboard.service';

const Checkbox = styled(CDLCheckbox)`
    & span {
        top: -3px;
        width: 20px;
        height: 20px;
    }
`;

export default class AddAssignModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      rightData: [],
      moduleData: [],
      openConfirm: false,
    };
  }

  componentDidMount() {
    const userMaint = {
      "userName": this.props.username,
      "role": this.props.role,
    }
    UserMaintService.getModulesSelectedNonSelected(userMaint).then(res => {
      if(res && res.list){
        const moduleData = res.list;
        const firstModule = res.list[0];
        this.setState({
          formData: firstModule?.haveForm ? firstModule.formsList : [],
          rightData: firstModule?.haveRight ? firstModule.rightsList : [],
          moduleData,
        });
        this.selectRow(0);
      }
    });

    DashboardService.is2LevelWorkflow(this.props.countrycode, this.props.groupmemberid).then(res => {
      this.setState({
        is2LevelWorkflow: res,
      })
    });

    DashboardService.isEDSWorkflow(this.props.countrycode, this.props.groupmemberid).then(res => {
      this.setState({
        isEDSWorkflow: res,
      })
    });
  }

  selectRow = (index) =>{
      $(`#moduleTable .rt-tr`).removeClass(ROW_SELECTED_CLASS);
      $(`#moduleTable .rt-tr:eq( ${index+1} )`).addClass(ROW_SELECTED_CLASS);
  }


  showForm = (index) => {
    this.setState(prevState => ({
      formData: prevState.moduleData[index].formsList,
    }))
  }

  showRight = (index) => {
    this.setState(prevState => ({
      rightData: prevState.moduleData[index].rightsList,
    }))
  }

  selectApproval = (moduleData, selectedModuleID) => {
    if( (selectedModuleID === "GenericEDSDashboard" || selectedModuleID === "EDSWorkflowEdit")  && this.state.isEDSWorkflow){
      moduleData.forEach((t) => {
        t.rightsList.forEach((f) => {
          if (f.rightsID === "EDSWorkflowApprove") {
            f.selected = true;
          }
        });
      });
    }
  }

  selectRelated = (checked, selectedModuleID, moduleData) => {

    if(checked){
      if(selectedModuleID === "EDSWorkflowApprove" || selectedModuleID === "EDSWorkflowEdit"){
        moduleData.forEach((t) => {
          t.formsList.forEach((f) => {
            if (f.formID === "GenericEDSDashboard") {
              f.selected = true;
            }
          });
        });

        this.toggleRight(moduleData, selectedModuleID);
      }

      this.selectApproval(moduleData, selectedModuleID);
    }else if(selectedModuleID === "GenericEDSDashboard"){
      moduleData.forEach((t) => {
        t.rightsList.forEach((f) => {
          if(f.rightsID === "EDSWorkflowApprove" || f.rightsID === "EDSWorkflowEdit"){
            f.selected = false;
          }
        });
      });
    }
  }

  setChecked = (moduleData, moduleID, checked, checkboxID) => {
    moduleData.forEach((t) => {
      if (t.moduleID === moduleID) {
        if (checked) {
          t.selected = true;
        }

        t.formsList.forEach((f) => {
          if (f.formID === checkboxID) {
            f.selected = checked;
          }
        });

        t.rightsList.forEach((f) => {
          if (f.rightsID === checkboxID) {
            f.selected = checked;
          }
        });
      }
    });
  }

  onCheck = (event) => {
    const {value, checked} = event.target;

    if(value && value.includes('@')){
      const tmp = value.split('@');
      const moduleID = tmp[0];
      const checkboxID = tmp[1];

      this.setState(prevState => {
        const {moduleData} = prevState;
        this.setChecked(moduleData, moduleID, checked, checkboxID);
        this.selectRelated(checked, checkboxID, moduleData);
        return {
          moduleData
        }
      }, ()=>this.unCheckParent(moduleID));
    }

    return "";
  }

  unCheckParent = (moduleID) => {
    this.setState(prevState => {
      const {moduleData} = prevState;
      moduleData.forEach((t) => {
        if(t.moduleID === moduleID && t.parentModuleID===''){
          let shouldUncheck = true;
          t.formsList.forEach((f) => {
            if(f.selected){
              shouldUncheck = false;
            }
          })

          t.rightsList.forEach((r) => {
            if(r.selected){
              shouldUncheck = false;
            }
          })

          if(shouldUncheck){
            t.selected = false;
          }
        }
      })

      return {
        moduleData
      }
    })
  }

  showSubforms = (index) => {
    this.selectRow(index);
    this.setState({
        formData: [],
        rightData: [],
      },
      ()=>{
        this.showForm(index);
        this.showRight(index);
      }
    )
  }

  onCheckModule = (event, index) => {
    const {value, checked} = event.target;

    if(checked){
      this.setState(prevState => {
        const {moduleData} = prevState;
        moduleData.forEach((t) => {
          if(t.moduleID === value){
            t.selected = true;
          }
        })
        return {
          moduleData,
        }
      },
      () => this.showSubforms(index)
      );
    }else{
      this.setState(prevState => {
        const {moduleData} = prevState;
        moduleData.forEach((t) => {
          if(t.moduleID === value){
            t.selected = false;
            t.formsList.forEach((f) => {
              f.selected = false;
            })
            t.rightsList.forEach((f) => {
              f.selected = false;
            })
          }
        })
        return {
          moduleData,
        }
      },
      () => this.showSubforms(index)
      );
    }

    return "";
  }

  save = () => {
    const userMaint = {
      "userName": this.props.username,
      "role": this.props.role,
    }

    UserMaintService.saveModules(userMaint, this.state.moduleData).then(res=>{
      if(res===true){
        this.props.success(`User "${this.props.username}" - "${this.props.fullname}"'s module has been updated successfully.`);
        this.props.close();
      }else if(res.includes(':')){
          const name = res.split(':')[1];
          const nameModule = res.split(' ')[0];
          this.setState({
            message: `You have no access to "${name.trim()}" ${nameModule.toLowerCase()}, please approach your administrator to assign related entitlement to yourself first.`,
            openMessage: true,
          })
        }else{
          this.setState({
            message: `Error updating user "${this.props.username}" - "${this.props.fullname}"'s module.`,
            openMessage: true,
          })
        }
    });
  }

  isDisabled = (cell) => {
    let isDisabled= false;
    const parentID = cell.original.parentModuleID;
    if(parentID===""){
      isDisabled = true;
    }else{
      this.state.moduleData.forEach((t) => {
        if(t.moduleID === parentID && !t.selected){
          isDisabled = true;
        }
      });
    }
    return isDisabled;
  }

  renderDesc = (cell) => {
    const isDisable = this.isDisabled(cell);
    if(isDisable && cell.original.parentModuleID!==''){
      return (<div style={{color: 'gray'}}>{cell.original.moduleDesc}</div>)
    }
      return (<div style={{cursor: 'pointer'}} onClick={() => this.showSubforms(cell.index)}>{cell.original.moduleDesc}</div>)


  }


  toggleRight(moduleData, selectedModuleID) {
    if (this.state.is2LevelWorkflow) {
      if (selectedModuleID === "EDSWorkflowApprove") {
        moduleData.forEach((t) => {
          t.rightsList.forEach((f) => {
            if(f.rightsID === "EDSWorkflowEdit"){
              f.selected = false;
            }
          })
        })
      }

      if (selectedModuleID === "EDSWorkflowEdit") {
        moduleData.forEach((t) => {
          t.rightsList.forEach((f) => {
            if(f.rightsID === "EDSWorkflowApprove"){
              f.selected = false;
            }
          })
        })
      }
    }
  }

  render(){
    const {openMessage, message} = this.state;
    return (
      <>
        {this.state.openConfirm &&
        <Confirm
          content="Are you sure you want to proceed?"
          isOpen={this.state.openConfirm}
          close={()=>{this.setState({openConfirm: false})}}
          confirm={this.save}
        />}
        <Modal
          ariaLabelledby="modal-heading"
          isOpen={this.props.isOpen && !this.state.openConfirm}
          width={1122}
        >
          <ModalHeader hasTopLine>
            <ModalTitle>Assign module</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.props.close} />
            </ModalBtnList>

          </ModalHeader>
          <ModalContent style={{ paddingTop: '0' }}>
            <Grid container spacing={0} style={{ paddingRight: '10px', paddingBottom: '20px' }}>
              {openMessage &&
              <Grid item xs={12} style={{paddingTop: '20px'}}>
                <ValidationMessage message={message} onClose={()=>this.setState({openMessage:false})} />
              </Grid>}
              <Grid item xs={4} style={{ paddingRight: '10px' }}>
                <CDLHeading level={2}>
                  <span className="medium">Modules</span>
                </CDLHeading>
                <Table
                  id="moduleTable"
                  showPagination={false}
                  defaultPageSizeOption={{'value':0}}
                  data={this.state.moduleData}
                  columns={[
                  {
                    Header: '',
                    Cell: ( cell ) => (<Checkbox
                      onChange={(event) => this.onCheckModule(event, cell.index)}
                      checked={cell.original.selected}
                      value={cell.original.moduleID}
                      disabled={this.isDisabled(cell)}
                    />),
                    width: 50,
                    sortable: false
                  },
                  {
                    Header: 'Module description',
                    accessor: 'moduleDesc',
                    Cell: ( cell ) => this.renderDesc(cell),
                    sortable: false
                  }
                ]}
                />
              </Grid>


              <Grid item xs={4} style={{ paddingRight: '10px' }}>
                <CDLHeading level={2}>
                  <span className="medium">Forms</span>
                </CDLHeading>

                <Table
                  showPagination={false}
                  defaultPageSizeOption={{ 'value': 0 }}
                  data={this.state.formData}
                  columns={[
                    {
                      Header: '',
                      accessor: '',
                      Cell: ( cell ) => (<TableCheckbox
                        onChange={this.onCheck}
                        checked={cell.original.selected}
                        value={`${cell.original.moduleID}@${cell.original.formID}`}
                      />),
                      width: 50,
                      sortable: false
                    },
                    {
                      Header: 'Form description',
                      accessor: 'formDesc'
                    }
                  ]}
                />
              </Grid>

              <Grid item xs={4}>
                <CDLHeading level={2}>
                  <span className="medium">Rights</span>
                </CDLHeading>

                <Table
                  showPagination={false}
                  defaultPageSizeOption={{ 'value': 0 }}
                  data={this.state.rightData}
                  columns={[
                    {
                      Header: '',
                      accessor: '',
                      Cell: ( cell ) => (<TableCheckbox
                        onChange={this.onCheck}
                        checked={cell.original.selected}
                        value={`${cell.original.moduleID}@${cell.original.rightsID}`}
                        disabled={this.state.isEDSWorkflow && cell.original.rightsID === "EDSWorkflowApprove"}
                      />),
                      width: 50,
                      sortable: false
                    },
                    {
                      Header: 'Right description',
                      accessor: 'rightsDesc'
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.props.close}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button onClick={()=>this.setState({openConfirm: true})} themeColor="primary">Save</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>
      </>
  )
              }


}
