import CDLDatePicker from '@cdl/date-picker';
import { colors } from '@cdl/foundations';
import CDLUserMessage from '@cdl/user-message';
import { DATE_FORMAT } from "common/utils/DateUtil";
import React from 'react';
import styled from "styled-components";

const moment = require('moment');

const Label = styled.label`
    display: block;
    margin-bottom: 0.625rem;
    line-height: 1;
    font-size: 0.875rem;
    color: ${props => props.eds ? colors.SLATE : colors.DARK_SLATE}
`;

const calulateWidth = (props, deviation) => `${props.width - deviation}px`;

const Container = styled.div`
    width: ${props => props.width ? calulateWidth(props, 20) : 'calc(100% - 20px)'};
    & input{
      width: ${props => props.width ? calulateWidth(props, 60) : 'calc(100% - 40px)'};
      height: 28px;
      box-sizing: border-box;
    }

    &.seamless {
      position: relative;
      background: white;
      width: 100%;
      height: 100%;
      border: 1px solid #929292;
      & >label {
        font-size: ${props => props.eds ? '12px' : '10px'};
        margin: ${props => props.eds ? '11px' : '14px'} 0 4px 10px;
      }
      & div[type="single"] {
        width: calc(100% - 5px);
        border: none;
        margin-bottom: inherit;
        &>input {
          font-size: 14px;
          background: transparent;
        }
        &>input:focus {
          outline: none;
        }
        &>div[role="button"] {
          position: absolute;
          transform: translateY(-50%);
          right: 0;
          padding: 12px;
        }
      }

      & div#default-datepicker-wrapper > div:last-child:not(:first-child) {
        margin-top: 7px;
     }
    }

    &.date-picker-wrapper {
      & > div > div > div > div {
        border-color: ${props => props.invalid ? '#af000d': ''};
        background-color: ${props => props.invalid ? '#f9f2f3': 'white'};
      }

      & > div #calendarWrapper {
        border-color: #929292;
        background-color: white;
      }

      & input {
        font-size: 14px;
        background-color: transparent;
      }
    }

    & div[role="button"] svg{
      width: 18px;
      height: 18px;
    }

    & div[role="button"]{
      padding-left: 10px;
      padding-right: 10px;
      border-left: 1px solid #767676;
    }

    & > div > div > div:first-of-type > div{
      width: 100%;
      border: 1px solid ${props => props.invalid ? '#af000d': '#929292'};
      margin-bottom: 0.625rem;
      ${props => props.disabled ? 'opacity: 0.5;' : ''}
    }

    & div#default-datepicker-wrapper > div:last-child:not(:first-child) {
       position: ${props => props.isModal ? 'fixed': 'absolute'};
       margin-top: -0.625rem;
    }
    
`;


const RangeContainer = styled.div`
    & .displayLabel {
      display: none;
    }

    & div>.displayContent {
      padding-top: initial;
      padding-bottom: initial;
    }

    
    & > div > div{
      margin-left: ${props => props.alignPwd ? '32px' : '0px'};
      height: 30px;
      border: 1px solid ${props => props.disabled ? '#b6b7b6': '#929292'}; 
      ${props => props.disabled ? 'opacity: 0.5;' : ''}    
    }

    #calendarWrapper {
      background-color: white;
    }
    
    & #datepicker-wrapper{
      display: block;
      & > div:first-of-type{
        width: auto;
      }

    }

`;


export default class DatePicker extends React.Component {

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state ={
      defaultDate: (props.value && props.value !== '') ? moment(props.value, DATE_FORMAT).toDate() : props.defaultDate
    };
  }


  static getDerivedStateFromProps(props, state) {
    // eslint-disable-next-line react/prop-types
    if (!props.value || props.value === '') {
      return {
        defaultDate: {from: null}
      }
    }
    if (typeof props.value === 'string') {
      return {
        defaultDate: {from: moment(props.value, DATE_FORMAT).toDate()}
      }
    }
    if (props.value && state.defaultDate &&
      moment(state.defaultDate, DATE_FORMAT).toDate().getTime() !== moment(props.value, DATE_FORMAT).toDate().getTime()) {
        return {
            // eslint-disable-next-line react/prop-types
            defaultDate:   props.value
        };
    }
    return null;
}

  dateSelected = (event) => {
    if (this.props.onChange) {
      if (this.props.type === 'quickRange') {
        this.props.onChange(event);
      } else if (this.props.dateFormat) {
          this.props.onChange(moment(event.from).format(this.props.dateFormat));
        } else {
          this.props.onChange(event.from);
        }
    }
  }


  render() {

      const {seamless, invalid, width, label, displayFormat, inputFormat, invalidMessage, disabled, hidden, alignPwd, ...rest} = this.props;
      const disabledLayer = disabled ? (<div style={{cursor: 'not-allowed' ,width: '100%', height: '32px', position: 'absolute', top: 0}} />) : null;
      const disabledSingleLayer = disabled ? (<div style={{width: '100%', height: '32px', position: 'absolute', top: '52px'}} />) : null;

      const singleDatePicker =
        <div style={{height: seamless ? '100%' : null, marginLeft: alignPwd ? '32px' : '0px'}} ref={this.ref}>
          <Container
            disabled={disabled}
            className={seamless ? 'seamless' : 'date-picker-wrapper'}
            invalid={invalid}
            width={width}
            eds={this.props.eds}
            isModal={this.props.isModal}
          >
            <Label eds={this.props.eds}>{label}</Label>
            <CDLDatePicker
              onDateSelected={this.dateSelected}
              defaultDate={this.state.defaultDate}
              displayFormat={displayFormat || DATE_FORMAT}
              inputFormat={inputFormat || DATE_FORMAT}
              allowBlank
              hidden={this.props.hidden}
              {...rest}
            />
            {invalid && !!invalidMessage &&
              <CDLUserMessage
                id={`${this.props.name  }Error`}
                status='error'
                iconName='triangle-error'
              >
                {invalidMessage}
              </CDLUserMessage>}

          </Container>
          {disabledSingleLayer}
        </div>

          ;
      const rangeDatePicker =
        <div style={{position: 'relative', display: this.props.hidden? 'none' : ''}} ref={this.ref}>
          <RangeContainer disabled={disabled} invalid={this.props.invalid} className="date-picker-wrapper" alignPwd={alignPwd}>
            {this.props.label ? <Label eds={this.props.eds}>{this.props.label}</Label> : null}
            <CDLDatePicker
              onDateSelected={this.dateSelected}
              defaultDate={this.state.defaultDate}
              displayFormat={displayFormat || DATE_FORMAT}
              inputFormat={inputFormat || DATE_FORMAT}
              hidden={this.props.hidden}
              {...rest}
            />

          </RangeContainer>
          {disabledLayer}
        </div>

    if (this.props.type === 'quickRange') {
      return rangeDatePicker;
    }
    return singleDatePicker;

  }
}

