import { FORM_NAME } from 'common/utils/Constants';

export function isAC(){
    return window.location.pathname.includes('AccessControl');
}

export function getCurrentTab(){
    return window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
}

export function getFormName(){
    const tabName = getCurrentTab();
    return FORM_NAME[tabName];
}

export function getStaticURLPrefix(){
    const urlDomain = isAC() ? 'AccessControl2':'DocExpress2';
    const port = window.location.port ? `:${ window.location.port}`: '';
    return `${window.location.protocol}//${window.location.hostname}${port}/${urlDomain}/flex/html/`;
}
