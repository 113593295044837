import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Autocomplete from "common/components/Autocomplete/Autocomplete";
import Button from "common/components/Button/Button";
import DatePicker from "common/components/DatePicker/DatePicker";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import TextArea from "common/components/TextArea/TextArea";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import ValidationMessage from 'common/components/UserMessage/ValidationMessage';
import invoiceService from 'common/services/invoice.service';
import { DATE_FORMAT } from 'common/utils/DateUtil';
import { basicValidation, REQUIRED } from 'common/utils/FieldValidation';
import { isBlank } from 'common/utils/StringUtils';
import React from 'react';

const moment = require('moment');

export default class InvoiceDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ({ ...props.data}) || {},
            isEdit: props.data && Object.keys(props.data).length > 0,
            packingListOptions: [],
            errors: {}
        };
    }

    componentDidMount() {
      if (this.state.data?.invdate) {
        const {data} = this.state;
        data.invdate = {from: moment(this.state.data?.invdate).toDate(), to: null}
        this.setState({
          data
        });
      }
      if (this.state.data?.mdocid) {
        invoiceService.getPackingList(this.state.data.mdocid).then(res => {
          if (res && res.statusCode === 200) {
            this.setState({
              packingListOptions: res.data.list
            });
          }
        });
      }
    }

    handleSave = () => {
      const resultData = this.state.data;
      if (resultData.invno && typeof resultData.invdate === 'object') {
        let nonDeletedItems = this.props.invoiceList.filter(item => !item.isDelete);
        if(this.state.isEdit){
          nonDeletedItems = nonDeletedItems.filter((item, index) => index !== this.props.selectedIndex);
        }
        if (nonDeletedItems.filter(d => d.invno === resultData.invno).length > 0) {
          this.setState({
            showDuplicateMessage: true
          })
          return;
        }
        this.props.close();
        setTimeout(()=>{
          this.props.save(resultData, this.state.isEdit, this.props.selectedIndex);
        })
      } else {
        this.setState(prevState => {
          prevState.errors.invno = basicValidation(REQUIRED , prevState.data.invno);
          if (typeof resultData.invdate !== 'object') {
            prevState.errors.invdateInvalid = true;
          }
          return {
            errors: prevState.errors
          }
        });
      }

    }

    handleClose = () => {
      const resultData = this.state.data;
      if (typeof resultData.invdate === 'object') {
        resultData.invdate = moment(resultData.invdate).format(DATE_FORMAT);
      }
      this.props.close();
    }

    handleInputValue = (name, val) => {
      const {data} = this.state;
      data[name] = val;
        this.setState({ data });
    }

    handleChange = (event) => {
      event.persist();
      this.setState(prevState => {
        prevState.data[event.target.name] = event.target.value;
        prevState.errors[event.target.name] = !isBlank(prevState.data[event.target.name]) ? null : prevState.errors[event.target.name];
      return {
          data: prevState.data,
          errors: prevState.errors
      }
      });

    }

    onDateSelected = (date) => {
      const {data, errors} = this.state;
      data.invdate = date;
      errors.invdateInvalid = false;
      this.setState({ data, errors });
    };

    render(){
        return (
          <div>
            <Modal
              ariaLabelledby="modal-heading"
              isOpen={this.props.isOpen}
              width={946}
            >
              <ModalHeader hasTopLine>
                <ModalTitle>Invoice details</ModalTitle>
                <ModalBtnList>
                  <ModalBtn name="delete" title="close" onClick={this.props.close} />
                </ModalBtnList>

              </ModalHeader>
              <ModalContent>
                <Grid container spacing={0}>


                  {this.state.showDuplicateMessage &&
                  <Grid item xs={12} style={{paddingRight: '20px'}}>
                    <ValidationMessage
                      message="Error adding invoice. Cannot save invoice: An invoice that you specified already exists. Please specify a different invoice number."
                      onClose={()=>this.setState({showDuplicateMessage:false})}
                    />
                  </Grid>}

                  <Grid item xs={4}>
                    <TextFieldForm
                      label="Invoice number*"
                      name="invno"
                      id="invno"
                      invalid={this.state.errors.invno}
                      invalidMessage={this.state.errors.invno}
                      value={this.state.data?.invno}
                      onChange={(event) => this.handleChange(event)}
                      maxLength={35}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePicker
                      label="Invoice date*"
                      id="invdate"
                      name="invdate"
                      value={this.state.data.invdate}
                      onDateSelected={this.onDateSelected}
                      displayFormat={DATE_FORMAT}
                      inputFormat={DATE_FORMAT}
                      invalid={this.state.errors.invdateInvalid}
                      invalidMessage="This field is required"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <Autocomplete
                      label="Packing list number"
                      name="plno"
                      id="plno"
                      options={this.state.packingListOptions}
                      userInput={this.state.data?.plno}
                      onChange={(value) => this.handleInputValue('plno', value)}
                      maxLength={35}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldForm
                      label="Packing mode"
                      name="plpackingmode"
                      id="plpackingmode"
                      value={this.state.data?.plpackingmode}
                      onChange={(event) => this.handleChange(event)}
                      maxLength={35}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      label="Qty/Weight UOM"
                      id="invqtyuom"
                      name="invqtyuom"
                      options={[
                        'PCS', 'SETS', 'KG', 'NT', 'CARTONS', 'PALLETS'
                      ]}
                      userInput={this.state.data?.invqtyuom}
                      onChange={(value) => this.handleInputValue('invqtyuom', value)}
                      maxLength={12}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextArea
                      label="Goods description"
                      name="descofgoods"
                      id="descofgoods"
                      value={this.state.data?.descofgoods}
                      handleChange={this.handleChange}
                      maxLength={6500}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextArea
                      label="Remarks"
                      name="invaddinfo2"
                      value={this.state.data?.invaddinfo2}
                      handleChange={this.handleChange}
                      maxLength={200}
                    />
                  </Grid>
                </Grid>
              </ModalContent>
              <ModalFooter hasBottomLine>
                <ModalAction>
                  <ModalActionExtraButton>
                    <Button onClick={this.handleClose}>Cancel</Button>
                  </ModalActionExtraButton>
                  <ModalActionButton>
                    <Button themeColor="primary" onClick={this.handleSave}>Save</Button>
                  </ModalActionButton>
                </ModalAction>
              </ModalFooter>
            </Modal>
          </div>
        )
    }
}
