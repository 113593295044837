import { ModalAction, ModalActionButton, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import React from 'react';
import { Cookies } from 'react-cookie';
import styled from "styled-components";

const Title = styled.span`
  font-family: 'HSBC-Universe-Medium';
`;

const Text = styled.div`
  padding-bottom: 14px;
`;

const cookies = new Cookies();
const cookieKey = 'CookieConsent';

export default class CookieConsent extends React.Component {
    constructor(props) {
      super(props);
      const accepted = cookies.get(cookieKey);
      this.state = {
        open: !accepted,
      }
    }

    handleClick = () => {
      const expiresDate = new Date();
      expiresDate.setTime(expiresDate.getTime() + (180*24*60*60*1000));
      cookies.set(cookieKey, true, { path: '/', expires: expiresDate, secure: true, sameSite: 'strict'});
      this.setState({open:false});
    }

    render(){
        return (
          <div>
            <Modal
              ariaLabelledby="modal-heading"
              isOpen={this.state.open}
              width={515}
            >
              <ModalHeader>
                <ModalTitle>Cookies on this website</ModalTitle>
              </ModalHeader>
              <ModalContent style={{fontFamily: 'HSBC-Universe'}}>
                <Grid container spacing={0}>
                  <Grid item xs={12} style={{paddingBottom:'6px'}}>
                    <div id="COOKIE_CONSENT">
                      <Text>
                        We use cookies to help ensure that our website and services are able to function properly. These cookies are necessary and so are set automatically.
                      </Text>

                      <Title style={{fontSize:'18px'}}>Strictly necessary cookies</Title>
                      <Text>We use strictly necessary cookies ensure that our website is able to function properly.</Text>

                      <Title>Cookies that help you log on</Title>
                      <Text>We use these cookies to remember who you are when you log on to secure areas of our website. You won&apos;t be able to log on without them.</Text>

                      <Title>Cookies that help us provide core services and features</Title>
                      <Text>We use these cookies to provide core services and features on our website. These services and features won&apos;t work without them.</Text>

                      <Text style={{paddingBottom: '0'}}>Please click &quot;Accept all cookies&quot; to proceed.</Text>
                    </div>
                  </Grid>
                </Grid>
              </ModalContent>
              <ModalFooter>
                <ModalAction>
                  <ModalActionButton>
                    <Button onClick={this.handleClick} themeColor="primary">Accept all cookies</Button>
                  </ModalActionButton>
                </ModalAction>
              </ModalFooter>
            </Modal>
          </div>
        )
    }
}
