import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import TextArea from "common/components/TextArea/TextArea";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import React from 'react';

export default class SubmitMasterDoc extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        remarks: "",
        email: "",
      };
      this.refEmail = React.createRef();
      this.refRemark = React.createRef();
    }

    save = () => {
        let isValid = this.refRemark.current.validate();
        isValid = this.refEmail.current.validate() && isValid;
        if(isValid){
          this.props.save(this.state);
          this.props.close();
        }
    }

    handleChange = (event) => {
      this.setState({ [event.target.name]: event.target.value });
    }


    render(){
      return (

        <Modal
          ariaLabelledby="modal-heading"
          classname="myClass"
          isOpen={this.props.isOpen}
          width={550}
        >
          <ModalHeader hasTopLine>
            <ModalTitle>Submit master document</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.props.close} />
            </ModalBtnList>

          </ModalHeader>
          <ModalContent>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <TextArea
                  name="remarks"
                  maxLength={1500}
                  label="Remarks*"
                  value={this.state.remarks}
                  onChange={this.handleChange}
                  ref={this.refRemark}
                  validations={["required"]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldForm
                  name="email"
                  label="Email notification*"
                  validations={["required", "multiEmail"]}
                  value={this.state.email}
                  onChange={this.handleChange}
                  ref={this.refEmail}
                />
              </Grid>
            </Grid>
          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.props.close}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button onClick={this.save} themeColor="primary">Save</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>

      )
    }
}
