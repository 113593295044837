import DocumentDate from "common/components/DocumentDate/DocumentDate";
import ReadonlyField from "common/components/ReadonlyField/ReadonlyField";
import UserService from 'common/services/user.service';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { formatDate } from "common/utils/DateUtil";
import { DOC_STATUS } from 'common/utils/Constants';

const Container = styled.div`
    display: inline-block;
    padding: 30px 0 20px 0;
`;

const InfoBox = styled.div`
    float: left;
    padding-right: 15px;
    font-size: 14px;
`;

export default function DocInfo(props) {

  const masterDoc = useSelector(state => state.masterDocAction?.initDoc) || {};

  const [masterDocDate, setMasterDocDate] = useState(masterDoc?.mdocdate || new Date());
  const [isConstruct, setIsConstruct] = useState(false);
  const user = UserService.getCurrentUser();

  const dispatcher = useDispatch();

  useEffect(() => {
    if (!isConstruct && !masterDoc.mdocdate) {
        dispatcher({type: 'UPDATE_MSATER_DOC_FIELD', payload: { field: 'mdocdate', data: formatDate(new Date()), isInit: true}});
    }
    setIsConstruct(true);
    if (masterDoc.mdocdate) {
      setMasterDocDate(masterDoc.mdocdate);
    }
    }, [masterDoc]
  );

  const selectDate = (date) => {
    setMasterDocDate(date);
    masterDoc.mdocdate = formatDate(date);
    dispatcher({type: 'UPDATE_MSATER_DOC_FIELD', payload: { field: 'mdocdate', data: masterDoc.mdocdate}});


  }

  const reviewStatus = masterDoc?.MDStatusHistoryList && masterDoc?.MDStatusHistoryList[0].status === DOC_STATUS.REVIEWED ?
                          `By ${masterDoc?.MDStatusHistoryList[0].userUpdate} on ${masterDoc?.MDStatusHistoryList[0].dateUpdate}` : '';

    const mdStatus = masterDoc?.status === DOC_STATUS.REVIEWED && !props.is2LevelWorkflow ? DOC_STATUS.SUBMITTED : masterDoc?.status;

    return (
      <Container>
        <InfoBox>
          <ReadonlyField label="DocumentExpressID" value={masterDoc?.codocexpressid || user.codocexpressid || masterDoc?.CoDocExpressId} />
        </InfoBox>

        <InfoBox className="leftBorder">
          <ReadonlyField label="Master document number" value={masterDoc?.mdocid} />
        </InfoBox>

        <InfoBox className="leftBorder">
          {!props.isEdsDashboard &&
          <>
            <div style={{paddingBottom: '10px'}}>Master document date</div>
            <DocumentDate val={masterDocDate} dateSelected={selectDate} />
          </>}
          {props.isEdsDashboard &&
          <ReadonlyField label="Master document date" value={formatDate(masterDocDate)} />}
        </InfoBox>

        {props.isEdsDashboard &&
        <>
          <InfoBox className="leftBorder">
            <ReadonlyField label="MD status" value={mdStatus} />
          </InfoBox>
          <InfoBox className="leftBorder">
            <ReadonlyField label="Bill reference number" value={props.refNo || masterDoc?.TempEDSRefNo} />
          </InfoBox>
          {reviewStatus && props.is2LevelWorkflow &&
          <InfoBox className="leftBorder">
            <ReadonlyField label="MD review status" value={reviewStatus} />
          </InfoBox>}
        </>}

      </Container>
    );
}

