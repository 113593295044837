import BaseService from 'common/services/BaseService';

class CompanyService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/CompanyBO/';
  }

  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  initCompanyDetail() {
    return this.post('initCompanyDetail', this.getInUserDTO());
  }

  initCompanyDetailForUpload(docExpressID) {
    const requestBody = {
        "indocexpressid": docExpressID,
        ...this.getInUserDTO()
    }
    return this.post('initCompanyDetailForUpload', requestBody, true);
  }

  getCompanyByProcCtrID(procctrid) {
    const requestBody = {
        "inprocctrid": procctrid,
        ...this.getInUserDTO()
    }
    return this.post('getCompanyByProcCtrID', requestBody, true);
  }

  getCompanyByDocExpressID(docExpressID) {
    const requestBody = {
        "indocexpressid": docExpressID,
        ...this.getInUserDTO()
    }
    return this.post('getCompanyByDocExpressID', requestBody, true);
  }

  getEDSCompanyByProcCtrID(proCtrID) {
    const requestBody = {
      "inprocctrid": proCtrID,
      ...this.getInUserDTO()
  }
  return this.post('getEDSCompanyByProcCtrID', requestBody, true);
  }

  addCompany(company) {
    const requestBody = {
        "incompanydetaildto": company,
        ...this.getInUserDTO()
    }

    return this.post('addCompany', requestBody, true);
  }

  updateCompany(company) {
    const requestBody = {
        "incompanydetaildto": company,
        ...this.getInUserDTO()
    }

    return this.post('updateCompany', requestBody, true);
  }

  getCompanyModules(countryCode, docexpressID, groupMemberID) {
    const requestBody = {
        "incountrycode": countryCode,
        "indocexpressid": docexpressID,
        "ingroupmemberid": groupMemberID,
        ...this.getInUserDTO()
    }
    return this.post('getCompanyModules', requestBody, true);
  }

  updateCompanyModules(modules, company) {
    const requestBody = {
        "moddto": modules,
        "compdetaildto": company,
        ...this.getInUserDTO()
    }
    return this.post('updateCompanyModules', requestBody, true);
  }

  addHouseKeeping(company) {
    const requestBody = {
        "incompanydetaildto": company,
        ...this.getInUserDTO()
    }
    return this.post('addHouseKeeping', requestBody, true);
  }

  getSampleBankCsv(type) {
    const requestBody = {
        "type": type
    }
    return this.post('getSampleBankCsv', requestBody);
  }

  getCoDocExpressID(inprocctrid) {
    const requestBody = {
        "inprocctrid": inprocctrid,
        ...this.getInUserDTO()
    }
    return this.post('getCoDocExpressID', requestBody);
  }

  deleteCompany(company) {
    const requestBody = {
        "incompanydetaildto": company,
        ...this.getInUserDTO()
    }
    return this.post('deleteCompany', requestBody, true);
  }

  updateEDSWolfSettings(company) {
    const requestBody = {
        "companydetaildto": company,
        ...this.getUserDTO()
    }
    return this.post('updateEDSWolfSettings', requestBody, true);
  }

  saveTradeID(exporters) {
    const requestBody = {
        exporters,
        ...this.getUserDTO()
    }
    return this.post('saveTradeID', requestBody, true);
  }

  getWolfSettings(coDocExpressID) {
    const requestBody = {
        "companydetaildto": {
          coDocExpressID
        },
        ...this.getUserDTO()
    }
    return this.post('getWolfSettings', requestBody, true);
  }

  updateWolfSettings(wolfsetting) {
    const requestBody = {
      wolfsetting,
      ...this.getUserDTO(),
    }
    return this.post('updateWolfSettings', requestBody, true);
  }

}

export default new CompanyService();
