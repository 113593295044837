const _ = require('lodash');

const maxLengthMessage = (length) => {
  return `Must be ${length} characters or less`;
}

export function validateAccountNumber(value) {
  // eslint-disable-next-line
  if(value && _.replace(value, /[\*\-]/g, "").length>12){
    return maxLengthMessage(12);
  }
  return '';
}

export function basicValidation(validator, value) {
    const emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const minRe = /min:[\d]+$/;
    const maxRe = /max:[\d]+$/;

    let maxLength = 0;
    let minLength = 0;
    if(maxRe.test(validator)){
      maxLength = validator.split(":")[1];
      validator = validator.split(":")[0];
    }else if(minRe.test(validator)){
      minLength = validator.split(":")[1];
      validator = validator.split(":")[0];
    }

    switch (validator) {
      case "required":
        return value && value.trim().length > 0 ? "" : "This field is required";
      case "email":
        if(value?.length>0){
          return emailRe.test(value) ? "" : "Please enter a valid email address.";
        }
        return "";
      case "multiEmail":
        // eslint-disable-next-line no-use-before-define
        return checkMultiEmail(emailRe, value);
      case "min":
        return value?.length >= minLength
          ? ""
          : `This value needs to be at least ${minLength} characters`;
      case "max":
        return value?.length <= maxLength
          ? ""
          : `This value cannot be more than ${maxLength} characters`;
      case "accountNumber":
        return validateAccountNumber(value);
      default:
        return "";
    }
}

export function checkMultiEmail(emailRe, value){
  if(value?.length>0){
    if(value.includes(';')){
      const emails = value.split(';');
      return emails.some(e => (e?.length>0 && !emailRe.test(e))) ? "Please enter a valid email address." : "";
    }

    return basicValidation("email", value);
  }
  return "";
}

export function customValidation(validator, value) {
  if ( typeof validator === "object" &&
        typeof validator.isValid === "function" &&
        typeof validator.invalidMessage === "string" ) {
    const isValid = validator.isValid(value);
    return isValid ? "" : validator.invalidMessage;
  }
  console.log("Unsupported validator type passed");
  return "";
}


export function runValidations(validations, value) {
    if (!validations) {
      return "";
    }

    let validationRes;
    validations.every(validator=>{
      if (typeof validator === "string"){
        validationRes = basicValidation(validator, value);
      }else{
        validationRes = customValidation(validator, value);
      }

      if(validationRes!==""){
        return false;
      }
      return true;
    });

    return validationRes;
  }

export function validateWolfCharacter(data){
  // eslint-disable-next-line
  const wolfFormat = /^[a-zA-Z0-9 \/\t\r\nÀàÂâÆæÇçÉéÈèÊêËëÍíÔôŒœÙùÛûÜüŸÿ?:().,'+{}\-]*$/;

  return wolfFormat.test(data);
}

export function validateWolfFields(fields){

    const invalidFieldName = [];

    fields.forEach(field => {
      if(!validateWolfCharacter(field.value)){
        invalidFieldName.push(field.label)
      }
    });

    return invalidFieldName;
  }


  export function lengthChecking(values, fields, length, error) {
    fields.forEach(f => {
      if (values[f] && values[f].length > length) {
        error[f] = maxLengthMessage(length);
      }
    })
  }


  export function wolfScan(data, fields){
    const scanFields = [];
    Object.keys(fields).forEach(key => {
      let value = '';
      if(key.includes('.')){
        const tmp = key.split('.');
        if(data[tmp[0]]){
          value = data[tmp[0]][tmp[1]];
        }
      }else{
        value = data[key];
      }

      scanFields.push({label: fields[key], value});

    });

    if(data.invoicedetaildto?.length>0){
      data.invoicedetaildto.forEach(invoice => {
        scanFields.push({label: `Invoice[${invoice.invno}] - Goods description`, value: invoice.descofgoods});
        if(invoice.invitemdetaildtolist?.length>0){
          invoice.invitemdetaildtolist.forEach(detail => {
            scanFields.push({label: `Invoice[${invoice.invno}] - Invoice detail - Product code`, value: detail.InvDetProdCode});
          });
        }
      });
    }

    return validateWolfFields(scanFields)
  }

  export const REQUIRED = 'required';
  export const EMAIL = 'email';
  export const MAX = 'max';
  export const MIN = 'min';
