import CDLCheckbox from '@cdl/checkbox';
import React from 'react';
import styled from "styled-components";


const CheckboxWrapper = styled.div`
    & span{
        width: 100%;
    }

    & label > span:first-of-type{
        width:20px;
    }   

`;


export default class Checkbox extends React.PureComponent {

    render() {

        return (
          <CheckboxWrapper>
            <CDLCheckbox {...this.props} />
          </CheckboxWrapper>
        );

    }
}

