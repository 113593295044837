/* eslint-disable import/no-extraneous-dependencies */
import CDLCheckbox from '@cdl/checkbox';
import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import DropdownField from 'common/components/Dropdown/DropdownField';
import IconButton from 'common/components/IconButton/IconButton';
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import SwitchTab from "common/components/SwitchTab/SwitchTab";
import Table from "common/components/Table/Table";
import TableCheckbox from "common/components/TableCheckbox/TableCheckbox";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import ValidationMessage from 'common/components/UserMessage/ValidationMessage';
import DraweeService from "common/services/drawee.service";
import MasterDocService from "common/services/masterDoc.service";
import ModuleService from 'common/services/module.service';
import UserService from 'common/services/user.service';
import { isAC } from 'common/utils/AppUtil';
import { ROW_SELECTED_CLASS, WOLF_SCAN_DRAWEE_FIELDS, WOLF_SCAN_IMPORTER_FIELDS } from 'common/utils/Constants';
import { wolfScan } from 'common/utils/FieldValidation';
import { getCountry } from 'common/utils/OptionUtil';
import WolfMsg from 'common/views/Popup/WolfMsg';
import React from 'react';
import styled from "styled-components";
import Confirm from 'common/views/Popup/Confirm';


const Wrapper = styled.div`
& .content-wrapper {
    width: initial;
    padding: initial;
    padding-right: 20px;
}
`;


const initialimpCfaData = Object.freeze({ "defCollectingBank": [{}], "defConsignee": [""], "defCountry": [""], "defIncotermList": [""],
  "defPortOfDischarge": [""], "defPortOfLoading": [""], "defTenorList": [""], "def_Bank": "", "def_Consignee": "", "consigneeName": "", "def_Incoterm": "",
  "def_PortDischarge": "", "def_PortLoad": "", "def_Tenor": "", "impAddr1": "", "impAddr2": "", "impAddr3": "", "impCity": "", "impCountry": "", "impEmail": "", "impFax": "",
  "impName": "", "impPostCode": "", "impRef": "", "impState": "", "impTel": "", "importerCode": "", "local": false, });

const subTableId = 'collectingBankTable';

export default class AddImporterConsigneeFowardingAgent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      impCfaData: props.edit ? props.editProps : {...initialimpCfaData},
      tabIndex: 0,
      collectingBankTableData: [],
      selectedId: null,
    };

    this.edsFlag = !(props.id === 'exporter' || props.id === 'bank');

    this.refImporterCode = React.createRef();
    this.refImpEmail = React.createRef();

    this.optConsigneecode = [];
    this.optIncoterm = [];
    this.optPort = [];
    this.optTenor = [];
    this.optCountry = getCountry(true);
  }

  componentDidMount(){
    if(isAC()){
      const company = {
        "coDocExpressID": this.props.company?.coDocExpressID,
        "coName": this.props.company?.coName,
        "isLFmode": this.props.company?.isLFmode
      }

      this.setLocalBuyer(company);

      this.initDraweeAC();
    }else{
      const user = UserService.getCurrentUser();
      if(user?.userroles === 'BA'){
        const company = {
          "coDocExpressID": user.codocexpressid,
        }
        this.setLocalBuyer(company);
      }else{
        this.setState({
          showLocalBuyer: ModuleService.hasSettlement(),
        })
      }

      this.loadBankTable();

      this.initDrawee();
    }

    if (this.props.editProps?.defCollectingBank) {
      const selectedRow = this.props.editProps?.defCollectingBank.find(item => item.bankCBIDCode === this.props.editProps.def_Bank);
      if (selectedRow?.bankCity) {
        this.checkinTable(selectedRow.bankCity);
      }
    }

    if(this.props.edit){
      this.setState({
        selectedId: this.props.editProps?.def_Bank
      })
    }

  }

  handleChange = (event) => {
    const {impCfaData} = this.state;
    const {name, value} = event.target;
    if (name === 'importerCode') {
      impCfaData[name] = value.trim();
    }else{
      impCfaData[name] = value;
    }

    this.setState({ impCfaData });
  }

  handleCheck = (event) => {
    const {impCfaData} = this.state;
    const {name, checked} = event.target;
    impCfaData[name] = checked;
    this.setState({ impCfaData });
  }

  clearState = () => {
    this.setState({
      impCfaData: {...initialimpCfaData},
      openConfirmPopup: false,
    })
  }

  closeMessage = () => {
    this.setState({
      message: "",
      showMessage: false,
    })
  }

  showError = (msg) => {
    this.setState({
      message: msg,
      showMessage: true,
      openConfirmPopup: false,
    })
  }

  getDisplayName = (isLowercase) => {
    let name = this.edsFlag ? 'Drawee' : 'Importer / consignee / forwarding agent';
    if(isLowercase){
      name = name.toLowerCase();
    }
    const {impCfaData} = this.state;
    let displayName = `${name} "${impCfaData.importerCode}"`;
    if(impCfaData.impName){
      displayName += ` - "${impCfaData.impName}"`;
    }
    return displayName;
  }


  handleSubmit = (e) => {
    e.preventDefault();
    const {impCfaData} = this.state;
    const action = this.props.edit ? DraweeService.saveDrawee(impCfaData) : DraweeService.addDrawee(impCfaData, this.props.coDocExpressID);
    action.then(response=>{
      if (response){
        const verErr = this.props.edit ? 'updating' : 'adding';
        const ver = this.props.edit ? 'updated' : 'added';
        if(response===1) {
          const {success, close} = this.props;
          success(`${this.getDisplayName()} has been ${ver} successfully.`);
          this.clearState();
          close();
        }else if(response===-8){
          this.showError(`Error ${verErr} ${this.getDisplayName(true)} - Reached maximum number of entries allowed.`);
        }else if(response===-9){
          this.showError(`Error ${verErr} ${this.getDisplayName(true)} - entry already exists.`);
        }else{
          this.showError(`Error ${verErr} ${this.getDisplayName(true)}.`);
        }
      }
    })
  }

  handleOpenConfirmPopup = () => {

    const scanFields = this.edsFlag ? WOLF_SCAN_DRAWEE_FIELDS : WOLF_SCAN_IMPORTER_FIELDS;
    const errorMsg = wolfScan(this.state.impCfaData, scanFields);
    if(errorMsg && errorMsg.length>0){
      this.setState({
        wolfMsg:errorMsg,
        openWolfMsg: true,
      })
      return;
    }

    let isValid = true;
    isValid = this.refImporterCode.current.validate() && isValid;
    isValid = this.refImpEmail.current.validate() && isValid;

    if(isValid){
      this.setState({
        openConfirmPopup: true,
        openWolfMsg: false,
        showMessage: false,
      })
    }

  }

  handleClose = () => {
    this.setState({
      openConfirmPopup: false,
    })
  }

  handleAutocomplete = name => value => {
    const {impCfaData} = this.state;
    impCfaData[name] = value;
    this.setState({ impCfaData });
    if (name === 'def_Consignee') {
      DraweeService.getConsigneeName(value, this.props.coDocExpressID).then(res => {
        impCfaData.consigneeName = res || '';
        this.setState({ impCfaData });
      })
    }
  }

  clearValue = (name) =>{
    const {impCfaData} = this.state;
    impCfaData[name] = '';
    this.setState({ impCfaData });
  }

  changeIndex = (index) => {
    this.setState({
      tabIndex: index,
    })
  }

    checkinTable = (selectedRowCity) => {
      const {preChecked} = this.state;
      const tableElement = document.querySelector(`#${subTableId} .rt-tbody`);
      const listNodes = tableElement.childNodes;
      const listNodeLength = listNodes.length;
      for (let i = 0 ; i < listNodeLength ; i++) {
        if (preChecked) {
          preChecked.checked = false;
          const rowElement = preChecked.parentElement.parentElement.parentElement;
          if (rowElement.classList.contains(ROW_SELECTED_CLASS)) {
            rowElement.classList.remove(ROW_SELECTED_CLASS);
          }
        }
        if (listNodes[i].textContent.includes(selectedRowCity)) {
          listNodes[i].firstChild.classList.add(ROW_SELECTED_CLASS);
          const preCheckedElement = listNodes[i].getElementsByTagName('input')[0];
          preCheckedElement.checked = true;

          this.setState({
            preChecked: preCheckedElement,
          })
          return;
        }
      }
    }

    onCheck = (item) => {
      const {impCfaData, selectedId} = this.state;
      if (selectedId === item) {
        impCfaData.def_Bank = null;
        this.setState({
          selectedId: '',
          impCfaData,
        })
      } else {
        impCfaData.def_Bank = item;
        this.setState({
          selectedId: item,
          impCfaData,
        })
      }
    }

  initDraweeAC = (isRefresh) => {
    DraweeService.initDrawee(this.props?.filters).then(res => {
      if (res) {
        if (res.defDrawee[0].defCollectingBank) {
          res.defDrawee[0].defCollectingBank = res.defDrawee[0].defCollectingBank.filter(element => {
            if (element && element.bankCity) {
              return true;
            }
            return false;
          });
          this.setState({
            collectingBankTableData: res.defDrawee[0].defCollectingBank,
          });
        }
        if(!isRefresh){
          this.constructOptions(this.optConsigneecode, res.defDrawee[0].defConsignee);
          this.constructOptions(this.optIncoterm, res.defDrawee[0].defIncotermList);
          this.constructOptions(this.optPort, res.defDrawee[0].defPortOfLoading);
          this.constructOptions(this.optTenor, res.defDrawee[0].defTenorList);
        }
      }
    });
  }

  initDrawee = () => {
    MasterDocService.initDrawee().then(res => {
      if (res) {
        this.constructOptions(this.optConsigneecode, res.defConsignee);
        this.constructOptions(this.optIncoterm, res.defIncotermList);
        this.constructOptions(this.optPort, res.defPortOfLoading);
        this.constructOptions(this.optTenor, res.defTenorList);
      }
    });
  }

  setLocalBuyer = (company) => {
    DraweeService.loadCompanyLFMode(company).then(res => {
      if (res) {
        this.setState({
          showLocalBuyer: res,
        });
      }
    });
  }

  loadBankTable = () => {
    MasterDocService.getMasterDocBankList().then(res => {
      if (res.data && res.data.list) {
        res.data.list = res.data.list.map(element => {
          if (element[0]) {
            return { 'bankName': element[0], 'bankCity': element[1], 'bankCBIDCode': element[2] };
          }
          return null;
        });
        this.setState({
          collectingBankTableData: res.data.list,
        });
      }
    });
  }

  constructOptions = (options, optionValues) => {
    if (optionValues) {
      optionValues.forEach((element) => {
        if (element) {
          options.push({ "label": element, "value": element });
        }
      });
    }
  }

  refreshTable = () => {
    if(isAC()){
      this.initDraweeAC(true);
    }else{
      this.loadBankTable();
    }
  }

  renderDetail() {
    const {tabIndex, impCfaData, disableField, showLocalBuyer} = this.state;

    return (
      <Wrapper style={{ display: tabIndex === 0 ? 'block' : 'none', paddingTop: '20px', overflow: 'auto' }}>
        <Grid container spacing={0} style={{ paddingBottom: '20px', paddingTop: '20px' }}>
          <Grid item xs={4}>
            <TextFieldForm
              label={this.edsFlag ? "Drawee ID*" : "Importer code*"}
              id="importerCode"
              name="importerCode"
              value={impCfaData.importerCode}
              maxLength={18}
              ref={this.refImporterCode}
              validations={["required"]}
              onChange={this.handleChange}
              disabled={this.props.edit}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldForm
              inputWidth="400px"
              label={this.edsFlag ? "Drawee name" : "Importer name"}
              id="impName"
              name="impName"
              value={impCfaData.impName}
              maxLength={55}
              onChange={this.handleChange}
              disabled={disableField}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldForm
              label={this.edsFlag ? "Drawee reference" : "Importer reference"}
              name="impRef"
              value={impCfaData.impRef}
              maxLength={20}
              onChange={this.handleChange}
            />
          </Grid>


          <Grid container spacing={0} alignItems="center">
            <Grid item xs={4}>
              <TextFieldForm
                label="Address line 1"
                name="impAddr1"
                value={impCfaData.impAddr1}
                maxLength={35}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldForm
                label="Address line 2"
                name="impAddr2"
                value={impCfaData.impAddr2}
                maxLength={35}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldForm
                label="Address line 3"
                name="impAddr3"
                value={impCfaData.impAddr3}
                maxLength={35}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>


          <Grid item xs={3}>
            <TextFieldForm
              label="City"
              name="impCity"
              value={impCfaData.impCity}
              maxLength={35}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs={3}>
            <TextFieldForm
              label="State"
              name="impState"
              value={impCfaData.impState}
              maxLength={35}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs={3}>
            <DropdownField
              labelOutside="Country / Territory"
              id="impCountry"
              name="impCountry"
              options={this.optCountry}
              onChange={this.handleAutocomplete("impCountry")}
              value={impCfaData.impCountry}
              selectWidth={232}
              country
              fontSizeFt
              clearValue={() => this.clearValue("impCountry")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldForm
              id="impPostCode"
              label="Postal code"
              name="impPostCode"
              value={impCfaData.impPostCode}
              maxLength={10}
              onChange={this.handleChange}
            />
          </Grid>


          <Grid item xs={3}>
            <TextFieldForm
              label="Telephone number"
              id="impTel"
              name="impTel"
              value={impCfaData.impTel}
              maxLength={30}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldForm
              label="Fax number"
              id="impFax"
              name="impFax"
              maxLength={30}
              value={impCfaData.impFax}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldForm
              label="Email address"
              id="impEmail"
              name="impEmail"
              value={impCfaData.impEmail}
              ref={this.refImpEmail}
              validations={["multiEmail"]}
              maxLength={100}
              onChange={this.handleChange}
            />
          </Grid>

          {showLocalBuyer &&
            <Grid item xs={2}>
              <CDLCheckbox
                label="Local buyer"
                id="localbuyer"
                name="local"
                checked={impCfaData.local}
                onChange={this.handleCheck}
              />
            </Grid>}
        </Grid>
      </Wrapper>
    )
  }

  renderDefaultSettings() {
    const {tabIndex, impCfaData, selectedId, collectingBankTableData, } = this.state;
    return (
      <Wrapper style={{ display: tabIndex === 1 ? 'block' : 'none', overflow: 'auto' }}>
        <Grid container spacing={0} style={{ paddingBottom: '20px', paddingRight: '20px' }}>
          <Grid item xs={2} style={{ paddingTop: '20px' }}>
            <span className="medium">Collecting Bank</span>
          </Grid>
          <Grid item xs={10} style={{ paddingTop: '20px' }}>
            <div className="rightBtn" style={{ paddingRight: '0' }}>
              <IconButton onClick={this.refreshTable} name="refresh">Refresh</IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Table
              maxRows={6}
              trim
              id={subTableId}
              showPagination={false}
              defaultPageSizeOption={{ 'value': 0 }}
              data={collectingBankTableData}
              columns={[
                {
                  Header: '',
                  accessor: 'checkbox',
                  Cell: ({ original }) => {
                    return (
                      <TableCheckbox
                        checked={selectedId === original.bankCBIDCode}
                        onChange={() => this.onCheck(original.bankCBIDCode)}
                      />
                    );
                  },
                  width: 40,
                  sortable: false
                },
                {
                  Header: 'City',
                  accessor: 'bankCity'
                },
                {
                  Header: 'Bank name',
                  accessor: 'bankName'
                }
              ]}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Grid item xs={4}>
            <DropdownField
              labelOutside="Consignee code"
              id={`consignee-code-${this.props.id}`}
              name="def_Consignee"
              options={this.optConsigneecode}
              onChange={this.handleAutocomplete("def_Consignee")}
              placeHolder={impCfaData.def_Consignee}
              value={impCfaData.def_Consignee}
              selectWidth={309}
              fontSizeFt
              clearValue={() => this.clearValue("def_Consignee")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldForm
              id="consigneeName"
              name="consigneeName"
              label="Consignee name"
              value={impCfaData.consigneeName}
              onChange={this.handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <DropdownField
              labelOutside="Seaport / Airport of loading"
              id={`seaport-airport-loading-${this.props.id}`}
              name="def_PortLoad"
              options={this.optPort}
              onChange={this.handleAutocomplete("def_PortLoad")}
              placeHolder={impCfaData.def_PortLoad}
              value={impCfaData.def_PortLoad}
              selectWidth={309}
              fontSizeFt
              clearValue={() => this.clearValue("def_PortLoad")}
            />
          </Grid>
          <Grid item xs={4}>
            <DropdownField
              labelOutside="Port of discharge"
              id={`port-of-discharge-${this.props.id}`}
              name="def_PortDischarge"
              options={this.optPort}
              onChange={this.handleAutocomplete("def_PortDischarge")}
              placeHolder={impCfaData.def_PortDischarge}
              value={impCfaData.def_PortDischarge}
              selectWidth={309}
              fontSizeFt
              clearValue={() => this.clearValue("def_PortDischarge")}
            />
          </Grid>
          <Grid item xs={4}>
            <DropdownField
              labelOutside="Incoterm"
              id={`incoterm-${this.props.id}`}
              name="def_Incoterm"
              options={this.optIncoterm}
              onChange={this.handleAutocomplete("def_Incoterm")}
              placeHolder={impCfaData.def_Incoterm}
              value={impCfaData.def_Incoterm}
              selectWidth={309}
              fontSizeFt
              clearValue={() => this.clearValue("def_Incoterm")}
            />
          </Grid>
          <Grid item xs={4}>
            <DropdownField
              labelOutside="Tenor"
              id={`tenor-${this.props.id}`}
              name="def_Tenor"
              options={this.optTenor}
              onChange={this.handleAutocomplete("def_Tenor")}
              placeHolder={impCfaData.def_Tenor}
              value={impCfaData.def_Tenor}
              selectWidth={309}
              fontSizeFt
              clearValue={() => this.clearValue("def_Tenor")}
            />
          </Grid>
        </Grid>
      </Wrapper>
    )
  }

  render(){
    const {openConfirmPopup, openWolfMsg, wolfMsg, showMessage, message} = this.state;

    return (
      <div>
        {openConfirmPopup &&
        <Confirm
          content={`Do you wish to ${this.props.edit ? 'update' : 'add'} the ${this.getDisplayName(true)}?`}
          isOpen={openConfirmPopup}
          confirm={this.handleSubmit}
          close={this.handleClose}
        />}
        {openWolfMsg &&
        <WolfMsg
          fields={wolfMsg}
          isOpen={openWolfMsg}
          close={()=>this.setState({openWolfMsg: false})}
        />}
        <Modal
          ariaLabelledby="modal-heading"
          classname="myClass"
          isOpen={this.props.isOpen && !openConfirmPopup && !openWolfMsg}
          width={1000}
        >
          <ModalHeader>
            <ModalTitle>{this.edsFlag ? 'Drawee details' : 'Add importer / consignee / forwarding agent'}</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.props.close} />
            </ModalBtnList>
          </ModalHeader>
          <ModalContent style={{paddingTop: showMessage ? '0px' : '16px', paddingRight: '0px', marginRight: '0px', display: 'flex', flexDirection:'column', overflow: 'hidden'}}>
            {showMessage &&
            <div style={{paddingTop: '20px'}}>
              <ValidationMessage message={message} onClose={this.closeMessage} />
            </div>}
            <SwitchTab
              tabs={[
                { title: this.edsFlag ? 'Drawee details' : 'Importer details (drawee / DC applicant)', url: 'applicant' },
                { title: 'Default settings', url: 'defaultSettings' }
              ]}
              onChangeIndex={this.changeIndex}
              reset={!this.props.isOpen}
            />

            {this.renderDetail()}

            {this.renderDefaultSettings()}

          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.props.close}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button onClick={this.handleOpenConfirmPopup} themeColor="primary">Save</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
