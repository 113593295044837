import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Field } from 'redux-form';
import styled from "styled-components";
import renderInput from '../ReduxForm/RenderInput';

const Label = styled.div`
    vertical-align: middle;
    display: table-cell;
    height: 30px;
`;

const Text = styled.div`
    & > div{
        margin-bottom: 15px;
        margin-right: 20px;
    }

    & #${props => props.id}Label{
        display: none;
    }
`;


export default function AdditionalField(props) {
    return (
      <>
        <Grid item xs={1}>
          <Label>{props.label}</Label>
        </Grid>
        <Grid item xs={4}>
          <Text id={props.fieldNameId}>
            <Field
              id={props.fieldNameId}
              name={props.fieldNameId}
              fullWidth
              component={renderInput}
              readOnly
              disabled
            />
          </Text>
        </Grid>
        <Grid item xs={7}>
          <Text id={props.fieldValueId}>
            <Field
              id={props.fieldValueId}
              name={props.fieldValueId}
              fullWidth
              component={renderInput}
              maxLength={props.maxLength}
            />
          </Text>
        </Grid>
      </>
    );
}

