import React from 'react';
import { connect } from 'react-redux';
import Filter from "common/components/Filter/Filter";
import { Grid } from '@material-ui/core';
import Dropdown from 'common/components/Dropdown/Dropdown';
import TextFieldForm from "common/components/TextField/TextFieldForm";
import DateRange from 'common/components/DatePicker/DateRange';
import { DATE_FORMAT, formatDate } from 'common/utils/DateUtil';
import Table from "common/components/Table/Table";
import Button from "common/components/Button/Button";
import MasterDocService from "common/services/masterDoc.service";
import DocPrepService from "common/services/doc-prep.service";
import CDLButton from '@cdl/button';
import { setTemplateFilter } from "common/redux/actions";

const _ = require('lodash');

const from = new Date();
from.setDate(from.getDate() -30);

const initialFilters = {
  mdocid: '',
  mdocdesc: '',
  exporter: '',
  importer: '',
  status: '',
  creator: '',
  from,
  to: new Date()
};


const tableColumn = [

    {
        Header: 'Template ID',
        accessor: 'id',
        className: 'docID',
        width: 120
    },
    {
        Header: 'Exporter',
        accessor: 'exporter',
        width: 310,
        Cell: row => <span title={row.original.exporter}>{row.original.exporter}</span>
    },
    {
        Header: 'Importer',
        accessor: 'importer',
        width: 310,
        Cell: row => <span title={row.original.importer}>{row.original.importer}</span>
    },
    {
        Header: 'Template description',
        accessor: 'desc',
        Cell: row => <span title={row.original.desc}>{row.original.desc}</span>
    },
    {
        Header: 'Last modified user',
        accessor: 'lastModifyUser',
        width: 145,
        Cell: row => <span title={row.original.lastModifyUser}>{row.original.lastModifyUser}</span>
    },
    {
        Header: 'Last modified on',
        accessor: 'lastModifyOn',
        width: 150
    }
  ]

class MDTemplate extends React.Component {
    constructor(props) {
        super(props);
        const filters = this.props.filters || _.cloneDeep(initialFilters);
        this.state = {
          filters,
          exporterOptions: [],
          importerOptions: [],
        }
    }

    componentDidMount() {
        MasterDocService.getMasterDocExporter().then(
            response => {
                if (response) {
                    this.setState({
                        exporterOptions: response.list.map(exporter => ({
                            label: exporter.expName ? `${exporter.exporterCode} | ${exporter.expName}` : exporter.exporterCode,
                            value: exporter.exporterCode
                        }))
                    });
                }
            }
        );
        MasterDocService.getMasterDocImporter().then(
            response => {
                if (response) {
                    this.setState({
                        importerOptions: response.list.map(importer => ({
                            label: importer.impName ? `${importer.importerCode} | ${importer.impName}` : importer.importerCode,
                            value: importer.importerCode
                        }))
                    });
                }
            }
        );
        this.fetchTableData();
    }


    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => {
          const {filters} = prevState;
          filters[name] = value;
          return { filters }
        })
      }

      handleAutocomplete = name => value => {
        this.setState(prevState => {
          const {filters} = prevState;
          filters[name] = value.value;
          return { filters }
        })
      }


      dateSelected = (name, value) => {
        this.setState(prevState => {
          const {filters} = prevState;
          filters[name] = value ? formatDate(value.from) : null;
          return { filters }
        })
      }

      onDateRangeSelected = (dateObj) => {
        this.setState(prevState => {
            const {filters} = prevState;
            filters.from = dateObj.from;
            filters.to = dateObj.to;
            return { filters }
        })
      }

    newMD = () => {
        this.props.setTemplateFilter(this.state.filters);
        MasterDocService.initMasterDoc().then(res => {
          DocPrepService.performResetDocument(res);
          this.props.history.push('/GenericDocPrep');
        });
    }

    handleRadioChange = (event) => {
        const {name, value} = event.target;
        this.setState({ [name]: value });
    }

    openMD = (id) => {
        const link = "/GenericDocPrep/GenericMasterDocument";
        const masterDoc = {
          mdocid: id,
          mdoctype: 'TM'
        }
        this.props.setTemplateFilter(this.state.filters);
        this.props.history.push(link, { masterDoc });
    }

    fetchTableData = () => {
        MasterDocService.filterMasterDocTemplate(this.state.filters).then(response => {
            if (response && response.list){
                const doclist = []
                response.list.forEach(element => {
                    doclist.push({
                        id : <CDLButton size="small" onClick={()=>this.openMD(element[0])} plain style={{ fontSize: '12px' }}>{element[0]}</CDLButton>,
                        exporter : element[1],
                        importer : element[2],
                        desc : element[3],
                        lastModifyUser : element[4],
                        lastModifyOn : element[5],
                    });
                });

                this.setState({
                    docList: doclist,
                });
            }
        });
    }

    clearFilter = () => {
      this.setState({
        filters: {
          mdocid: '',
          mdocdesc: '',
          fromDate: null,
          toDate: null,
          exporter: '',
          importer: '',
          status: ''
        }
      }, this.fetchTableData);
    }

    render() {
        const { exporterOptions, importerOptions, docList, filters, } = this.state;

        return (
          <>
            <Grid container spacing={0} style={{marginTop: '30px'}}>
              <Filter clearFilter={this.clearFilter} applyFilter={this.fetchTableData}>
                <Grid item xs="auto" style={{width: '130px'}}>
                  <TextFieldForm
                    seamless
                    name="mdocid"
                    value={filters.mdocid}
                    onChange={this.handleChange}
                    label="Template ID"
                    maxLength={11}
                    eds
                  />
                </Grid>

                <Grid item xs={3}>
                  <Dropdown
                    seamless
                    id="exporter"
                    name="exporter"
                    options={exporterOptions}
                    onChange={this.handleAutocomplete("exporter")}
                    placeHolder={filters.exporter || 'All'}
                    value={filters.exporter}
                    labelInside="Exporter"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Dropdown
                    seamless
                    id="importer"
                    name="importer"
                    options={importerOptions}
                    onChange={this.handleAutocomplete("importer")}
                    placeHolder={filters.importer || 'All'}
                    value={filters.importer}
                    labelInside="Importer"
                  />
                </Grid>

                <Grid item xs={3}>
                  <DateRange
                    defaultDate={{from: filters.from, to: filters.to}}
                    onDateSelected={this.onDateRangeSelected}
                    inputFormat={DATE_FORMAT}
                    displayFormat={DATE_FORMAT}
                    id="qtyUOM"
                    type="quickRange"
                    localeData={{'displayLabel':'Last modified on'}}
                    label="Last modified on"
                  />
                </Grid>

                <Grid item xs="auto" style={{width: '162px'}}>
                  <TextFieldForm
                    seamless
                    name="mdocdesc"
                    value={filters.mdocdesc}
                    onChange={this.handleChange}
                    label="Template description"
                    maxLength={35}
                    eds
                  />
                </Grid>
              </Filter>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} style={{paddingBottom: '20px', paddingTop: '30px'}}>
                <Table
                  id="openDocTable"
                  defaultPageSizeOption={{ 'value': 0 }}
                  showPagination
                  data={docList}
                  loading={this.state.loading}
                  maxRows={11}
                  columns={tableColumn}
                />
              </Grid>

              <Grid item xs={10} />
              <Grid item xs={2}>
                <Button disabled={this.state.isOpenDisabled} themeColor="primary" onClick={this.newMD} style={{float: 'right'}}>Create new template</Button>
              </Grid>
            </Grid>


          </>
        );
    }
}

const mapStateToProps = state => {
  return {
    filters: state.filterAction?.tplfilters,
  }
}

const mapDispatchToProps = dispatch => {
    return {
      setTemplateFilter: (data) => dispatch(setTemplateFilter(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MDTemplate);
