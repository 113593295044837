import React from 'react';
import IconButton from "common/components/IconButton/IconButton";
import CDLButton from '@cdl/button';
import CDLIcon from "@cdl/icon";
import Grid from '@material-ui/core/Grid';


export default class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: true,
    };
  }

  toggleFilter = () => {
    this.setState(prevState => {
      return {
        showFilter: !prevState.showFilter
      }
    })
  }

  render() {
    const {showFilter} = this.state;

    return (
      <>
        <IconButton
          transparent
          noBorder
          name='filter'
          size={14}
          onClick={this.toggleFilter}
        >
          Filter
          {showFilter &&
          <CDLIcon name="chevron-up-small" /> ||
          <CDLIcon name="chevron-down-small" />}
        </IconButton>
        {showFilter &&
          <Grid container spacing={0} style={{ paddingTop: '15px', paddingBottom: '15px', paddingLeft: '20px', paddingRight: '20px', backgroundColor: '#ededed' }}>
            <Grid container style={{ backgroundColor: '#eee' }}>
              {this.props.children}
            </Grid>
            <Grid container spacing={2} justify="flex-end" style={{ marginTop: '12px' }}>
              <Grid item style={{ marginTop: '5px', paddingRight: '12px' }}>
                <CDLButton onClick={() => this.props.clearFilter()} plain>Clear all</CDLButton>
              </Grid>
              <Grid item>
                <CDLButton size="small" themeColor="secondary" variant="slate" onClick={() => this.props.applyFilter()}>Apply filters</CDLButton>
              </Grid>
            </Grid>
          </Grid>}
      </>
    );
  }
}

