import Grid from '@material-ui/core/Grid';
import Divider from "common/components/Divider/Divider";
import Heading from "common/components/Heading/Heading";
import ReadonlyField from "common/components/ReadonlyField/ReadonlyField";
import renderDropdownCommon from 'common/components/ReduxForm/RenderDropdownCommon';
import renderTextArea from 'common/components/ReduxForm/RenderTextArea';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';


const options = [
    {label: '0', value: '0'},
    {label: '1', value: '1'},
    {label: '2', value: '2'},
    {label: '3', value: '3'},
    {label: '4', value: '4'},
    {label: '5', value: '5'}
];

const selectSize = 127;

const Dropdown = ({ ...props }) => (
  <Field
    component={renderDropdownCommon}
    selectWidth={selectSize}
    options={options}
    {...props}
  />
)

function Common() {

    const masterDoc = useSelector(state => state.form?.masterDoc?.values);

    // for master doc default value please set it in the service
    return (
      <div>
        <Heading level={2} brandbar><span className="medium">Decimal places masking</span></Heading>
        <div style={{paddingBottom:'10px'}}>Decimal places categories*</div>


        <Dropdown
          label="Unit price"
          id="unitPrice"
          setValue={{label: '3', value: 3}}
          name="unitpricedeccat"
        />

        <Field
          component={renderDropdownCommon}
          selectWidth={selectSize}
          options={[
            {label: '0', value: '0'},
            {label: '1', value: '1'},
            {label: '2', value: '2'},
            {label: '3', value: '3'}
          ]}
          label="Amount"
          id="amount"
          setValue={{label: '2', value: 2}}
          name="amtdeccat"
        />

        <Dropdown
          label="Quantity"
          id="quantity"
          setValue={{label: '2', value: 2}}
          name="qtydeccat"
        />

        <Dropdown
          label="No. of packages"
          id="noOfPackages"
          setValue={{label: '0', value: 0}}
          name="noofpkgdeccat"
        />

        <Dropdown
          label="Gross mass"
          id="grossMass"
          setValue={{label: '2', value: 2}}
          name="grossmassdeccat"
        />

        <Dropdown
          label="Net mass"
          id="netMass"
          setValue={{label: '2', value: 2}}
          name="netmassdeccat"
        />

        <Dropdown
          label="Cubic mass"
          id="cubicMass"
          setValue={{label: '2', value: 2}}
          name="cubicmassdeccat"
        />

        <Dropdown
          label="Interest rate"
          id="interestRate"
          setValue={{label: '4', value: 4}}
          name="interestratedeccat"
        />

        <Divider short />

        <Heading level={2} brandbar><span className="medium">General remark</span></Heading>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Field
              component={renderTextArea}
              id="remarksToInvoices"
              name="invaddinfo1"
              label="Remarks (Applicable to all invoices)"
              maxLength={200}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={renderTextArea}
              id="remarksToPackagingList"
              name="pladdinfo1"
              label="Remarks (Applicable to all packing lists)"
              maxLength={200}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={renderTextArea}
              id="remarksToBeneficiary"
              name="bcaddinfo1"
              label="Remarks (Applicable to all beneficiary cert./miscellaneous doc.)"
              maxLength={200}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={renderTextArea}
              id="remarksToBillExchange"
              name="boeaddinfo1"
              label="Remarks (Applicable to all bill of exchange)"
              maxLength={280}
            />
          </Grid>
        </Grid>


        <Divider short />

        <Heading level={2} brandbar><span className="medium">Master document version details</span></Heading>

        <Grid container spacing={0}>
          <Grid item xs={2}>
            <ReadonlyField name="VerNo" label="Version number" value={masterDoc?.verno} />

          </Grid>
          <Grid item xs={2}>
            <ReadonlyField name="Status" label="Status" value={masterDoc?.status === 'REVIEWED' ? 'SUBMITTED' : masterDoc?.status} />

          </Grid>
          <Grid item xs={2}>
            <ReadonlyField name="AppUserName" label="Approval user name" value={masterDoc?.appusername} />

          </Grid>
          <Grid item xs={2}>
            <ReadonlyField name="AppTime" label="Approval time" value={masterDoc?.apptime} />

          </Grid>
          <Grid item xs={2}>
            <ReadonlyField name="AppDate" label="Approval date" value={masterDoc?.appdate} />

          </Grid>

        </Grid>
      </div>
    )
}


export default Common
