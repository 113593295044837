import React from 'react';
import Heading from "common/components/Heading/Heading";
import Grid from '@material-ui/core/Grid';
import DropdownField from 'common/components/Dropdown/DropdownField';

export default class ReportType extends React.PureComponent {


    render() {
        const { reportOpt, countryOpt, reportType, country, disabled, changeReport, changeCountry, showCountry, returnOption } = this.props;
        return (
          <>
            <Grid item xs={12}>
              <Heading level={2} brandbar><span className="medium">Report type</span></Heading>
            </Grid>

            <Grid item xs={6}>
              <DropdownField
                id="reportType"
                options={reportOpt}
                value={reportType}
                onChange={changeReport}
                labelOutside="Report"
                inline
                selectWidth={400}
                labelWidth={100}
                returnOption={returnOption}
              />
            </Grid>

            {showCountry &&
            <Grid item xs={6}>
              <DropdownField
                options={countryOpt}
                inline
                selectWidth={300}
                labelWidth={200}
                value={country}
                onChange={changeCountry}
                labelOutside="Country / Territory"
                disabled={disabled}
              />
            </Grid>}
          </>
        );
    }
}
