import CDLDropdown from '@cdl/dropdown-legacy';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";

const DropdownContainer = styled.div`
    display: inline-block;
    padding-right: 20px;
    padding-bottom: 20px;

    div{
        label{
            font-size: 12px; 
            display: block;
            line-height: 1;
            font-family: "HSBC-Universe-Medium";
        }
    }

    div.Select-control{
        height: 32px;
    }

    

`;

export default function DropdownCommon(props) {

    const { onChange, value, ...rest } = props;

    const [currentValue, setCurrentValue] = useState(value || props.setValue?.value ? props.setValue.value === 0 : 0 || props.setValue.value);

    useEffect(() => {

      if (props.setValue) {
        setCurrentValue(props.setValue.value.toString());
        props.onChange(props.setValue.value.toString());
      }

      if (value !== currentValue) {
        setCurrentValue(value.toString());
        props.onChange(value.toString());
      }


      }, [props.value]
    );

    const onSelectChange = (event) => {
        props.onChange(event.value);
    }

    return (
      <DropdownContainer>
        <CDLDropdown
          onChange={onSelectChange}
          {...rest}
          value={currentValue}
        />
      </DropdownContainer>
    );
}

