import React from 'react';
import { ModalTitle, ModalBtnList, ModalBtn, ModalAction, ModalActionExtraButton, ModalActionButton } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalHeader from "common/components/Modal/ModalHeader";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";


export default function SessionTimeoutModal(props) {
    return (
      <Modal
        ariaLabelledby="modal-heading"
        classname="myClass"
        height={300}
        isOpen={props.isOpen}
        width={400}
      >
        <ModalHeader>
          <ModalTitle>Your session is about to time out</ModalTitle>
          <ModalBtnList>
            <ModalBtn name="delete" title="close" onClick={props.onClose} />
          </ModalBtnList>

        </ModalHeader>
        <ModalContent>
          To continue using the system, please extend your secure connection.
          <br /><br />
          1 minutes remaining
        </ModalContent>
        <ModalFooter hasBottomLine>
          <ModalAction>
            <ModalActionExtraButton>
              <Button onClick={props.onLogout}>Log off</Button>
            </ModalActionExtraButton>
            <ModalActionButton>
              <Button themeColor="primary" onClick={props.onContinue}>Extend my connection</Button>
            </ModalActionButton>
          </ModalAction>
        </ModalFooter>
      </Modal>
    )
}
