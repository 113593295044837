import CDLPageHeader from '@cdl/page-header';
import React from 'react';
import styled from "styled-components";
import { detectChanges } from 'common/utils/MasterDocUtil';

const PageHeaderWrapper = styled.div`
    & > div > h1{
      font-size: 26px;
    }
`;

export default class PageHeader extends React.PureComponent {

    pageClick = () => {
        detectChanges(() => {
          this.props.history.goBack();
        });
    }

    render() {

        let headingCls = "heading";
        if(this.props.noNav){
            headingCls += " noNav";
        }
        let element = null;
        if (this.props.title) {
            element =
              <PageHeaderWrapper className={headingCls}>
                <CDLPageHeader
                  pageTitle={this.props.title}
                  withLeftArrow={this.props.withLeftArrow}
                  onPageLinkClick={this.pageClick}
                />
              </PageHeaderWrapper>
        }

      return (
        <>
          {element}
        </>
      );


    }
  }
