import CDLButton from '@cdl/button';
import CDLHeader from '@cdl/header';
import Navigation from "common/components/Navigation/Navigation";
import UserInfo from "common/components/UserInfo/UserInfo";
import React from 'react';
import { Link } from 'react-router-dom';
import AuthService from "common/services/auth.service";
import styled from "styled-components";
import { detectChanges } from 'common/utils/MasterDocUtil';
import $ from "jquery";

const HeaderWrapper = styled.div`
  
    header {
        left: auto;
        min-width: 1250px;
        max-width: 1250px;
        z-index: 100;
        
        &> div:first-of-type{
            padding: 0 24px;
        }
    }

    > div.heading > div > h1{
        font-size: 26px;
    }

    .heading svg path{
        fill: white;
    }

    .heading a{
        margin: 0;
    }

    div.headerTitle{
        margin: 0 15px;
        background: #1d262c;
        padding-left: 15px;
        float: left;
        border-left: 0.0625rem solid rgba(253, 253, 253, 0.2);
        height: 20px;
    }

    div.headerTitle a{
        color: white;
        text-decoration: none;
    }

    div.backgroundBox{
        position: fixed;
        z-index: 99;
        width: 100%;
        height: 60px;
        left: 0;
        max-width: 1000rem;
        background: #1d262c;
    }
    

`;

const NavWrapper = styled.div`

    .headerNav nav {
        margin: inherit;
    }

`;


export default class Header extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            links: this.props.links
        };
    }

    componentDidMount() {
        if(this.props.history){
            $("header div div a").on('click', (event) => {
                event.preventDefault();
                detectChanges( ()=>{
                    this.props.history.push('/Home')
                } );
            });
        }
    }

    logOff = () => {
        detectChanges( ()=>{
            AuthService.logout().then(response => {
                this.props.history.push("/Logout", response);
            });
        } );
    }


    render() {

        return (
          <HeaderWrapper>
            <div className="backgroundBox" />

            <CDLHeader linkTo="/Home">
              <div className="headerTitle">
                <Link to="/Home">DocumentExpress</Link>
              </div>

              <div style={{marginLeft: 'auto'}}>
                {this.props.isLogin &&
                <div>
                  <UserInfo history={this.props.history} />

                  <CDLButton size="small" themeColor="secondary" variant="slate" onClick={this.logOff}>Log off</CDLButton>
                </div>}
              </div>
            </CDLHeader>

            <NavWrapper>
              {this.props.showNav &&
              <Navigation
                path={this.props.location.pathname}
                exact="false"
                links={this.state.links}
                history={this.props.history}
              />}

            </NavWrapper>

          </HeaderWrapper>
          )
    }


}
