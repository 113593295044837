import React from 'react';
import styled from "styled-components";

const widthStyle = props => props.short ? `width: calc(100% - 20px);`: '';

const DividerContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;

  hr {
    color:'#D7D8D6';
  }

  ${props => widthStyle(props)}

`;

export default class Divider extends React.PureComponent {
  render() {
    return (
      <DividerContainer short={this.props.short}>
        <hr />
      </DividerContainer>
    );
  }
}

