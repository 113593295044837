import React from 'react';

const RenderField = ({ component, input, meta: {  touched, invalid, error  }, ...rest }) => {
    const invalidMessage = (touched && error) ? error : '';
    return (
        React.createElement(component,
            {
                invalid: (touched && invalid),
                invalidMessage,
                ...input,
                ...rest,
            }
        )
    )
}

export default RenderField
