import CDLIcon from "@cdl/icon";
import AccessControl from "common/components/AccessControl/AccessControl";
import ButtonItem from "common/components/ButtonList/ButtonItem";
import $ from "jquery";
import React, { Component } from 'react';
import styled from "styled-components";

const Button = styled.button`
    height: 40px;
    border: none;
    background: transparent;
    padding: 0 10px 0 20px;
    outline: none;
    font-size: 14px;
    
    & span {
      padding-right: 20px;
      line-height: 2;
    }
`;

const Container = styled.div`
    display: flex;
    position: relative;
    border: 1px solid #767676;

    #${props => props.containerId} {
        width: calc(100% + 18px);
    }

    & .option-active{
        background-color: #f1f1f1;
    }

    background-color: white;

    &:hover {
      background-color: #ededed;
    }

    & input{
      box-sizing: border-box;
    }

`;


const ItemList = styled.div`
    position: absolute;
    z-index: 100;
    right: -1px;
    border: 1px solid black;
    min-width: 100%;
    white-space: nowrap;
    
`;


export default class ButtonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
      firstOption: this.props.options[0].label ? this.props.options[0].label : this.props.options[0]
    };
  }

  componentDidUpdate() {
    const selector = `#${this.props.id} .menuContainer`;

    if($(selector).length>0){
      $(selector).css('top', -$(selector).height()-2);
    }
  }

  onClick = () => {
    this.setState({
      showOptions: false,
    });
  };

  onButtonClick = () => {

    this.setState(prevState => ({
      showOptions: !prevState.showOptions,
    }));

  };


  onBlur = () => {
    this.setState({
     showOptions: false
    });
  };

  render() {
    const {
      onButtonClick,
      onBlur,
      state: { showOptions, firstOption }
    } = this;

    let optionList;
    let iconName = 'chevron-up';

    if (showOptions) {
      iconName = 'chevron-down';
      if (this.props.options) {
        const items = this.props.options.map((option, index) => {
            // first item not as menu item
            if(index>0){
              let label;
              if(typeof option === 'object'){
                label = option.label;
              }else{
                label = option;
              }

              const button = option.id || label;
              return (
                <AccessControl
                  key={button}
                  button={button}
                  docStatus={this.props.docStatus}
                  userPermissions={this.props.userPermissions}
                  printChoice={this.props.printChoice}
                  docType={this.props.docType}
                  statusList={this.props.statusList}
                >
                  <ButtonItem label={label} key={label} onClick={option.onClick} />
                </AccessControl>
              )
            }
              return null;


        });

        optionList = (
          <ItemList className="menuContainer">
            {items}
          </ItemList>
        );
      }
    }


    return (
      <Container containerId={this.props.id} id={this.props.id} onMouseLeave={onBlur}>
        <Button title="Open" type="button" onClick={onButtonClick}>
          <span>{firstOption}</span><CDLIcon name={iconName} size={18} />
        </Button>
        {optionList}
      </Container>
    );
  }
}


