import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Autocomplete from "common/components/Autocomplete/Autocomplete";
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import UploadFile from "common/components/UploadFile/UploadFile";
import ValidationMessage from 'common/components/UserMessage/ValidationMessage';
import FileService from 'common/services/file.service';
import UserService from 'common/services/user.service';
import { getFormName } from "common/utils/AppUtil";
import { getOption } from 'common/utils/OptionUtil';
import React from 'react';
import { connect } from 'react-redux';

class UploadMulti extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: {
          otherdoctype: "1st Party Document",
          filenames: "",
          successFiles: [],
          failedFiles: [],
          currentCount: null
        },
      }
      this.refFile = React.createRef();
      this.refDocType = React.createRef();
    }

    handleInputValue = (name, val) => {
      const {data} = this.state;
      data[name] = val;
      this.setState({ data });
    }

    uploadDoc = (user, docType, fileName, file) => {
      const uploadDocData = new FormData();
      const docStatus = this.props.isEDS ? "Incomplete" : "Pending Review";
      uploadDocData.append("encryptsid", user.encryptsid);
      uploadDocData.append("MDocID", this.props.mdocid);
      uploadDocData.append("docTitle", fileName);
      uploadDocData.append("docStatus", docStatus);
      uploadDocData.append("remarks", "");
      uploadDocData.append("docDesc", fileName);
      uploadDocData.append("docRefNoName", "");
      uploadDocData.append("docRefNo", "");
      uploadDocData.append("otherDocType", docType);
      uploadDocData.append("DocID","0");
      uploadDocData.append("countrycode", user.countrycode);
      uploadDocData.append("groupmemberid", user.groupmemberid);
      uploadDocData.append("username", user.username);
      uploadDocData.append("userroles", user.userroles);
      uploadDocData.append("codocexpressid", user.codocexpressid);
      uploadDocData.append("formname", getFormName());
      uploadDocData.append("jobNo", Math.floor(Math.random() * (1 + 9999 - 1000)) + 1000);
      uploadDocData.append("currentFile", 1);
      uploadDocData.append("totalFile", 1);
      uploadDocData.append("file", file);

      FileService.upload(uploadDocData).then(res => {
        if (res.includes('Upload Successful')) {
          this.setState(prevState => {
            prevState.data.successFiles.push(fileName);
            return {
              successFiles: prevState.data.successFiles,
              currentCount: prevState.currentCount - 1
            }
          },
            () => { this.closeAndDisplaySuccessFile() }
          )
        } else {
          this.setState(prevState => {
            prevState.data.failedFiles.push(fileName);
            return {
              failedFiles: prevState.data.failedFiles,
              currentCount: prevState.currentCount - 1
            }
          },
            () => { this.closeAndDisplaySuccessFile() }
          )
        }


      }).catch(error => {
        this.setState({
          showErrorMessage: true,
          message: error.response.data,
        })
      });
    }

    handleSave = () =>{
      if(this.refDocType.current.validate() && this.refFile.current.validate()){
        const {files} = this.refFile.current.inputRef.current;
        let total = 0;
        Array.from(files).forEach(file => {
          total += file.size;
        });
        if(this.props.masterDoc?.thirdpartydoc?.length>0){
          for(let i=0; i<this.props.masterDoc?.thirdpartydoc?.length; i++){
            total += this.props.masterDoc?.thirdpartydoc[i].docsize;
          }
        }

        if(total < 10*1024*1024){
          const user = UserService.getCurrentUser();
          const that = this;
          this.setState(prevState => {
            return {
              currentCount: prevState.data.filenames.split(/\s*,\s*/).length
            }
          }, () => {
            this.state.data.filenames.split(/\s*,\s*/).forEach((filename, i) => {
              setTimeout(() => {
                that.uploadDoc(user, that.state.data.otherdoctype, filename, that.refFile.current.inputRef.current.files[i]);
              }, i * 300);
            });
          })
        }else{
          this.setState({
            showErrorMessage: true,
            message: "Total uploaded file(s) size can not larger than 10MB per MD",
          });
        }
      }
    }

    closeAndDisplaySuccessFile() {
      if (this.state.currentCount === 0){
        const arr = this.state.data.filenames.split(/\s*,\s*/).filter(filename => {
          return (this.state.data.successFiles.findIndex(successFile => successFile === filename) > -1);
        })
        this.props.callback(arr.join(', '));
        if(this.state.data.failedFiles.length>0){
          this.props.showErrMessage(this.state.data.failedFiles.join(', '));
        }

        this.props.close();
      }
    }

    render(){
      return (
        <div>
          <Modal
            ariaLabelledby="modal-heading"
            isOpen={this.props.isOpen}
            width={560}
          >
            <ModalHeader>
              <ModalTitle>Upload multiple files</ModalTitle>
              <ModalBtnList>
                <ModalBtn name="delete" title="close" onClick={this.props.close} />
              </ModalBtnList>

            </ModalHeader>
            <ModalContent>
              <Grid container spacing={0} style={{paddingBottom: '20px'}}>
                {this.state.showErrorMessage &&
                <Grid item xs={12} style={{paddingRight:'20px'}}>
                  <ValidationMessage
                    message={this.state.message}
                    onClose={()=>this.setState({showErrorMessage:false})}
                  />
                </Grid>}
                <Grid item xs={12}>
                  <Autocomplete
                    label="Document Type*"
                    id="otherdoctype"
                    options={getOption('getDocTypeList')}
                    userInput={this.state.data.otherdoctype}
                    onChange={(value) => this.handleInputValue('otherdoctype', value)}
                    validations={["required"]}
                    ref={this.refDocType}
                  />
                </Grid>
                <Grid item xs={12} style={{paddingRight: '20px'}}>
                  <UploadFile
                    multiple
                    name="filenames"
                    label="Files to upload"
                    button="Browse"
                    accept=".pdf, .tif, .tiff, .jpg, .jpeg, .png, .bmp, .doc, .docx, .xls, .xlsx, .txt, .rtf, .msg"
                    onChange={this.handleInputValue}
                    ref={this.refFile}
                    validations={["required"]}
                    fileNameLength={200}
                  />
                </Grid>
              </Grid>
            </ModalContent>
            <ModalFooter hasBottomLine>
              <ModalAction>
                <ModalActionExtraButton>
                  <Button onClick={this.props.close}>Cancel</Button>
                </ModalActionExtraButton>
                <ModalActionButton>
                  <Button onClick={this.handleSave} themeColor="primary">Save</Button>
                </ModalActionButton>
              </ModalAction>
            </ModalFooter>
          </Modal>
        </div>
      )
    }
}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values,
})


export default connect(mapStateToProps)(UploadMulti)
