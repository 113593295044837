import CDLDropdown from '@cdl/dropdown-legacy';
import CDLUserMessage from '@cdl/user-message';
import React from 'react';
import styled from "styled-components";

const DropdownContainer = styled.div`
    display: inline-block;
    padding-right: 20px;

    & >div{
        label{
            display: block;
            line-height: 1;
            font-size: ${props => props.fontSizeFt ? '14px' : '12px'}
        }
    }
    div.Select-control{
        height: 32px;
        margin-bottom: 0.625rem;

        & .Select-value-label {
            font-weight: normal;
        }

    }
    &.seamless {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding-right: 0;
        border: 1px solid #929292;
        & >div {
            flex: 1;
        }
        & label {
            position: absolute;
            z-index: 1;
            top: 10px;
            left: 10px;
            font-size: 12px;
        }
        & .Select-arrow-zone {
            z-index: 1;
            padding: 14px auto;
        }


        & .cdl-dropdown {
            width: 100%;
            height: 100%;
            &>div.Select {
                display: flex;
                flex-direction: column;
                height: 100%;
                &>.Select-control {
                    height: 100%;
                    flex: 1;
                    border: none;
                    margin-bottom: 0;

                    & .Select-value {
                        top: 6px;
                    }

                    & .Select-placeholder {
                        top: 6px;
                    }
                }
            }
        }
        
    }



`;

export default class Dropdown extends React.Component {
    componentDidUpdate(prevProps){
        if(prevProps.disabled !== this.props.disabled
            && !this.props.disabled && this.props.onSelectValueChange){
                if(this.props.setDisableValue){
                    this.props.onSelectValueChange(this.props.setDisableValue);
                }
                if(this.props.setValue){
                    this.props.onSelectValueChange(this.props.setValue);
                }
        }
    }

    render(){
        const {seamless, invalid, invalidMessage, ...rest} = this.props;

        return (
          <DropdownContainer className={seamless ? 'seamless': null} seamless={seamless} invalid={invalid} fontSizeFt={this.props?.fontSizeFt}>
            <CDLDropdown size="large" {...rest} invalid={invalid} />
            {invalid && !!invalidMessage &&
              <CDLUserMessage
                id={`${this.props.name  }Error`}
                status='error'
                iconName='triangle-error'
              >
                {invalidMessage}
              </CDLUserMessage>}
          </DropdownContainer>
        );
    }
}

