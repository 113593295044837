/* eslint-disable import/no-extraneous-dependencies */
import CDLIcon from "@cdl/icon";
import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import React from 'react';

export default class Confirm extends React.PureComponent {

    handleConfirm = (event) => {
      this.props.confirm(event);
      this.props.close();
    }

    render() {
        const extraButton = this.props.extraButton ? this.props.extraButton : "No";
        const actionButton = this.props.actionButton ? this.props.actionButton : "Yes";
        const title = this.props.title || "Are you sure?";

        let {content} = this.props;
        if(this.props.titleField){
          content = content.replace("@title", this.props.titleField);
        }
        if(content){
          content = content.replace("@title", '');
        }


        return (
          <Modal
            ariaLabelledby="modal-heading"
            width={this.props.width || 400}
            height={this.props.height || 200}
            isOpen={this.props.isOpen}
          >
            <ModalHeader>
              <ModalTitle>
                <span style={{paddingRight:'5px'}}>
                  <CDLIcon name="circle-error-solid" color="WARNING" size={29} />
                </span>
                {title}
              </ModalTitle>
              <ModalBtnList>
                <ModalBtn name="delete" title="close" onClick={this.props.close} />
              </ModalBtnList>

            </ModalHeader>
            <ModalContent>
              <div style={{wordWrap: 'break-word'}}>
                {content}
              </div>
            </ModalContent>
            <ModalFooter hasBottomLine>
              <ModalAction>
                <ModalActionExtraButton>
                  <Button onClick={this.props.close}>{extraButton}</Button>
                </ModalActionExtraButton>
                <ModalActionButton>
                  <Button onClick={this.handleConfirm} themeColor="primary">{actionButton}</Button>
                </ModalActionButton>
              </ModalAction>
            </ModalFooter>
          </Modal>
        )
    }
}
