import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import { Radio } from '@cdl/radio-group';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import RadioVertical from "common/components/RadioVertical/RadioVertical";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import { setMasterDocSubmitType, showMasterDocMessage } from 'common/redux/actions';
import masterDocService from 'common/services/masterDoc.service';
import { getCurrentTab } from 'common/utils/AppUtil';
import { isBlank } from 'common/utils/StringUtils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import docPrepService from "common/services/doc-prep.service";


export default function SaveAs(props) {

  const form = useSelector(state => state.form);
  const packingList = useSelector(state => state.masterDocAction?.packingList);
  const beneficiaryCert = useSelector(state => state.masterDocAction?.beneficiaryCert);

  const [documentType, setDocumentType] = useState('MD');
  const [description, setDescription] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [isConstruct, setIsConstruct] = useState(false);
  const [openType, setOpenType] = useState(null);

  const dispatch = useDispatch();

  const mdocDesForDisplay = !isBlank(description) ? `- “${  description  }“` : '';

  const handleResp = (resp, metaData, currentTab) => {
    docPrepService.performGetDoucmnet(currentTab, form.masterDoc.values.mdocid, form.masterDoc.values.mdoctype.toUpperCase()).subscribe((responses) => {
      if (!responses.find(res => res.statusCode !== 200)) {
        docPrepService.handleResp(currentTab, responses, props, () => {
          masterDocService.getHouseKeepingBalance(form.masterDoc.values.mdoctype).then(res => {
            dispatch(showMasterDocMessage({
              isShowMessage: true,
              masterDocMessage: `Master Document “${form.masterDoc.values.mdocid}“ ${mdocDesForDisplay} has been saved successfully. 
              Space remaining (No. of master document) = ${res}`
            }));
          })
          props.submit();
        })
      }
    })
  }


  useEffect(() => {

    if (!isConstruct) {
      setOpenType(form.masterDoc.values.mdoctype);
      setIsConstruct(true);
    }


    if (canSubmit && form.masterDoc.submitSucceeded) {
      const currentTab = getCurrentTab();
      const metaData = { mdoctype: form.masterDoc?.mdoctype || documentType.toUpperCase(), mdocdesc: description };

      docPrepService.saveAs(form, metaData, packingList, beneficiaryCert).then(res=>{
        handleResp(res, metaData, currentTab);
      }, (err) => {
        props.close();
        let msg = 'Maximum number of transactions has been reached. Document will not be saved. Please contact your administrator.';
        if(err===-3){
          msg = "Maximum number of templates has been reached. Document will not be saved. Please contact your administrator.";
        }
        dispatch(showMasterDocMessage({
          isShowMessage: true,
          masterDocMessage: msg,
          messageType: 'ERROR'
        }));
      });

    } else if (canSubmit && !form.masterDoc.submitSucceeded) {
      props.close();
    }

    setCanSubmit(false);

  }, [canSubmit])


  const save = () => {
    // set timeout to cope with debounce handle input
    dispatch(setMasterDocSubmitType(documentType.toUpperCase()));
    setTimeout(() => {
      dispatch(submit('masterDoc'));
      dispatch(setMasterDocSubmitType(null));
      setCanSubmit(true);
    }, 0);


  }

  const onChangeType = (event) => {
    setDocumentType(event.target.value);
  }


  const modalTitle = openType === 'TM' ? 'Save as new master document' : 'Save as';
  return (
    <div>
      <Modal
        ariaLabelledby="modal-heading"
        classname="myClass"
        isOpen={props.isOpen}
        width={450}
      >
        <ModalHeader>
          <ModalTitle>{modalTitle}</ModalTitle>
          <ModalBtnList>
            <ModalBtn name="delete" title="close" onClick={props.close} />
          </ModalBtnList>

        </ModalHeader>
        <ModalContent>
          <Grid container spacing={0}>
            {openType !== 'TM' &&
              <Grid item xs={12}>
                <RadioVertical name="docType" legend="Doc type*">
                  <Radio onChange={onChangeType} checked={documentType === 'MD'} id="MD" label="Save as new master document" value="MD" />
                  <Radio onChange={onChangeType} checked={documentType === 'TM'} id="TM" label="Save as new master document template" value="TM" />
                </RadioVertical>
              </Grid>}
            <Grid item xs={12}>
              <TextFieldForm fullWidth inline label="Description" id="masDocDesc" onChange={(event) => setDescription(event.target.value)} />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalFooter hasBottomLine>
          <ModalAction>
            <ModalActionExtraButton>
              <Button onClick={props.close}>Cancel</Button>
            </ModalActionExtraButton>
            <ModalActionButton>
              <Button themeColor="primary" onClick={save}>Save</Button>
            </ModalActionButton>
          </ModalAction>
        </ModalFooter>
      </Modal>
    </div>

  )
}
