
import PwdService from 'common/services/pwd.service';
import UserService from 'common/services/user.service';
import {
  LOGIN_STATUSCODE_CONCURRENTLOGON
} from 'common/utils/Constants';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PrivateRoute(props) {
  const isForceUpdated = useSelector(state => state.pwdAction.isForceUpdated);
  const { component: Component, path, title, module, ...rest } = props;

  return (
    <Route
      {...rest}
      path={path}
      render={p => {
        const currentUser = UserService.getCurrentUser();

        const pathName = props.location?.pathname;

        if (pathName === '/jsp/healthCheck.jsp') {
          return null;
        }

        if (pathName === '/flex/AccessControl.html' || pathName === '/flex/DocExpress.html') {
          return <Redirect to="/Home" />;
        }

        if (!currentUser) {
          console.log('not authen');
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/Login', state: { from: props.location } }} />
        }

        if (currentUser.status_code === LOGIN_STATUSCODE_CONCURRENTLOGON) {
          return <Redirect to={{ pathname: '/Login', state: { from: props.location } }} />
        }


        if (PwdService.isForceChange(currentUser.status_code) && !isForceUpdated) {
          return <Redirect to={{ pathname: '/ForceChangePWD', state: { from: props.location } }} />
        }

        // authorised so return component
        return <Component form={props.form} {...p} title={title} module={module} />
      }}
    />
  );


}
