import React from 'react';
import DropdownField from '../Dropdown/DropdownField';
import RenderField from './RenderField';

const renderDropdown = ({ ...props }) => (
  <RenderField
    component={DropdownField}
    {...props}
  />
  )

export default renderDropdown
