import React from "react";

const defaultButton = props => <button type="button" {...props}>{props.children}</button>;

export default class Pagination extends React.Component {
  constructor(props) {
    super(props);

    this.changePage = this.changePage.bind(this);

    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.changePage(nextProps.page + 1);
  }


  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    this.props.onPageChange(page - 1);
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const activePage = this.props.page + 1;

    return (
      <div className="pagination-bottom">
        <div className="-pagination" style={{paddingBottom: 0}}>
          <div className="-center" />
          <div className="-previous">
            <PageButtonComponent
              className="-btn"
              onClick={() => {
                  if (activePage === 1) {
                    return;
                  }
                  this.changePage(activePage - 1);
                }}
              disabled={activePage === 1}
            >
              <i className="arrow" aria-label="Go to previous page" />
            </PageButtonComponent>
          </div>


          <div className="-next">
            <PageButtonComponent
              className="-btn"
              onClick={() => {
                  if (activePage === this.props.pages) {
                    return;
                  }
                  this.changePage(activePage + 1);
                }}
              disabled={activePage === this.props.pages}
            >
              <i className="arrow" aria-label="Go to next page" />
            </PageButtonComponent>
          </div>
        </div>
      </div>
    );
  }
}
