import BaseService from 'common/services/BaseService';
import UserService from 'common/services/user.service';
import {
    LOGIN_STATUSCODE_FIRSTLOGON,
    LOGIN_STATUSCODE_PWDCHGOVER90DAYS,
    LOGIN_STATUSCODE_FORCECHANGEPASSWORD,
} from 'common/utils/Constants';

class PwdService extends BaseService {

    changePWD(newpassword, oldpassword){
        const user = UserService.getCurrentUser();
        const requestBody = {
            "changepwddto": {
                "codocexpressid": user.codocexpressid,
                "countrycode": user.countrycode,
                "groupmemberid": user.groupmemberid,
                "newpassword": newpassword,
                "oldpassword": oldpassword,
                "username": user.username
            }
          }

        return this.post('/api/ChangePwdBO/verifyChangePwd', requestBody);
    }

    // eslint-disable-next-line class-methods-use-this
    isForceChange(statusCode){
        return statusCode === LOGIN_STATUSCODE_FIRSTLOGON
                || statusCode === LOGIN_STATUSCODE_PWDCHGOVER90DAYS
                || statusCode === LOGIN_STATUSCODE_FORCECHANGEPASSWORD
    }
}

export default new PwdService();
