import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from "common/components/IconButton/IconButton";
import ActionButtons from "common/components/ActionButtons/ActionButtons";
import AddNewUser from "common/views/Popup/AddNewUser";
import AddAssignModule from "common/views/Popup/AddAssignModule";
import UserService from 'common/services/user.service';
import UserMaintService from 'common/services/user-maint.service';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import Confirm from 'common/views/Popup/Confirm';
import Table from 'common/components/Table/Table';
import CDLButton from '@cdl/button';
import DropdownField from 'common/components/Dropdown/DropdownField';

const buttonArray = [
  { id: 'openEdit', name: 'Edit', showModal: 'openEditUser' },
  { id: 'openDelete', name: 'Delete', showModal: 'showDeleteRequestMessage' }
];


const columns = [
  {
    Header: 'User name',
    accessor: 'userName',
    width: 220
  },
  {
    Header: 'Full name',
    accessor: 'fullName',
    Cell: row => <span title={row.original.fullName}>{row.original.fullName}</span>
  },
  {
    Header: 'Description',
    accessor: 'desc',
    width: 430
  },
  {
    Header: 'Settings',
    accessor: 'moduleBtn',
    maxWidth: 80,
    sortable: false
  },
  {
    Header: 'Action',
    accessor: 'actionBtn',
    maxWidth: 110,
    sortable: false
  }
]

export default class UserMaintenanceSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewUserPopup: false,
      tableData: [],
      message: '',
      isEdit: false,
      role: '',
      openMessageDialog: false,
      selectedIndex: null,
      openAssignModule: false,
      roleOptions: [],
      openDeletePopup: false,
    }
  }


  componentDidMount(){
    const {roleOptions} = this.state;
    if(roleOptions.length === 0){
      UserMaintService.loadUserMaintRoles(UserService.getCurrentUser()?.userroles).then(response => {
        if (response && response.list) {
          const roles = [];
          response.list.forEach(element => {
            if(element.rolesID!=='SA'){
              roles.push({ "label": element.rolesDesc, "value": element.rolesID });
            }
          });
          this.setState({roleOptions: roles});
        }
      });
    }
  }

  assignModule = (rowIndex) => {
    this.setState({
      selectedIndex: rowIndex,
      openAssignModule: true,
    })
  }

  openEditUser = (rowIndex) => {
    this.setState({
      selectedIndex: rowIndex,
      openNewUserPopup: true,
      isEdit: true,
    })
  }

  showDeleteRequestMessage = (rowIndex) => {
    this.setState({
      selectedIndex: rowIndex,
      openDeletePopup: true,
    })
  }

  fetchTableData = () => {
    UserMaintService.loadUserMaint(this.state.role).then(response => {
      if (response && response.list) {
        const {username} = UserService.getCurrentUser();
        const data = response.list.filter(d => d.userName !== username).map((t, i) => {
          return Object.assign(t, {
            actionBtn: <ActionButtons
              rowIndex={i}
              buttonArray={buttonArray}
              openEditUser={this.openEditUser}
              showDeleteRequestMessage={this.showDeleteRequestMessage}
              justify='flex-start'
            />,
            moduleBtn: <CDLButton size="small" onClick={() => this.assignModule(i)} plain style={{ fontSize: '12px' }}>Module</CDLButton>
          })
        });

        this.setState({tableData: data});
      }
    }).catch(error => {
      throw error;
    });
  }


  showSuccessMessage = (msg) => {
    this.setState({
      message: msg,
      openMessageDialog: true,
    })
    this.fetchTableData();
  }


  handleAutocomplete = opt => {
    this.setState({role: opt.value, roleDesc: opt.label}, this.fetchTableData);
  }


  deleteUserMaint = () => {
    const { tableData, selectedIndex } = this.state;
    const { userName, fullName } = tableData.find((item, index) => index === selectedIndex);

    UserMaintService.deleteUserMaint({userName, fullName}).then(response => {
      if (response) {
        this.showSuccessMessage(`User "${tableData[selectedIndex]?.userName}" - "${tableData[selectedIndex]?.fullName}" has been deleted successfully`);
      }
    });
  }


  addNewUser = () => {
    this.setState({
      openNewUserPopup: true,
      isEdit: false,
      selectedIndex: null,
    })
  }

  render(){
    const { role, roleDesc, tableData, selectedIndex, openDeletePopup, message, isEdit,
      openMessageDialog, roleOptions, openNewUserPopup, openAssignModule  } = this.state;

    return (
      <>
        {openDeletePopup &&
        <Confirm
          content={`Do you wish to delete the user "${tableData[selectedIndex]?.userName}" - "${tableData[selectedIndex]?.fullName}"?`}
          confirm={this.deleteUserMaint}
          isOpen={openDeletePopup}
          close={()=>this.setState({openDeletePopup:false})}
        />}
        <Grid container spacing={0} style={{ paddingTop: '20px' }}>
          {openMessageDialog &&
          <Grid item xs={12}>
            <SuccessMessage message={message} handleCloseButtonClick={()=>this.setState({openMessageDialog:false})} />
          </Grid>}

          <Grid item xs={6}>
            <DropdownField
              options={roleOptions}
              onChange={this.handleAutocomplete}
              labelWidth={50}
              value={this.state.role}
              returnOption
              labelOutside="Role"
              inline
              width={300}
            />
          </Grid>
          <Grid item xs={6}>
            <div className="rightBtn" style={{ paddingRight: '0' }}>
              <IconButton disabled={!role} onClick={this.addNewUser} name="add">Add new user</IconButton>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Table
              maxRows={12}
              showPagination={false}
              defaultPageSizeOption={{'value':0}}
              data={tableData}
              columns={columns}
            />
          </Grid>
        </Grid>

        {openNewUserPopup &&
        <AddNewUser
          isOpen={openNewUserPopup}
          edit={isEdit}
          close={()=>this.setState({openNewUserPopup:false})}
          success={this.showSuccessMessage}
          role={role}
          roleDesc={roleDesc}
          username={tableData[selectedIndex]?.userName}
        />}

        {openAssignModule &&
        <AddAssignModule
          isOpen={openAssignModule}
          close={()=>this.setState({openAssignModule:false})}
          role={role}
          success={this.showSuccessMessage}
          username={tableData[selectedIndex]?.userName}
          fullname={tableData[selectedIndex]?.fullName}
          countrycode={tableData[selectedIndex]?.countrycode}
          groupmemberid={tableData[selectedIndex]?.groupmemberid}
        />}
      </>
  )
      }
}
