import CDLButton from '@cdl/button';
import Grid from '@material-ui/core/Grid';
import TableHelper from 'common/components/Table/TableHelper';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import FileService from 'common/services/file.service';
import MasterDocService from "common/services/masterDoc.service";
import { OVERFLOW_CLASS } from "common/utils/Constants";
import Confirm from "common/views/Popup/Confirm";
import DocumentDetail from "common/views/Popup/DocumentDetail";
import Notice from "common/views/Popup/Notice";
import UploadMulti from "common/views/Popup/UploadMulti";
import React from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";
import ValidationMessage from 'common/components/UserMessage/ValidationMessage';
import { updateMasterDocForm } from 'common/redux/actions';

const _ = require('lodash');

const TableWrapper = styled.div`
          padding-right: 15px;
`;


class UploadDocument extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        openNotice: false,
        openMulti: false,
        message: "",
        success: false,
        errMessage: "",
        showErrorMessage: false,
      };
      this.tableRef = React.createRef();
    }

    static getDerivedStateFromProps (props, prevState) {
      if(props.masterDoc?.mdocid !== prevState.prevID){
          return {
            prevID: props.masterDoc?.mdocid,
            message: "",
            success: false,
          };
      }
      return null;
    }

    upload =() => {
      this.tableRef.current.newRow();
    }


    uploadMulti =() => {
      this.setState({openMulti:true});
      document.documentElement.classList.add(OVERFLOW_CLASS);
    }

    closeMulti =() => {
      this.setState({openMulti:false});
      document.documentElement.classList.remove(OVERFLOW_CLASS);
    }

    download = (rowInfo) => {
      if(_.isEmpty(rowInfo.datetimeupload)){
        this.setState({openNotice: true});
      }else{
        MasterDocService.getDocFile(rowInfo.docid, this.props.masterDoc?.mdocid, this.props.queryType).then(res => {
          if(res){
            FileService.download(res);
          }
        });
      }
    }

    delete = (rowInfo) => {
      MasterDocService.deleteOtherDocs(
        rowInfo.docid,
        this.props.masterDoc?.mdocid,
        rowInfo.docimage,
        rowInfo.doctitle
      ).then(res => {
        if(res > 0){
          this.setState({
            message: "Other Documents entry successfully deleted.",
            success: true,
          });
          this.refreshTable();
        }

      });
    }

    closeMessage = () => {
      this.setState({message:"", success: false});
    }

    showMessage = (data, msg) => {
      const title = (typeof data === 'object') ? data.doctitle : data;
      const message = msg || `Other Documents [${title}] saved successfully.`;

      this.setState({message, success: true});

      this.refreshTable();
    }

    showErrMessage = (msg) => {
      this.setState({
        showErrorMessage: true,
        errMessage: `Other Documents [${msg}] saved failed.`,
      });
    }

    refreshTable = () => {
      if (this.props.isEDS) {
        MasterDocService.get3rdPartyDocsForAccessControl(this.props.masterDoc?.mdocid, this.props.queryType).then(res => {
          if(res.list){
            this.tableRef.current.refreshTable(res.list);
            this.props.masterDoc.thirdpartydoc = res.list;
            this.props.updateMasterDocForm(this.props.masterDoc);
          }
        });
      } else {
        MasterDocService.get3rdPartyDocs(this.props.masterDoc?.mdocid).then(resp => {
          if(resp.list){
            this.tableRef.current.refreshTable(resp.list);
            this.props.masterDoc.thirdpartydoc = resp.list;
            this.props.updateMasterDocForm(this.props.masterDoc);
          }
        });
      }
    }

    isDisabled() {
      let disableUpload = true;

      if (this.props.masterDoc?.status === 'COMPLETED' || this.props.masterDoc?.status === 'UNLOCKED') {
        disableUpload = false;
      }

      if (!this.props.masterDoc?.mdocid) {
        disableUpload = true;
      }

      if(this.props.isEDS && this.props.hasEdit && this.props.masterDoc?.mdocid){
        disableUpload = false;
      }

      return disableUpload;
    }

    render(){
      const disableUpload = this.isDisabled();

      const buttonArray = [{id: 'openDetail', name: 'Details',  showModal: 'showRowItem'},
                    {id: 'openDelete', disabled: disableUpload || this.props.queryType === 'E', name: 'Delete', action: 'deleteRow', modal: Confirm,
                     modalProps :{content: 'Do you wish to delete the upload document "@title"?'} },
                     {id: 'download', name: 'Download', showModal: 'extraAction',}
                   ];
      if (this.props.isMDDashboard) {
        buttonArray.splice(1, 1);
      }

    return (
      <div>
        {this.state.success &&
          <div style={this.props.inca? {paddingRight: '0px'}:{paddingRight: '20px'}}>
            <SuccessMessage message={this.state.message} handleCloseButtonClick={this.closeMessage} />
          </div>}
        {this.state.showErrorMessage &&
          <Grid item xs={12}>
            <ValidationMessage
              message={this.state.errMessage}
              onClose={()=>this.setState({showErrorMessage:false})}
            />
          </Grid>}
        <Grid container spacing={0} alignItems="flex-end">
          <Grid item xs={8}>
            {this.props.heading}
          </Grid>
          <Grid item xs={4}>
            {!this.props.isMDDashboard && !this.props.hideButton &&
              <div>
                <div className="rightBtn">
                  <CDLButton size="small" onClick={this.uploadMulti} disabled={disableUpload}>Upload multiple files</CDLButton>
                </div>
                <div className="rightBtn">
                  <CDLButton size="small" onClick={this.upload} disabled={disableUpload}>Upload single file</CDLButton>
                </div>
              </div>}
          </Grid>


          <Grid item xs={12}>
            <TableWrapper className="table-wrapper">
              <TableHelper
                ref={this.tableRef}
                component={DocumentDetail}
                buttonArray={buttonArray}
                data={this.props.masterDoc?.thirdpartydoc || []}
                mdocid={this.props.masterDoc?.mdocid}
                extraAction={this.download}
                deleteRow={this.delete}
                callback={this.showMessage}
                titleField="doctitle"
                isEDS={this.props.isEDS}
                exemptDisableButtonMap={this.props.isEDS ? {openDelete: {userroles: 'CA'}} : null}
                queryType={this.props.queryType}
                inca={this.props.inca}
                hasEdit={this.props.hasEdit}
                columns={[
  {
    Header: 'Document title',
    accessor: 'doctitle',
    Cell: row => <span title={row.original.doctitle}>{row.original.doctitle}</span>
  },
  {
    Header: 'Description',
    accessor: 'docdesc',
    Cell: row => <span title={row.original.docdesc}>{row.original.docdesc}</span>
  },
  {
    Header: 'Reference number',
    accessor: 'docrefno',
    Cell: row => <span title={row.original.docrefno}>{row.original.docrefno}</span>
  },
  {
    Header: 'Uploaded by',
    accessor: 'uploadedby'
  },
  {
    Header: 'Date / Time uploaded',
    accessor: 'datetimeupload'
  },
  {
    Header: 'Document status',
    accessor: 'docstatus'
  },
  {
    Header: 'Action',
    accessor: 'actionBtn',
    sortable: false,
    width: 200
  }

]}
              />
            </TableWrapper>
          </Grid>


        </Grid>

        {this.state.openMulti &&
        <UploadMulti
          isOpen={this.state.openMulti}
          close={this.closeMulti}
          mdocid={this.props.masterDoc?.mdocid}
          callback={this.showMessage}
          showErrMessage={this.showErrMessage}
          isEDS={this.props.isEDS}
          hasEdit={this.props.hasEdit}
        />}

        <Notice
          content="Please upload the file before viewing."
          isOpen={this.state.openNotice}
          close={()=>{this.setState({openNotice: false})}}
        />

      </div>
    )
  }

}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values
})

const mapDispatchToProps = dispatch => {
  return {
    updateMasterDocForm: (payload) => dispatch(updateMasterDocForm(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocument)
