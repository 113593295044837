import BaseService from 'common/services/BaseService';

class ProcessingCentreService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/ProcessingCtrBO/';
  }

  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }


  loadProcessingCtr() {
    return this.post('loadProcessingCtr', this.getInUserDTO());
  }


}

export default new ProcessingCentreService();
