import React from 'react';
import LiFungSection from "./Sections/LiFungSection";


export default class LiFung extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
          <div>
            <LiFungSection />
          </div>
        );
    }
}
