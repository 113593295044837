import React from 'react';
import NumberField from "common/components/NumberField/NumberField";

const renderNumberField = ({ input, meta: {  touched, invalid, error  }, ...rest }) => {
    const invalidMessage = (touched && error) ? error : '';

    return <NumberField
      invalid={touched && invalid}
      invalidMessage={invalidMessage}
      reduxForm
      {...input}
      {...rest}
    />

}

export default renderNumberField
