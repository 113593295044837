import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from "common/components/Button/Button";
import { required } from 'common/utils/ReduxFormValidation';
import Grid from '@material-ui/core/Grid';
import renderInput from 'common/components/ReduxForm/RenderInput';


const LoginForm = props => {
  const { handleSubmit, submitting } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div style={{float: 'left', paddingRight:'120px'}}>
            <Field
              label="User ID"
              placeholder="User Name"
              name="username"
              inline
              size="big"
              inputWidth="280"
              validate={required}
              component={renderInput}
            />
          </div>

          <div style={{float: 'left', paddingRight:'20px'}}>
            <Field
              label="Password"
              placeholder="Password"
              type="password"
              name="password"
              inline
              size="big"
              inputWidth="280"
              validate={required}
              component={renderInput}
            />
          </div>

          <div>
            <Button themeColor="primary" type="submit" disabled={submitting}>Logon</Button>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}


export default reduxForm({
  form: 'loginForm',
  initialValues: {
    username: '',
    password: '',
  },
})(LoginForm)
