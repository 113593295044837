import React from 'react'
import { Field, reduxForm, reset } from 'redux-form';
import Button from "common/components/Button/Button";
import { required, minLength12, maxLength20, oneUpper, oneLower, oneSpecial, consecutive, oneNumber, nonSpace } from 'common/utils/ReduxFormValidation'
import UserService from 'common/services/user.service';
import styled from "styled-components";
import renderInput from 'common/components/ReduxForm/RenderInput';
import { CHANGEPWD_STATUSCODE_AUTHORISEDPWDCHG } from 'common/utils/Constants';
import PwdService from 'common/services/pwd.service';

const Container = styled.div`
    margin-bottom: 15px;
`;

const submit = (values) => {
  return PwdService.changePWD(values.newPassword, values.existingPassword)
}

const PwdForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Container>
        <Field
          name="existingPassword"
          component={renderInput}
          label="Existing password*"
          validate={required}
          maxLength={20}
          inputWidth={300}
          size="big"
          inline
          type="password"
          longLabel
        />
      </Container>
      <Container>
        <Field
          name="newPassword"
          component={renderInput}
          label="New password*"
          maxLength={20}
          inputWidth={300}
          size="big"
          inline
          type="password"
          longLabel
          validate={[required, minLength12, maxLength20, oneUpper, oneLower, oneNumber, oneSpecial, consecutive, nonSpace]}
        />
      </Container>
      <Container>
        <Field
          name="confirmPassword"
          component={renderInput}
          label="Confirm password*"
          validate={[required]}
          maxLength={20}
          inputWidth={300}
          size="big"
          inline
          type="password"
          longLabel
        />
      </Container>
      <div style={{float: 'right', paddingRight: '105px', paddingTop: '29px'}}>
        <Button themeColor="primary" type="submit" disabled={submitting}>Apply now</Button>
      </div>
    </form>
  )
}


const validate = values => {
  const errors = {}
  if(values.newPassword && values.newPassword.toLowerCase().includes(UserService.getCurrentUser().username?.toLowerCase())){
    errors.newPassword = 'Not be based on the username';
  }else if(values.newPassword === values.existingPassword){
    errors.newPassword = 'New password and Existing password can not the same';
  }else if(values.newPassword !== values.confirmPassword){
    errors.confirmPassword = 'New password and Confirm password is not the same';
  }
  return errors;
}

const afterSubmit = (result, dispatch, props) => {
  if(result === CHANGEPWD_STATUSCODE_AUTHORISEDPWDCHG){
    dispatch(reset('changePassword'));
  }
  props.handleResult(result);
}


export default reduxForm({
  form: 'changePassword',
  validate,
  initialValues: {
    existingPassword: '',
    newPassword: '',
    confirmPassword: '',
  },
  onSubmitSuccess: afterSubmit,
})(PwdForm)
