import Collapsible from "common/components/Collapsible/Collapsible";
import { setMasterDoc, setMasterDocState } from "common/redux/actions";
import docPrepService from "common/services/doc-prep.service";
import { isBlank } from "common/utils/StringUtils";
import { fixActionBarBottom, initActionBarPosition } from "common/utils/UserInterfaceUtil";
import DraweeAndReimbursementBankSection from "common/views/CommonPage/DraweeAndReimbursementBankSection";
import ExportInstruction from "common/views/CommonPage/ExportInstruction";
import ExportInstructionOthers from "common/views/CommonPage/ExportInstructionOthers";
import OtherInstructions from "common/views/CommonPage/OtherInstructions";
import React from 'react';
import { connect } from "react-redux";
import { masterDocDefaultValue } from "common/utils/DocPrepConstant";
import { getStatus } from 'common/utils/StatusUtil';
import { isDADP } from 'common/utils/MasterDocUtil';

class ExportTradeCollectionAndNegotiationInstruction extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
      const masterDoc = this.props.location.state?.masterDoc || this.props.masterDoc;
      if (masterDoc.mdocid) {
        const metaData = {mdocid: masterDoc.mdocid, mdoctype: masterDoc.mdoctype};
        docPrepService.loadExportInstruction(metaData).subscribe(responses => {
          this.props.setMasterDoc(null);
          const [masterDocResponse, billOfExchangeResponse, exportInstructionResponse] = responses;

          if (isBlank(exportInstructionResponse.data)) {
            masterDocResponse.data.eidto = { ...masterDocDefaultValue.eidto};
          } else {
            masterDocResponse.data.eidto = exportInstructionResponse.data;
          }

          const eiType = masterDocResponse.data.eidto.eitype || masterDocResponse.data.eidto.EIType;
          if(isDADP(eiType)){
            masterDocResponse.data.eidto.donotprotest = !masterDocResponse.data.eidto.protest;
          }
          masterDocResponse.data.billofexchangedto = billOfExchangeResponse.data
          this.props.setMasterDoc(masterDocResponse.data);
          this.props.setMasterDocState(masterDocResponse.data.status);
        })
      }

      initActionBarPosition();
      fixActionBarBottom();

    }


    updateStatus = () => {
      const {masterDocProps} = this.props;
      const status = getStatus('ei', masterDocProps);
      this.setState({
        updatedStatus: status
      })
    }

    render() {

        return (
          <div>
            <Collapsible label="5. Drawee bank / reimbursing bank" className="collapsible">
              <DraweeAndReimbursementBankSection />
            </Collapsible>

            <Collapsible label="6. Export instructions" className="collapsible" section='ei' updatedStatus={this.state.updatedStatus}>
              <ExportInstruction updateStatus={this.updateStatus} />
            </Collapsible>

            <Collapsible label="7. Other instructions" className="collapsible">
              <OtherInstructions />
            </Collapsible>

            <Collapsible label="8. Others" className="collapsible last">
              <ExportInstructionOthers />
            </Collapsible>
          </div>
          )
    }
}

const mapStateToProps = state => ({
  masterDocProps: state.form?.masterDoc,
  masterDoc: state.form?.masterDoc?.values,
})

const mapDispatchToProps = dispatch => {
  return {
      setMasterDoc: (data) => dispatch(setMasterDoc(data)),
      setMasterDocState: (data) => dispatch(setMasterDocState(data)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ExportTradeCollectionAndNegotiationInstruction)
