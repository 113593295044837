import React from 'react';
import Checkbox from 'common/components/Checkbox/Checkbox';

const renderCheckbox = ({ input, label, name, disabled, id, meta: { touched, invalid, error  }}) => (
  <Checkbox
    invalid={touched && invalid}
    label={label}
    id={id}
    name={name}
    checked={input.value}
    invalidMessage={touched && error}
    disabled={disabled}
    {...input}
  />
  )

export default renderCheckbox
