import { isBlank } from 'common/utils/StringUtils';

const _ = require('lodash');

export function masterDocAction(state = {}, action = {}) {
  switch (action.type) {
    case 'SET_MASTER_DOC':
      return {...state, initDoc: action.payload, masterDoc: {}, masterDocOriginal: _.cloneDeep(action.payload),
             showSaveAs: false, isDirty: false, isReset:false, hideErrors: {}}
    case 'SET_MASTER_DOC_STATE':
      return {...state, masterDocState: action.payload};
    case 'SET_INVOICE_LIST':
      if (!action.payload) {
        return {...state, invoiceList: null}
      }
      return {...state, invoiceList: {initial: _.cloneDeep(action.payload), values: action.payload}}
    case 'SET_PACKING_LIST':
      if (!action.payload) {
        return {...state, packingList: null}
      }
      return {...state, packingList: {initial: _.cloneDeep(action.payload), values: action.payload} }
    case 'SET_BENEFICIARY_CERT':
      if (!action.payload) {
        return {...state, beneficiaryCert: null}
      }
      return {...state, beneficiaryCert: {initial: _.cloneDeep(action.payload), values: action.payload} }
    case 'SET_VESSEL_LIST':
      if (action.payload) {
        return {...state, vesselList: action.payload}
      }
      return {...state}
    case 'SET_MASTER_DOC_SUBMIT_TYPE':
      return {...state, submitType: action.payload}
    case 'RESET_FORM':
      return {...state, isReset: action.payload.isReset, isDirty: false, targetUrl: action.payload.targetUrl, packingList: null, beneficiaryCert: null};
    case 'SHOW_SAVE_AS':
      return {...state, showSaveAs: action.payload.showSaveAs, targetUrl: action.payload.targetUrl};
    case 'SHOW_MASTER_DOC_MESSAGE':
      if (action.payload.isShowMessage) {
        window.scrollTo(0, 0);
      }
      return {...state, isShowMessage: action.payload.isShowMessage, masterDocMessage: action.payload.masterDocMessage, messageType: action.payload.messageType};
    case 'SET_COUNTRY_LIST':
      return {...state, countryList: action.payload}
    case 'CONFIRM_CHANGE_ROUTE':
      return {...state, isReset: false, isDirty: false, latestUrl: action.payload.latestUrl}
    case 'DISMISS_RESET':
      return {...state, isReset: false};
    case 'DISMISS_TARGET_URL':
      return {...state, targetUrl: null};
    case 'SET_HIDE_ERROR':
      if (isBlank(action.payload)) {
        return {...state, hideErrors: {}}
      }
      return {...state, hideErrors: Object.assign(state.hideErrors, {[action.payload]: true})}
    case 'DISABLE_INVOICE_AMOUNT':
      return {...state, disableInvoiceAmt: action.payload}
    default:
      return state;
    }
}


