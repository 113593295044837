import {
    conditionalBankFields, conditionalExporterFields, conditionalOtherFields, conditionalBankFieldsForEDS,
    masterDocExporterImporterMandatoryFields, masterDocOtherInformationFields, statusIncomplete,
    statusError, EDSFields, billOfExchangeFields, exportInstructionsFields, masterDocOtherInformationMandatoryFields,
    masterDocExporterImporterMandatoryFieldsForEDS, conditionalExporterFieldsForEDS, masterDocOtherInformationMandatoryFieldsForEDS,
    conditionalOtherFieldsForEDS, masterDocOtherLCMandatoryFields, masterDocOtherLCMandatoryFieldsForEDS
} from 'common/utils/DocPrepConstant';
import ModuleService from 'common/services/module.service';
import { MSG_EMPTY } from 'common/utils/Constants';
import { isBlank } from 'common/utils/StringUtils';
import { getCurrentTab } from "common/utils/AppUtil";

const _ = require('lodash');

const hasWarning = (fields, submitErrors) => {
    return submitErrors && fields.find(field => field.find(item => submitErrors[item]));
}

const hasError = (fields, submitErrors) => {
    return submitErrors && fields.find(field =>
        field.find(item => submitErrors[item] && submitErrors[item] !== MSG_EMPTY && !submitErrors[item]?.includes('Please create at least')));
}

const checkStatus = (fields, submitErrors, status) => {
    if (hasWarning(fields, submitErrors)) {
        status = statusIncomplete;
    }
    if (hasError(fields, submitErrors)) {
        status = statusError;
    }
    return status;
}

const submitFailedCase = (section, submitErrors) => {
    let status = null;
    if(!submitErrors){
        return null;
    }
    switch (section) {
        case 'importer':
            status = checkStatus([masterDocExporterImporterMandatoryFields, conditionalExporterFields], submitErrors, status);
            break;
        case 'importerEDS':
            status = checkStatus([masterDocExporterImporterMandatoryFieldsForEDS, conditionalExporterFieldsForEDS], submitErrors, status);
            break;
        case 'bank':
            status = checkStatus([conditionalBankFields], submitErrors, status);
            break;
        case 'bankEDS':
            status = checkStatus([conditionalBankFieldsForEDS], submitErrors, status);
            break;
        case 'other':
            status = checkStatus([masterDocOtherInformationFields, conditionalOtherFields, EDSFields], submitErrors, status);
            if(status === null && getCurrentTab()==='GenericBillOfExchange'){
                status = checkStatus([['boecurrencycode']], submitErrors.billofexchangedto, status);
            }
            break;
        case 'otherEDS':
            status = checkStatus([masterDocOtherInformationMandatoryFieldsForEDS, conditionalOtherFieldsForEDS], submitErrors, status);
            break;
        case 'invoice':
            status = checkStatus([['invoicedetaildto']], submitErrors, status);
            break;
        case 'packingList':
            status = checkStatus([['packingList']], submitErrors, status);
            break;
        case 'beneficiaryCert':
            status = checkStatus([['beneficiaryCert']], submitErrors, status);
            break;
        case 'boe':
            status = checkStatus([billOfExchangeFields], submitErrors.billofexchangedto, status);
            break;
        case 'ei':
            status = checkStatus([exportInstructionsFields], submitErrors.exportinstructions, status);
            break;
        default:
            break;
    }
    return status;
}

function isIncomplete(condition) {
    return (condition ? statusIncomplete : null);
}

function initCase(section, masterDoc, masterDocProps) {
    let status = null;
    switch (section) {
        case 'importer': {
            status = isIncomplete(!masterDoc || masterDocExporterImporterMandatoryFields.find(field => isBlank(masterDoc[field])));
            break;
        }
        case 'bank':
            status = isIncomplete(ModuleService.hasLFEDSModule() && isBlank(masterDoc.bankname));
            break;
        case 'other': {
            const otherFields = masterDoc.transportationmode === 'Not Applicable' ? masterDocOtherLCMandatoryFields : masterDocOtherInformationMandatoryFields;
            status = isIncomplete(!masterDoc || otherFields.find(field => isBlank(masterDoc[field])));
            break;
        }
        case 'invoice':
            status = isIncomplete(_.isEmpty(masterDoc?.invoicedetaildto));
            break;
        case 'packingList':
            status = isIncomplete(_.isEmpty(masterDocProps?.packingList));
            break;
        case 'beneficiaryCert':
            status = isIncomplete(_.isEmpty(masterDocProps?.beneficiaryCert));
            break;
        case 'boe':
            status = isIncomplete(!masterDoc?.billofexchangedto || billOfExchangeFields.find(field => isBlank(masterDoc?.billofexchangedto[field])));
            break;
        case 'ei':
            status = isIncomplete(!masterDoc || isBlank(masterDoc.eidto?.eitype));
            break;
        default:
            status = statusIncomplete;
            break;
    }

    return status;
}

function initEDS(section, masterDoc) {
    let status = null;
    switch (section) {
        case 'importerEDS':{
            status = isIncomplete(!masterDoc || masterDocExporterImporterMandatoryFieldsForEDS.find(field => isBlank(masterDoc[field])));
            break;
        }
        case 'bankEDS':
            status = isIncomplete(isBlank(masterDoc.bankName));
            break;
        case 'otherEDS':{
            const otherFields = masterDoc.transportationMode === 'Not Applicable' ? masterDocOtherLCMandatoryFieldsForEDS : masterDocOtherInformationMandatoryFieldsForEDS;
            status = isIncomplete(!masterDoc || otherFields.find(field => isBlank(masterDoc[field])));
            break;
        }
        default:
            status = statusIncomplete;
            break;
    }
    return status;
}

export const getStatus = (section, masterDocProps) => {
    const {submitErrors, submitFailed} = masterDocProps || {};
    const masterDoc = masterDocProps?.values || {};
    let status = null;

    if (submitFailed) {
        status = submitFailedCase(section, submitErrors)
    } else if (!masterDoc?.mdocid) {
        status = initCase(section, masterDoc, masterDocProps);
    } else if (masterDoc?.MDOCID) {
        status = initEDS(section, masterDoc);
    }

    return status;
}

