import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import { wolfScan } from 'common/utils/FieldValidation';
import React from 'react';
import ValidationMessage from 'common/components/UserMessage/ValidationMessage';
import WolfMsg from 'common/views/Popup/WolfMsg';
import Confirm from 'common/views/Popup/Confirm';
import DropdownField from 'common/components/Dropdown/DropdownField';
import CDLCheckbox from '@cdl/checkbox';
import TextArea from "common/components/TextArea/TextArea";
import NumberField from "common/components/NumberField/NumberField";
import AmountField from "common/components/AmountField/AmountField";

export default class GenericPopup extends React.Component {
    constructor(props) {
        super(props);
        const editData = props.editProps || props.initalData;
        this.state = {
          data: {...editData},
        };

        props.fields.forEach(field => {
          this[`${field.name}_ref`] = React.createRef();
        });
    }


    handleChange = (event) => {
      const {data} = this.state;
      const {name, value} = event.target;
      data[name] = value;
      this.setState({ data });
    }

    trimChange = (event) => {
      const {data} = this.state;
      const {name, value} = event.target;
      data[name] = value.trim();
      this.setState({ data });
    }

    handleCheck = (event) => {
      const {data} = this.state;
      const {name, checked} = event.target;
      data[name] = checked;
      this.setState({ data });
    }

    clearState = () => {
      this.setState({
        data: {...this.props.initalData},
        openConfirmPopup: false,
      })
    }

    closeMessage = () => {
      this.setState({
        message: "",
        showMessage: false,
      })
    }

    showError = (msg) => {
      this.setState({
        message: msg,
        showMessage: true,
      })
    }

    getDisplayName = (toLowercase) => {
      const {data} = this.state;
      let displayName = `${this.props.displayFieldTile} `;
      if(toLowercase){
        displayName = displayName.toLowerCase();
      }
      this.props.displayField.forEach((field, index) => {
        if(data[field]){
          if(index>0){
            displayName += ` - `;
          }
          displayName += `"${data[field]}"`;
        }
      });
      return displayName;
    }

    handleSubmit = (e) => {
      e.preventDefault();
      const {data} = this.state;
      this.props.save(data).then(response=>{
        if (response){
          const ver = this.props.edit ? 'updated' : 'added';
          const verErr = this.props.edit ? 'updating' : 'adding';
          if(response===1) {
            this.props.success(`${this.getDisplayName()} has been ${ver} successfully.`);
            this.clearState();
            this.props.close();
          }else if(response===-9){
            this.showError(`Error ${verErr} ${this.getDisplayName(true)} - entry already exists.`);
          }else if(response===-8){
            const tail = this.props.tailMap ? this.props.tailMap[response] : 'entry does not exists.';
            this.showError(`Error ${verErr} ${this.getDisplayName(true)} - ${tail}`);
          }else{
            this.showError(`Error ${verErr} ${this.getDisplayName(true)}.`);
          }
        }
      })
    }

    handleOpenConfirmPopup = () => {

      if(this.props.wolfField){
        const errorMsg = wolfScan(this.state.data, this.props.wolfField);
        if(errorMsg && errorMsg.length>0){
          this.setState({
            wolfMsg:errorMsg,
            openWolfMsg: true,
          })
          return;
        }
      }

      let isValid = true;
      this.props.fields.forEach(field => {
        if(field.isMandatory || field.validations){
          isValid = this[`${field.name}_ref`].current.validate() && isValid;
        }
      });

      if(isValid){
        this.setState({
          openConfirmPopup: true,
          openWolfMsg: false,
          showMessage: false,
        })
      }

    }

    handleClose = () => {
      this.setState({
        openConfirmPopup: false,
      })
    }

    handleAutocomplete = name => value => {
      const {data} = this.state;
      data[name] = value;
      this.setState({ data });
      if(this.props.onChange && name===this.props.onChangeSource){
        this.props.onChange(value).then(res=>{
          data[this.props.onChangeTarget] = res;
          this.setState({ data });
        })
      }
    }

    handleNumberField = (name, value) => {
      const {data} = this.state;
      data[name] = value;
      this.setState({ data });
    }

    clearValue = (name) =>{
      const {data} = this.state;
      data[name] = '';
      this.setState({ data });
    }

    renderField = (field, data) => {
      const type = field.type || 'text';
      let validateProps = {};
      if(field.isMandatory){
        validateProps ={
          validations: ["required"],
          ref: this[`${field.name}_ref`]
        }
      }
      if(field.validations){
        validateProps ={
          validations: field.validations,
          ref: this[`${field.name}_ref`]
        }
      }
      switch (type) {
        case 'dropdown':
          return <DropdownField
            labelOutside={field.label}
            name={field.name}
            options={field.options}
            onChange={this.handleAutocomplete(field.name)}
            value={data[field.name]}
            clearValue={()=>this.clearValue(field.name)}
            disabled={field.disabled}
            {...validateProps}
          />
        case 'checkbox':
          return <CDLCheckbox
            label={field.label}
            id={field.id}
            name={field.name}
            checked={data[field.name]}
            onChange={this.handleCheck}
          />
        case 'textarea':
          return <TextArea
            label={field.label}
            name={field.name}
            value={data[field.name]}
            onChange={this.handleChange}
            maxLength={field.maxLength}
          />
        case 'number':
          return <NumberField
            label={field.label}
            id={field.id}
            name={field.name}
            value={data[field.name]}
            onChange={this.handleNumberField}
            decimalScale={field.decimalScale}
            maxLength={field.maxLength}
            formatted
          />
        case 'amount':
          return <AmountField
            label={field.label}
            id={field.id}
            name={field.name}
            value={data[field.name]}
            onChange={this.handleNumberField}
            decimalScale={field.decimalScale}
            maxLength={field.maxLength}
          />
        default:
          return <TextFieldForm
            id={field.id}
            label={field.label}
            name={field.name}
            value={data[field.name]}
            maxLength={field.maxLength}
            onChange={field.trim ? this.trimChange : this.handleChange}
            disabled={field.disabled}
            regex={field.regex}
            isAccount={field.isAccount}
            {...validateProps}
          />
      }
    }

    render() {
      const {openConfirmPopup, data, openWolfMsg, wolfMsg, showMessage, message} = this.state;
      return (
        <div>
          {openConfirmPopup &&
          <Confirm
            content={`Do you wish to ${this.props.edit ? 'update' : 'add'} the ${this.getDisplayName(true)}?`}
            isOpen={openConfirmPopup}
            confirm={this.handleSubmit}
            close={this.handleClose}
          />}
          {openWolfMsg &&
          <WolfMsg
            fields={wolfMsg}
            isOpen={openWolfMsg}
            close={()=>this.setState({openWolfMsg: false})}
          />}
          <Modal
            ariaLabelledby="modal-heading"
            isOpen={this.props.isOpen && !openConfirmPopup && !openWolfMsg}
            width={this.props.popupWidth || 400}
          >
            <ModalHeader hasTopLine>
              <ModalTitle>{this.props.title}</ModalTitle>
              <ModalBtnList>
                <ModalBtn name="delete" title="close" onClick={this.props.close} />
              </ModalBtnList>

            </ModalHeader>
            <ModalContent>
              {showMessage &&
              <div style={{paddingRight:'20px'}}>
                <ValidationMessage message={message} onClose={this.closeMessage} />
              </div>}
              <Grid container spacing={0}>
                {
                    this.props.fields.map((field) => {
                        return (
                          <React.Fragment key={field.id}>
                            <Grid item xs={field.size || 12} style={field.style}>
                              {this.renderField(field, data)}
                            </Grid>
                          </React.Fragment>)
                    })
                }
              </Grid>
            </ModalContent>

            <ModalFooter hasBottomLine>
              <ModalAction>
                <ModalActionExtraButton>
                  <Button onClick={this.props.close}>Cancel</Button>
                </ModalActionExtraButton>
                <ModalActionButton>
                  <Button onClick={this.handleOpenConfirmPopup} themeColor="primary">Save</Button>
                </ModalActionButton>
              </ModalAction>
            </ModalFooter>
          </Modal>
        </div>
      )
    }
}
