import TextFieldForm from 'common/components/TextField/TextFieldForm';
import React from 'react';
import RenderField from './RenderField';

const renderInput = ({ ...props }) => (
  <RenderField
    component={TextFieldForm}
    reduxForm
    reduxInput
    {...props}
  />
  )

export default renderInput
