import React from 'react';
import ProductService from 'common/services/product.service';
import { WOLF_SCAN_PRODUCT_FIELDS } from 'common/utils/Constants';
import GenericPopup from 'common/views/Popup/GenericPopup';

const initalData = Object.freeze({
  drawerlist: [], drawername: "", exportercode: "", prodcode: "", prodcomlist: [],
  prodcommodity: "", proddesc: "", prodid: "", serialno: "", unitprice: "0.000", uom: ""
});

export default class ProductAddDetail extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        optDrawerID: [],
        optProductCommodity: [],
      };
  }

  componentDidMount() {
    ProductService.initProduct().then(res => {
      if (res) {
        if (res.drawerlist) {
          const opt = [];
          res.drawerlist.forEach(drawer => {
            if (drawer) {
              opt.push({ "label": drawer, value: drawer });
            }
          })
          this.setState({
            optDrawerID: opt,
          })
        }
        if (res.prodcomlist) {
          const opt = [];
          res.prodcomlist.forEach(element => {
            if (element) {
              opt.push({ "label": element, value: element });
            }
          })
          this.setState({
            optProductCommodity: opt,
          })
        }
      }
    });
  }

  onChangeDrawer = (id) => {
      return ProductService.getDrawerName(id);
  }

  save = (data) => {
    if (this.props.edit) {
      if(data.unitprice && data.unitprice.indexOf(',')<0){
        data.unitprice = Number(data.unitprice).toLocaleString('en',{minimumFractionDigits: 3, maximumFractionDigits: 3});
      }
      return ProductService.saveProduct(data);
    }
    return ProductService.addProduct(data);
  }

  render(){
    const fields = [
      {id: 'exportercode', label:'Drawer ID*', name: 'exportercode', isMandatory: true, type: 'dropdown', size: 4, options: this.state.optDrawerID, disabled: this.props.edit},
      {id: 'drawername', label:'Drawer name', name:'drawername', size: 4, disabled: true},
      {id: 'prodcommodity', label:'Product commodity', name: 'prodcommodity', type: 'dropdown', size: 4, options: this.state.optProductCommodity, },

      {id: 'prodcode', label:'Code*', name:'prodcode', size: 8, maxLength: 35, isMandatory: true, disabled: this.props.edit, trim: true},
      {id: 'serialno', label:'Serial number', name: 'serialno', maxLength: 35, size: 4, },

      {id: 'proddesc', label:'Description', name: 'proddesc', maxLength: 255, size: 12, type: 'textarea', },

      {id: 'uom', label:'Unit of measurement', name:'uom', size: 6, maxLength: 35, },
      {id: 'unitprice', label:'Unit price', name: 'unitprice', maxLength: 20, size: 6, decimalScale: 3, type: 'amount', },

    ]
    return (
      <GenericPopup
        popupWidth={600}
        initalData={initalData}
        fields={fields}
        {...this.props}
        save={this.save}
        displayField={['exportercode','prodcode']}
        displayFieldTile="Product"
        wolfField={WOLF_SCAN_PRODUCT_FIELDS}
        title="Product details"
        onChange={this.onChangeDrawer}
        onChangeSource="exportercode"
        onChangeTarget="drawername"
      />
    )
  }
}
