export function filterAction(state = {}, action = {}) {
  if (action.type==='SET_TPL_FILTER') {
    return {...state, tplfilters: action.payload };
  }
  if (action.type==='SET_FILTER') {
      return {...state, filters: action.payload };
  }
  if (action.type==='SET_DC_FILTER') {
    return {...state, dcfilters: action.payload };
  }
  if (action.type==='SET_DC') {
    return {...state, isDC: true, isCollection: false };
  }
  if (action.type==='SET_COLLECTION') {
    return {...state, isDC: false, isCollection: true };
  }
  if (action.type==='RESET_DC_COLLECTION') {
    return {...state, isDC: false, isCollection: false };
  }
  return state;
}
