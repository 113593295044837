import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Autocomplete from "common/components/Autocomplete/Autocomplete";
import Button from "common/components/Button/Button";
import DatePicker from "common/components/DatePicker/DatePicker";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import TextArea from "common/components/TextArea/TextArea";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import UserMessage from 'common/components/UserMessage/UserMessage';
import invoiceService from 'common/services/invoice.service';
import { isBlank } from 'common/utils/StringUtils';
import Confirm from "common/views/Popup/Confirm";
import React from 'react';
import { connect } from 'react-redux';


const DATE_FORMAT = 'DD MMM yyyy';

const moment = require('moment');

class PackingListDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        data: ({ ...props.data}) || {plDate: new Date()},
        isEdit: props.data && Object.keys(props.data).length > 0,
        errors: [],
        invoiceNumberList: [],
        invoiceList: [],
        showDuplicateMessage: false
    };
    this.refPackingListNo = React.createRef();
  }

  componentDidMount() {
    if (this.state.data?.pldate) {
      const {data} = this.state;
      data.pldate = {from: moment(this.state.data?.pldate).toDate(), to: null}
      this.setState({
        data
      });
    }
    if (this.state.data?.plinvdate) {
      const {data} = this.state;
      data.plinvdate = {from: moment(this.state.data?.plinvdate).toDate(), to: null}
      this.setState({
        data
      });
    }
    if (!isBlank(this.props.masterDoc?.values?.mdocid)) {
      invoiceService.getInvoiceList('INV', this.props.masterDoc.values.mdocid, this.props.masterDoc.values.mdoctype).then(res => {
        console.log(res);
        if (res && res.statusCode === 200) {
          this.setState({
            invoiceNumberList: res.data.list.map(item => item.invno),
            invoiceList: res.data.list
          })
        }
      })
    }
  }

    deleteErrorMsg = () =>{
      this.setState({showDuplicateMessage: false})
    }

    setDefaultValue = (data, field, defaultValue) => {
      const oriValue = data[field]
      if(!oriValue && oriValue!==""){
        data[field] = defaultValue;
      }
    }

    isDuplicated = (resultData) => {
      let offset = 0;
      if (this.state.isEdit) {
        offset = this.props.fullDataSet[this.props.selectedIndex].plno === resultData.plno ? 1 : 0;
      }

      if (this.props.fullDataSet.filter(data => data.plno === resultData.plno).length > offset) {
        this.setState({
          showDuplicateMessage: true
        })
        return true;
      }
      return false;
    }

    handleSave = () => {
      const resultData = this.state.data;
      if(this.refPackingListNo.current.validate() && !this.isDuplicated(resultData)){

          if (typeof resultData.pldate === 'object') {
            resultData.pldate = moment(resultData.pldate.from).format(DATE_FORMAT);
          }
          if (typeof resultData.plinvdate === 'object') {
            resultData.plinvdate = moment(resultData.plinvdate.from).format(DATE_FORMAT);
          }
          if (this.state.selectedInv?.invitemdetaildtolist.length > 0) {
            const addedData =  this.state.selectedInv?.invitemdetaildtolist.map(item => {
              return {
                PLCaseNo: item.InvDetCaseNo,
                PLDetProdCode: item.InvDetProdCode,
                PLDetProdDesc: item.InvDetProdDesc,
                PLDetQty: item.InvDetQty,
                PLNo: item.plno,
                PLDetCubic: '0.00',
                PLDetGrossMass: '0.00',
                PLDetNetMass: '0.00',
                PLDetNoPKGS: '0.00',
              }
            });
            if (resultData.packinglistitemdetaildtolist) {
              resultData.packinglistitemdetaildtolist = [...resultData.packinglistitemdetaildtolist, ...addedData]
            } else {
              resultData.packinglistitemdetaildtolist = addedData;
            }

          }
          this.setDefaultValue(resultData, 'casenoheading', 'Case no.');
          this.setDefaultValue(resultData, 'partnoheading', 'Prod. code');
          this.setDefaultValue(resultData, 'proddescheading', 'Product description');
          this.setDefaultValue(resultData, 'qtyheading', 'Quantity');
          this.setDefaultValue(resultData, 'nopkgsheading', 'No. of pkgs.');
          this.setDefaultValue(resultData, 'gmassheading', 'Gross mass');
          this.setDefaultValue(resultData, 'netmassheading', 'Net mass');
          this.setDefaultValue(resultData, 'cubicmassheading', 'Cubic mass');

          this.props.save(resultData, this.state.isEdit, this.props.selectedIndex);

      }
    }

    populateInvoice = () => {
      const {data, selectedInv} = this.state;
      data.plinvdate = {from: moment(selectedInv.invdate).toDate(), to: null};
      data.plpackingmode = selectedInv.plpackingmode;
      data.descofgoods = selectedInv.descofgoods;
      data.pladdinfo2 =  selectedInv.invaddinfo2;
      data.plqtyuom = selectedInv.invqtyuom;
      data.casenoheading = 'Case no.';
      data.partnoheading = 'Prod. code';
      data.proddescheading = 'Product description';
      data.qtyheading = 'Quantity';
      data.nopkgsheading = 'No. of pkgs.';
      data.gmassheading = 'Gross mass';
      data.netmassheading = 'Net mass';
      data.cubicmassheading = 'Cubic mass';
      this.setState({data});
    }

    handleInputValue = (name, val) => {
      const {data} = this.state;
      data[name] = val;

      if (name === 'plinvno') {
        const inv = this.state.invoiceList.find(invoiceItem => invoiceItem.invno === val);
        if (inv) {
            this.setState({
              openConfirm: true,
              selectedInv: inv,
            });
        }
      }

      this.setState({ data });
    }

    onDateSelected = (name, date) => {
      const {data, errors} = this.state;
      data[name] = date;
      errors.plinvdateInvalid = false;
      this.setState({ data, errors });
    };

    handleChange = (event) => {
      event.persist();
      this.setState(prevState => {
        prevState.data[event.target.name] = event.target.value;
        prevState.errors[event.target.name] = !isBlank(prevState.data[event.target.name]) ? null : prevState.errors[event.target.name];
      return {
          data: prevState.data,
          errors: prevState.errors
      }
      });
    }

    render(){

    return (
      <>
        {this.state.openConfirm &&
        <Confirm
          content='Do you wish to import the selected invoice details?'
          isOpen={this.state.openConfirm}
          close={()=>{this.setState({openConfirm: false})}}
          confirm={this.populateInvoice}
        />}
        <Modal
          ariaLabelledby="modal-heading"
          classname="myClass"
          height={500}
          isOpen={this.props.isOpen && !this.state.openConfirm}
          width={946}
        >
          <ModalHeader hasTopLine>
            <ModalTitle>Packing list details</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.props.close} />
            </ModalBtnList>

          </ModalHeader>
          <ModalContent>
            <Grid container spacing={0}>
              <Grid item xs={12} style={{paddingRight: '20px'}}>
                {this.state.showDuplicateMessage &&
                <UserMessage
                  showCloseButton
                  border
                  type="userError"
                  iconName="triangle-error"
                  iconColor="ERROR"
                  handleCloseButtonClick={this.deleteErrorMsg}
                  closeText="Close"
                >
                  <strong>Error adding packing list.</strong> Cannot save packing list: A packing list that you specified already exists.
                  Please specify a different packing list number.
                </UserMessage>}
              </Grid>

              <Grid item xs={3}>
                <TextFieldForm
                  label="Packing list number*"
                  name="plno"
                  maxLength={35}
                  validations={["required"]}
                  value={this.state.data?.plno}
                  onChange={(event) => this.handleChange(event)}
                  ref={this.refPackingListNo}
                />
              </Grid>
              <Grid item xs={3}>
                <DatePicker
                  label="Packing list date"
                  id="pldate"
                  name="pldate"
                  defaultDate={this.state.data.pldate}
                  onDateSelected={(date) => this.onDateSelected('pldate', date)}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  label="Invoice number"
                  id="plinvno"
                  name="plinvno"
                  options={this.state.invoiceNumberList}
                  userInput={this.state.data?.plinvno}
                  onChange={(value) => this.handleInputValue('plinvno', value)}
                  maxLength={35}
                />
              </Grid>
              <Grid item xs={3}>
                <DatePicker
                  label="Invoice date"
                  id="plinvdate"
                  name="plinvdate"
                  value={this.state.data.plinvdate}
                  onDateSelected={(date) => this.onDateSelected('plinvdate', date)}
                  isModal
                />
              </Grid>

              <Grid item xs={3}>
                <Autocomplete
                  label="Packing list title"
                  id="plTitle"
                  name="pltitle"
                  options={[
                    'Packing List', 'Weight List', 'Packing/Weight List'
                  ]}
                  userInput={this.state.data?.pltitle}
                  onChange={(value) => this.handleInputValue('pltitle', value)}
                  maxLength={30}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  label="Quantity / weight UOM"
                  id="plqtyuom"
                  name="plqtyuom"
                  options={[
                    {label: 'PCS', value: 'PCS'},
                    {label: 'SETS', value: 'SETS'},
                    {label: 'KG', value: 'KG'},
                    {label: 'MT', value: 'MT'},
                    {label: 'CARTONS', value: 'CARTONS'}
                  ]}
                  userInput={this.state.data?.plqtyuom}
                  onChange={(value) => this.handleInputValue('plqtyuom', value)}
                  maxLength={12}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  label="Gross mass UOM"
                  id="plgmassuom"
                  name="plgmassuom"
                  options={[
                    {label: 'KG', value: 'KG'},
                    {label: 'MT', value: 'MT'},
                    {label: 'CARTONS', value: 'CARTONS'},
                    {label: 'PALLETS', value: 'PALLETS'}
                  ]}
                  userInput={this.state.data?.plgmassuom}
                  onChange={(value) => this.handleInputValue('plgmassuom', value)}
                  maxLength={12}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  label="Cubic UOM"
                  id="plcubicuom"
                  name="plcubicuom"
                  userInput={this.state.data?.plcubicuom}
                  onChange={(value) => this.handleInputValue('plcubicuom', value)}
                  maxLength={12}
                />
              </Grid>

              <Grid item xs={4}>
                <TextFieldForm
                  label="Container no."
                  name="plcontainerno"
                  maxLength={35}
                  value={this.state.data?.plcontainerno}
                  onChange={(event) => this.handleChange(event)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldForm
                  label="Seal number"
                  name="plsealno"
                  maxLength={35}
                  value={this.state.data?.plsealno}
                  onChange={(event) => this.handleChange(event)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldForm
                  label="Packing mode"
                  name="plpackingmode"
                  maxLength={35}
                  value={this.state.data?.plpackingmode}
                  onChange={(event) => this.handleChange(event)}
                />
              </Grid>


              <Grid item xs={12}>
                <TextArea
                  label="Goods description"
                  name="descofgoods"
                  maxLength={6500}
                  value={this.state.data?.descofgoods}
                  onChange={(event) => this.handleChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextArea
                  label="Remarks"
                  name="pladdinfo2"
                  maxLength={200}
                  value={this.state.data?.pladdinfo2}
                  onChange={(event) => this.handleChange(event)}
                />
              </Grid>
            </Grid>
          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.props.close}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button themeColor="primary" onClick={this.handleSave}>Save</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>
      </>
    )
    }
}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc
})

export default connect(mapStateToProps, null)(PackingListDetail)
