import CDLIcon from "@cdl/icon";
import CDLTextField from '@cdl/text-field';
import { basicValidation, REQUIRED, runValidations } from 'common/utils/FieldValidation';
import { isBlank } from 'common/utils/StringUtils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from "styled-components";


const Button = styled.button`
    height: 16px;
    top: ${props => props.inline ? '8px': '30px'};
    position: relative;
    border: none;
    background: ${props => props.invalid ? '#f9f2f3': 'white'} ;
    left: ${props => props.inline ? '-40px': '-23px'};
    padding: 0 10px;
    outline: none;
    border-left: 1px solid ${props => props.disabled ? '#b6b7b6': '#767676'};
    width: 40px;
`;

const widthStyle = props => props.width ? `${props.width}px`: '';
const labelID = props => `#${props.containerId}Label`;

const Container = styled.div`
    display: ${props => props.inline ? 'inline-flex': 'flex'};
    position: relative;
    word-break: break-all;
    width: ${props => props.inline ? 'auto': ''};
    width: ${props => widthStyle(props)};
    flex: ${props => props.inline ? '1': ''};

    & > div:first-of-type{
        width: 100%;
        display: ${props => props.inline ? 'flex' : 'block'};
        align-items: ${props => props.inline ? 'center' : ''};
    }

    & > div:last-child {
      top: ${props => props.inline ? '30px': ''};
      width: ${props => props.inline ? 'calc(100% - 40px)' : ''};
    }

    #${props => props.containerId} {
        width: calc(100% + 18px);
        height: 30px;
        display: ${props => props.inline ? 'inline-block': 'block'};
    }

    ${props => labelID(props)} {
      display: ${props => props.inline ? 'inline-block': 'block'};

    }

    & .option-active{
        background-color: #f1f1f1;
    }

    & input{
      box-sizing: border-box;
    }

    & .lastItem{
      border-bottom: none;
    }

`;

const Item = styled.div`
    padding: 8px 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
    font-size: 12px;
    color: black;
`;

const ItemList = styled.div`
    position: absolute;
    border: 1px solid #929292;
    border-top: none;
    z-index: 101;
    top: 55px;
    left: 0;
    right: 0;
    width: calc(100% - 23px);
    max-height: 200px;
    overflow: auto;

    & ${Item}:hover{
        background-color: #f1f1f1;
    }
`;

let selecting = false;

export class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidMessage: "",
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: props.userInput || props.value || '',
      options: props.options,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.inline) {
      const labels = document.querySelectorAll('.autocomplete-inline-label');
      labels.forEach(label => {
        label.style.marginRight = '20px';
      })
    }

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const value = nextProps.reduxForm ? nextProps.value : nextProps.userInput;
    if (value !== this.state.userInput) {
      this.setState({
        userInput: value,
      })
    }
    // eslint-disable-next-line no-undefined
    if (!nextProps.reduxForm && (nextProps.invalidMessage || nextProps.invalidMessage === '') && nextProps.invalidMessage !== this.state.invalidMessage) {
      this.setState({
        invalidMessage: nextProps.invalidMessage
      })
    }

    if (nextProps.options && JSON.stringify(nextProps.options) !== JSON.stringify(this.state.options)) {
      this.setState({
        options: nextProps.options
      })
    }
    if (nextProps.options && nextProps.options.length === 0) {
      this.setState({
        options: []
      })
    }

    if(!this.state.keyMoving){
      const index = this.state.options?.findIndex(item => item===value || item.label === value || item.value === value);
      this.setState({
        activeOption: index !== -1 ? index : 0,
      })
    }

    return null;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        showOptions: false
      })
    }
  }

  handleChange = (value) => {
    const { options } = this.state;
    const filteredOptions = options?.filter((item) => {
      const text = this.getValue(item);
      return text.toLowerCase().indexOf(value.toLowerCase()) > -1
    });
    if(filteredOptions?.length>0){
      this.setState(prevState => {
        if (prevState.invalidMessage === basicValidation(REQUIRED, prevState.userInput) && !isBlank(prevState.userInput)) {
            prevState.invalidMessage = null;
        }
        return {
          invalidMessage: prevState.invalidMessage,
          filteredOptions,
          showOptions: true,
          userInput: value
        }
      });
    }else{
      this.setState({
        showOptions: false,
        userInput: value
      });
    }
  }

  onChange = (e) => {
    const {value} = e.currentTarget;
    if(this.props.fetchData && !this.state.fetched) {
      this.props.fetchData().then(res=>{
        if (this.props.setOptions) {
          this.props.setOptions(res);
        }
        this.setState({options: res, fetched: true, userInput: value});
      })
    } else {
      this.setState({
        userInput: value
      })
    }
  };

  onSelect = (e) => {
    selecting = true;
    if (this.props.onChange) {
      this.props.onChange(e.currentTarget.innerText);
    }
    if (this.props.input?.onChange) {
      this.props.input.onChange(e.currentTarget.innerText);
    }
    this.setState({
      invalidMessage: "",
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText
    }, ()=>this.validate());
  };

  onButtonClick = () => {
    if(!this.props.disabled){
      if(this.props.fetchData){
        this.props.fetchData().then(res=>{
          if (this.props.setOptions) {
            this.props.setOptions(res);
          }
          this.setState(prevState => ({
            filteredOptions: res,
            showOptions: !prevState.showOptions,
            userSelect: true,
            options: res
          }))
        })
      }else{
        this.setState(prevState => ({
          filteredOptions: prevState.options,
          showOptions: !prevState.showOptions,
          userSelect: true
        }));
      }
    }

  };

  onKeyDown = (e) => {
    const { activeOption, filteredOptions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        showOptions: false, keyMoving: false
      });
      if (this.props.onChange) {
        this.props.onChange(this.getValue(filteredOptions[activeOption]));
      }
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1, keyMoving: true });
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        return;
      }
      this.setState({ activeOption: activeOption + 1, keyMoving: true });
    }
  };

  onBlur = () => {
        if (this.props.disableCustom && !selecting) {
            const index = this.state.filteredOptions.findIndex(f => f.label?.toLowerCase() === this.state.userInput?.toLowerCase());
            if (index < 0) {
              this.setState({
                userInput: '',
                showOptions: false,
                fetched: false,
                keyMoving: false,
              });
            } else {
              this.setState(prevState => {
                return {
                  userInput: prevState.filteredOptions[index].label,
                  showOptions: false,
                  fetched: false,
                  keyMoving: false,
                }
              })
            }
        }else{
          this.setState({
            fetched: false,
            keyMoving: false,
          });
        }

        selecting = false;

        this.validate();


  };

  validate = () =>{
    const { userInput } = this.state;
    const { validations, id } = this.props;
    const msg = runValidations(validations, userInput);
    this.setState({
        invalidMessage: msg
    });
    const isValid = (msg.length === 0);

    if(isValid && this.props.handleInputValue){
        this.props.handleInputValue(id, userInput);
    }

    if (this.props.onChange) {
      this.props.onChange(userInput);
    }
    if (this.props.input?.onChange) {
      this.props.input.onChange(userInput);
    }

    return isValid;
  }

  getValue = (item) => {
    let text = '';
    if (typeof item === 'object') {
      text = item.label;
    }
    else {
      text = item;
    }
    return text;
  }

  getItems = () => {
    const {
      onSelect,
      state: { activeOption, filteredOptions }
    } = this;

    return filteredOptions.map((option, index) => {

      let label;
      let val;
      if(typeof option === 'object'){
        label = option.label;
        val = option.data || option.value;
      }else{
        label = option;
        val = option;
      }
      let className = '';

      if(index === activeOption){
        className = 'option-active ';
      }
      if(filteredOptions.length-1 === index){
        className += " lastItem";
      }

      return (
        <Item className={className} key={val} onClick={onSelect}>
          {label}
        </Item>)
  });
  }

  render() {
    const {
      onChange,
      onKeyDown,
      onButtonClick,
      onBlur,
      state: { filteredOptions, showOptions, userInput, userSelect, invalidMessage }
    } = this;

    let optionList;
    const iconName = showOptions ? 'chevron-up' : 'chevron-down';
    const iconColor = this.props.disabled ? 'SILVER_TINT' : 'GRAPHITE_SHADE';

    const icon = <CDLIcon size={18} name={iconName} color={iconColor} />;


    if (showOptions && (userInput || userSelect) && filteredOptions && filteredOptions.length) {
        const items = this.getItems();
        optionList = (
          <ItemList>
            {items}
          </ItemList>
        );

    }

    const inlineLabel = this.props.inline ? <label className="autocomplete-inline-label" htmlFor={this.props.id}>{this.props.label}</label> : null;

    return (
      <div ref={this.wrapperRef} style={{display: this.props.inline ? 'flex': 'block'}}>

        {inlineLabel}
        <Container containerId={this.props.id} inline={this.props.inline} width={this.props.width}>
          <CDLTextField
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            inline={this.props.inilne}
            invalid={(invalidMessage?.length > 0 || this.props.invalid)}
            invalidMessage={this.props.invalidMessage || invalidMessage}
            id={this.props.id}
            disabled={this.props.disabled}
            label={this.props.label && !this.props.inline ? this.props.label : null}
            value={this.state.userInput}
            maxLength={this.props.maxLength}
          />
          {this.props.labelButton}
          <Button disabled={this.props.disabled} title="Open" type="button" inline={this.props.inline} invalid={this.props.invalid} onClick={onButtonClick}>
            {icon}
          </Button>
          {optionList}
        </Container>
      </div>

    );
  }
}

Autocomplete.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired
};

export default Autocomplete;
