import { lengthChecking } from 'common/utils/FieldValidation';
import DocPrepService from 'common/services/doc-prep.service';
import Message from 'common/views/Popup/Message';
import { isAC } from "common/utils/AppUtil";
import DashboardService from "common/services/dashboard.service";
import { changeLoadingState, resetMasterDocForm, setMasterDoc } from 'common/redux/actions';
import store from 'common/redux/store';
import { submit, reset } from 'redux-form';
import SaveAsDetectChange from 'common/views/Popup/SaveAsDetectChange';
import { isBlank } from 'common/utils/StringUtils';
import $ from "jquery";
import UserService from 'common/services/user.service';

export function isNotNew(docStatus){
    // check undefined
    return docStatus && docStatus!=='isNew';
}

export function isNew(docStatus){
    return docStatus === 'isNew';
}

export function isDC(eiType){
    return eiType === 'ND' || eiType === 'NA' || eiType === 'AD' || eiType === 'PA' || eiType === 'NU';
}

export function isDADP(eiType){
    return eiType === 'FI' || eiType === 'PP';
}

export function validate(values) {
    const error = {};
    const maxLength200Fields = ['invaddinfo1', 'pladdinfo1', 'bcaddinfo1'];
    const maxLength280Fields = ['boeaddinfo1'];
    const maxLength35Fields = ['countryOri'];
    const maxLength55Fields = ['expname', 'expcontact'];
    const maxLength20Fields = ['expref'];

    lengthChecking(values, maxLength200Fields, 200, error);
    lengthChecking(values, maxLength280Fields, 280, error);

    lengthChecking(values, maxLength35Fields, 35, error);

    lengthChecking(values, maxLength55Fields, 55, error);

    lengthChecking(values, maxLength20Fields, 20, error);
    return error;
}

function saveMasterDocPrep(masterDoc, state, continueAction, hideMsg) {
    const masterDocProps = {
        form: masterDoc,
        packingList: state.masterDocAction.packingList,
        beneficiaryCert: state.masterDocAction.beneficiaryCert,
    };

    DocPrepService.performUpdateDocument(masterDocProps).subscribe(responses => {
        if (Array.isArray(responses) && !responses.find(res => res && res.statusCode !== 200)) {
            continueAction();
            store.dispatch(resetMasterDocForm({ isReset: false }));
            if (!hideMsg) {
                Message.notice(`Master Document "${masterDoc.values.mdocid}" has been saved successfully.`);
            }
        } else {
            Message.error(`Master Document "${masterDoc.values.mdocid}" fail to update.`);
        }
    });
}

function saveMasterDoc(masterDoc, continueAction, state, hideMsg) {
    if (isAC()) {
        DashboardService.updateMasterDoc(masterDoc.values.eidto, masterDoc.values).then(res => {
            if (res && res === 1) {
                continueAction();
                DashboardService.getMasterDoc(masterDoc.values.MDOCID, 'APPROVAL').then(response => {
                    if (response && response.statusCode === 200) {
                      response.data.masterdocdto.eidto = response.data.eidto;
                      store.dispatch(setMasterDoc(response.data.masterdocdto));
                    }
                })
                Message.notice(`Master Document "${masterDoc.values.MDOCID}" has been saved successfully.`);
            } else {
                Message.error(`Master Document "${masterDoc.values.MDOCID}" fail to update.`);
            }
        });
    } else {
        saveMasterDocPrep(masterDoc, state, continueAction, hideMsg);
    }
}

function isNotSaveNeeded() {
    if(isAC()){
        const hasEdit = UserService.getCurrentUser()?.clientRight.edsEdit;
        return (!window.location.pathname.includes('GenericEDSDashboard') || $('button.saveBtn').prop('disabled')
            || $("#radio-number-pending").prop('checked') || $("#radio-number-enquiry").prop('checked')
            || !hasEdit );
    }

    return !window.location.pathname.includes('GenericDocPrep');
}

function comfirm(continueAction, hideMsg) {
    if (!isAC() && !UserService.getCurrentUser().editright) {
        Message.notice(`You do not have edit right to save current record.`);
        return;
    }
    store.dispatch(changeLoadingState(true));
    setTimeout(() => {
        store.dispatch(submit('masterDoc'));
        const state = store.getState();
        const { masterDoc } = state.form;

        if (masterDoc.submitSucceeded) {
            if (!isAC() && isBlank(masterDoc.values.mdoctype)) {
                SaveAsDetectChange.confirm(continueAction);
            } else {
                saveMasterDoc(masterDoc, continueAction, state, hideMsg);
            }
        }
    });
}

export function detectChanges(continueAction, hideMsg) {

    if(isNotSaveNeeded()){
        continueAction();
        return;
    }

    if (DocPrepService.noDirty()) {
        continueAction();
    } else {
        Message.select('Do you want to save the changes made?',
            () => {
                comfirm(continueAction, hideMsg)
            },
            () => {
                store.dispatch(reset('masterDoc'));
                store.dispatch(resetMasterDocForm({ isReset: false }));
                continueAction();
            },
            () => {
                // to show cancel button
                console.log('cancel');
            }
        );
    }
}
