export const objectToEmptyString = (obj) => {
    Object.keys(obj).forEach(key => {
      if (!obj[key]) {
        obj[key] = '';
      }
    });
  }


export function formatEmptyValues(item, decimalFields) {
  Object.keys(item).forEach(k2 => {
    if (decimalFields.find(d => d === k2)) {
      if (!item[k2] || item[k2] === '') {
        item[k2] = 0;
      } else {
        item[k2] = parseFloat(item[k2]);
      }
    }
    if (!item[k2] && item[k2] !== 0) {
      item[k2] = '';
    }
  });

  decimalFields.forEach(d => {
    if (!item[d]) {
      item[d] = 0;
    }
  })
}
