import React from 'react';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Filter from "common/components/Filter/Filter";
import { Grid } from '@material-ui/core';
import Checkbox from 'common/components/Checkbox/Checkbox';
import TextFieldForm from "common/components/TextField/TextFieldForm";
import { DATE_FORMAT, formatDate } from 'common/utils/DateUtil';
import styled from "styled-components";
import Table from "common/components/Table/Table";
import Button from "common/components/Button/Button";
import MasterDocService from "common/services/masterDoc.service";
import DatePicker from 'common/components/DatePicker/DatePicker';
import DropdownField from 'common/components/Dropdown/DropdownField';
import { setDC, setDCFilter, resetMasterDoc } from "common/redux/actions";
import { connect } from 'react-redux';
import NumberField from "common/components/NumberField/NumberField";

const _ = require('lodash');
const moment = require('moment');

const from = new Date();
from.setDate(from.getDate() -30);

const initialFilters = {
  issuingBank: '',
  applicantName: '',
  shipmentDate: '',
  expireDate: '',
  outstandingAmount: '',
  creditNO: '',
  location: '',
  accountNumber: '',
  originalAmount: '',
  utilizedAmount: ''
};


const FilterWrapper = styled.div`
    padding-top: 30px;

    .MuiGrid-grid-xs-auto {
        width: 20%;
        height: 57px;
    }
`;

const ExpandContainer = styled.div`
    padding: 20px 30px;

    .MuiGrid-item {
        padding-top: 10px;
    }
`;

const CheckboxWrapper = styled.div`
    float: right;
    margin-top: 10px;
    margin-right: 50px;
`;

const tableColumn = [

    {
        Header: 'HSBC DC number',
        accessor: 'dcNumber',
        width: 160
    },
    {
        Header: 'Issuing bank',
        accessor: 'issuanceBank.bankName',
        Cell: row => <span title={row.original.issuanceBank.bankName}>{row.original.issuanceBank.bankName}</span>
    },
    {
        Header: 'Applicant name',
        accessor: 'applicantName',
        Cell: row => <span title={row.original.applicantName}>{row.original.applicantName}</span>,
        width: 150
    },
    {
        Header: 'Latest shipment date',
        accessor: 'latestShipmentDate',
        width: 160,
    },
    {
        Header: 'Expire date',
        accessor: 'expiryDate',
        width: 120,
    },
    {
        Header: 'Outstanding amount',
        accessor: 'dcAmount.outstandingAmount',
        width: 160,
    },
    {
        Header: 'Location',
        Cell: (row) => `${row.original.tradeAccountNumber.accountCountry}${row.original.tradeAccountNumber.institutionCode}`,
        width: 100,
    },
    {
        Header: 'Account number',
        accessor: 'tradeAccountNumber.accountNumber',
        width: 150,
    },

  ]


  const SubComponent = row => {
    return (
      <ExpandContainer>
        <Grid container spacing={0}>
          <Grid item xs={12}><b>DC summary</b></Grid>
          <Grid item xs={2}>Issuing bank</Grid>
          <Grid item xs={4}>
            {row.original.issuanceBank.bankName}
          </Grid>
          <Grid item xs={2}>Original amount</Grid>
          <Grid item xs={4}>
            {row.original.dcAmount.amount}
          </Grid>
          <Grid item xs={2}>DC utilised amount</Grid>
          <Grid item xs={4}>
            {row.original.dcAmount.utilizedAmount}
          </Grid>
          <Grid item xs={2}>Latest shipment date</Grid>
          <Grid item xs={4}>
            {row.original.latestShipmentDate}
          </Grid>
          <Grid item xs={2}>Expire date</Grid>
          <Grid item xs={4}>
            {row.original.expiryDate}
          </Grid>
        </Grid>
      </ExpandContainer>
    );
  };

class DCSummary extends React.Component {
    constructor(props) {
        super(props);
        const filters = this.props.dcfilters || _.cloneDeep(initialFilters);
        this.state = {
          filters,
          exporterOptions: [],
          docList: [],
          isNotAdvised: false,
        }
    }

    componentDidMount() {
        MasterDocService.getMasterDocExporterWithTradeID().then(
            response => {
                if (response) {
                    const defaultExporter = response.list[0].tradeID;
                    this.setState(prevState => {
                        const {filters} = prevState;
                        filters.tradeID = defaultExporter;
                        return {
                          filters,
                          exporterOptions: response.list.map(exporter => {
                            const val = exporter.tradeID;
                            return {
                              label: `${exporter.expName  } / ${  val}`,
                              value: val
                            }
                          })
                        }
                    }, this.fetchTableData);
                }
            }
        );
    }


    setFilters = (name, value) => {
      this.setState(prevState => {
        const {filters} = prevState;
        filters[name] = value;
        return { filters }
      });
    }

    handleChange = (e) => {
      const { name, value } = e.target;
      this.setFilters(name, value);
    }

    handleSelect = (value) => {
      this.setState(prevState => {
          const {filters} = prevState;
          filters.tradeID = value;
          return {filters}
      });
    }

    handleInputValue = (name, val) => {
      const {data} = this.state;
      data[name] = val;
      this.setState({ data });
    }


    dateSelected = (name, value) => {
      this.setState(prevState => {
        const {filters} = prevState;
        filters[name] = value ? formatDate(value.from) : null;
        return { filters }
      })
    }


    newMD = () => {
      const { docList, selectedRowIndex, isNotAdvised, filters} = this.state;
      this.props.setDCFilter(filters);
      this.props.setDC();
      this.props.resetMasterDoc();

      let dc = docList[selectedRowIndex];
      if(isNotAdvised){
        dc = null;
      }else {
        dc.tradeID = filters.tradeID;
      }
      this.props.history.push('/GenericDocPrep/GenericExportInstruction', {'dc':dc});
    }

    handleCheck = (event) => {
      const {checked} = event.target;
      this.setState({ isNotAdvised: checked, selectedRowIndex: null });
    }


    fetchTableData = () => {
        const {filters, exporterOptions} = this.state;
        if(!filters.tradeID){
          filters.tradeID = exporterOptions.map(option => option.value).join(', ');
        }

        this.setState({loading: true})

        MasterDocService.filterDC(filters).then((response) => {
          this.setState({
            docList: response.list,
            loading: false
          })
        })
    }

    clearFilter = () => {
      this.setState(prevState => {
        const filters = _.cloneDeep(initialFilters)
        const {tradeID} = prevState.filters;
        filters.tradeID = tradeID;
        return { filters }
      }, this.fetchTableData);
    }

    getTrProps = (rowInfo) => {
        if (rowInfo && rowInfo.row) {
          return {
            onClick: () => {
              if (rowInfo.index !== this.state.selectedRowIndex) {
                this.setState({
                  selectedRowIndex: rowInfo.index,
                  isNotAdvised: false,
                });
              }
            },
            className: rowInfo.index === this.state.selectedRowIndex ? "row-selected-expand" : "",
          };
        }
        return {};
      }


    render() {
        const { exporterOptions, docList, filters, selectedRowIndex, isNotAdvised} = this.state;
        const isDisabled = !(selectedRowIndex!=null || isNotAdvised);

        return (
          <>

            <PageHeader title="DC summary" withLeftArrow history={this.props.history} />
            <div className="mainContent">

              <FilterWrapper>
                <Filter clearFilter={this.clearFilter} applyFilter={this.fetchTableData}>
                  <Grid container spacing={0}>
                    <Grid item xs="auto" style={{width: '40%', marginBottom: '20px'}}>
                      <DropdownField
                        labelOutside="Exporter / trade ID"
                        id="tradeID"
                        name="tradeID"
                        options={exporterOptions}
                        value={filters.tradeID}
                        onChange={this.handleSelect}
                        width='100%'
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs="auto">
                      <TextFieldForm
                        seamless
                        name="issuingBank"
                        value={filters.issuingBank}
                        onChange={this.handleChange}
                        label="Issuing bank"
                        maxLength={255}
                        eds
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <TextFieldForm
                        seamless
                        name="applicantName"
                        value={filters.applicantName}
                        onChange={this.handleChange}
                        label="Applicant name"
                        maxLength={35}
                        eds
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <DatePicker
                        id="shipment-date"
                        seamless
                        name="shipmentDate"
                        defaultDate={{ from: filters.shipmentDate ? moment(filters.shipmentDate, DATE_FORMAT).toDate() : null }}
                        placeHolder={filters.shipmentDate || DATE_FORMAT}
                        displayFormat={DATE_FORMAT}
                        onDateSelected={(event) => {
                            if (event.from) {
                                this.dateSelected('shipmentDate', event);
                            } else {
                                this.dateSelected('shipmentDate', null);
                            }
                        }}
                        inputFormat={DATE_FORMAT}
                        dateFormat={DATE_FORMAT}
                        label="Latest shipment date"
                        eds
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <DatePicker
                        id="expire-date"
                        seamless
                        name="expireDate"
                        defaultDate={{ from: filters.expireDate ? moment(filters.expireDate, DATE_FORMAT).toDate() : null }}
                        placeHolder={filters.expireDate || DATE_FORMAT}
                        displayFormat={DATE_FORMAT}
                        onDateSelected={(event) => {
                        if (event.from) {
                            this.dateSelected('expireDate', event)
                        } else {
                            this.dateSelected('expireDate', null)
                        }
                    }}
                        inputFormat={DATE_FORMAT}
                        dateFormat={DATE_FORMAT}
                        label="Expire date"
                        eds
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <NumberField
                        seamless
                        name="outstandingAmount"
                        value={filters.outstandingAmount}
                        onChange={this.setFilters}
                        label="Outstanding amount"
                        maxLength={20}
                        eds
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} style={{paddingTop: '20px'}}>
                    <Grid item xs="auto">
                      <TextFieldForm
                        seamless
                        name="creditNO"
                        value={filters.creditNO}
                        onChange={this.handleChange}
                        label="HSBC DC number"
                        maxLength={19}
                        eds
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <TextFieldForm
                        seamless
                        name="location"
                        value={filters.location}
                        onChange={this.handleChange}
                        label="Location"
                        maxLength={6}
                        eds
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <TextFieldForm
                        seamless
                        name="accountNumber"
                        value={filters.accountNumber}
                        onChange={this.handleChange}
                        label="Account number"
                        maxLength={12}
                        eds
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <NumberField
                        seamless
                        name="originalAmount"
                        value={filters.originalAmount}
                        onChange={this.setFilters}
                        label="Original amount"
                        maxLength={20}
                        eds
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <NumberField
                        seamless
                        name="utilizedAmount"
                        value={filters.utilizedAmount}
                        onChange={this.setFilters}
                        label="DC utilized amount"
                        maxLength={20}
                        eds
                      />
                    </Grid>
                  </Grid>
                </Filter>
              </FilterWrapper>
              <Grid container spacing={0}>
                <Grid item xs={12} style={{paddingBottom: '20px', paddingTop: '30px'}}>
                  <Table
                    id="dcTable"
                    defaultPageSizeOption={{ 'value': 0 }}
                    showPagination
                    data={docList}
                    loading={this.state.loading}
                    maxRows={11}
                    columns={tableColumn}
                    SubComponent={SubComponent}
                    hoverSelect
                    getTrProps={(state, rowInfo) => this.getTrProps(rowInfo)}
                  />
                </Grid>

                <Grid item xs={8} />
                <Grid item xs={4} style={{marginTop: '10px'}}>
                  <Button disabled={isDisabled} themeColor="primary" onClick={this.newMD} style={{float: 'right'}}>Create new MD</Button>
                  <CheckboxWrapper>
                    <Checkbox
                      label="Non-HSBC advised DC"
                      id="notAdvised"
                      name="notAdvised"
                      onChange={this.handleCheck}
                      style={{float: 'left'}}
                      checked={isNotAdvised}
                    />
                  </CheckboxWrapper>
                </Grid>
              </Grid>

            </div>
          </>
        );
    }
}

const mapStateToProps = state => {
  return {
    dcfilters: state.filterAction?.dcfilters,
  }
}

const mapDispatchToProps = dispatch => {
    return {
        setDC: (data) => dispatch(setDC(data)),
        setDCFilter: (data) => dispatch(setDCFilter(data)),
        resetMasterDoc: (data) => dispatch(resetMasterDoc(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DCSummary);
