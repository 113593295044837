import Grid from '@material-ui/core/Grid';
import Divider from "common/components/Divider/Divider";
import Heading from "common/components/Heading/Heading";
import NewButton from "common/components/NewButton/NewButton";
import renderInput from 'common/components/ReduxForm/RenderInput';
import renderAutocomplete from 'common/components/ReduxForm/RenderAutocomplete';
import renderDropdown from 'common/components/ReduxForm/RenderDropdown';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import { updateMasterDocByFieldArray, updateMasterDocByFields } from 'common/redux/actions';
import MasterDocService from "common/services/masterDoc.service";
import UserService from 'common/services/user.service';
import { getOption, getOptionRealTime } from 'common/utils/OptionUtil';
import { getFieldName } from 'common/utils/FieldNameUtil';
import AddImporterBank from 'common/views/Popup/AddImporterBank';
import AddImporterConsigneeFowardingAgent from 'common/views/Popup/AddImporterConsigneeFowardingAgent';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import BankImporter from './BankImporter';
import BankTable from './BankTable';


const bankEDSFieldMap = {
  agtname: 'agtname',
  agtaddr1: 'agtaddr1',
  agtaddr2: 'agtaddr2',
  agtaddr3: 'agtaddr3',
  agtcity: 'agtcity',
  agtstate: 'agtstate',
  agtcountry: 'agtcountry',
  agtpostcode: 'agtpostCode',
  consigneecode: 'consigneeCode',
  consigneename: 'consigneeName',
  consigneeaddr1: 'consigneeAddr1',
  consigneeaddr2: 'consigneeAddr2',
  consigneeaddr3: 'consigneeAddr3',
  consigneecity: 'consigneeCity',
  consigneestate: 'consigneeState',
  consigneecountry: 'consigneeCountry',
  consigneepostcode: 'consigneePostCode'
}

class Bank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openImporterBankPopup: false,
      openImporterPopup: false,
      openMessageDialog: false,
      message: "",
      forwardingAgentOptions: [],
      consigneeOptions: []
    }

    this.optImpCode = getOption('getMasterDocImpCode');
    this.isFieldDisabled = false;
  }

  componentDidUpdate() {
    this.isFieldDisabled = UserService.getCurrentUser()?.isCompanyEDS || this.props.isEDS;
  }


  showSuccessMessage = (msg) => {
    this.setState({
      message: msg,
      openMessageDialog: true
    });
  }


  closeImporterBankPopup = () => {
    this.setState({openImporterBankPopup: false});
  }

  closeImporterPopup = () => {
    this.setState({openImporterPopup: false});
  }

  closeMessage = () => {
    this.setState({
      message: "",
      openMessageDialog: false
    });
  }

  getSelected = (options, value) => {
    if(!options || !options.length || !value) {
      return null;
    }
    let selected = options.find(option => option.value?.trim() === value.trim());
    if(!selected) {
      selected = options.find(option => option.value?.replace(/\s+/g, '') === value.replace(/\s+/g, ''));
    }
    return selected;
  }

  changeForwardingAgent = (value) => {
    const {isEDS} = this.props;
    const selected = this.getSelected(this.state.forwardingAgentOptions, value);
    if(selected){
      MasterDocService.getMasterDocImpDetailsByCode(selected.value, isEDS ? this.props.masterDoc.CoDocExpressId : null).then(res => {
        if(res){
          const data = [];
          data.push({ field: 'agtname', data: res.impName });
          data.push({ field: 'agtaddr1', data: res.impAddr1 });
          data.push({ field: 'agtaddr2', data: res.impAddr2 });
          data.push({ field: 'agtaddr3', data: res.impAddr3 });
          data.push({ field: 'agtcity', data: res.impCity });
          data.push({ field: 'agtstate', data: res.impState });
          data.push({ field: 'agtcountry', data: res.impCountry });
          data.push({ field: getFieldName(isEDS, 'agtpostCode'), data: res.impPostCode });

          this.props.updateMasterDocByFieldArray({ fieldArray: data });
        }
      });
    }
  }

  changeConsignee = (value) => {
    const {isEDS} = this.props;
    const selected = this.getSelected(this.state.consigneeOptions, value);
    if(selected){
      MasterDocService.getMasterDocImpDetailsByCode(selected.value, isEDS ? this.props.masterDoc.CoDocExpressId : null).then(res => {
        if(res){
          const data = [];

          data.push({ field: getFieldName(isEDS, 'consigneeName'), data: res.impName });
          data.push({ field: getFieldName(isEDS, 'consigneeAddr1'), data: res.impAddr1 });
          data.push({ field: getFieldName(isEDS, 'consigneeAddr2'), data: res.impAddr2 });
          data.push({ field: getFieldName(isEDS, 'consigneeAddr3'), data: res.impAddr3 });
          data.push({ field: getFieldName(isEDS, 'consigneeCity'), data: res.impCity });
          data.push({ field: getFieldName(isEDS, 'consigneeState'), data: res.impState });
          data.push({ field: getFieldName(isEDS, 'consigneeCountry'), data: res.impCountry });
          data.push({ field: getFieldName(isEDS, 'consigneePostCode'), data: res.impPostCode });

          this.props.updateMasterDocByFieldArray({ fieldArray: data });
        }
      });
    }
  }

  clearValue = (fieldName)=> {
    this.props.updateMasterDocByFields({field: fieldName, data: '', isInit: true})
  }

  renderForwardingAgent(isEDS) {
    return (
      <>
        <Grid container spacing={0}>

          <Grid item xs={4}>
            <Field
              component={renderAutocomplete}
              label="Forwarding agent code"
              id="AgtCode"
              name="agtcode"
              options={[]}
              setOptions={(res) => this.setState({forwardingAgentOptions: res})}
              onChange={this.changeForwardingAgent}
              fetchData={()=>getOptionRealTime('getMasterDocImpCode', isEDS ? this.props.masterDoc.CoDocExpressId : null)}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={renderInput}
              label="Forwarding agent name"
              id="AgtName"
              name={isEDS? bankEDSFieldMap.agtname : 'agtname'}
              maxLength={55}
              disabled={this.isFieldDisabled}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Field
              component={renderInput}
              label="Address line 1"
              id="AgtAddr1"
              name={isEDS? bankEDSFieldMap.agtaddr1 : 'agtaddr1'}
              maxLength={35}
              disabled={this.isFieldDisabled}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={renderInput}
              label="Address line 2"
              id="AgtAddr2"
              name={isEDS? bankEDSFieldMap.agtaddr2 : 'agtaddr2'}
              maxLength={35}
              disabled={this.isFieldDisabled}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={renderInput}
              label="Address line 3"
              id="AgtAddr3"
              name={isEDS? bankEDSFieldMap.agtaddr3 : 'agtaddr3'}
              maxLength={35}
              disabled={this.isFieldDisabled}
            />
          </Grid>


          <Grid item xs={3}>
            <Field
              component={renderInput}
              label="City"
              id="AgtCity"
              name={isEDS? bankEDSFieldMap.agtcity : 'agtcity'}
              maxLength={35}
              disabled={this.isFieldDisabled}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={renderInput}
              label="State"
              id="AgtState"
              name={isEDS? bankEDSFieldMap.agtstate : 'agtstate'}
              maxLength={35}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={renderDropdown}
              labelOutside="Country / Territory"
              id="AgtCountry"
              name={isEDS? bankEDSFieldMap.agtcountry : 'agtcountry'}
              options={this.props.optCountry}
              maxLength={35}
              disabled={this.isFieldDisabled}
              clearValue={()=>this.clearValue('agtcountry')}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={renderInput}
              label="Postal code"
              id="AgtPostCode"
              name={isEDS? bankEDSFieldMap.agtpostcode : 'agtpostcode'}
              maxLength={10}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  renderConsignee(isEDS) {
    return (
      <>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Field
              component={renderAutocomplete}
              id="ConsigneeCode"
              name={isEDS ? bankEDSFieldMap.consigneecode : 'consigneecode'}
              label="Consignee code"
              selectWidth={366}
              options={[]}
              setOptions={(res) => this.setState({consigneeOptions: res})}
              onChange={this.changeConsignee}
              fetchData={()=>getOptionRealTime('getMasterDocImpCode', isEDS ? this.props.masterDoc.CoDocExpressId : null)}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={renderInput}
              label="Consignee name"
              id="ConsigneeName"
              name={isEDS ? bankEDSFieldMap.consigneename : 'consigneename'}
              maxLength={55}
              disabled={this.isFieldDisabled}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Field
              component={renderInput}
              label="Address line 1"
              id="ConsigneeAddr1"
              name={isEDS ? bankEDSFieldMap.consigneeaddr1 : 'consigneeaddr1'}
              maxLength={35}
              disabled={this.isFieldDisabled}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={renderInput}
              label="Address line 2"
              id="ConsigneeAddr2"
              name={isEDS ? bankEDSFieldMap.consigneeaddr2 : 'consigneeaddr2'}
              maxLength={35}
              disabled={this.isFieldDisabled}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={renderInput}
              label="Address line 3"
              id="ConsigneeAddr3"
              name={isEDS ? bankEDSFieldMap.consigneeaddr3 : 'consigneeaddr3'}
              maxLength={35}
              disabled={this.isFieldDisabled}
            />
          </Grid>


          <Grid item xs={3}>
            <Field
              component={renderInput}
              label="City"
              id="ConsigneeCity"
              name={isEDS ? bankEDSFieldMap.consigneecity : 'consigneecity'}
              maxLength={35}
              disabled={this.isFieldDisabled}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={renderInput}
              label="State"
              id="ConsigneeState"
              name={isEDS ? bankEDSFieldMap.consigneestate : 'consigneestate'}
              maxLength={35}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={renderDropdown}
              labelOutside="Country / Territory"
              id="ConsigneeCountry"
              name={getFieldName(isEDS, 'consigneeCountry')}
              options={this.props.optCountry}
              disabled={this.isFieldDisabled}
              maxLength={35}
              clearValue={()=>this.clearValue(getFieldName(isEDS, 'consigneeCountry'))}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={renderInput}
              label="Postal code"
              id="ConsigneePostCode"
              name={isEDS ? bankEDSFieldMap.consigneepostcode : 'consigneepostcode'}
              maxLength={10}
            />
          </Grid>
        </Grid>
      </>
    )
  }


  render(){
    const {isEDS} = this.props;
    const {
      openImporterBankPopup,
      openImporterPopup,
      openMessageDialog,
      message
    } = this.state;
    return (
      <div>
        {openImporterBankPopup &&
          <AddImporterBank
            isOpen={openImporterBankPopup}
            close={this.closeImporterBankPopup}
            save={this.closeImporterBankPopup}
            success={this.showSuccessMessage}
            title="Add importer's bank / collecting bank"
          />}
        {openMessageDialog &&
        <div style={{paddingRight: '20px', paddingTop: '20px'}}>
          <SuccessMessage message={message} handleCloseButtonClick={this.closeMessage} />
        </div>}
        {openImporterPopup &&
          <AddImporterConsigneeFowardingAgent
            id="bank"
            isOpen={openImporterPopup}
            close={this.closeImporterPopup}
            save={this.closeImporterPopup}
            success={this.showSuccessMessage}
          />}
        <Heading level={2} brandbar>
          <span className="medium">Importer’s bank / DC issuing bank details</span>
          {this.props.queryType !== 'enquiry' &&
          <NewButton onClick={() => this.setState({openImporterBankPopup: true, openMessageDialog: false})} style={{ right: '0px' }} />}
        </Heading>

        <BankTable id="commonBank" updateStatus={this.props.updateStatus} updateMasterDocByFieldArray={this.props.updateMasterDocByFieldArray} isEDS={isEDS} />

        <BankImporter isFieldDisabled={this.isFieldDisabled} optCountry={this.props.optCountry} isEDS={isEDS} updateMasterDocByFields={this.props.updateMasterDocByFields} />

        <Divider short />

        <Heading level={2} brandbar>
          <span className="medium">Forwarding agent details</span>
          {this.props.queryType !== 'enquiry' &&
            <NewButton onClick={() => this.setState({openImporterPopup: true, openMessageDialog: false})} />}
        </Heading>

        {this.renderForwardingAgent(isEDS)}

        <Divider short />

        <Heading level={2} brandbar>
          <span className="medium">Consignee details</span>
          {this.props.queryType !== 'enquiry' &&
            <NewButton onClick={() => this.setState({openImporterPopup: true, openMessageDialog: false})} />}
        </Heading>

        {this.renderConsignee(isEDS)}

      </div>
    )
            }
}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values,
  test: state,
})

const mapDispatchToProps = dispatch => {
  return {
    updateMasterDocByFields: (payload) => dispatch(updateMasterDocByFields(payload)),
    updateMasterDocByFieldArray: (payload) => dispatch(updateMasterDocByFieldArray(payload))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Bank)
