/* eslint-disable import/no-extraneous-dependencies */
import { colors, rem } from '@cdl/foundations';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


const ContainerWrapper = styled.div`
    width: 100%;
    position: relative;
`;

const Container = styled.nav`
  max-width: ${rem('1600px')};

  ul {
    margin: 0;
    padding: 0;
    display: flex;
  }

  li {
    margin-right: ${rem('2px')};
    border-bottom: 1px solid ${colors.BLACK};
    &:last-child {
      flex: 1;
      margin-right: 0;
    }
    &.active {
      border-bottom: none;
    }
  }

  a {
    display: block;
    padding: ${rem('13px')} ${rem('20px')};
    transition: padding 400ms ease, box-shadow 400ms ease;
    will-change: padding, box-shadow;
    line-height: 1;
    font-size: ${rem('14px')};
    background: ${colors.SLATE_TINT_15};
    color: ${colors.BLACK};
    font-weight: 500;
    text-decoration: none;
    font-family:HSBC-Universe-Medium;
    cursor: pointer;


    &.active,
    &.active:hover {
      background: ${colors.WHITE};
      color: ${colors.BLACK};
      box-shadow: inset 0 3px 0 0 ${colors.RED};
    }


  }
`;

export default function SwitchTab(props) {

    const [active, setActive] = useState(props.tabs[0].title);

    useEffect(() => {
      if (props.reset) {
        setActive(props.tabs[0].title)
        props.onChangeIndex(0);
      }
    }, [props.reset])

    function switchTab(item, index) {
      setActive(item.title);
      props.onChangeIndex(index);
    }

    return (
      <ContainerWrapper>
        <div>
          <Container>
            {props.tabs &&
              <ul>
                { props.tabs.map((item, index) => (
                  <li className={active === item.title ? 'active' : ''} key={item.title}>
                    <a className={active === item.title ? 'active' : ''} onClick={() => switchTab(item, index)}>{item.title}</a>
                  </li>
                    ))}
                <li />
              </ul>}
            {props.tabs &&
              props.tabs.map(tab => (
                <div key={tab.url}>{tab.component}</div>
              ))}
          </Container>
        </div>
      </ContainerWrapper>
    );

}
