import BaseService from 'common/services/BaseService';

class TenorService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/TenorBO/';
  }


  // eslint-disable-next-line class-methods-use-this
  getExtraPropsFromUser() {
    const current = new Date();
    return {
      "dateUpdate": current,
      "deleted": false,
      "userUpdate": "PENDING"
    }
  }

  // eslint-disable-next-line class-methods-use-this
  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  addTenor(tenorDTO, raFlag = false) {
    let requestBody = {};
    if (raFlag) {
      const inUserDTO = this.getInUserDTO();
      requestBody = {
        ...inUserDTO,
        "tenor": {
          ...tenorDTO
        }
      }
    } else {
      const userDTO = this.getUserDTO();
      const extraPropsFromUser = this.getExtraPropsFromUser();
      requestBody = {
        "tenor": {
          "tenorDesc": tenorDTO?.tenorDesc,
          "tenorID": tenorDTO?.tenorID,
          ...extraPropsFromUser,
          "coDocExpressID": this.getDocExpressId()
        },
        ...userDTO
      }
    }
    return this.post('addTenor', requestBody, true);
  }

  deleteTenor(tenorDTO, raFlag = false) {
    let requestBody = {};
    if (raFlag) {
      const inUserDTO = this.getInUserDTO();
      delete tenorDTO.actionBtn;
      requestBody = {
        ...inUserDTO,
        "tenor": {
          ...tenorDTO
        }
      }
    } else {
      const userDTO = this.getUserDTO();
      requestBody = {
        "id": tenorDTO,
        ...userDTO
      }
    }
    return this.post('deleteTenor', requestBody, true);
  }

  getTenor(selectedID) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      "tenorid": selectedID,
      ...userDTO
    }
    return this.post('getTenor', requestBody, false);
  }

  loadTenor(raFlag = false) {
    if (raFlag) {
      return this.post('loadTenor', {}, true);
    }
    const useDTO = this.getUserDTO();
    const requestBody = {
      ...useDTO
    }
    return this.post('loadTenor', requestBody, true);
  }

  saveTenor(tenorDTO) {
    const userDTO = this.getUserDTO();

    const requestBody = {
      "tenor": {
        "tenorID": tenorDTO.tenorID,
        "tenorDesc": tenorDTO.tenorDesc,
      },
      ...userDTO
    }
    return this.post('saveTenor', requestBody, false);
  }

  updateTenor(tenorDTO) {
    const inUserDTO = this.getInUserDTO();
    delete tenorDTO.actionBtn;
    const requestBody = {
      ...inUserDTO,
      "tenor": {
        ...tenorDTO
      }
    }
    return this.post('updateTenor', requestBody, true);
  }

}

export default new TenorService();
