import CDLRadioGroup from '@cdl/radio-group';
import { isBlank } from 'common/utils/StringUtils';
import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  & fieldset{
    margin-bottom: 0;
  }

  & fieldset > div{
    display: block;
  }

  & legend{
    float: left;
    width: 100px;
    height: ${props => props.childCount*37}px;
    padding: 5px 0 0 0;
  }

  & label{
    padding: 5px 5px 15px 5px;
    overflow: hidden;
  }
`;

export default function RadioVertical(props) {

    const childCount = React.Children.count(props.children);
    const defaultCheckedRadio = props.children.find(c => c.props.defaultChecked);

    if (defaultCheckedRadio && isBlank(props.value)) {
      props.handleChange(defaultCheckedRadio.props.value);
    }


    return (
      <Container childCount={childCount}>
        <CDLRadioGroup name={props.name} id={props.id}>
          {React.Children.map(props.children, child => (<div>{child}</div>))}
        </CDLRadioGroup>
      </Container>
    );
}

