export const initActionBarPosition = () => {
    window.scrollTo(0, 0);
    const actionBar = document.querySelector('#actionbar');
    const mainContent = document.querySelector('.mainContent').getBoundingClientRect();
    if(actionBar) {
      if (window.innerHeight > document.documentElement.getBoundingClientRect().height) {
        actionBar.style.bottom = `${Math.floor(window.innerHeight - mainContent.top - mainContent.height)}px`;
      } else {
        actionBar.style.bottom = `${Math.floor(0)}px`;
      }
    }
}

export const fixBottom = () => {
  const actionBar = document.querySelector('#actionbar');
  if (actionBar) {
    const footerTop = document.querySelector('footer').getBoundingClientRect().top;
    const actionBarTop = actionBar.getBoundingClientRect().top;

    const dis = window.innerHeight - footerTop;

    if ((footerTop - actionBarTop) > 100) {
      if (dis > 0) {
        actionBar.style.bottom = `${Math.floor(dis)}px`;
      } else {
        actionBar.style.bottom = '0px';
      }
    } else if (dis > 0) {
      actionBar.style.bottom = `${Math.floor(dis)}px`;
    } else {
      actionBar.style.bottom = '100px';
    }
  }
}

export const fixActionBarBottom = () =>{
    const collapsible = document.querySelectorAll('.collapsible');

    collapsible.forEach(coll => {
        coll.addEventListener('click', () => {
          setTimeout(() => {
            fixBottom();
          }, 700);
      });
    })
}
