import CDLHeading from '@cdl/heading';
import { ModalAction, ModalActionButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import Table from 'common/components/Table/Table';
import TextArea from "common/components/TextArea/TextArea";
import React from 'react';

const moment = require('moment');

export default class HSBCApproval extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceNumber: ""
        };
    }

    reset = () =>{
        this.setState({});
    }

    handleSave = () => {
        this.props.save(this.state);
    }

    handleInputValue = (name, val) => {
        this.setState({ [name]: val });
    }

    handleChange = (event) => {
        console.log(event.target);
        this.setState({ [event.target.name]: event.target.value });
    }

    onDateSelected = (date) => {
        console.log(date);
        this.setState({
            invoiceDate : moment(date.from).format("DD MMM YYYY")
        })
    };

    render(){
        console.log(this.props);
        const {statusHistory, documentSummary} = this.props;
        let status = "PENDING";
        let remark = "";
        if(statusHistory && statusHistory.length > 0){
          const statusObj = statusHistory[0];
          if(statusObj.status === "BANK_APPROVED" || statusObj.status === "BANK_REJECTED"){
            status = statusObj.displayname;
            remark = statusObj.remarks;
          }
        }

        return (
          <div>
            <Modal
              ariaLabelledby="modal-heading"
              classname="myClass"
              height={500}
              isOpen={this.props.isOpen}
              width={600}
            >
              <ModalHeader hasTopLine>
                <ModalTitle>HSBC approval status</ModalTitle>
                <ModalBtnList>
                  <ModalBtn name="delete" title="close" onClick={this.props.close} />
                </ModalBtnList>

              </ModalHeader>
              <ModalContent>
                <Grid container spacing={0}>
                  <Grid item xs={12} style={{paddingBottom:'20px'}}>
                    HSBC approval status:&nbsp;<strong>{status}</strong>
                  </Grid>
                  <Grid item xs={12}>
                    <TextArea disabled name="remark" label="HSBC approval comment" value={remark} />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} style={{paddingRight:'20px', paddingBottom:'20px'}}>
                    <CDLHeading level={3} brandbar>
                      <span className="medium">Document review summary</span>
                    </CDLHeading>
                    <Table
                      defaultPageSizeOption={{ 'value': 0 }}
                      showPagination={false}
                      data={documentSummary}
                      columns={[
                                        {
                                            Header: 'Document title',
                                            accessor: 'doctitle'
                                        },
                                        {
                                            Header: 'Data / time',
                                            accessor: 'updatedate'
                                        },
                                        {
                                            Header: 'Comment',
                                            accessor: 'comments'
                                        },
                                        {
                                            Header: 'By',
                                            accessor: 'updateby'
                                        }
                                    ]}
                    />
                  </Grid>
                </Grid>
              </ModalContent>
              <ModalFooter hasBottomLine>
                <ModalAction>
                  <ModalActionButton>
                    <Button onClick={this.props.close}>Close</Button>
                  </ModalActionButton>
                </ModalAction>
              </ModalFooter>
            </Modal>
          </div>
        )
    }
}
