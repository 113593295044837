import CDLButton from '@cdl/button';
import Grid from '@material-ui/core/Grid';
import AdditionalField from "common/components/AdditionalField/AdditionalField";
import { updateMasterDocByFields } from 'common/redux/actions';
import MasterDocService from "common/services/masterDoc.service";
import React from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";

const Label = styled.div`
  font-size: 12px; 
  font-family: "HSBC-Universe-Medium";
`;

class AdditionalFields extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.getList = this.getList.bind(this);
    this.handleInputValue = this.handleInputValue.bind(this);

  }

  getList = () => {

    MasterDocService.getMasterDocAddField().then(res => {
      if (res) {
        const { length } = Object.keys(res);
        for (let i = 0; i < length; i++) {
          const name = `md_fieldlbl${i + 1}`

          this.props.updateMasterDocByFields({field: name, data: res[Object.keys(res)[i]]});

        }

      }
    });
  }


  close() {
    this.setOpen(false);
  }

  openItemDetail() {
    this.setOpenDetail(true);
  }

  closeItemDetail() {
    this.setOpenDetail(false);
  }

  handleInputValue(name, event) {
    this.setState({
      [name]: event.target.value
    });
  }


  render() {
    return (
      <div style={{ paddingTop: '30px' }}>

        <Grid container spacing={0}>
          <Grid item xs={10} />
          <Grid item xs={2}>
            <div className="rightBtn">
              <CDLButton size="small" onClick={this.getList}>Get list</CDLButton>
            </div>
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={4} style={{ paddingBottom: '10px' }}>
            <Label>Name</Label>
          </Grid>
          <Grid item xs={7}>
            <Label>Value</Label>
          </Grid>

          <AdditionalField
            label="Field 1"
            fieldNameId="md_fieldlbl1"
            fieldValueId="md_field1"
            handleInputName={e => this.handleInputValue("name1", e)}
            handleInputValue={e => this.handleInputValue("val1", e)}
            maxLength={35}
          />
          <AdditionalField
            label="Field 2"
            fieldNameId="md_fieldlbl2"
            fieldValueId="md_field2"
            handleInputName={e => this.handleInputValue("name2", e)}
            handleInputValue={e => this.handleInputValue("val2", e)}
            maxLength={35}
          />
          <AdditionalField
            label="Field 3"
            fieldNameId="md_fieldlbl3"
            fieldValueId="md_field3"
            fieldName={this.state.name3}
            fieldValue={this.state.val3}
            handleInputName={e => this.handleInputValue("name3", e)}
            handleInputValue={e => this.handleInputValue("val3", e)}
            maxLength={35}
          />
          <AdditionalField
            label="Field 4"
            fieldNameId="md_fieldlbl4"
            fieldValueId="md_field4"
            fieldName={this.state.name4}
            fieldValue={this.state.val4}
            handleInputName={e => this.handleInputValue("name4", e)}
            handleInputValue={e => this.handleInputValue("val4", e)}
            maxLength={35}
          />
          <AdditionalField
            label="Field 5"
            fieldNameId="md_fieldlbl5"
            fieldValueId="md_field5"
            fieldName={this.state.name5}
            fieldValue={this.state.val5}
            handleInputName={e => this.handleInputValue("name5", e)}
            handleInputValue={e => this.handleInputValue("val5", e)}
            maxLength={35}
          />
          <AdditionalField
            label="Field 6"
            fieldNameId="md_fieldlbl6"
            fieldValueId="md_field6"
            fieldName={this.state.name6}
            fieldValue={this.state.val6}
            handleInputName={e => this.handleInputValue("name6", e)}
            handleInputValue={e => this.handleInputValue("val6", e)}
            maxLength={35}
          />
          <AdditionalField
            label="Field 7"
            fieldNameId="md_fieldlbl7"
            fieldValueId="md_field7"
            fieldName={this.state.name7}
            fieldValue={this.state.val7}
            handleInputName={e => this.handleInputValue("name7", e)}
            handleInputValue={e => this.handleInputValue("val7", e)}
            maxLength={35}
          />
          <AdditionalField
            label="Field 8"
            fieldNameId="md_fieldlbl8"
            fieldValueId="md_field8"
            fieldName={this.state.name8}
            fieldValue={this.state.val8}
            handleInputName={e => this.handleInputValue("name8", e)}
            handleInputValue={e => this.handleInputValue("val8", e)}
            maxLength={35}
          />
          <AdditionalField
            label="Field 9"
            fieldNameId="md_fieldlbl9"
            fieldValueId="md_field9"
            fieldName={this.state.name9}
            fieldValue={this.state.val9}
            handleInputName={e => this.handleInputValue("name9", e)}
            handleInputValue={e => this.handleInputValue("val9", e)}
            maxLength={35}
          />
          <AdditionalField
            label="Field 10"
            fieldNameId="md_fieldlbl10"
            fieldValueId="md_field10"
            fieldName={this.state.name10}
            fieldValue={this.state.val10}
            handleInputName={e => this.handleInputValue("name10", e)}
            handleInputValue={e => this.handleInputValue("val10", e)}
            maxLength={35}
          />

          <Grid item xs={1} />
        </Grid>
      </div>
    )
  }

}

const mapDispatchToProps = dispatch => {
  return {
    updateMasterDocByFields: (data) => dispatch(updateMasterDocByFields(data))
  }
}

export default connect(null, mapDispatchToProps)(AdditionalFields)
