import BaseService from 'common/services/BaseService';

class CompanyAddFieldService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/CompanyAddFieldBO/';
  }

  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  loadCompanyAddField() {
    const useDTO = this.getUserDTO();
    const requestBody = {
        "moduleid": "DocPrep",
        ...useDTO
    }
    return this.post('loadCompanyAddField', requestBody, true);
  }

  saveCompanyAddField(companyAddFieldDTO) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      "companyaddfield": {
        "company_FieldLb1": companyAddFieldDTO.company_FieldLb1,
        "company_FieldLb2": companyAddFieldDTO.company_FieldLb2,
        "company_FieldLb3": companyAddFieldDTO.company_FieldLb3,
        "company_FieldLb4": companyAddFieldDTO.company_FieldLb4,
        "company_FieldLb5": companyAddFieldDTO.company_FieldLb5,
        "company_FieldLb6": companyAddFieldDTO.company_FieldLb6,
        "company_FieldLb7": companyAddFieldDTO.company_FieldLb7,
        "company_FieldLb8": companyAddFieldDTO.company_FieldLb8,
        "company_FieldLb9": companyAddFieldDTO.company_FieldLb9,
        "company_FieldLb10": companyAddFieldDTO.company_FieldLb10,
        "moduleID": "DocPrep"
      },
      ...userDTO
    }

    return this.post('saveCompanyAddField', requestBody, true);
  }

}

export default new CompanyAddFieldService();
