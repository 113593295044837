import Grid from '@material-ui/core/Grid';
import Footer from "common/components/Footer/Footer";
import Header from "common/components/Header/Header";
import PageHeader from 'common/components/PageHeader/PageHeader';
import InfoMessage from 'common/components/UserMessage/InfoMessage';
import React from 'react';
import UserService from 'common/services/user.service';
import UserMessage from 'common/components/UserMessage/UserMessage';
import {
  CHANGEPWD_STATUSCODE_AUTHORISEDPWDCHG,
  CHANGEPWD_STATUSCODE_INVALIDOLDPASSWORD,
  CHANGEPWD_STATUSCODE_INVALIDNEWPASSWORD,
  CHANGEPWD_STATUSCODE_INVALIDLEGACYPASSWORD,
} from 'common/utils/Constants';
import { Redirect } from 'react-router-dom';
import PwdService from 'common/services/pwd.service';
import store from 'common/redux/store';
import { forceUpdatePWD } from 'common/redux/actions';
import PwdForm from "./PwdForm";

export default class PwdPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      success: "",
      error: "",
      isForceUpdated: false,
    };
  }

  handleResult = (result) => {
    switch (result) {
      case CHANGEPWD_STATUSCODE_AUTHORISEDPWDCHG: {
        this.setState({
          success: "Password has been changed successfully.",
          error: ""
        });
        const user = UserService.getCurrentUser();
        if(PwdService.isForceChange(user.status_code)){
          store.dispatch(forceUpdatePWD());
          this.setState({
            isForceUpdated: true,
          });
        }
        break;
      }
      case CHANGEPWD_STATUSCODE_INVALIDOLDPASSWORD:
        this.setState({
          success: "",
          error: "Invalid password. Please re-enter."
        });
        break;
      case CHANGEPWD_STATUSCODE_INVALIDNEWPASSWORD:
        this.setState({
          success: "",
          error: "Re-selecting the recently used passwords is not allowed. Please try again."
        });
        break;
      case CHANGEPWD_STATUSCODE_INVALIDLEGACYPASSWORD:
        this.setState({
          success: "",
          error: "Please do not reuse the 10 most recent passwords."
        });
        break;
      default:
        this.setState({
          success: "",
          error: "Change Password Failure. Please Contact Administrator."
        });
    }
  }

  render() {
    return (
      <div className="App">
        {!UserService.getCurrentUser() &&
          <Redirect to="/Login" />}
        {this.state.isForceUpdated &&
          <Redirect to={{
            pathname: "/Home",
            state: { isForceUpdated: true }
          }}
          />}
        <Header noNav isLogin history={this.props.history} />
        <PageHeader title="Change password" withLeftArrow noNav isLogin history={this.props.history} />


        <div className="mainContent mainContentPWD">

          <InfoMessage type='cookie'>
            This system may only be accessed by authorised users,
            and that unauthorised access might be considered as a criminal act in certain jurisdictions.
          </InfoMessage>

          {this.state.success!=="" &&
          <div style={{marginTop: '20px'}}>
            <UserMessage
              showCloseButton
              border
              iconName="circle-confirmation-solid"
              iconColor="confirmation"
              type="confirmation"
              handleCloseButtonClick={()=>{this.setState({success:""})}}
              closeText="Close"
            >
              {this.state.success}
            </UserMessage>
          </div>}

          {this.state.error!=="" &&
          <div style={{marginTop: '20px'}}>
            <UserMessage
              showCloseButton
              border
              type="userError"
              iconName="triangle-error"
              iconColor="ERROR"
              handleCloseButtonClick={()=>{this.setState({error:""})}}
              closeText="Close"
            >
              {this.state.error}
            </UserMessage>
          </div>}


          <div style={{paddingTop: '15px'}}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <PwdForm handleResult={this.handleResult} />
              </Grid>

              <Grid item xs={6}>
                <div style={{borderLeft: '0.8px solid black', paddingLeft: '30px',}}>
                  New password must:
                  <ul className="importantNotesUL">
                    <li>At least twelve (12) characters and maximum twenty (20) characters</li>
                    <li>Include at least one Uppercase letters (A-Z)</li>
                    <li>Include at least one Lowercase letters (a-z)</li>
                    <li>Include at least one Numbers (0-9)</li>
                    <li>{"Include at least one Special Characters (!\"#$%&'()*+,-./:;<=>?@[]^_`{|}~)"}</li>
                    <li>Cannot contain consecutive characters (aa, 22, ..)</li>
                    <li>Cannot contain blank (space) character</li>
                    <li>Not be based on the username</li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}


