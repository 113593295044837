import React from 'react';
import UserMessage from './UserMessage';


export default function SuccessMessage(props) {
        return(
          <UserMessage
            showCloseButton
            border
            iconName="circle-confirmation-solid"
            iconColor="confirmation"
            type="confirmation"
            handleCloseButtonClick={props.handleCloseButtonClick}
            closeText="Close"
          >
            {props.message}
          </UserMessage>
                );
}
