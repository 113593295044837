import CDLDataTable from '@cdl/data-table';
import Pagination from 'common/components/Table/Pagination';
import React from 'react';
import styled from "styled-components";

const TableWrapper = styled.div`

    & .rt-table {
        &>.rt-tbody {
            position: relative;
            height: ${props => props.trim ? '225px' : ''};
            &>.rt-tr-group>.rt-tr {
                border-left: 3px solid transparent;     
                
                .rt-expander::after {
                  border: solid #929292;
                  border-width: 0 3px 3px 0;
                  display: inline-block;
                  padding: 3px;
                  transform: rotate(45deg);
                  top: 0;
                  left: -10px;
                }

                .rt-expander.-open::after {
                  transform: rotate(-135deg);
                  top: 4px;
                }
            }
        
            &>.rt-tr-group>.rt-tr.row-selected {
                border-left: 3px solid #00847F;
                background-color: #E5F2F2;
                box-sizing: border-box;
        
                &>.rt-td {
                    border-top: 1px solid #00847F;
                    border-bottom: 1px solid #00847F;
                }

                &>.rt-td:last-child {
                    border-right: 1px solid #00847F;
                }
            }

            &>.rt-tr-group:has(.row-selected-expand) {
              >.rt-tr {
                border-left: 3px solid #00847F;
                background-color: #E5F2F2;
                box-sizing: border-box;
        
                &>.rt-td {
                    border-top: 1px solid #00847F;
                }

                

                &>.rt-td:last-child {
                    border-right: 1px solid #00847F;
                }
              }

              &>div:last-child {
                    border-left: 3px solid #00847F;
                    border-bottom: 1px solid #00847F;
                    border-right: 1px solid #00847F;
              }
            }

            &>.rt-tr-group:hover {
              background-color: ${props => props.hoverSelect ? '#E5F2F2' : null};
            }

        }

        &>.rt-thead>.rt-tr {
            border-left: 3px solid #dee0e1;
        }

        & .rt-td {
          height: 30px;        
        }

        
    }


    & .ReactTable .rt-table .rt-tbody .rt-td {
      padding: 7px 10px 6px;
    }

`;

const _ = require('lodash');

export default class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    shouldComponentUpdate(nextProps){
      if(this.props.shortTable){
        return true;
      }
      return !_.isEqual(this.props, nextProps);
    }

    onSortedChange = () => {
      if (this.props.sortedChange) {
        this.props.sortedChange();
      }

    }

    render() {

        const {showPagination, pageSize, ...rest} = this.props;
        const pSize = pageSize || 10;
        let table = '';
        if(showPagination){
          if(this.props.data?.length<=pSize){
            table = <CDLDataTable
              pageSize={this.props.data?.length}
              showPagination={false}
              {...rest}
            />
          }else{
            table = <CDLDataTable
              PaginationComponent={Pagination}
              onSortedChange={this.onSortedChange}
              pageSize={pSize}
              {...this.props}
              minRows={0}
            />
          }
        }else{
          table = <CDLDataTable
            pageSize={this.props.data.length}
            onSortedChange={this.onSortedChange}
            {...this.props}
            key={this.props.data.length}
          />;
        }

        return (
          <TableWrapper id={this.props.id} trim={this.props.trim} hoverSelect={this.props.hoverSelect}>
            {table}
          </TableWrapper>

        );
    }
}

