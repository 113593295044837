export const isBlank = (value) => {
    if(typeof value === 'string'){
        return value.trim() === '';
    }
    return !value && value !== 0;
}

export const randomString = () => {
    return Math.random().toString(36).substring(2);
}

export const capitalizeFirstLetter = (s) => {
    if (typeof s !== 'string' || s.length === 0) {
        return ''
    }
    if(s.length===1){
        return s.toUpperCase();
    }
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}
