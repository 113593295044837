import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Radio } from '@cdl/radio-group';
import DatePicker from "common/components/DatePicker/DatePicker";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import styled from "styled-components";
import { formatDate } from "common/utils/DateUtil";
import Divider from "common/components/Divider/Divider";

const StyleTimePicker = styled(TimePicker)`
  & .MuiInputBase-input{  
    font-size: 14px;
    width: 182px;
    padding-left: 0.625rem;
  }
`

export default class DatetimeSection extends React.Component {
    constructor(props) {
        super(props);

        const from = new Date();
        const startTime = new Date();
        const endTime = new Date();
        from.setDate(from.getDate() - 30);
        startTime.setHours(0, 0, 0, 0);
        endTime.setHours(23, 59, 0, 0);
        this.state = {
            dateFrom: from,
            dateTo: new Date(),
            timeFrom: startTime,
            timeTo: endTime,
            criteriaType: props.defaultType,
        };
    }

    handleRadioChange = (event) => {
        this.setState({ criteriaType: event.target.value });
    }

    onDateSelected = (name, date) => {
        if (name === 'RangeDatePickerFrom') {
            this.setState(
                {
                    dateFrom: date.from
                }
            );
            console.log("set dateFrom :", formatDate(date.from));
        }

        if (name === 'RangeDatePickerTo') {
            this.setState(
                {
                    dateTo: date.from
                }
            );
            console.log("set dateTo :", formatDate(date.from));
        }

    };

    handleKeyboardTimeChange = (name, time) => {

        if (name === 'timeFrom') {
            this.setState(
                {
                    timeFrom: time
                }
            );
        }

        if (name === 'timeTo') {
            this.setState(
                {
                    timeTo: time
                }
            );
        }

    }

    getTimeString = (time) => {
        if (typeof time !== 'undefined') {
            let hourString = null;
            let minuteString = null;
            if (time.getHours() >= 0 && time.getHours() < 10) {
                console.log("add 0 for padding in hr");
                hourString = `0${time.getHours().toString()}`;
            }
            else {
                hourString = time.getHours().toString();
            }

            if (time.getMinutes() >= 0 && time.getMinutes() < 10) {
                console.log("add 0 for padding in min");
                minuteString = `0${time.getMinutes().toString()}`;
            }
            else {
                minuteString = time.getMinutes().toString();
            }

            console.log("hourString :", hourString);
            console.log("minuteString :", minuteString);
            return `${hourString}:${minuteString}`;
        }
        return null;
    }

    getDatetime = () => {
        const DATEFORMAT = "DD MMM YYYY";
        let dateTimeDTO = {};
        if (this.state.criteriaType === 'all') {
            if(this.props.hideTime){
                dateTimeDTO = {
                    "sdatefrom": " ",
                    "sdateto": " ",
                };
            }else{
                dateTimeDTO = {
                    "sdatefrom": " ",
                    "sdateto": " ",
                    "stimefrom": "",
                    "stimeto": ""
                };
            }
        } else if(this.props.hideTime){
                dateTimeDTO = {
                    "sdatefrom": formatDate(this.state.dateFrom, DATEFORMAT),
                    "sdateto": formatDate(this.state.dateTo, DATEFORMAT),
                };
            }else{
                dateTimeDTO = {
                    "sdatefrom": formatDate(this.state.dateFrom, DATEFORMAT),
                    "sdateto": formatDate(this.state.dateTo, DATEFORMAT),
                    "stimefrom": this.getTimeString(this.state.timeFrom),
                    "stimeto": this.getTimeString(this.state.timeTo)
                };
            }

        return dateTimeDTO;
    }

    render() {
        const { criteriaType } = this.state;
        return (
          <>
            <Divider />

            <Grid container spacing={0} style={{ paddingBottom: '20px', fontSize: '14px' }}>
              <Grid item xs={12}>
                <Radio onChange={this.handleRadioChange} checked={criteriaType === 'all'} id="all" label="All" value="all" />
              </Grid>

              <Grid item xs={12} style={{ paddingTop: '20px' }}>
                <Radio onChange={this.handleRadioChange} checked={criteriaType === 'byDate'} id="byDate" label="By date" value="byDate" />
              </Grid>

              {criteriaType === 'byDate' &&
                <Grid container spacing={0}>
                  <Grid item xs={1} style={{ paddingTop: '35px' }}>
                    <label style={{ marginTop: '30px', paddingTop: '30px', textAlign: "center", height: '50px', fontSize: '14px' }} htmlFor="RangeDatePickerFrom">
                      Date range
                    </label>
                  </Grid>

                  <Grid item xs={2} style={{ paddingTop: '20px', width: '200px', alignSelf: "center", textAlign: "center" }}>
                    <DatePicker
                      id="RangeDatePickerFrom"
                      name="RangeDatePickerFrom"
                      defaultDate={{ from: this.state.dateFrom, to: null }}
                      width="210"
                      onDateSelected={(date) => { this.onDateSelected("RangeDatePickerFrom", date) }}
                    />
                  </Grid>
                  <Grid item xs={1} style={{ paddingTop: '35px', paddingLeft: '33px' }}>
                    <label style={{ paddingTop: '30px', textAlign: "center", height: '50px', fontSize: '14px' }} htmlFor="RangeDatePickerTo"> To </label>
                  </Grid>
                  <Grid item xs={2} style={{ paddingTop: '20px', width: '200px', alignSelf: "center", textAlign: "center" }}>
                    <DatePicker
                      id="RangeDatePickerTo"
                      name="RangeDatePickerTo"
                      defaultDate={{ from: this.state.dateTo, to: null }}
                      width="210"
                      onDateSelected={(date) => { this.onDateSelected("RangeDatePickerTo", date) }}
                    />
                  </Grid>
                  <Grid item xs={5} />


                  <Grid item xs={12} />

                  {!this.props.hideTime &&
                  <>
                    <Grid item xs={1} style={{ paddingTop: '5px' }}>
                      <label style={{ marginTop: '20px', paddingTop: '20px', textAlign: "center", height: '50px', fontSize: '14px' }}>Time range</label>
                    </Grid>
                    <Grid item xs={2} style={{ width: '200px' }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <StyleTimePicker
                          value={this.state.timeFrom}
                          onChange={(time) => this.handleKeyboardTimeChange("timeFrom", time)}
                        />
                      </MuiPickersUtilsProvider>

                    </Grid>

                    <Grid item xs={1} style={{ paddingTop: '5px', alignSelf: "center", textAlign: "center" }}>
                      <label style={{ marginTop: '20px', marginRight: '20px', paddingTop: '20px', textAlign: "center", height: '50px', fontSize: '14px' }}> To </label>
                    </Grid>

                    <Grid item xs={2} style={{ width: '200px' }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <StyleTimePicker
                          value={this.state.timeTo}
                          onChange={(time) => this.handleKeyboardTimeChange("timeTo", time)}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={5} />
                  </>}
                </Grid>}

            </Grid>
          </>
        );
    }
}
