import Beneficiary from "de/views/DocPreparation/Beneficiary";
import BillofExchange from 'de/views/DocPreparation/BillofExchange';
import ExportTradeCollectionAndNegotiationInstruction from 'de/views/DocPreparation/ExportTradeCollectionAndNegotiationInstruction';
import Invoice from "de/views/DocPreparation/Invoice";
import MasterDocument from "de/views/DocPreparation/MasterDocument";
import PackingList from "de/views/DocPreparation/PackingList";
import MasterDocSection from 'de/views/DocPreparation/Sections/MasterDocSection';
import FormPage from 'common/components/FormPage/FormPage';
import LiFung from "common/views/CommonPage/LiFung";
import UserActLog from "common/views/CommonPage/UserActLog";
import HomePage from "common/views/HomePage/HomePage";
import CompanyAdditionalFields from "common/views/CommonPage/CompanyAdditionalFields";
import Drawer from "common/views/CommonPage/Drawer";
import Product from 'common/views/CommonPage/Product';
import Tenor from "common/views/CommonPage/Tenor";
import CollectingBank from "common/views/CommonPage/CollectingBank";
import DataMaintenance from 'common/views/CommonPage/DataMaintenance';
import Drawee from "common/views/CommonPage/Drawee";
import UserMaintenanceSection from "common/views/CommonPage/Sections/UserMaintenanceSection";
import MasterDocOverview from "de/views/DocPreparation/MasterDocOverview";
import DCSummary from "de/views/DocPreparation/DCSummary";
import MDSummary from "de/views/DocPreparation/MDSummary";
import MDTemplate from "de/views/DocPreparation/MDTemplate";

export const moduleMap = {
    "Home" : {component: HomePage, forms: {}},
    "MasterDocOverview" : {
        component: MasterDocOverview,
        forms: {
            "MDSummary": MDSummary,
            "MDTemplate": MDTemplate,
            "DCSummary": DCSummary,
        }
    },
    "DCSummary": {
        component: DCSummary,
        forms: {}
    },
    "GenericDocPrepSupport" : {
        component: FormPage,
        forms: {
            "CollectingBank" : CollectingBank,
            "Drawee" : Drawee,
        }
    },
    "GenericDocPrep" : {
        component: MasterDocument,
        forms: {
            "GenericMasterDocument" : MasterDocSection,
            "GenericInvoice" : Invoice,
            "GenericPackingList" : PackingList,
            "GenericBeneCert" : Beneficiary,
            "GenericBillOfExchange" : BillofExchange,
            "GenericExportInstruction" : ExportTradeCollectionAndNegotiationInstruction,
        }
    },
    "GenericCompanyAccess" : {
        component: FormPage,
        forms: {
            "Drawee" : Drawee,
            "Drawer" : Drawer,
            "Product": Product,
            "Tenor" : Tenor,
            "CollectingBank" : CollectingBank,
            "COAddField" : CompanyAdditionalFields,
            "GenericDataMaintenance" : DataMaintenance,
            "UserMaint": UserMaintenanceSection
        }
    },
   "GenericCompanyReport" : {
       component: FormPage,
       forms: {
        "UserActLog" : UserActLog,
        "LiFung"     : LiFung
        }
    },
}
