import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Autocomplete from "common/components/Autocomplete/Autocomplete";
import Button from "common/components/Button/Button";
import DatePicker from "common/components/DatePicker/DatePicker";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import TextArea from "common/components/TextArea/TextArea";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import { formatDate } from "common/utils/DateUtil";
import { basicValidation, REQUIRED } from 'common/utils/FieldValidation';
import { isBlank } from 'common/utils/StringUtils';
import React from 'react';
import { connect } from 'react-redux';
import InvoiceService from 'common/services/invoice.service';

const moment = require('moment');

const DATE_FORMAT = 'DD MMM yyyy'

class BeneficiaryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: props.data ? { ...props.data} : {bccerttitle: 'Beneficiary Certificate'},
          isEdit: props.data && Object.keys(props.data).length > 0,
          invoiceList: [],
          errors: {}
        };
        console.log(props.masterDoc);
    }

    componentDidMount() {
      const masterDoc = this.props.masterDoc?.values;
      if (!isBlank(masterDoc?.mdocid)) {
        if(isBlank(masterDoc?.invoicedetaildto)){
          InvoiceService.getInvoiceList('INV', masterDoc.mdocid, masterDoc.mdoctype).then(res => {
            if (res && res.statusCode === 200) {
              this.setState({
                invoiceList: res.data.list.map(item => item.invno)
              })
            }
          })
        }else{
          this.setState({
            invoiceList: masterDoc?.invoicedetaildto.map(item => item.invno)
          })
        }
      }
    }

    handleSave = () => {
      const resultData = this.state.data;
      resultData.bcdate = formatDate(resultData.bcdate || new Date());
      if (isBlank(this.state.data.bccerttitle)) {
        this.setState(prevState => {
          prevState.errors.bccerttitle = basicValidation(REQUIRED , prevState.data.bccerttitle);
          return {
            errors: prevState.errors
          }
        });
      } else {
        this.props.save(resultData, this.state.isEdit, this.props.selectedIndex);
      }
    }

    handleChange = (event) => {
      event.persist();
      const {name, value} = event.target;
      this.setState(prevState => {
        prevState.data[name] = value;
        prevState.errors[name] = !isBlank(prevState.data[name]) ? null : prevState.errors[name];
        return {
            data: prevState.data,
            errors: prevState.errors
        }
      });
    }

    onDateSelected = (date) => {
      const {data} = this.state;
      data.bcdate = formatDate(date.from);
      this.setState({
          data
      })
    };

    handleInputValue = (name, val) => {
      const {data} = this.state;
      data[name] = val;
      this.setState({ data });
    }

    render(){
        return (
          <div>
            <Modal
              ariaLabelledby="modal-heading"
              classname="myClass"
              height={500}
              isOpen={this.props.isOpen}
              width={946}
            >
              <ModalHeader hasTopLine>
                <ModalTitle>Beneficiary cert. / miscellaneous doc. details</ModalTitle>
                <ModalBtnList>
                  <ModalBtn name="delete" title="close" onClick={this.props.close} />
                </ModalBtnList>

              </ModalHeader>
              <ModalContent>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <TextFieldForm
                      label="Document title*"
                      validations={["required"]}
                      maxLength={35}
                      name="bccerttitle"
                      value={this.state.data?.bccerttitle}
                      invalidMessage={this.state.errors.bccerttitle}
                      onChange={(event) => this.handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePicker
                      label="Document date*"
                      id="bcdate"
                      name="bcdate"
                      validations={["required"]}
                      defaultDate={{from: this.state.data?.bcdate ? moment(this.state.data?.bcdate, DATE_FORMAT).toDate() : new Date()}}
                      onDateSelected={this.onDateSelected}
                    />
                  </Grid>
                  <Grid item xs={4}>

                    <Autocomplete
                      label="Invoice number"
                      id="bcinvno"
                      name="bcinvno"
                      options={this.state.invoiceList}
                      userInput={this.state.data?.bcinvno}
                      onChange={(value) => this.handleInputValue('bcinvno', value)}
                      maxLength={35}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextArea
                      label="To"
                      name="bcto"
                      maxLength={200}
                      value={this.state.data?.bcto}
                      onChange={(event) => this.handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextArea
                      label="Remarks"
                      name="bcaddinfo2"
                      maxLength={32000}
                      value={this.state.data?.bcaddinfo2}
                      onChange={(event) => this.handleChange(event)}
                      height={140}
                    />
                  </Grid>
                </Grid>
              </ModalContent>
              <ModalFooter hasBottomLine>
                <ModalAction>
                  <ModalActionExtraButton>
                    <Button onClick={this.props.close}>Cancel</Button>
                  </ModalActionExtraButton>
                  <ModalActionButton>
                    <Button themeColor="primary" onClick={this.handleSave}>Save</Button>
                  </ModalActionButton>
                </ModalAction>
              </ModalFooter>
            </Modal>
          </div>
        )
    }
}


const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc
})

export default connect(mapStateToProps, null)(BeneficiaryDetail)
