import React from 'react';
import styled from "styled-components";
import { CircularProgress } from '@material-ui/core';

const LoadingSpinnerWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .color-frame {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        opacity: 0.4;
    }

    .wrapper {
        z-index: 1001;
    }
    
    .MuiCircularProgress-colorSecondary {
        color: red;
    }
`;


export default function LoadingSpinner(props) {

    React.useEffect(() => {
        if (props.showLoading) {
            document.documentElement.classList.add('loading');
        } else {
            document.documentElement.classList.remove('loading');
        }
    });


    return props.showLoading ?
      <LoadingSpinnerWrapper>
        <div className="color-frame" />
        <div className="wrapper">
          <CircularProgress color="secondary" size={60} disableShrink thickness={2} />
        </div>

      </LoadingSpinnerWrapper> : null;
}
