import CDLRadioGroup from '@cdl/radio-group';
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const verticalStyle = `
  & fieldset{
    margin-bottom: 5px;
  }

  & fieldset > div{
    display: block;   
  }

  & fieldset > div > div{
    margin-bottom: 10px;
  }
`;

const Container = styled.div`
    & fieldset{
      margin-bottom: 15px;
    }

    ${props => props.vertical ? verticalStyle : ''};
    
`;


export default function RadioGroup(props) {

  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);


  let elements = React.Children.toArray(props.children);

    if (elements[0].props) {
      elements = elements[0].props.children.map(element => {

        return React.cloneElement(element,
          {checked: element.props.value === value,
            key: element.props.id,
            onChange: props.onChange
           });
      })
    }


    return (
      <Container vertical={props.vertical}>
        <CDLRadioGroup name={props.name} id={props.id}>{elements}</CDLRadioGroup>
      </Container>
    );
}

