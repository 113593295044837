/* eslint-disable import/no-extraneous-dependencies */
import CDLIcon from "@cdl/icon";
import Grid from '@material-ui/core/Grid';
import ActionButtons from "common/components/ActionButtons/ActionButtons";
import Dropdown from 'common/components/Dropdown/Dropdown';
import IconButton from "common/components/IconButton/IconButton";
import Table from "common/components/Table/Table";
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import DraweeService from "common/services/drawee.service";
import UserMaintService from 'common/services/user-maint.service';
import { isAC } from "common/utils/AppUtil";
import AddImporterConsigneeFowardingAgent from 'common/views/Popup/AddImporterConsigneeFowardingAgent';
import Msg from "common/views/Popup/Msg";
import React from 'react';

const buttonArray = [
  { id: 'openEdit', name: 'Edit', showModal: 'openEditDraweePopup' },
  { id: 'openDelete', name: 'Delete', showModal: 'showDeleteRequestMessage' }
];

const tableArray = [
  {
    Header: 'Drawee ID',
    accessor: 'importerCode',
    maxWidth: 230
  },
  {
    Header: 'Drawee name',
    accessor: 'impName',
    maxWidth: 400,
    Cell: row => <span title={row.original.impName}>{row.original.impName}</span>
  },
  {
    Header: 'Email address',
    accessor: 'impEmail',
    Cell: row => <span title={row.original.impEmail}>{row.original.impEmail}</span>
  }
];

const initialFilters = {
  indraweecompanydto: {
    coDocExpressID: "",
    coName: ""
  },
  indraweeprocctrdto: {
    processCtrID: "",
    processCtrName: ""
  }
}

const caFlag = isAC();

export default class Drawee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defProcCtrlOptions: [],
      defCompanyOptions: [],
      tableData: [],
      filters: initialFilters,
      showFilter: true
    };
  }

  componentDidMount() {
    this.fetchProcCtrlOptions();
  }

  fetchCompanyOptions = async () => {
    DraweeService.loadDrawee(this.state.filters).then(response => {
      let opt = [];
      if (response && response.defCompany) {
        opt =  response.defCompany.map((element) => {
          return {label: `${element.coDocExpressID  } - ${  element.coName}`, value: element.coDocExpressID, isLFmode: element.isLFmode}
        });
      }
      this.setState(prevState => {
        const {filters} = prevState;
        filters.indraweecompanydto = { coDocExpressID: opt[0]?.value, coName: opt?.[0]?.label.split(' - ')[1] };
        return {
          defCompanyOptions: opt,
          filters
        }
      },
      this.fetchTableData);
    }).catch(error => {
      throw error;
    });

  }

  fetchProcCtrlOptions = () => {
    UserMaintService.loadProCtr().then(response => {
      let opt = [];
      if (response && response.list) {
        opt = response.list.map((element) => {
          return {label: element.proctridname, value: element.proctrid}
        });
      }
      this.setState(prevState => {
        const {filters} = prevState;
        filters.indraweeprocctrdto = { processCtrID: opt[0]?.value, processCtrName: opt[0]?.label };
        return {
          defProcCtrlOptions: opt,
          filters,
        }
      },
      this.fetchCompanyOptions);
    }).catch(error => {
      throw error;
    });

  }


  fetchTableData = () => {
    DraweeService.loadDrawee(this.state.filters).then(response => {
      if (response && response.defDrawee) {
        this.setState({
          tableData: response.defDrawee,
          showAddNew: !response.showNewBtn,
        })
      }
    }).catch(error => {
      throw error;
    });
  }


  fetchEditData = () =>  {
      const {tableData, selectedIndex} = this.state;
      const selectedDTO = caFlag ? tableData.find((item, index) => index === selectedIndex) : tableData.find((item, index) => index === selectedIndex)?.importerCode;
      DraweeService.getDrawee(selectedDTO).then(response => {
        if (response) {
          this.setState({
            editProps: response,
            openDraweePopup: true,
          })
        }
      }).catch(error => {
        throw error;
      });
  }


  showSuccessMessage = (msg) => {
    this.setState({
      message: msg,
      success: true,
      isEdit: false,
      openDeletePopup: false,
    },
    this.fetchTableData)
  }

  handleAutocomplete = name => value => {
    if (name === 'indraweecompanydto') {
      this.setState(prevState => {
        const {filters} = prevState;
        filters.indraweecompanydto = {coDocExpressID: value.value, coName: value.label.split(' - ')[1]};
        return {
          filters
        }
      },
      this.fetchTableData);
    } else if (name === 'indraweeprocctrdto') {
      this.setState(prevState => {
        const {filters} = prevState;
        filters.indraweeprocctrdto = {processCtrID: value.value, processCtrName: value.label};
        return {
          filters
        }
      },
      this.fetchCompanyOptions);
    }
  }

  openEditDraweePopup = (rowIndex) => {
    this.setState({
      selectedIndex: rowIndex,
      isEdit: true,
    },
    this.fetchEditData);
  }

  showDeleteRequestMessage = (rowIndex) => {
    this.setState({
      selectedIndex: rowIndex,
      openDeletePopup: true,
    });
  }

  closeDeletePopup = () => {
    this.setState({
      selectedIndex: null,
      openDeletePopup: false,
    });
  }

  closeDraweePopup = () => {
    this.setState({
      isEdit: false,
      openDraweePopup: false,
    });
  }

  closeMessage = () => {
    this.setState({
      message: '',
      success: false,
    });
  }

  getDisplayName = () => {
    const {tableData, selectedIndex} = this.state;
    let displayName = `"${tableData[selectedIndex]?.importerCode}"`;
    if(tableData[selectedIndex]?.impName){
      displayName += ` - "${tableData[selectedIndex].impName}"`;
    }
    return displayName;
  }

  deleteDrawee = () => {
    const {tableData, selectedIndex} = this.state;
    const selectedItem = tableData.find((item, index) => index === selectedIndex);

    DraweeService.deleteDrawee(selectedItem).then(response => {
      if (response) {
        this.showSuccessMessage(`Drawee ${this.getDisplayName()} has been deleted successfully`);
      }
    })
  }

  toggleFilter = () => {
    this.setState(prevState=>{
      return {
        showFilter: !prevState.showFilter
      }
    })
  }

  renderFilter = () => {
    const {defProcCtrlOptions, defCompanyOptions, filters, showFilter} = this.state;
    const companyVal = filters.indraweecompanydto?.coName ? `${filters.indraweecompanydto?.coDocExpressID} - ${filters.indraweecompanydto?.coName}` : '';

    if(caFlag){
      return (
        <>
          <IconButton
            transparent
            noBorder
            name='filter'
            size={14}
            onClick={this.toggleFilter}
          >
            Filter
            {showFilter &&
            <CDLIcon name="chevron-up-small" /> ||
            <CDLIcon name="chevron-down-small" />}
          </IconButton>
          {showFilter &&
          <Grid container spacing={0} style={{ paddingTop: '20px', paddingBottom: '30px', paddingLeft: '20px', paddingRight: '20px', backgroundColor: '#ededed' }}>
            <Grid container style={{ backgroundColor: '#eee' }}>
              <Grid item xs={6}>
                <Dropdown
                  seamless
                  id="processing-centre"
                  options={defProcCtrlOptions}
                  onChange={this.handleAutocomplete("indraweeprocctrdto")}
                  placeHolder={filters.indraweeprocctrdto?.processCtrName}
                  value={filters.indraweeprocctrdto?.processCtrName}
                  labelInside="Processing centre"
                />
              </Grid>
              <Grid item xs={6}>
                <Dropdown
                  seamless
                  id="company-name"
                  options={defCompanyOptions}
                  onChange={this.handleAutocomplete("indraweecompanydto")}
                  placeHolder={companyVal}
                  value={companyVal}
                  labelInside="Company"
                />
              </Grid>
            </Grid>
          </Grid>}
        </>)
    }

    return <></>;

  }

  render(){
    const {tableData, openDeletePopup, success, message, filters, showAddNew, openDraweePopup, editProps, isEdit } = this.state;

    const displayData = tableData.length > 0 ? tableData.filter(d => !d.deleted).map((t, i) => {
      return Object.assign(t, {actionBtn: <ActionButtons
        rowIndex={i}
        buttonArray={buttonArray}
        openEditDraweePopup={this.openEditDraweePopup}
        showDeleteRequestMessage={this.showDeleteRequestMessage}
        justify='flex-start'
      />});
    }) : [];

    return (
      <div>
        {openDeletePopup &&
        <Msg
          title="Are you sure?"
          content={`Do you wish to delete the drawee ${this.getDisplayName()}?`}
          width={500}
          height={300}
          confirm={this.deleteDrawee}
          isOpen={openDeletePopup}
          close={this.closeDeletePopup}
          warning
        />}

        <Grid container spacing={0} style={{ paddingTop: '20px' }}>
          {success &&
            <Grid item xs={12}>
              <SuccessMessage message={message} handleCloseButtonClick={this.closeMessage} />
            </Grid>}

          {this.renderFilter()}

          {(caFlag ||showAddNew) &&
            <Grid container spacing={0}>
              <Grid item xs={10} style={{ paddingTop: caFlag ? '10px' : '0px' }} />
              <Grid item xs={2}>
                <div className="rightBtn" style={{ paddingRight: '0px', paddingTop: caFlag ? '20px' : '0px' }}>
                  <IconButton disabled={caFlag && !filters?.indraweecompanydto?.coDocExpressID} onClick={() => this.setState({openDraweePopup:true})} name="add">
                    Add new drawee
                  </IconButton>
                </div>
              </Grid>
            </Grid>}
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Table
              maxRows={12}
              showPagination={false}
              defaultPageSizeOption={{'value':0}}
              data={displayData}
              columns={caFlag ? tableArray.concat(
                [{
                  Header: 'Wolf status',
                  accessor: 'wolfStatus',
                  width: 128
                },
                {
                  Header: 'Action',
                  accessor: 'actionBtn',
                  maxWidth: 120,
                  sortable: false
                }]) : tableArray.concat(
                [{
                  Header: 'Action',
                  accessor: 'actionBtn',
                  maxWidth: 140,
                  sortable: false
                }])}
            />
          </Grid>
        </Grid>
        {openDraweePopup &&
          <AddImporterConsigneeFowardingAgent
            id="drawee"
            isOpen={openDraweePopup}
            edit={isEdit}
            editProps={editProps}
            filters={filters}
            close={this.closeDraweePopup}
            success={this.showSuccessMessage}
            hasEditRight={showAddNew}
            coDocExpressID={filters.indraweecompanydto?.coDocExpressID}
            company={filters.indraweecompanydto}
          />}
      </div>
    );
  }
}
