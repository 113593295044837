import BaseService from 'common/services/BaseService';


class EDSService extends BaseService {

    chkForEDSValues(mdocid, isEDS) {
        const requestBody = {
            "mdocid": mdocid,
            "dc_dadp_eds": isEDS,
            ...this.getUserDTO()
        }

        return this.post('/api/EdsBO/chkForEDSValues', requestBody);
    }

    validateVariousField(mdocid) {
        const requestBody = {
            "mdocid": mdocid,
            ...this.getUserDTO()
        }

        return this.post('/api/EdsBO/validateVariousField', requestBody);
    }

    updateFinancedAmt(amount, mdocid) {
        const requestBody = {
            "financedamt": amount,
            "mdocid": mdocid,
            ...this.getUserDTO()
        }

        return this.post('/api/EdsBO/updateFinancedAmt', requestBody);
    }

    updateEDSValues(mdocid, value) {
        const requestBody = {
            "mdocid": mdocid,
            "updateedsval": value,
            ...this.getUserDTO()
        }

        return this.post('/api/EdsBO/updateEDSValues', requestBody);
    }
}

export default new EDSService();
