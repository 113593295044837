import React, { useState, useEffect } from 'react';
import ActionButtons from "common/components/ActionButtons/ActionButtons";
import CompanyAdditionalFieldsDetail from "common/views/Popup/CompanyAdditionalFieldsDetail";
import Grid from '@material-ui/core/Grid';
import Table from 'common/components/Table/Table';
import CompanyAddFieldService from 'common/services/company-add-field.service';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';


const buttonArray = [
  { id: 'openEdit', name: 'Edit', showModal: 'openEditCompanyAdditionalPopup' },
  { id: 'openDelete', name: '', showModal: 'openEditCompanyAdditionalPopup' },
];

export default function CompanyAdditionalFields() {
  const [tableData, setTableData] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openAddCompanyAdditionalPopup, setAddCompanyAdditionalPopupOpen] = useState(false);
  const [editProps, setEditProps] = useState({});
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [openMessageDialog, setMessageDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    const fetchTableData = () => {
      CompanyAddFieldService.loadCompanyAddField().then(response => {
        if (response && response.list) {
          response.list.forEach(element => {
            element.description = "Document Preparation"
          });
          setTableData(response.list);
        }
      }).catch(error => {
        throw error;
      });
    }
    fetchTableData();
  }, [isRefresh]);

  // use async await pair if necessary
  useEffect(() => {
    const findEditData = () =>  {
      const selectedItem = tableData.find((item, index) => index === selectedIndex);

      if (selectedItem) {
        setEditProps(selectedItem);
      }
      setAddCompanyAdditionalPopupOpen(true);
    }
    if (isEdit) {
      findEditData();
    }

  }, [isEdit]);

  const showSuccessMessage = (result, cat, msg) => {
    if (result) {
      setMessage(msg);
      if (cat === 'editAdditionalData')
        {setSuccess(true);}
      setMessageDialogOpen(true);
      setIsRefresh(!isRefresh);
      setIsEdit(false);
    }
  }

  const openEditCompanyAdditionalPopup = (rowIndex) => {
    setSelectedIndex(rowIndex);
    setIsEdit(true);
  }

  function closeAddCompanyAdditionalPopup() {
    setIsEdit(false);
    setAddCompanyAdditionalPopupOpen(false);
  }

  const closeMessage = () => {
    setMessage("");
    setMessageDialogOpen(false);
  }

  const displayData = tableData.map((t, i) => {
      return Object.assign(t, {actionBtn: <ActionButtons
        rowIndex={i}
        buttonArray={buttonArray}
        openEditCompanyAdditionalPopup={openEditCompanyAdditionalPopup}
        justify='flex-start'
      />});
  });

  return (
    <div>
      <Grid container spacing={0} style={{paddingTop: '30px'}}>
        <CompanyAdditionalFieldsDetail
          isOpen={openAddCompanyAdditionalPopup}
          edit={isEdit}
          editProps={editProps}
          close={closeAddCompanyAdditionalPopup}
          success={showSuccessMessage}
        />
        {success && openMessageDialog &&
          <Grid item xs={12}>
            <SuccessMessage message={message} handleCloseButtonClick={closeMessage} />
          </Grid>}
        <Grid item xs={12}>
          <Table
            maxRows={12}
            showPagination={false}
            defaultPageSizeOption={{'value': 0}}
            data={displayData}
            columns={[
              {
                Header: 'Module ID',
                accessor: 'moduleID'
              },
              {
                Header: 'Module description',
                accessor: 'description'
              },
              {
                Header: 'Action',
                accessor: 'actionBtn',
                maxWidth: 140,
                sortable: false
              }
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
}
