import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import React from 'react';
import EDSService from "common/services/eds.service";
import NumberField from "common/components/NumberField/NumberField";

export default class FinancedAmount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invalidMessage: "",
            invalid: false,
            financedAmt: Number(props.maxAmount),
        };
    }


    handleSave = () => {
        console.log(this.state.financedAmt, this.props.maxAmount)
        if(this.state.financedAmt <= 0){
            this.setState({
                invalidMessage: "Amount financed cannot be zero or less than zero.",
                invalid: true,
            });
        }else if(this.state.financedAmt > Number(this.props.maxAmount)){
            this.setState({
                invalidMessage: "Amount financed cannot exceed the bill amount specified for this Collection Order.",
                invalid: true,
            });
        }else{
            EDSService.updateFinancedAmt(this.state.financedAmt, this.props.mdocid).then(res => {
                if(res && res>0){
                    this.props.save(true);
                }
            });
            this.props.close();
        }

    }


    handleChange = (name, value) => {
        this.setState({
          [name]: value,
          invalidMessage: "",
          invalid: false,
        });
    }


    render(){
        console.log(this.props, this.state);
        return (
          <div>
            <Modal
              ariaLabelledby="modal-heading"
              isOpen={this.props.isOpen}
              width={450}
            >
              <ModalHeader>
                <ModalTitle>Financed amount</ModalTitle>
                <ModalBtnList>
                  <ModalBtn name="delete" title="close" onClick={this.props.close} />
                </ModalBtnList>

              </ModalHeader>
              <ModalContent>
                <Grid container spacing={0}>
                  <Grid item xs={12} style={{paddingBottom:'20px'}}>
                    <NumberField
                      label="Amount"
                      name="financedAmt"
                      maxLength={15}
                      value={this.state.financedAmt}
                      onChange={this.handleChange}
                      allowNegative={false}
                      invalidMessage={this.state.invalidMessage}
                      invalid={this.state.invalid}
                      decimalScale={this.props.decimalScale}
                    />
                  </Grid>

                </Grid>

              </ModalContent>
              <ModalFooter hasBottomLine>
                <ModalAction>
                  <ModalActionExtraButton>
                    <Button onClick={this.props.close}>Cancel</Button>
                  </ModalActionExtraButton>
                  <ModalActionButton>
                    <Button themeColor="primary" onClick={this.handleSave}>Save</Button>
                  </ModalActionButton>
                </ModalAction>
              </ModalFooter>
            </Modal>
          </div>
        )
    }
}
