import CDLButton from '@cdl/button';
import React from 'react';
import styled from "styled-components";

const Container = styled.span`
    &> button{
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        padding: 0 20px;
    }
`;


export default function Button(props) {
    return (
      <Container>
        <CDLButton {...props} />
      </Container>
    );
}

